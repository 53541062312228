import React, { useEffect, useState } from 'react';
import { Breadcrumb, Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useToasts } from 'react-toast-notifications';
import { APIMethod, FileUploadMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import moment from "moment";
import ModalPopup from '../../../../components/ModalPopUp';
import DeleteModal from '../../../../components/DeleteModal';
import ReactModal from 'react-modal-resizable-draggable';

const EditTaskOrder = (props) => {
    const [formFields, setFormFields] = useState({
        companyName: '', taskOrderContraints: '', projectDescription: '', projectScope: '', taskOrderCommentsArea: '', title: '',
        task_order_number: '', version: '', description: '', document_type: '', task_order_start_date: undefined,
        contract_end_date: undefined, contract_due_date: undefined
    })
    const [editData, setEditData] = useState()
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    const [taskOrderId, setTaskOrderId] = useState('')
    const [documentList, setDocumentList] = useState([]);
    // Toast
    const { addToast } = useToasts();
    const [flag, setFlag] = useState(false);
    // Add modal box
    const [show, setShow] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);
    const [modalButtonLoader, SetModalButtonLoader] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [addDocuments, setAddDocuments] = useState([]);
    // file
    const [file, setFile] = useState();
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [deleteIndex, setDeleteIndex] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        if (props.location.state == null) {
        } else {
            getTaskOrderDetail(props.location.state.details.id)
            setTaskOrderId(props.location.state.details.id)
            getDocumentTypeList()
        }
    }, [])

    const getTaskOrderDetail = (id) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/task-order/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    setEditData(data)
                    setFormFields({
                        ...formFields, task_order_number: data.task_order_number, title: data.task_order_title, companyName: data.company_name,
                        taskOrderContraints: data.contraints, projectDescription: data.description, projectScope: data.scope, taskOrderCommentsArea: data.comments,
                        task_order_start_date: moment(data.start_date, 'YYYY-MM-DD').toDate(), contract_end_date: moment(data.end_date, 'YYYY-MM-DD').toDate(),
                        contract_due_date: moment(data.due_date, 'YYYY-MM-DD').toDate()
                    })
                    setAddDocuments(data.attachments)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const getDocumentTypeList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentList(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const filehandler = event => {
        setFile(event.target.files[0])
    }

    const save = () => {
        var start_date = moment(formFields.task_order_start_date).format('YYYY-MM-DD')
        var end_date = moment(formFields.contract_end_date).format('YYYY-MM-DD')
        var due_date = moment(formFields.contract_due_date).format('YYYY-MM-DD')
        if (formFields.task_order_number == '' || formFields.title == '' || formFields.companyName == '' || formFields.taskOrderContraints == '' ||
            formFields.projectDescription == '' || formFields.projectScope == '' || formFields.taskOrderCommentsArea == '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (addDocuments.length == 0) {
            setFlag(true)
            addToast('Please add document', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            var method = 'PUT'
            var params = JSON.stringify({
                id: taskOrderId,
                pre_rfx_id: editData?.pre_rfx_id,
                contract_id: editData?.contract_id,
                rfx_number: editData?.rfx_number,
                contact_number: editData?.contact_number,
                task_order_number: formFields.task_order_number,
                task_order_title: formFields.title,
                start_date: start_date,
                end_date: end_date,
                due_date: due_date,
                company_name: formFields.companyName,
                contraints: formFields.taskOrderContraints,
                description: formFields.projectDescription,
                scope: formFields.projectScope,
                comments: formFields.taskOrderCommentsArea,
                attachments: addDocuments
            })
            const apifetcherObj = APIMethod(`/task-order`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode == 200) {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        setFormFields({ ...formFields, task_order_number: '', title: '', companyName: '', taskOrderContraints: '', projectDescription: '', projectScope: '', taskOrderCommentsArea: '' })
                        goBack()
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    }
                })
        }
    }

    const _onAddDocument = () => {
        if (formFields.version === '' || formFields.description === '' || file === undefined || formFields.document_type === '') {
            setModalFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            if (file.type) {
                SetModalButtonLoader(true)
                var method = 'POST'
                var form = new FormData();
                form.append('file', file);
                const apifetcherObj = FileUploadMethod(`/document/attachment/upload`, form, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]
                        var name = ''
                        if (statusCode === 200) {
                            for (var i in documentList) {
                                if (documentList[i].id == formFields.document_type) {
                                    name = documentList[i].name
                                }
                            }
                            var obj = {
                                document_id: formFields.document_type,
                                document_type_name: name,
                                file_name: file.name,
                                version: formFields.version,
                                file: data.file,
                                description: formFields.description
                            }

                            if (editIndex >= 0) {
                                for (var index in addDocuments) {
                                    if (index == editIndex) {
                                        addDocuments.splice(editIndex, 1);
                                        addDocuments.splice(editIndex, 0, obj);
                                        setEditIndex(-1)
                                        setAddDocuments(addDocuments)
                                        break;
                                    }
                                }
                            } else {
                                if (addDocuments.length > 0) {
                                    for (var j in addDocuments) {
                                        if (addDocuments[j].document_id == obj.document_id) {
                                            alert('you can not add this with document type')
                                            SetModalButtonLoader(false)
                                            return;
                                        }
                                    }
                                }
                                addDocuments.push(obj)
                                setAddDocuments(addDocuments)
                            }
                            setShow(!show)
                            setEditIndex(-1)
                            setFile('')
                            setFormFields({ ...formFields, version: '', description: '', document_type: '' })
                            SetModalButtonLoader(false)
                        } else {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        }
                    })
                    .catch(error => {
                        SetModalButtonLoader(false)
                    })
            } else {
                var name = ''
                for (var i in documentList) {
                    if (documentList[i].id == formFields.document_type) {
                        name = documentList[i].name
                    }
                }
                for (var i in addDocuments) {
                    if (i == editIndex) {
                        addDocuments[i].document_id = formFields.document_type,
                            addDocuments[i].document_type_name = name,
                            addDocuments[i].file_name = file.name,
                            addDocuments[i].version = formFields.version,
                            addDocuments[i].description = formFields.description
                    }
                }
                setShow(!show)
                setEditIndex(-1)
                setFormFields({ ...formFields, document_id: '', fileName: '', version: '', description: '' })
                setFile('')
            }
        }
    }

    const handleDate = (date, type) => {
        const obj = {
            ...formFields,
            [type]: date
        }
        setFormFields(obj);
    }

    const _onDeleteAttachment = (id, index) => {
        if (id) {
            setDeleteId(id)
            isShowPopup(true)
        } else {
            setDeleteModal(!deleteModal)
        }
    }

    const onCancelModal = () => {
        setFormFields({ ...formFields, document_type: "", version: "", description: "" })
        setShow(!show)
        setEditIndex(-1)
        setFile('')
    }

    const _onEditDocument = (index, data) => {
        setFormFields({ ...formFields, document_type: data.document_id, version: data.version, description: data.description })
        setShow(!show)
        setEditIndex(index)
        setFile({ name: data.file_name })
    }

    const getData = () => {
        getTaskOrderDetail(props.location.state.details.id)
    }

    const deleteLocalDocument = () => {
        setDeleteIndex(deleteIndex)
        addDocuments.splice(deleteIndex, 1);
        var temp = [];
        for (var j in addDocuments) {
            temp.push(addDocuments[j])
            if (j == addDocuments.length - 1) {
                setAddDocuments(temp)
                setDeleteModal(!deleteModal)
            }
        }
        if (addDocuments.length == 0) {
            setAddDocuments(temp)
            setDeleteModal(!deleteModal)
        }
        setDeleteIndex('')

    }

    const isDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Contracts</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>Edit Contract</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edit Task Order</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <h2 className="page-title ">Edit Task Order</h2>

                    <div className="form-wraper form-style mb-4">
                        <div className="row">
                            <div className="col-12">
                                <p className="font-medium">Task Order Information</p>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Rfx Number</label>
                                    <input type="text" className="form-control" value={editData?.rfx_number} disabled style={{ backgroundColor: 'white' }} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Contract Number</label>
                                    <input type="text" className="form-control" value={editData?.contact_number} disabled style={{ backgroundColor: 'white' }} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Task Order Number</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="task_order_number" value={formFields['task_order_number']} />
                                    <span className="text-danger">{flag === true && formFields.task_order_number === '' ? 'Please Enter Task Order Number' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Task Order Title</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="title" value={formFields['title']} />
                                    <span className="text-danger">{flag === true && formFields.title === '' ? 'Please Enter Task Order Title' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Task Order Start Date</label>
                                    <DatePicker
                                        selected={formFields.task_order_start_date}
                                        onChange={(date) => handleDate(date, 'task_order_start_date')}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Task Order End Date</label>
                                    <DatePicker
                                        selected={formFields.contract_end_date}
                                        onChange={(date) => handleDate(date, 'contract_end_date')}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Task Order Due Date</label>
                                    <DatePicker
                                        selected={formFields.contract_due_date}
                                        onChange={(date) => handleDate(date, 'contract_due_date')}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Client Agency/Company Name</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="companyName" value={formFields['companyName']} />
                                    <span className="text-danger">{flag === true && formFields.companyName === '' ? 'Please Enter Client Agency/Company Name' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Task Order Contraints</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="taskOrderContraints" value={formFields['taskOrderContraints']} />
                                    <span className="text-danger">{flag === true && formFields.taskOrderContraints === '' ? 'Please Enter Task Order Contraints' : null}</span>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Project Description</label>
                                    <textarea rows="5" className="form-control h-auto" onChange={handleChange} name="projectDescription" value={formFields['projectDescription']}></textarea>
                                    <span className="text-danger">{flag === true && formFields.projectDescription === '' ? 'Please Enter Project Description' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Project Scope</label>
                                    <textarea rows="5" className="form-control h-auto" onChange={handleChange} name="projectScope" value={formFields['projectScope']}></textarea>
                                    <span className="text-danger">{flag === true && formFields.projectScope === '' ? 'Please Enter Project Scope' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Task Order Comments</label>
                                    <textarea rows="5" className="form-control h-auto" onChange={handleChange} name="taskOrderCommentsArea" value={formFields['taskOrderCommentsArea']}></textarea>
                                    <span className="text-danger">{flag === true && formFields.taskOrderCommentsArea === '' ? 'Please Enter Task Order Comments' : null}</span>
                                </div>
                            </div>

                            <div className="col-12 my-3">
                                <div className="d-flex flex-row justify-content-between">
                                    <p className="font-medium"> Attachments:</p>
                                    <button type="button" className="btn btn-brand-1 btn-h-40 bg-success px-4 mb-4" onClick={() => setShow(!show)}>+ Add</button>
                                </div>
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">
                                    <tr>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        <th>Version</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>

                                    {
                                        addDocuments.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{res.document_type_name}</td>
                                                    <td>{res.file_name}</td>
                                                    <td>{res.version}</td>
                                                    <td>{res.description}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-action" onClick={() => window.open(res.file, "_blank")}><i className="icofont-eye-alt"></i></button>
                                                        <button type="button" className="btn btn-action edit mr-1 ml-1" onClick={() => _onEditDocument(i, res)}><i className="icofont-pencil-alt-2"></i></button>
                                                        <button type="button" className="btn btn-action delete" onClick={() => _onDeleteAttachment(res.id, i)}><i className="icofont-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                            {
                                <ModalPopup
                                    showModalPopup={showModalPopup}
                                    onPopupClose={isShowPopup}
                                    id={deleteId}
                                    endPoint={'/task-order/document/'}
                                    updateList={getData}
                                ></ModalPopup>
                            }
                            {
                                <DeleteModal
                                    showModalPopup={deleteModal}
                                    onPopupClose={isDeleteModal}
                                    index={deleteIndex}
                                    deleteData={deleteLocalDocument}
                                ></DeleteModal>
                            }
                            <div className="col-12 text-center mt-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={goBack}>Cancel</button>
                                <button type="button" className="btn btn-lg btn-brand-1 mr-3" onClick={() => save()}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL */}
            <ReactModal
                initWidth={500}
                initHeight={'auto'}
                top={450}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setShow(!show)}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{editIndex != '-1' ? 'Edit' : 'Add'} Documents</h5>
                    <button type="button" className="close" onClick={() => onCancelModal()}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Version</label>
                                <input type="text" className="form-control" onChange={handleChange} name="version" value={formFields['version']} />
                                <span className="text-danger">{modalFlag === true && formFields.version === '' ? 'Please Enter Version' : null}</span>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="description" value={formFields['description']}></textarea>
                                    <span className="text-danger">{modalFlag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Document Type</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['document_type']} name="document_type" onChange={handleChange}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        documentList.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{modalFlag === true && formFields.document_type_id === '' ? 'Please Select Document Type' : null}</span>
                            </div>
                            <div className="form-group">
                                <label>Upload File</label>
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="inputGroupFile02" onChange={filehandler} />
                                    <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file === undefined ? 'Choose File' : file.name}</label>
                                    <span className="text-danger">{modalFlag === true && file === undefined ? 'Please Select file' : null}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => onCancelModal()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onAddDocument()} disabled={modalButtonLoader}>
                        {modalButtonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>
        </>
    )
}

export default EditTaskOrder;