import React, { useEffect, useState } from "react";
import { Breadcrumb, Collapse } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from '../../../../components/Loader';
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import { useToasts } from 'react-toast-notifications';
import moment from "moment";
import { useParams } from "react-router-dom";

const AddRole = () => {
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    const [roleName, setRoleName] = useState('');
    const [description, setDescription] = useState('');

    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [listData, setListData] = useState([]);
    const [flag, setFlag] =useState(false);
    // Toast
    const { addToast } = useToasts();
    const { id } = useParams();

    useEffect(() => {
        getList()
    }, [])

    const handleClick = (index) => {
        var temp = [];
        for (var i in listData) {
            if (index == i) {
                listData[index].isExpand = !listData[index].isExpand;
            }

            temp.push(listData[i]);
        }
        setListData(temp);
    };

    const getList = () => {
        setLoading(true)
        var method = 'GET'
        var Edit_id = id === undefined ? 'default/list' : id
        const apifetcherObj = APIMethod(`/roles/${Edit_id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]


                if (statusCode === 200) {
                    for (var index in data.role_access) {
                        data.role_access[index].isExpand = true;
                        var is_create = true, is_update = true, is_delete = true, is_view = true;
                        for (var j in data.role_access[index].access) {
                            if (!data.role_access[index].access[j].is_create) {
                                is_create = false;
                            }

                            if (!data.role_access[index].access[j].is_update) {
                                is_update = false;
                            }

                            if (!data.role_access[index].access[j].is_delete) {
                                is_delete = false;
                            }

                            if (!data.role_access[index].access[j].is_view) {
                                is_view = false;
                            }
                        }
                        data.role_access[index].is_create = is_create;
                        data.role_access[index].is_update = is_update;
                        data.role_access[index].is_delete = is_delete;
                        data.role_access[index].is_view = is_view;
                    }

                    setListData(data.role_access)
                    setRoleName(id === undefined ? '' : data.name)
                    setDescription(id === undefined ? '' : data.description)
                    if (id === undefined) {
                        setStartDate(new Date());
                    } else {
                        const IsoDateTo = moment(data.effective_date, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss');
                        setStartDate(new Date(IsoDateTo))
                    }
                    setLoading(false)
                    setFlag(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setLoading(false)
                }
            })
            .catch(error => {
                alert(error)
                setLoading(false)
            })
    }

    function select(index, j, type) {
        var temp = [];
        for (var i in listData) {
            if (index == i) {
                if (type == 'is_create') {
                    listData[index].access[j].is_create = !listData[index].access[j].is_create
                }

                if (type == 'is_update') {
                    listData[index].access[j].is_update = !listData[index].access[j].is_update
                }

                if (type == 'is_delete') {
                    listData[index].access[j].is_delete = !listData[index].access[j].is_delete
                }

                if (type == 'is_view') {
                    listData[index].access[j].is_view = !listData[index].access[j].is_view
                }
            }

            temp.push(listData[i]);
        }

        setListData(temp);
    }
    function selectAll(index, type) {
        var temp = [];
        for (var i in listData) {
            if (index == i) {
                if (type == 'is_create') {
                    listData[index].is_create = !listData[index].is_create;
                }

                if (type == 'is_update') {
                    listData[index].is_update = !listData[index].is_update;
                }

                if (type == 'is_delete') {
                    listData[index].is_delete = !listData[index].is_delete;
                }

                if (type == 'is_view') {
                    listData[index].is_view = !listData[index].is_view;
                }

                for (var j in listData[index].access) {
                    if (type == 'is_create') {
                        listData[index].access[j].is_create = listData[index].is_create;
                    }

                    if (type == 'is_update') {
                        listData[index].access[j].is_update = listData[index].is_update;
                    }

                    if (type == 'is_delete') {
                        listData[index].access[j].is_delete = listData[index].is_delete;
                    }

                    if (type == 'is_view') {
                        listData[index].access[j].is_view = listData[index].is_view;
                    }

                }
            }

            temp.push(listData[i]);
        }

        setListData(temp);
    }



    const _onSave = () => {
        setButtonLoader(true)
        if (id === undefined) {
            var method = 'POST'
            var params = JSON.stringify({
                name: roleName,
                effective_date: moment(startDate).format('DD/MM/YYYY'),
                description: description,
                role_access: listData
            })
        } else {
            var method = 'PUT'
            var params = JSON.stringify({
                id: id,
                name: roleName,
                effective_date: moment(startDate).format('DD/MM/YYYY'),
                description: description,
                role_access: listData
            })
        }
        const apifetcherObj = APIMethod(`/roles`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                    goBack();
                    setButtonLoader(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setButtonLoader(false)
                }
            })
            .catch(error => {
                setButtonLoader(false)
            })
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Manage Role</Breadcrumb.Item>
                            <Breadcrumb.Item active>{id ? 'Edit Role' : 'Add Role'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center">
                    <span className="add-title ">{id ? 'Edit Role' : 'Add Role'}</span>
                    </div>

                    <div className="form-wraper form-style mb-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Role Name</label>
                                    <input type="text" className="form-control" value={roleName} onChange={event => setRoleName(event.target.value)} />
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4">
                                <div className="form-group">
                                    <label>Effective Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        minDate={new Date()}
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-4">
                                <div className="form-group">
                                    <label>Description</label>
                                    <input type="text" className="form-control" value={description} onChange={event => setDescription(event.target.value)} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="card role-card">
                                    <div className="card-header d-md-block d-none ">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-4">
                                                Navigation  Menu
                                            </div>
                                            <div className="col-lg-1 col-md-2">Create</div>
                                            <div className="col-lg-1 col-md-2">Update</div>
                                            <div className="col-lg-1 col-md-2">Delete</div>
                                            <div className="col-lg-1 col-md-2">View</div>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        {/* Organizational Setup  */}
                                        {
                                            listData.map((res, i) => {
                                                return (
                                                    <div>
                                                        <div className="row mb-3">
                                                            <div className="col-lg-8 col-md-4">
                                                                <a className="roles-menu" onClick={() => {
                                                                    handleClick(i)
                                                                }} aria-controls="collapse-role-1" aria-expanded={res.isExpand}><span className="font-20 mr-2"><i className="icofont-plus-square "></i></span> {res.name}</a>
                                                            </div>
                                                            <div className="col-lg-1 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" id={'os_create' + i} defaultChecked={res.is_create} defaultChecked={res.is_create} onChange={() => selectAll(i, 'is_create')} />
                                                                    <label className="custom-control-label" for={'os_create' + i}><span className="d-md-none d-block">Create</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-1 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" id={'os_update' + i} defaultChecked={res.is_update} onChange={() => selectAll(i, 'is_update')} />
                                                                    <label className="custom-control-label" for={'os_update' + i}><span className="d-md-none d-block">Update</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-1 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" id={'os_delete' + i} defaultChecked={res.is_delete} onChange={() => selectAll(i, 'is_delete')} />
                                                                    <label className="custom-control-label" for={'os_delete' + i}><span className="d-md-none d-block">Delete</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-1 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" id={'os_view' + i} defaultChecked={res.is_view} onChange={() => selectAll(i, 'is_view')} />
                                                                    <label className="custom-control-label" for={'os_view' + i}><span className="d-md-none d-block">View</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            res.access.map((access, j) => {
                                                                return (
                                                                    <Collapse in={res.isExpand}>
                                                                        <div id="collapse-role-1">
                                                                            <div className="row mb-2">
                                                                                <div className="col-lg-8 col-md-4"><span className="ml-4 pl-2">{access.name}</span></div>
                                                                                <div className="col-lg-1 col-md-2">
                                                                                    <div className="custom-control custom-checkbox">
                                                                                        <input type="checkbox" className="custom-control-input" id={'but_create_' + i + '_' + j} checked={access.is_create} defaultChecked={res.is_create} onChange={() => select(i, j, 'is_create')} />
                                                                                        <label className="custom-control-label" for={'but_create_' + i + '_' + j}><span className="d-md-none d-block">Create</span></label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-1 col-md-2">
                                                                                    <div className="custom-control custom-checkbox">
                                                                                        <input type="checkbox" className="custom-control-input" id={'but_update_' + i + '_' + j} checked={access.is_update} onChange={() => select(i, j, 'is_update')} />
                                                                                        <label className="custom-control-label" for={'but_update_' + i + '_' + j}><span className="d-md-none d-block">Update</span></label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-1 col-md-2">
                                                                                    <div className="custom-control custom-checkbox">
                                                                                        <input type="checkbox" className="custom-control-input" id={'but_delete_' + i + '_' + j} checked={access.is_delete} onChange={() => select(i, j, 'is_delete')} />
                                                                                        <label className="custom-control-label" for={'but_delete_' + i + '_' + j}><span className="d-md-none d-block">Delete</span></label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-1 col-md-2">
                                                                                    <div className="custom-control custom-checkbox">
                                                                                        <input type="checkbox" className="custom-control-input" id={'but_view_' + i + '_' + j} checked={access.is_view} onChange={() => select(i, j, 'is_view')} />
                                                                                        <label className="custom-control-label" for={'but_view_' + i + '_' + j}><span className="d-md-none d-block">View</span></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Collapse>)
                                                            })
                                                        }

                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>


                            <div className="col-12 text-right mt-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={goBack}>Cancel</button>
                                <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onSave()} disabled={buttonLoader}>
                                    {buttonLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default AddRole;