import React from "react";

const SearchNoDataFound = (props) => {
    return (
        <div className="col-12" style={{ alignItems: 'center', textAlign: 'center', justifyContent: 'center', backgroundColor: 'white', marginTop: '-22px', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
            <div style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                <strong>{props.message}</strong>
            </div>
        </div>
    )
}

export default SearchNoDataFound;