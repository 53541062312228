import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Pagination from '../../../../components/Pagination';
import NoData from "../../../../components/NoDataFound";
import ModalPopup from '../../../../components/ModalPopUp';
import { useHistory } from "react-router";
import ReactModal from 'react-modal-resizable-draggable';
import moment from "moment";
import { useToasts } from 'react-toast-notifications';
import Loader from '../../../../components/Loader';
import SearchNoDataFound from "../../../../components/SearchNoDataFound";

const Contracts = (props) => {
    const [getListData, setGetListData] = useState([]);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [rfxTypeData, setRfxTypeData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [formFields, setFormFields] = useState({
        business_unit: '', rfx_type: '', client: '', rfx_category: '', rfx_title: '', rfx_number: '', approval_status: '',
        contract_status: '', contract_start_date: undefined, contract_end_date: undefined, contract_status: ''
    })
    // Add modal box
    const [show, setShow] = useState(false);
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState('');
    const [contractStatus, setContractStatus] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false);
    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const history = useHistory();
    const { addToast } = useToasts();

    useEffect(() => {
        getList(1)
        getBusinessUnitList()
        getRfxTypeList()
        getRfxCategoryList()
        getContractStatus()
    }, [])

    const getList = (page, key) => {
        const new_key = key === undefined ? '' : key
        global.new_key = key === undefined ? '' : key
        const filter_start_date = formFields.contract_start_date ? moment(formFields.contract_start_date).format('DD-MM-YYYY') : ''
        const filter_end_date = formFields.contract_end_date ? moment(formFields.contract_end_date).format('DD-MM-YYYY') : ''
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/contract/list/${page}?business_unit_id=${formFields.business_unit}&rfx_type_id=${formFields.rfx_type}&client_agency=${formFields.client}&category_id=${formFields.rfx_category}&rfx_title=${formFields.rfx_title}&rfx_number=${formFields.rfx_number}&status_id=${formFields.contract_status}&contract_status=${'PC'}&from_date=${filter_start_date}&to_date=${filter_end_date}&keyword=` + new_key, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getBusinessUnitList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getRfxTypeList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-types/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRfxTypeData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getRfxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getContractStatus = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/contract/status/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setContractStatus(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const Search = (key) => {
        getList(1, key)
    }

    const nextPage = (pageNumber) => {
        getList(pageNumber)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const dateValidation = (date) => {
        if (formFields.contract_start_date) {
            const formFieldsObject = {
                ...formFields,
                contract_end_date: date
            }
            setFormFields(formFieldsObject);
        } else {
            addToast('Please select Contract Start Date first', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
        }
    }

    const setFromDate = (date) => {
        const formFieldsObject = {
            ...formFields,
            contract_start_date: date
        }
        setFormFields(formFieldsObject);
    }

    const _onFilter = () => {
        getList(1)
        handleClose()
    }

    const _onResetFilter = (type) => {
        if (type === 'BUSINESS_UNIT_TYPE') {
            formFields.business_unit = "";
        } else if (type === 'RFX_TYPE') {
            formFields.rfx_type = "";
        } else if (type === 'RFX_CLIENT') {
            formFields.client = "";
        } else if (type === 'RFX_CATEGORY') {
            formFields.rfx_category = "";
        } else if (type === 'RFX_TITLE') {
            formFields.rfx_title = "";
        } else if (type === 'RFX_NUMBER') {
            formFields.rfx_number = "";
        } else if (type === 'CONTRACT_STATUS') {
            formFields.contract_status = "";
        } else if (type === 'CONTRACT_START_DATE') {
            formFields.contract_start_date = undefined;
        } else if (type === 'CONTRACT_END_DATE') {
            formFields.contract_end_date = undefined;
        } else {
            formFields.business_unit = "";
            formFields.rfx_type = "";
            formFields.client = "";
            formFields.rfx_category = "";
            formFields.rfx_title = "";
            formFields.rfx_number = "";
            formFields.contract_start_date = undefined;
            formFields.contract_end_date = undefined;
            handleClose()
        }
        getList(1)
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Contracts</h2>
                        </div>
                        <div className="col-lg-6 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search by Contract Title" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            <button type="button" className="btn btn-brand-1 btn-h-40 mr-3" onClick={handleShow}><i className="fa fa-filter mr-2" aria-hidden="true"></i> Filter</button>
                            {/* <NavLink to="/add-contract" className="btn btn-brand-1 bg-success px-4">+ Add</NavLink> */}
                        </div>
                    </div>

                    <div className="mb-2 filter-by">
                        {
                            formFields.business_unit ?
                                <div className="badge">
                                    <span className="lable">Business Unit</span>
                                    {
                                        businessUnitData.map((res) => {
                                            return (
                                                <span className="value">{formFields.business_unit == res.id ? res.name : null}</span>
                                            )
                                        })
                                    }
                                    <i class="icofont-close" onClick={() => _onResetFilter('BUSINESS_UNIT_TYPE')}></i>
                                </div> : null
                        }
                        {
                            formFields.rfx_type ?
                                <div className="badge">
                                    <span className="lable">RFx Type</span>
                                    {
                                        rfxTypeData.map((res) => {
                                            return (
                                                <span className="value">{formFields.rfx_type == res.id ? res.name : null}</span>
                                            )
                                        })
                                    }
                                    <i class="icofont-close" onClick={() => _onResetFilter('RFX_TYPE')}></i>
                                </div> : null
                        }
                        {
                            formFields.client ?
                                <div className="badge">
                                    <span className="lable">Client / Agency</span>
                                    <span className="value">{formFields.client}</span>
                                    <i class="icofont-close" onClick={() => _onResetFilter('RFX_CLIENT')}></i>
                                </div> : null
                        }
                        {
                            formFields.rfx_category ?
                                <div className="badge">
                                    <span className="lable">RFx Category</span>
                                    {
                                        RFxCategoryData.map((res) => {
                                            return (
                                                <span className="value">{formFields.rfx_category == res.id ? res.name : null}</span>
                                            )
                                        })
                                    }
                                    <i class="icofont-close" onClick={() => _onResetFilter('RFX_CATEGORY')}></i>
                                </div> : null
                        }
                        {
                            formFields.rfx_title ?
                                <div className="badge">
                                    <span className="lable">RFx Title</span>
                                    <span className="value">{formFields.rfx_title}</span>
                                    <i class="icofont-close" onClick={() => _onResetFilter('RFX_TITLE')}></i>
                                </div> : null
                        }
                        {
                            formFields.rfx_number ?
                                <div className="badge">
                                    <span className="lable">RFx Number</span>
                                    <span className="value">{formFields.rfx_number}</span>
                                    <i class="icofont-close" onClick={() => _onResetFilter('RFX_NUMBER')}></i>
                                </div> : null
                        }
                        {
                            formFields.contract_status ?
                                <div className="badge">
                                    <span className="lable">Contract Status</span>
                                    {
                                        contractStatus.map((res) => {
                                            return (
                                                <span className="value">{formFields.contract_status == res.id ? res.name : null}</span>
                                            )
                                        })
                                    }
                                    <i class="icofont-close" onClick={() => _onResetFilter('CONTRACT_STATUS')}></i>
                                </div> : null
                        }
                        {
                            formFields.contract_start_date ?
                                <div className="badge">
                                    <span className="lable">Contract Start Date</span>
                                    <span className="value">{moment(formFields.contract_start_date).format('MM-DD-YYYY')}</span>
                                    <i class="icofont-close" onClick={() => _onResetFilter('CONTRACT_START_DATE')}></i>
                                </div> : null
                        }
                        {
                            formFields.contract_end_date ?
                                <div className="badge">
                                    <span className="lable">Contract End Date</span>
                                    <span className="value">{moment(formFields.contract_end_date).format('MM-DD-YYYY')}</span>
                                    <i class="icofont-close" onClick={() => _onResetFilter('CONTRACT_END_DATE')}></i>
                                </div> : null
                        }
                    </div>

                    <table className="table table-style-1 table-responsive-lg mb-4 table reports" id="reportTable">
                        <thead>
                            <tr>
                                <th scope="col">BU</th>
                                <th scope="col">Contract Number</th>
                                <th scope="col">Contract Title</th>
                                <th scope="col">Status</th>
                                <th scope="col">Contract Start Date</th>
                                <th scope="col">Contract End Date</th>
                                <th scope="col">Client</th>
                                <th scope="col">State/Province </th>
                                <th scope="col" className="text-center" width="110">Action</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/contract/'}
                            updateList={getList}
                        ></ModalPopup>
                        <tbody>

                            {
                                loading === false ? getListData.map((val) => {
                                    return (
                                        <tr>
                                            <td>{val.business_unit}</td>
                                            <td>{val.contract_number}</td>
                                            <td title={val.contract_title}>{val.contract_title}</td>
                                            <td>{val.contract_status}</td>
                                            <td>{val.start_date ? moment(val.start_date, 'YYYY-MM-DD').format('MM-DD-YYYY') : null}</td>
                                            <td>{val.end_date ? moment(val.end_date, 'YYYY-MM-DD').format('MM-DD-YYYY') : null}</td>
                                            <td>{val.client}</td>
                                            <td>{val.state_name}</td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-action edit mr-1" onClick={() => history.push({ pathname: "/edit-contract", state: { detail: { id: val.pre_rfx_id, contract_id: val.id, rfx_number: val.rfx_number, contract_number: val.contract_number, rfx_client_agency: val.client, contract_title: val.contract_title, page: 'Contract' } } })}><i className="icofont-pencil-alt-2"></i></button>
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }

                        </tbody>
                    </table>
                    {
                        getListData.length === 0 && loading === false && global.new_key ?
                            <SearchNoDataFound message={'No Contract Found'} />
                            : getListData.length === 0 && loading === false ? <SearchNoDataFound message={'No Contract Found'} /> : null
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">


                            <Pagination
                                pages={pageCount}
                                endPoint={'/business-unit-type/list/'}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                            />
                        </nav>
                    </div>

                </div>
            </div>

            {/* Filter Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={580}
                top={137.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Filter Contract</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Business Unit</label>
                                <select id="disabledSelect" className="form-control custom-select" onChange={handleChange} name="business_unit" value={formFields['business_unit']}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        businessUnitData.map((res) => {
                                            return (
                                                <option value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx type</label>
                                <select id="disabledSelect" className="form-control custom-select" onChange={handleChange} name="rfx_type" value={formFields['rfx_type']}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        rfxTypeData.map((res) => {
                                            return (
                                                <option value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Client / Agency</label>
                                <input type="text" className="form-control" onChange={handleChange} name="client" value={formFields['client']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Category</label>
                                <select id="disabledSelect" className="form-control custom-select" onChange={handleChange} name="rfx_category" value={formFields['rfx_category']}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        RFxCategoryData.map((res) => {
                                            return (
                                                <option value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Title</label>
                                <input type="text" className="form-control" onChange={handleChange} name="rfx_title" value={formFields['rfx_title']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Number</label>
                                <input type="text" className="form-control" onChange={handleChange} name="rfx_number" value={formFields['rfx_number']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Contract Status</label>
                                <select id="disabledSelect" className="form-control custom-select" onChange={handleChange} name="contract_status" value={formFields['contract_status']}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        contractStatus.map((res) => {
                                            return (
                                                <option value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Contract Start Date</label>
                                <DatePicker
                                    selected={formFields.contract_start_date}
                                    onChange={(date) => setFromDate(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Contract End Date</label>
                                <DatePicker
                                    selected={formFields.contract_end_date}
                                    onChange={(date) => dateValidation(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    minDate={formFields.contract_start_date}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer ">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button className="btn btn-lg btn-brand-1" onClick={() => _onResetFilter()}>Reset</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onFilter()}>Search</button>
                </div>
            </ReactModal>
        </>
    )
}
export default Contracts;