import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import Form from 'react-bootstrap/Form'
import { useHistory, useParams, NavLink } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { APIMethod, FileUploadMethod, tiny_key, TOAST_TIMEOUT } from "../../../../API/APIClient";
import moment from "moment";
import ModalPopup from '../../../../components/ModalPopUp';
import Comment from "../../../../components/Comment";
import Toggle from 'react-toggle';
import { Editor } from '@tinymce/tinymce-react';
import Chat from "../../../../components/Chat";
import chatIcon from '../../../../assets/css/images/chat.png';


const EditPreRfxApproval = (props) => {
    const descriptionRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState();
    const [modalButtonLoader, SetModalButtonLoader] = useState(false);
    const [pageComing, setPageComing] = useState('');
    // file
    const [file, setFile] = useState();
    const [fileResponse, setFileResponse] = useState();
    // Add modal box
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [RFxTypeData, setRFxTypeData] = useState([]);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [RFxStatusData, setRFxStatusData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [editData, setEditData] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [documentData, setDocumentData] = useState([]);
    const [addDocuments, setAddDocuments] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [role, setRole] = useState('');
    const [bid, setBid] = useState(false);
    const [vendors, setVendors] = useState(false);
    const [licenses, setLicenses] = useState(false);
    const [conditions, setConditions] = useState(false);
    const [constraints, setconstraints] = useState(false);
    const [business, setBusiness] = useState(false);


    //Goto back
    const goBack = () => {
        window.history.back();
    }
    const [formFields, setFormFields] = useState({
        businessUnit: '', RFxNumber: '', RFxTitle: '', RFxType: '', RFxStatus: '', RFxCategory: '', RFxClientAgency: '',
        description: '', sourceURL: '', state: '', submissionFormat: '', buyerName: '', buyerTitle: '', buyerContact: '',
        buyerEmail: '', id: '', comment: '', fileName: '', document_type_id: '', version: '', documentDescription: '',
        countryId: '', stateId: '', thirdPartySourceUrl: '', nicasCode: '', bidData: '', vendorData: '', licenseData: '',
        conditionData: '', constraintsData: '', businessData: '', isComment: false
    })
    const [flag, setFlag] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);
    // Toast
    const { addToast } = useToasts();
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);

    //--------date-Piicker----------
    const [startDate, setStartDate] = useState();
    //---Date Time picker
    const [startDateTime, setStartDateTime] = useState();
    const { id } = useParams();
    const [showChatModal, setShowChatModal] = useState(false)
    const handleClose = () => {
        setShow(!show)
        setEditIndex(-1)
        setFormFields({ ...formFields, document_type_id: '', fileName: '', version: '', documentDescription: '' })
    }

    useEffect(() => {
        getBusinessUnitList()
        getRFxTypeList()
        getRFxStatusList()
        getRFxCategoryList()
        getDocumentTypeList()
        getCountryList()
        check()
    }, [])

    const check = () => {
        const userRole = localStorage.getItem('userRole');
        setRole(userRole)
        if (props.location.state == null) {
        } else {
            setPageComing(props.location.state.page)
        }
        if (id == null) {
        } else {
            setLoading(true)
            var method = 'GET'
            const apifetcherObj = APIMethod(`/pre-rfx/${id}`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setEditData(data)
                        setFormFields({
                            ...formFields, id: data.id, businessUnit: data.business_unit, RFxNumber: data.rfx_number,
                            RFxTitle: data.title, RFxCategory: data.rfx_category, RFxClientAgency: data.rfx_client_agency,
                            description: data.scope, sourceURL: data.client_url, RFxType: data.rfx_type_id,
                            RFxStatus: data.rfx_status_id, state: data.state, submissionFormat: data.subbmission_format,
                            buyerName: data.business_name, buyerTitle: data.buyer_title, buyerContact: data.buyer_contact,
                            buyerEmail: data.buyer_email, thirdPartySourceUrl: data.third_ps_url, nicasCode: data.naics,
                            buyerName: data.buyer_name, stateId: data.state_id, countryId: data.country_id, bidData: data.rfx_constraints.conference_mandatory,
                            vendorData: data.rfx_constraints.local_vendor_eligible, licenseData: data.rfx_constraints.certification_license_needed,
                            conditionData: data.rfx_constraints.financial_condition, constraintsData: data.rfx_constraints.other_constraints,
                            businessData: data.rfx_constraints.small_firms
                        })
                        setVendors(data.vendor)
                        setVendors(data.rfx_constraints.is_local_vendor_eligible)
                        setBid(data.rfx_constraints.is_conference_mandatory)
                        setLicenses(data.rfx_constraints.is_certification_license_needed)
                        setConditions(data.rfx_constraints.is_financial_condition)
                        setconstraints(data.rfx_constraints.is_other_constraints)
                        setBusiness(data.rfx_constraints.is_small_firms)
                        setDocumentData(data.documents)
                        setAddDocuments(data.documents)
                        setLoading(false)
                        setFlag(false)
                        if (data.id) {
                            getComments()
                        }
                        if (data.country_id) {
                            getStateList(data.country_id)
                        }
                        if (data.published) {
                            setStartDate(moment(data.published, 'DD/MM/YYYY').toDate())
                        }
                        if (data.due) {
                            setStartDateTime(moment(data.due, 'DD/MM/YYYY HH:mm:ss').toDate())
                        }
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setLoading(false)
                })
        }
    }

    const getBusinessUnitList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getDocumentTypeList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentList(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRFxTypeList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-types/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxTypeData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRFxStatusList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-statuses/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxStatusData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRFxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getCountryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/region/country/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCountryData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getStateList = (id) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/region/state/list/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setStateData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })

    }

    const getComments = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/comments/${id}?comment_type=PRE_RFX`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCommentData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
        if (name === 'countryId') {
            getStateList(event.target.value)
        }
    }

    const _onSendApproval = (val) => {
        if (formFields.isComment == false) {
            addToast("Please Enter Comment First", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (val) {
            setButtonLoader(val)
            setLoading(true)
            var method = 'PUT'
            var params = JSON.stringify({
                id: id,
                rfx_status: val
            })

            setTimeout(() => {
                setButtonLoader(false)
                goBack()
            }, 1000 * 30);
            const apifetcherObj = APIMethod(`/pre-rfx/status`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setLoading(false)
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        goBack()
                        formFields.isComment = false;
                        if (val == 'APPROVED') {
                            // onUpdateStatus('OPEN', data.id)
                        } else {
                            setButtonLoader(val)
                        }
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setButtonLoader(val)
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }


    const onUpdateStatus = (key, aiId) => {
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/action/event/${key}/${aiId ? aiId : id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    setButtonLoader()
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                alert(error)
            })
    }

    const onSetComment = () => {
        formFields.isComment = true;
    }

    let config = {
        events: {
            initialized() {
                const editor = this;
                editor.edit.off();

                setTimeout(() => {
                    editor.edit.off();
                }, 5000);
            }
        }
    };


    return (
        <>
            <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={'/pre-rfx/document/'}
                updateList={check}
            ></ModalPopup>
            <div className="page-wrapper d-flex">
                <div className="page-content">
                    <div className="mb-3" >
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Pre-RFx</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>Pre-RFx Approval</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edit Pre-RFx Approval</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center">
                        <span className="add-title ">Pre-RFx Approval</span>
                    </div>
                    <div className="form-wraper form-style mb-4" >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                    {/*<p className="font-medium">RFx Information</p>*/}
                                    {/* Commenting end. Divya. September 01 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                    <p className="font-20 font-bold">RFx Information</p>
                                    {/* Divya. September 01 2023. Code End */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Business Unit</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['businessUnit']} name="businessUnit" onChange={handleChange} disabled={true}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            businessUnitData.map((res, index) => {
                                                return (
                                                    <option key={index} value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.businessUnit === '' ? 'Please Enter Business Unit' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Number</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="RFxNumber" value={formFields['RFxNumber']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.RFxNumber === '' ? 'Please Enter RFx Number' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label>RFx Title</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="RFxTitle" value={formFields['RFxTitle']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.RFxTitle === '' ? 'Please Enter RFx Title' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>RFx Type</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['RFxType']} name="RFxType" onChange={handleChange} disabled={true}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            RFxTypeData.map((res, index) => {
                                                return (
                                                    <option key={index} value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.RFxType === '' ? 'Please Select RFx Type' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Publish on</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        minDate={new Date()}
                                        readOnly={true}
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Due Date & Time</label>
                                    <DatePicker
                                        className="form-control"
                                        selected={startDateTime}
                                        onChange={(date) => setStartDateTime(date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        placeholderText="Select Date & Time"
                                        readOnly={true}
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Category</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['RFxCategory']} name="RFxCategory" onChange={handleChange} disabled={true}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            RFxCategoryData.map((res, index) => {
                                                return (
                                                    <option key={index} value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.RFxCategory === '' ? 'Please Select RFx Category' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Client Agency</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="RFxClientAgency" value={formFields['RFxClientAgency']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.RFxClientAgency === '' ? 'Please Enter RFx Client Agency' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                    {/*<label>Description/Scope</label>*/}
                                    {/* Commenting end. Divya. September 01 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                    <p className="font-20 font-bold">Description/Scope</p>
                                    {/* Divya. September 01 2023. Code End */}
                                    <Editor
                                        apiKey={tiny_key}
                                        onInit={(evt, editor) => descriptionRef.current = editor}
                                        initialValue={formFields.description}
                                        disabled={true}
                                        init={{
                                            height: 500,
                                            menubar: 'file edit view insert format tools table tc help',
                                            selector: 'textarea#full-featured',
                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                        }}
                                    />
                                    <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description/Scope' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Country</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['countryId']} name="countryId" onChange={handleChange} disabled={true}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            countryData.map((val, index) => {
                                                return (
                                                    <option key={index} value={val.id}>{val.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.countryId === '' ? 'Please Select Country' : null}</span>
                                </div>
                            </div>
                            {
                                formFields.countryId ?
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label>State/Province</label>
                                            <select id="disabledSelect" className="form-control custom-select" value={formFields['stateId']} name="stateId" onChange={handleChange} disabled={true}>
                                                <option value="" disabled selected>Select your option</option>
                                                {
                                                    stateData.map((res, index) => {
                                                        return (
                                                            <option key={index} value={res.id}>{res.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{flag === true && formFields.stateId === '' ? 'Please Select State/Province' : null}</span>
                                        </div>
                                    </div> : null
                            }

                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Client/Source URL</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="sourceURL" value={formFields['sourceURL']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.sourceURL === '' ? 'Please Enter Client/Source URL' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Submission Format</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['submissionFormat']} name="submissionFormat" onChange={handleChange} disabled={true}>
                                        <option value="" disabled selected>Select your option</option>
                                        <option>Online</option>
                                        <option>Email</option>
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.submissionFormat === '' ? 'Please Select Submission Format' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>3rd Party Source URL</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="thirdPartySourceUrl" value={formFields['thirdPartySourceUrl']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.thirdPartySourceUrl === '' ? 'Please Enter 3rd Party Source URL' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>NAICS Codes</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="nicasCode" value={formFields['nicasCode']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.nicasCode === '' ? 'Please Enter NIACS Codes' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                {/*<p className="font-medium">RFx Constraints:</p>*/}
                                {/* Commenting end. Divya. September 01 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                <p className="font-20 font-bold">RFx Constraints:</p>
                                {/* Divya. September 01 2023. Code End */}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Pre-Bid Conference mandatory? </lable><br />
                                    <Toggle
                                        checked={bid}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setBid(!bid)}
                                        disabled={true}
                                    />
                                    {
                                        bid === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="bidData" value={formFields['bidData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Only local vendors eligible?</lable><br />
                                    <Toggle
                                        checked={vendors}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setVendors(!vendors)}
                                        disabled={true} />
                                    {
                                        vendors === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="vendorData" value={formFields['vendorData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Any Certification/licenses needed?</lable><br />
                                    <Toggle
                                        checked={licenses}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setLicenses(!licenses)}
                                        disabled={true} />
                                    {
                                        licenses === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="licenseData" value={formFields['licenseData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Any financial conditions?</lable><br />
                                    <Toggle
                                        checked={conditions}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setConditions(!conditions)}
                                        disabled={true} />
                                    {
                                        conditions === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="conditionData" value={formFields['conditionData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Any other constraints</lable><br />
                                    <Toggle
                                        checked={constraints}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setconstraints(!constraints)}
                                        disabled={true} />
                                    {
                                        constraints === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="constraintsData" value={formFields['constraintsData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Set aside for small business/minority firms?</lable><br />
                                    <Toggle
                                        checked={business}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setBusiness(!business)}
                                        disabled={true} />
                                    {
                                        business === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="businessData" value={formFields['businessData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                {/*<p className="font-medium">Buyer Information</p>*/}
                                {/* Commenting end. Divya. September 01 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                <p className="font-20 font-bold">Buyer Information</p>
                                {/* Divya. September 01 2023. Code End */}
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Name</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="buyerName" value={formFields['buyerName']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.buyerName === '' ? 'Please Enter Buyer Name' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Title</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="buyerTitle" value={formFields['buyerTitle']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.buyerTitle === '' ? 'Please Enter Buyer Title' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Contact</label>
                                    <input type="text" className="form-control" onChange={handleChange} disabled={true} onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            return false;
                                        }

                                        return true;
                                    }} name="buyerContact" value={formFields['buyerContact']} />
                                    <span className="text-danger">{flag === true && formFields.buyerContact === '' ? 'Please Enter Buyer Contact' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Email</label>
                                    <input type="text" className="form-control" onChange={handleChange} disabled={true} name="buyerEmail" value={formFields['buyerEmail']} />
                                    <span className="text-danger">{flag === true && formFields.buyerEmail === '' ? 'Please Enter Buyer Email' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                {/*<p className="font-medium">RFx Attachments</p>*/}
                                {/* Commenting end. Divya. September 01 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                <p className="font-20 font-bold">RFx Attachments</p>
                                {/* Divya. September 01 2023. Code End */}
                            </div>
                            <div className="col-12 text-right">
                                {
                                    editData.rfx_status == "PENDING" || formFields.id === '' ?
                                        <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={handleShow}>Add</button>
                                        :
                                        null
                                }

                            </div>

                            <div className="col-12 my-3">
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">

                                    <tr>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        <th>Version</th>
                                        <th>Description</th>
                                        {/* <th>Action</th> */}
                                    </tr>
                                    {
                                        addDocuments.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{res.document_type_name}</td>
                                                    <td>{res.file_name}</td>
                                                    <td>{res.version}</td>
                                                    <td title={res.description}>{res.description && res.description.length > 145 ? `${res.description.substring(0, 145)}...` : res.description}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-action" onClick={() => window.open(res.file, "_blank")}><i className="icofont-eye-alt"></i></button>
                                                        {/* <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(i, res)}><i className="icofont-pencil-alt-2"></i></button>
                                                        <button type="button" className="btn btn-action delete" onClick={() => deleteDocument(i)}><i className="icofont-trash"></i></button> */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                            {
                                formFields.id ?
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="font-16 mb-0">Pre-RFx Comments</h4>
                                            </div>
                                            <div className="card-body bg-white">
                                                {
                                                    commentData.map((res, index) => {
                                                        return (
                                                            <div key={index} className="bg-light p-2 rounded mb-2">
                                                                <p className="mb-1">
                                                                    <span className="font-medium mr-2">{res.user.first_name} {res.user.last_name}</span>
                                                                    <span className="text-secondary font-12 mr-3">{moment(res.created_date).format("D-MMM-YYYY h:mm a")}</span>
                                                                </p>
                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: res.comment }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <Comment
                                                    id={formFields.id}
                                                    updateCommentList={getComments}
                                                    enableComment={onSetComment}
                                                    editor={true} />
                                            </div>
                                        </div>
                                    </div> : null
                            }
                            <div className="col-12 text-center mt-4 row" style={{ justifyContent: 'center' }}>

                                {
                                    editData.rfx_status === 'DECLINED' || editData.rfx_status === 'APPROVED' || editData.rfx_status === 'PUSH_BACK' ?
                                        null
                                        :
                                        <div>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={() => _onSendApproval('DECLINED')} disabled={buttonLoader == 'DECLINED' ? true : false}> {buttonLoader == 'DECLINED' && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}No Go</button>
                                            <button type="button" className="btn btn-lg btn-brand-1 mr-3" onClick={() => _onSendApproval('APPROVED')} disabled={buttonLoader == 'APPROVED' ? true : false}>{buttonLoader == 'APPROVED' && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}Go</button>
                                            <button type="button" className="btn btn-brand-1  px-4" onClick={() => _onSendApproval('PUSH_BACK')} disabled={buttonLoader == 'PUSH_BACK' ? true : false}>{buttonLoader == 'PUSH_BACK' && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}Push Back</button>
                                        </div>

                                }
                            </div>
                            <div className="col-12 text-center mt-4 row" style={{ justifyContent: 'center' }}>
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3 mt-1" onClick={goBack}>Cancel</button>
                            </div>
                                
                                {/* <NavLink to="/pre-rfx-approvals-filters" className="btn btn-brand-1  px-4">Push Back </NavLink> */}
                            
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="add-header text-center" style={{ marginTop: 40 }}>
                        <span className="add-title ">Capability Match</span>
                    </div>
                    <div className="form-wraper form-style mb-4" >
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="form-group">
                                     {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                    {/*<label>RFx Scope/Requirements</label>*/}
                                    {/* Commenting end. Divya. September 01 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                    <p className="font-20 font-bold">RFx Scope/Requirements</p>
                                    {/* Divya. September 01 2023. Code End */}
                                    <Editor
                                        apiKey={tiny_key}
                                        onInit={(evt, editor) => descriptionRef.current = editor}
                                        initialValue={formFields.description}
                                        disabled={true}
                                        init={{
                                            height: 500,
                                            menubar: 'file edit view insert format tools table tc help',
                                            selector: 'textarea#full-featured',
                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                        }}
                                    />
                                    <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description/Scope' : null}</span>
                                </div>
                            </div>

                            <div className="col-12 my-3">
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">

                                    <tr>
                                        <th>Company</th>
                                        <th>Bus. Type</th>
                                        <th>Product Match</th>
                                        <th>Service Match</th>
                                        <th>Quality Match</th>
                                    </tr>
                                    {
                                        addDocuments.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>Solomns</td>
                                                    <td>Self</td>
                                                    <td>75%</td>
                                                    <td>95%</td>
                                                    <td>45%</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>

                            {/* Commenting the following code for WOII-86. Divya. September 11 2023.. Code Start */}
                            {/*<div className="col-12 mt-4 row" style={{ justifyContent: 'center', width: 620 }}>*/}
                            {/* Commenting end. Divya. September 11 2023.. */}
                            {/* Added this code for  WOII-86. Divya. September 11 2023.. Code Start */}
                            <div className="col-12 mt-4 row" style={{ justifyContent: 'center', width: 620, height: '100px' }}>
                            {/* Divya. September 11 2023. Code End */}
                                <button type="button" className="btn btn-lg btn-brand-1">Match My Company Capabilities Again</button>
                                <button type="button" className="btn btn-lg btn-brand-1 ml-3">Match Other Companies</button>
                            </div>

                            <div className="col-12 my-3">
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">

                                    <tr>
                                        <th>Company</th>
                                        <th>Bus. Type</th>
                                        <th>Product Match</th>
                                        <th>Service Match</th>
                                        <th>Quality Match</th>
                                    </tr>
                                    {
                                        addDocuments.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>Solomns</td>
                                                    <td>Self</td>
                                                    <td>75%</td>
                                                    <td>95%</td>
                                                    <td>45%</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showChatModal == false &&
                <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                    <img src={chatIcon} />
                </div>
            }
            {
                showChatModal &&
                <Chat
                    id={id}
                    title={formFields.RFxTitle}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default EditPreRfxApproval;