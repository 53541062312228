import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Style } from "react-style-tag";
import { isValidEmail } from "../../../utility/utility";
import { APIMethod, TOAST_TIMEOUT } from '../../../API/APIClient'
import { useHistory } from "react-router";
import { useToasts } from 'react-toast-notifications';
import { NavLink } from "react-router-dom";
import Loader from '../../../components/Loader';

const Login = (props) => {
    // modal 
    const [show, setShow] = useState(false);
    const [showUser, setShowUser] = useState(false);
    // Form Validation
    const [formFields, setFormFields] = useState({ email: '', password: '', forgotPasswordEmail: '', forgotUsernameEmail: '' })
    const [flag, setFlag] = useState(false);
    // Loader
    const [isLoading, setLoading] = useState(false);
    const [forgotLoader, setForgotLoader] = useState(false);
    // Toast
    const { addToast } = useToasts();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseUser = () => setShowUser(false);
    const handleShowUser = () => setShowUser(true);

    const history = useHistory();

    const submit = () => {
        if (formFields.email === '' || formFields.password === '') {
            setFlag(true)
            addToast('Please Enter Email and Password', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setLoading(true)

            var params = JSON.stringify({
                username: formFields.email,
                password: formFields.password,
                grant_type: "password",
                role: "SUPER_ADMIN"
            })

            var method = 'POST'
            const apifetcherObj = APIMethod('/oauth/token', params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        localStorage.setItem('token', data.access_token);
                        userDetail()
                        //  setLoading(false)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }

    const userDetail = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod('/users/me', null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    localStorage.setItem('userImage', data.image);
                    localStorage.setItem('userName', data.first_name);
                    localStorage.setItem('userRoleID', data.role_id)
                    localStorage.setItem('userId', data.id)
                    if (data.role_id === undefined) {
                        addToast('Thanks for Sign Up. Administrator will get back to you shortly.', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'info', autoDismiss: true });
                        localStorage.removeItem('token')
                        setLoading(false)
                    } else {
                        getUserRole(data.role_id)
                        //   sendToken(data.id)
                    }
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setLoading(false)
                }
            })
            .catch(error => {
            })
    }

    const getUserRole = (id) => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/roles/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    localStorage.setItem('Login_time', { isLogin: true })
                    props.signInSuccess();
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setLoading(false)
                }
            })
            .catch(error => {
            })
    }

    const sendToken = (id) => {
        var method = 'POST'
        var params = JSON.stringify({
            user_id: id,
            token: "1235",
            platform: 'web'
        })
        const apifetcherObj = APIMethod(`/device-token`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    //   props.signInSuccess();
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setLoading(false)
                }
            })
            .catch(error => {
            })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const forgotUsername = () => {
        setLoading(true)
        if (formFields.forgotUsernameEmail === '') {
            setFlag(true)
            addToast('Please Enter Email', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            var method = 'PUT'
            var params = JSON.stringify({
                email: formFields.forgotUsernameEmail
            })
            const apifetcherObj = APIMethod('/users/username-reset', params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        setFormFields({ ...formFields, forgotUsernameEmail: '' })
                        setLoading(false)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }
    const forgotPassword = () => {
        setForgotLoader(true)
        if (formFields.forgotPasswordEmail === '') {
            setFlag(true)
            addToast('Please Enter Email', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            var method = 'PUT'
            var params = JSON.stringify({
                email: formFields.forgotPasswordEmail
            })
            const apifetcherObj = APIMethod('/users/forgot-password', params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        setFormFields({ ...formFields, forgotPasswordEmail: '' })
                        setForgotLoader(false)
                        handleClose()
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setForgotLoader(false)
                    }
                })
                .catch(error => {
                    setForgotLoader(false)
                })
        }
    }



    return (
        <>
            <div className="" id="main-wraper" >
                <Style>{`
                .header{
                    display: none;
                }
                .login-img{
                    height: 100vh;width: 100%;object-fit: cover;
                }
                }
                `}</Style>
                <div className="login-page">
                    <div className="row mx-0 mt-md-0 mt-5 bg-white">
                        <div className="col-md-5 pl-0 d-md-block d-none">
                            <img src="/images/login-img.png" className="login-img img-fluid" />
                        </div>
                        <div className="col-md-7 align-self-center">
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <img src="/images/logo.png" alt="Logo" width="200" className="mb-4" />
                                        <h2 className="font-24 font-medium text-secondary mb-3">Welcome to Winopps </h2>
                                        <p className="font-18 text-secondary">Please Sign-In to your account and start</p>
                                    </div>

                                    <div className=" login-form">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" className="form-control" onChange={handleChange} name="email" value={formFields['email']} onKeyPress={(e) => e.key === 'Enter' && submit()} />
                                            <span className="text-danger">{flag === true && formFields.email === '' ? 'Please Enter Email' : null}</span>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Password</label>
                                            <input type="password" className="form-control" onChange={handleChange} name="password" value={formFields['password']} onKeyPress={(e) => e.key === 'Enter' && submit()} />
                                            <span className="text-danger">{flag === true && formFields.password === '' ? 'Please Enter Password' : null}</span>
                                        </div>
                                        <p className="mb-3 d-flex" style={{ justifyContent: 'flex-end' }}>
                                            {/* <a href="javascript:void(0)" onClick={handleShowUser}>Forgot Username?</a> */}
                                            <a href="javascript:void(0)" onClick={handleShow}>Forgot Password?</a>
                                        </p>
                                        <div className="form-group row ">
                                            <div className="col-md-12 mb-2">
                                                {/* <button type="button" className="btn btn-brand-1 btn-block" onClick={() => submit()}>Login</button> */}
                                                <button type="button" className="btn btn-brand-1 btn-block" onClick={() => submit()} disabled={isLoading}>
                                                    {isLoading && (
                                                        <img src={require('../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                    )}
                                                    <span>Login</span>
                                                </button>
                                            </div>
                                            <div className="col-md-12 text-center">
                                                <span className="mr-2">Dont have an Account?</span> <NavLink to="/signup">Signup</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Forgot Password  */}
            <Modal show={show} onHide={handleClose}
                {...props}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="modal-header px-md-5">
                    <h5 className="modal-title" id="">Forgot Password?</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <Modal.Body>
                    <div class="row form-style px-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label className="mb-3">Enter your Email Address and your password will be emailed to you</label>
                                <input type="email" class="form-control" onChange={handleChange} name="forgotPasswordEmail" value={formFields['forgotPasswordEmail']} />
                                <span className="text-danger">{flag === true && formFields.forgotPasswordEmail === '' ? 'Please Enter Email' : null}</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div class="modal-footer text-center d-block px-4">
                    <button type="button" className="btn btn-lg btn-brand-1  w-100" onClick={() => forgotPassword()} disabled={forgotLoader}>
                        {forgotLoader && (
                            <img src={require('../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Submit</span>
                    </button>
                    <p class="text-center">
                        <a href="javscript:void(0)" onClick={handleClose}><i class="icofont-rounded-left"></i> Back to Login</a>
                    </p>
                </div>
            </Modal>

            {/* Forgot Username  */}
            <Modal show={showUser} onHide={handleCloseUser}

                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="modal-header px-md-5">
                    <h5 className="modal-title" id="">Forgot Username?</h5>
                    <button type="button" className="close" onClick={handleCloseUser}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <Modal.Body>
                    <div class="row form-style px-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label className="mb-3">Enter your Email Address and your username will be emailed to you</label>
                                <input type="email" class="form-control" onChange={handleChange} name="forgotUsernameEmail" value={formFields['forgotUsernameEmail']} />
                                <span className="text-danger">{flag === true && formFields.forgotUsernameEmail === '' ? 'Please Enter Email' : null}</span>
                                {/* <span className="text-danger">emailError</span> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div class="modal-footer text-center d-block px-4">
                    <button type="button" class="btn btn-lg btn-brand-1  w-100" onClick={() => forgotUsername()}>Submit</button>
                    <p class="text-center">
                        <a href="javscript:void(0)" onClick={handleClose}><i class="icofont-rounded-left"></i> Back to Login</a>
                    </p>
                </div>
            </Modal>

        </>
    )
}
export default Login;