import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css"
import { APIMethod, FileUploadMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import ModalPopup from '../../../../components/ModalPopUp';
import Pagination from '../../../../components/Pagination';
import Loader from '../../../../components/Loader';
import { useToasts } from 'react-toast-notifications';
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from 'react-modal-resizable-draggable';

const WordTemplates = (props) => {
    const [getListData, setGetListData] = useState([]);
    const [formFields, setFormFields] = useState({ templateName: '', description: '' })
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    // Edit
    const [edit, setEdit] = useState('');
    const [id, setId] = useState('');
    // Delete
    const [deleteId, setDeleteId] = useState('');
    // Delete PopUp
    const [showModalPopup, setShowModalPopup] = useState(false);
    // Pagination
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // file
    const [file, setFile] = useState();
    // Add modal box
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setFormFields({ ...formFields, templateName: '', description: '' })
        setId('')
        setShow(false);
        setFlag(false)
        setEdit()
        setFile()
    }
    const handleShow = () => setShow(true);
    // Toast
    const { addToast } = useToasts();
    const [flag, setFlag] = useState(false);
    //--------date-Piicker----------
    const [adding, setAdding] = useState();
    const [editing, setEditing] = useState();
    const [deleting, setDeleting] = useState();

    useEffect(() => {
        getWordTemplatesList(1)
        var data = JSON.parse(localStorage.getItem("user_Role"))
        checkPermission(data)
    }, [])

    const checkPermission = (data) => {
        const resObject = data.find(item => item.name === "Organizational Setup")
        const valObject = resObject.access.find(item => item.name === "Word Templates")
        setAdding(valObject.is_create)
        setEditing(valObject.is_update)
        setDeleting(valObject.is_delete)
    }

    const getWordTemplatesList = (page, key) => {
        setLoading(true)
        const new_key = key === undefined ? '' : key
        global.new_key = key === undefined ? '' : key
        var method = 'GET'
        const apifetcherObj = APIMethod(`/word-template/list/${page}?keyword=` + new_key, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const nextPage = (pageNumber) => {
        getWordTemplatesList(pageNumber)
    }

    const save = () => {
        if (formFields.templateName === '' || formFields.description === '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setButtonLoader(true)
            if (edit === '/') {
                var params = JSON.stringify({
                    id: id,
                    name: formFields.templateName,
                    description: formFields.description,
                })
                var method = 'PUT'
            } else {
                params = JSON.stringify({
                    name: formFields.templateName,
                    description: formFields.description,
                })
                method = 'POST'
            }
            const apifetcherObj = APIMethod('/word-template', params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        if (file.type) {
                            sendFile(data.id)
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        } else {
                            handleClose()
                            setButtonLoader(false)
                            setFlag(false)
                            setFormFields({ ...formFields, templateName: '', description: '' })
                            setFile()
                            setId('')
                            setEdit('')
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                            getWordTemplatesList('1', '')
                        }
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        }
    }

    const sendFile = (id) => {
        var form = new FormData();
        form.append('file', file);
        var method = 'POST'
        const apifetcherObj = FileUploadMethod(`/word-template/file/` + id, form, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    handleClose()
                    setButtonLoader(false)
                    setFlag(false)
                    setFormFields({ ...formFields, templateName: '', description: '' })
                    setFile()
                    setId('')
                    setEdit('')
                    getWordTemplatesList(currentPage)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    }

    const _onEdit = (res) => {
        handleShow()
        setFormFields({ ...formFields, templateName: res.name, description: res.description })
        setEdit('/')
        setId(res.id)
        setFile({ name: res.file })
    }


    const Search = (key) => {
        getWordTemplatesList(1, key)
    }

    const onChangeHandler = event => {
        setFile(event.target.files[0])
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const getData = () => {
        getWordTemplatesList(1)
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Word Templates</h2>
                        </div>
                        <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search name and description" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            {
                                adding === true ?
                                    <button type="button" className="btn btn-brand-1 btn-h-40 bg-success px-4" onClick={handleShow}>+ Add</button> : null
                            }
                        </div>
                    </div>
                    <table className="table table-style-1 table-responsive-lg mb-4 table reports" id="reportTable">
                        <thead>
                            <tr>

                                <th scope="col">Template Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">File</th>
                                <th scope="col" className="text-center" width="110">{editing === true || deleting === true ? 'Action' : null}</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/word-template/'}
                            updateList={getData}
                        />
                        <tbody>
                            {
                                loading === false ? getListData.map((res, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{res.name}</td>
                                            <td title={res.description}>{res.description.length > 100 ? `${res.description.substring(0, 100)}...` : res.description}</td>
                                            {
                                                res.file ?
                                                    <td><button type="button" className="btn btn-action" onClick={() => window.open(res.fill_full_name, "_blank")}><i className="icofont-eye-alt"></i></button></td>
                                                    :
                                                    <td></td>
                                            }
                                            <td className="text-center">
                                                {
                                                    editing === true ?
                                                        <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                }
                                                {/* UnCommenting the following code for WOII-29. Divya. August 28 2023.. Code Start */}
                                                 {
                                                    deleting === true ?
                                                        <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-trash"></i></button> : null
                                                } 
                                                 {/* UnCommenting end. Divya. August 28 2023.. */}

                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                    {
                        getListData.length === 0 && loading === false && global.new_key ?
                            <SearchNoDataFound message={'No Word Template Found'} />
                            : getListData.length === 0 && loading === false ? <NoData /> : null
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">
                            <Pagination
                                pages={pageCount}
                                endPoint={'/word-template/list/'}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                            />
                        </nav>
                    </div>
                </div>
            </div>

            {/* Add Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={132.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{id ? 'Edit Word Template' : 'Add Word Template'}</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Template Name </label>
                                <input type="text" className="form-control" value={formFields['templateName']} name="templateName" onChange={handleChange} />
                                <span className="text-danger">{flag === true && formFields.templateName === '' ? 'Please Enter Template Name' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description</label>
                                <textarea className="form-control h-auto" id="" cols="30" rows="3" value={formFields['description']} name="description" onChange={handleChange} />
                                <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Select file</label>
                                <div className="input-group mb-3">
                                    <div className="custom-file">
                                        <input type="file" accept=".doc, .docx,.txt,.pdf" className="custom-file-input" id="inputGroupFile02" onChange={onChangeHandler} />
                                        <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file === undefined ? 'Choose File' : file.name}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button type="button" className="btn btn-brand-1 btn-h-50 bg-success px-4" onClick={() => save()} disabled={buttonLoader}>
                        {buttonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>



        </>
    )
}
export default WordTemplates;