import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { NavLink, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Pagination from '../../../../components/Pagination';
import moment from "moment";
import Loader from '../../../../components/Loader';
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import { useToasts } from 'react-toast-notifications';
import ReactModal from 'react-modal-resizable-draggable';
import { CONTRACTS, isCreate, isDelete, isEdit, isView, MANAGE_CONTRACTS, MANAGE_OPPORTUNITIES, MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_AWARDS, PROPOSAL_TEMPLATE, PROPOSAL_TEMPLATE_APPROVAL, RFXS, roleHelper } from "../../../../utility/utility";
import ModalPopup from "../../../../components/ModalPopUp";


const RfxList = (props) => {
    const [loading, setLoading] = useState(false);
    const [getListData, setGetListData] = useState([]);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [RFxStatusData, setRFxStatusData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [proposalTemplate, setProposalTemplate] = useState(false);
    const [proposalExists, setProposalExists] = useState(false);
    const [awardExists, setAwardExists] = useState(false);
    const [contractExists, setContractExists] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);

    const [timer, setTimer] = useState(0);
    

    const [formFields, setFormFields] = useState({
        businessUnitId: '', rfxClient: '', rfxTitle: '', rfxNumber: '', rfxStatus: '', rfxCategory: '', fromDate: undefined, toDate: undefined
    })
    // page
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // Add modal box
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const history = useHistory();
    const location = useLocation();
    const { addToast } = useToasts();
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [goStatusButtonLoader, setGoStatusButtonLoader] = useState('')

    useEffect(() => {
        var value = localStorage.getItem('rfxlist_page')
        var final_data = value && JSON.parse(value);
        getRFxList(final_data ? final_data.page : 1);
        getBusinessUnitList()
        getRFxStatusList()
        getRfxCategoryList()
            }, [])

    useEffect(() => {
        const interval = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1000);
        }, 1000);
        return () => clearInterval(interval);
      }, []);

    var access_Proposal_template = roleHelper(MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_TEMPLATE)
    var access_Proposal_actions = roleHelper(MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_TEMPLATE_APPROVAL)
    var access_award_action = roleHelper(MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_AWARDS)
    var access_contract_action = roleHelper(MANAGE_CONTRACTS, CONTRACTS)
    var access_rfx = roleHelper(MANAGE_OPPORTUNITIES, RFXS)

    function formatDuration(durationInMilliseconds) {
        const seconds = Math.floor((durationInMilliseconds / 1000) % 60);
        const minutes = Math.floor((durationInMilliseconds / (1000 * 60)) % 60);
        const hours = Math.floor((durationInMilliseconds / (1000 * 60 * 60)) % 24);
        const days = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24));

        const formattedDays = days.toString().padStart(2, '0');
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
    
        return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    
        const getRFxList = (page, key) => {
        setLoading(true)
        localStorage.removeItem("rfxlist_page");
        const new_key = key === undefined ? '' : key
        var method = 'GET'
        var filter_start_date = ''
        if (formFields.fromDate) {
            filter_start_date = moment(formFields.fromDate).format('DD-MM-YYYY') == moment(formFields.toDate).format('DD-MM-YYYY') ? '' : moment(formFields.fromDate).format('DD-MM-YYYY')
        }
        const filter_end_date = formFields.toDate === undefined ? '' : moment(formFields.toDate).format('DD-MM-YYYY')
        const apifetcherObj = APIMethod(`/pre-rfx/rfx/list/${page}?isApproval=false&is_proposal=false&client=${formFields.rfxClient}&rfx_number=${formFields.rfxNumber}&business_unit_id=${formFields.businessUnitId}&category=${formFields.rfxCategory}&closing_from_date=${filter_start_date}&closing_to_date=${filter_end_date}&status=${formFields.rfxStatus}&is_proposal=${proposalExists}&is_template=${proposalTemplate}&is_award=${awardExists}&is_contract=${contractExists}&keyword=${new_key || formFields.rfxTitle}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                

                if (statusCode === 200) {
                    setGetListData(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                        localStorage.setItem("rfxlist_count", JSON.stringify({ pageCount: data.count }))
                    }
                    else{
                        const value = localStorage.getItem("rfxlist_count")
                        const final_data = value && JSON.parse(value)
                        setPageCount(final_data.pageCount);
                        // console.log("page "+page+"count "+pageCount)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const nextPage = (pageNumber) => {
        getRFxList(pageNumber)
    }

    const getBusinessUnitList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getRFxStatusList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-statuses/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxStatusData(data)
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getRfxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const setFromDate = (date) => {
        const formFieldsObject = {
            ...formFields,
            fromDate: date
        }
        setFormFields(formFieldsObject);
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const _onFilter = () => {
        getRFxList(1)
        handleClose()
    }

    const Search = (key) => {
        getRFxList(1, key)
    }

    const _onResetFilter = (type) => {
        if (type === 'BUSINESS_UNIT_TYPE') {
            formFields.businessUnitId = "";
        } else if (type === 'RFX_CLIENT') {
            formFields.rfxClient = "";
        } else if (type === 'RFX_TITLE') {
            formFields.rfxTitle = "";
        } else if (type === 'RFX_NUMBER') {
            formFields.rfxNumber = "";
        } else if (type === 'RFX_STATUS') {
            formFields.rfxStatus = "";
        } else if (type === 'RFX_CATEGORY') {
            formFields.rfxCategory = "";
        } else if (type === 'FROM_DATE') {
            formFields.fromDate = undefined;
        } else if (type === 'TO_DATE') {
            formFields.toDate = undefined;
        } else if (type === 'PROPOSAL_TEMPLATE_EXISTS') {
            setProposalTemplate(false);
        } else if (type === 'PROPOSAL_EXISTS') {
            setProposalExists(false);
        } else if (type === 'AWARD_EXISTS') {
            setAwardExists(false);
        } else if (type === 'CONTRACT_EXISTS') {
            setContractExists(false);
        } else {
            formFields.businessUnitId = "";
            formFields.rfxClient = "";
            formFields.rfxTitle = "";
            formFields.rfxNumber = "";
            formFields.rfxStatus = "";
            formFields.rfxCategory = "";
            formFields.fromDate = undefined;
            formFields.toDate = undefined;
            setProposalTemplate(false);
            setProposalExists(false);
            setAwardExists(false);
            setContractExists(false);
            handleClose()
        }
        getRFxList(1)

    }

    const dateValidation = (date) => {
        if (formFields.fromDate) {
            const formFieldsObject = {
                ...formFields,
                toDate: date
            }
            setFormFields(formFieldsObject);
        } else {
            addToast('Please select RFx closing from date first', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
        }
    }

    const onStatusUpdate = (rfxData, status, type) => {
        setButtonLoader(true)
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/action/event/${status}/${rfxData.id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    if (type === 'TEMPLATE') {
                        history.push({ pathname: `/add-proposal-template/${rfxData.id}`, state: { detail: rfxData.id, page: 'Add', title: rfxData.title, rfx_number: rfxData.rfx_number, from: 'RFxs', status: rfxData.t_status } })
                    } else {
                        updateRfxStatus(rfxData)
                    }
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setButtonLoader(false)
                }
            })
            .catch(error => {
                setDoneButton(false)
                setButtonLoader(false)
            })
    }

    const updateRfxStatus = (rfxData) => {
        var method = 'PUT'
        var params = JSON.stringify({
            id: rfxData.id,
            proposal_status: 'PENDING',
            is_proposal_exist: true,
            proposal_start_date: moment(new Date()).format('DD/MM/YYYY')
        })
        const apifetcherObj = APIMethod(`/pre-rfx`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    history.push({ pathname: `/mp-genrate-proposal/${rfxData.id}`, state: { detail: rfxData.id, rfx_number: rfxData.rfx_number ? rfxData.rfx_number : null, rfx_title: rfxData.title ? rfxData.title : null, from: 'RFxs' } })
                    setButtonLoader(true)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setButtonLoader(false)
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setButtonLoader(false)
            })
    }

    const updateGoStatus = (status, id) => {
        setButtonLoader(true)
        const currentDatetime = new Date();
        const year = currentDatetime.getUTCFullYear();
        const month = String(currentDatetime.getUTCMonth() + 1).padStart(2, '0');
        const day = String(currentDatetime.getUTCDate()).padStart(2, '0');
        const hours = String(currentDatetime.getUTCHours()).padStart(2, '0');
        const minutes = String(currentDatetime.getUTCMinutes()).padStart(2, '0');
        const seconds = String(currentDatetime.getUTCSeconds()).padStart(2, '0');
        const formattedDatetime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
        
        var method = 'PUT'
        var params = JSON.stringify({
            ai_status: status,
            timer_stamp:formattedDatetime,
            id: id
        })
        console.log(params)
        setTimeout(() => {
            setButtonLoader(false)
        }, 1000 * 10);
        const apifetcherObj = APIMethod(`/pre-rfx/ai-status`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                

                if (statusCode === 200) {
                    getRFxList(currentPage)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    getRFxList(1)
                }
                setButtonLoader(false)
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setButtonLoader(false)
            })
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const getData = () => {
        getRFxList(currentPage);
    }

    // console.log("fd",goStatusButtonLoader)

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <Loader
                        isLoading={buttonLoader}
                        type={'FULLPAGE'}
                    />
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-4 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">RFxs</h2>
                        </div>


                        <div className="col-lg-7 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">

                                <input type="search" className="form-control" placeholder="Search by RFx title" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            <button type="button" className="btn btn-brand-1 btn-h-40 mr-3" onClick={handleShow}><i className="icofont-filter mr-2"></i> Filter</button>
                            <NavLink to="/parse-rfx" className="btn btn-brand-1 bg-info px-3" style={{ minWidth: "130px", height: "40px" }}>Parse an RFx</NavLink>
                        </div>

                    </div>

                    <div className="mb-2 filter-by">
                        {
                            formFields.businessUnitId ?
                                <div className="badge">
                                    <span className="lable">Business Unit Type</span>
                                    {
                                        businessUnitData.map((res, index) => {
                                            return (
                                                <span key={index} className="value">{formFields.businessUnitId == res.id ? res.name : null}</span>
                                            )
                                        })
                                    }
                                    <i className="icofont-close" onClick={() => _onResetFilter('BUSINESS_UNIT_TYPE')}></i>
                                </div> : null
                        }
                        {
                            formFields.rfxClient ?
                                <div className="badge">
                                    <span className="lable">Client / Agency</span>
                                    <span className="value">{formFields.rfxClient}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('RFX_CLIENT')}></i>
                                </div> : null
                        }
                        {
                            formFields.rfxTitle ?
                                <div className="badge">
                                    <span className="lable">RFx Title</span>
                                    <span className="value">{formFields.rfxTitle}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('RFX_TITLE')}></i>
                                </div> : null
                        }
                        {
                            formFields.rfxNumber ?
                                <div className="badge">
                                    <span className="lable">RFx Number</span>
                                    <span className="value">{formFields.rfxNumber}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('RFX_NUMBER')}></i>
                                </div> : null
                        }
                        {
                            formFields.rfxStatus ?
                                <div className="badge">
                                    <span className="lable">RFx Status</span>
                                    {
                                        RFxStatusData.map((res, index) => {
                                            return (
                                                <span key={index} className="value">{formFields.rfxStatus == res.id ? res.name : null}</span>
                                            )
                                        })
                                    }
                                    <i className="icofont-close" onClick={() => _onResetFilter('RFX_STATUS')}></i>
                                </div> : null
                        }
                        {
                            formFields.rfxCategory ?
                                <div className="badge">
                                    <span className="lable">RFx Category</span>
                                    {
                                        RFxCategoryData.map((res, index) => {
                                            return (
                                                <span key={index} className="value">{formFields.rfxCategory == res.id ? res.name : null}</span>
                                            )
                                        })
                                    }
                                    <i className="icofont-close" onClick={() => _onResetFilter('RFX_CATEGORY')}></i>
                                </div> : null
                        }
                        {
                            formFields.fromDate ?
                                <div className="badge">
                                    <span className="lable">From Date</span>
                                    <span className="value">{moment(formFields.fromDate).format('MM-DD-YYYY')}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('FROM_DATE')}></i>
                                </div> : null
                        }
                        {
                            formFields.toDate ?
                                <div className="badge">
                                    <span className="lable">To Date</span>
                                    <span className="value">{moment(formFields.toDate).format('MM-DD-YYYY')}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('TO_DATE')}></i>
                                </div> : null
                        }
                        {
                            proposalTemplate === true ?
                                <div className="badge">
                                    <span className="lable">Proposal Template Exists but not Proposal</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('PROPOSAL_TEMPLATE_EXISTS')}></i>
                                </div> : null

                        }
                        {
                            proposalExists === true ?
                                <div className="badge">
                                    <span className="lable">Proposal Exists</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('PROPOSAL_EXISTS')}></i>
                                </div> : null
                        }
                        {
                            awardExists === true ?
                                <div className="badge">
                                    <span className="lable">Award Exists</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('AWARD_EXISTS')}></i>
                                </div> : null
                        }
                        {
                            contractExists === true ?
                                <div className="badge">
                                    <span className="lable">Contract Exists</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('CONTRACT_EXISTS')}></i>
                                </div> : null
                        }
                    </div>

                    <div className="table-responsive pb-3" style={{ overflowY: "hidden" }}>
                        <table className="table table-style-1 mb-4" id="">
                            <thead>
                                <tr>
                                    <th scope="col">BU</th>
                                    <th scope="col" style={{ minWidth: "125px", textTransform: 'none' }}>RFx TITLE</th>
                                    <th scope="col" style={{ width: 181, minWidth: "125px", textTransform: 'none' }}>RFx STATUS</th>
                                    <th scope="col">Client/Agency</th>
                                    <th scope="col" style={{ minWidth: "125px", textTransform: 'none' }}>RFx DUE</th>
                                    <th scope="col" style={{ minWidth: "125px", textTransform: 'none' }}>OWNER</th>
                                    <th scope="col" style={{ textTransform: 'none', minWidth: "115px" }}>RFxs</th>
                                    <th scope="col" style={{ minWidth: "125px" }}>Proposal Templates</th>
                                    <th scope="col" style={{ minWidth: "125px" }}>Proposals</th>
                                    <th scope="col" style={{ minWidth: "125px" }}>Awards</th>
                                    <th scope="col" style={{ minWidth: "125px" }}>Contracts</th>

                                </tr>
                            </thead>
                            <ModalPopup
                                showModalPopup={showModalPopup}
                                onPopupClose={isShowPopup}
                                id={deleteId}
                                endPoint={'/pre-rfx/'}
                                updateList={getData}
                            ></ModalPopup>
                            <tbody>
                                {
                                    loading === false ? getListData.map((res, index) => {
                                        var rfx_date = moment(res.created_date).format('YYYY-MM-DD')
                                        var proposal_template_date = moment(res.proposal_template_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
                                        var proposal_start_date = res.proposal_start_date && moment(res.proposal_start_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
                                        var a = moment(proposal_template_date);
                                        var b = moment(rfx_date);
                                        var c = moment(proposal_start_date)
                                        var first = a.diff(b, 'days')
                                        var second = c.diff(b, 'days')
                                        {res.timer_stamp == undefined ? 'N/A' : res?.timer_stamp}
                                        if (res.timer_stamp==undefined){
                                            var timestamp = new Date(res.created_date);
                                        }
                                        else{
                                            var timestamp = new Date(res.timer_stamp);
                                        }
                                        var currentTime = new Date();
                                        var date = new Date(timestamp);
                                        var year = date.getFullYear();
                                        var month = date.getMonth() + 1;
                                        var day = date.getDate();
                                        return (
                                            <tr key={index}>
                                                <td>{res.business_unit === 0 ? null : res.business_name}</td>
                                                <td title={res.title}>{res.title == undefined ? '' : res?.title.replace(/(.{30})..+/, "$1…")}</td>
                                                <td style={{ width: 181 }}>{res.rfx_status_name}</td>
                                                <td>{res.rfx_client_agency == undefined ? '' : res.rfx_client_agency.replace(/(.{20})..+/, "$1…")}</td>
                                                <td>{res.due && moment(res.due, 'DD/MM/YYYY hh:mm:ss').format("MM-DD-YYYY")}</td>
                                                <td owner={res.owner}>{res.owner == undefined || res.owner=='undefined' ? (res.creator == undefined ? 'N/A' : res?.creator) : res?.owner}</td>
                                                
                                                {
                                                    res.ai_status == 'COMPLETED' &&
                                                    <td className="text-center">
                                                        <button
                                                        type="button"
                                                        className="btn btn-action edit mr-1"
                                                        disabled={!isEdit(access_rfx)}
                                                        onClick={() => {
                                                            localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage }));
                                                            history.push({
                                                            pathname: "/parse-rfx-detail",
                                                            state: { detail: res.id, rfx_status_name: res.rfx_status_name },
                                                            });
                                                        }}
                                                        >
                                                        <i className="icofont-pencil-alt-2"></i>
                                                        </button>
                                                        <button type="button" className="btn btn-action" onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: "/view-parse-rfx-detail", state: { detail: res.id, page: 'View' } }); }}><i className="icofont-eye-alt"></i></button>
                                                        <button type="button" className="btn btn-action delete ml-1" onClick={() => _onDelete(res.id)} disabled={!isDelete(access_rfx)}><i className="icofont-trash"></i></button>
                                                        <div className="rfx-dates">RFx Created on {moment(res.created_date).format('MM/DD/YYYY')}</div>
                                                    </td>
                                                }

                                                {/* Proposal Template */}
                                                {
                                                    res.ai_status == 'COMPLETED' &&
                                                    <td className="text-center" >
                                                        <button type="button" className="btn btn-action edit mr-1" disabled={!isEdit(access_Proposal_template) || res.is_proposal_template_exist_but_not_proposal == false || res.p_status == 'Proposal Is Approved' || res.p_status == 'Proposal Sent for Approval' || res.p_status == 'Proposal is Pushedback' || res.p_status == 'Proposal Is Denied'} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: `/edit-proposal-template/${res.id}`, state: { detail: res.id, page: 'template', status: res.t_status, title: res.title, rfx_number: res.rfx_number, from: 'RFxs' } }); }}><i className="icofont-pencil-alt-2"></i></button>
                                                        <button type="button" className="btn btn-action mr-1" disabled={!isView(access_Proposal_template) || res.is_proposal_template_exist_but_not_proposal == false} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: `/edit-proposal-template/${res.id}`, state: { detail: res.id, page: 'View', status: res.t_status, title: res.title, rfx_number: res.rfx_number, from: 'RFxs' } }); }}><i className="icofont-eye-alt"></i></button>
                                                        <button type="button" className="btn btn-action bg-success" disabled={!isCreate(access_Proposal_template) || res.is_proposal_template_exist_but_not_proposal == true || res.rfx_status_name == 'Open'} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); onStatusUpdate(res, 'GENERATE_PROPOSAL_TEMPLATE', 'TEMPLATE'); }}><i className="icofont-plus"></i></button>
                                                        {
                                                            res.proposal_template_date ? <div className="rfx-dates">Created {first} Days ago since RFx creation</div> : <div className="rfx-dates">Not Created</div>
                                                        }
                                                    </td>
                                                }
                                                {/* Proposals */}
                                                {
                                                    res.ai_status == 'COMPLETED' &&
                                                    <td className="text-center" >
                                                        <button type="button" className="btn btn-action edit mr-1" disabled={!isEdit(access_Proposal_actions) || res.is_proposal_exist == false} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: `/mp-genrate-proposal/${res.id}`, state: { detail: res.id, rfx_number: res.rfx_number ? res.rfx_number : null, rfx_title: res.title ? res.title : null, from: 'RFxs', page: 'Edit', status: res.p_code } }); }}><i className="icofont-pencil-alt-2"></i></button>
                                                        <button type="button" className="btn btn-action mr-1" disabled={!isView(access_Proposal_actions) || res.is_proposal_exist == false} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: `/mp-genrate-proposal/${res.id}`, state: { detail: res.id, rfx_number: res.rfx_number ? res.rfx_number : null, rfx_title: res.title ? res.title : null, page: 'View', from: 'RFxs', status: res.p_code } }); }}><i className="icofont-eye-alt"></i></button>
                                                        <button type="button" className="btn btn-action bg-success" disabled={!isCreate(access_Proposal_actions) || res.is_proposal_exist == true || res.t_status != 'Proposal Template Approved'} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); onStatusUpdate(res, 'GENERATE_PROPOSAL', 'TEMPLATE'); }}><i className="icofont-plus"></i></button>
                                                        {
                                                            res.proposal_start_date ? <div className="rfx-dates">Created {second} Days ago since RFx creation</div> : <div className="rfx-dates">Not Created</div>
                                                        }
                                                    </td>
                                                }
                                                {/* Awards */}
                                                {
                                                    res.ai_status == 'COMPLETED' &&
                                                    <td className="text-center">
                                                        <button type="button" className="btn btn-action edit mr-1" disabled={!isEdit(access_award_action) || res.is_award_exist == false} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: "/edit-proposal-awards", state: { detail: res, from: 'RFxs', page: 'Edit' } }); }}><i className="icofont-pencil-alt-2"></i></button>
                                                        <button type="button" className="btn btn-action mr-1" disabled={!isView(access_award_action) || res.is_award_exist == false} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: "/edit-proposal-awards", state: { detail: res, page: 'View', from: 'RFxs' } }); }}><i className="icofont-eye-alt"></i></button>
                                                        <button type="button" className="btn btn-action bg-success" disabled={!isCreate(access_award_action) || res.is_award_exist == true || res.p_code != 'PIC'} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: "/add-proposal-awards", state: { detail: res, from: 'RFxs' } }); }}><i className="icofont-plus"></i></button>
                                                        {
                                                            res.reward ? <div className="rfx-dates">Created on {moment(res.reward).format('MM/DD/YYYY')}</div> : <div className="rfx-dates">Not Created or Not awarded</div>
                                                        }
                                                    </td>
                                                }
                                                {/* Contracts */}
                                                {
                                                    res.ai_status == 'COMPLETED' &&
                                                    <td className="text-center" >
                                                        <button type="button" className="btn btn-action edit mr-1" disabled={!isEdit(access_contract_action) || res.is_contract_exist == false || res.is_award == false} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: "/edit-contract", state: { detail: res, from: 'RFxs' } }); }}><i className="icofont-pencil-alt-2"></i></button>
                                                        <button type="button" className="btn btn-action mr-1" disabled={!isView(access_contract_action) || res.is_contract_exist == false || res.is_award == false} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: "/edit-contract", state: { detail: res, page: 'View', from: 'RFxs' } }); }}><i className="icofont-eye-alt"></i></button>
                                                        <button type="button" className="btn btn-action bg-success" disabled={!isCreate(access_contract_action) || res.is_contract_exist == true || res.is_award_exist == false || res.is_award == false} onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: "/add-contract", state: { detail: res, from: 'RFxs' } }); }}><i className="icofont-plus"></i></button>
                                                        {
                                                            res.contract_created ? <div className="rfx-dates">Created on {moment(res.contract_created).format('MM/DD/YYYY')}</div> : <div className="rfx-dates">Not Created</div>
                                                        }
                                                    </td>
                                                }
                                                {
                                                    res.ai_status != 'COMPLETED' &&
                                                    <td colSpan={5} className="text-center">{res.ai_message == "Failed to parsing RFX data" ? (
                                                        <div>{res.ai_message} : "  
                                                          {res.ai_error}" Error  
                                                         </div>
                                                      ) : null} 
                                                        
                                                        {res.ai_status === 'IN_PROGRESS' ? (
                                                            
                                                            <span>
                                                                <div style={{display:"inline-block", verticalAlign:"middle", width:"400px"}}>The RFx data extraction started at {(timestamp.getMonth() + 1).toString().padStart(2, '0')}/{timestamp.getDate().toString().padStart(2, '0')}/{timestamp.getFullYear()} {timestamp.getHours().toString().padStart(2, '0')}:{timestamp.getMinutes().toString().padStart(2, '0')}<br /> The process has been running for {formatDuration(currentTime - timestamp)}</div>    
                                                            </span>
                                                            ) : (
                                                            null
                                                            )}
                                                    {res.ai_status == 'FAILED' && <>
                                                        <button type="button" className="btn btn-lg btn-brand-1 mr-3 ml-3" style={{ paddingBottom: 30 }} onClick={() => updateGoStatus('IN_PROGRESS', res.id)} disabled={goStatusButtonLoader == 'IN_PROGRESS' ? true : false}>{goStatusButtonLoader == 'IN_PROGRESS' && (
                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                        )}Retry</button>
                                                        <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" style={{ paddingBottom: 30 }} onClick={() => updateGoStatus('FAILED', res.id)} disabled={goStatusButtonLoader == 'FAILED' ? true : false}>{goStatusButtonLoader == 'FAILED' && (
                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                        )}Force Go</button>
                                                    </>}
                                                        {res.ai_status == 'IN_PROGRESS' && <>
                                                        <button
                                                            type="button"
                                                            className="btn btn-action"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="If you would like to view the RFx data the way it’s processed as of now, you can click on View RFx. Or you can click on 'Stop and Go' if you would like to stop the process and enter the data manually."
                                                            onClick={() => { localStorage.setItem("rfxlist_page", JSON.stringify({ page: currentPage })); history.push({ pathname: "/view-parse-rfx-detail", state: { detail: res.id, page: 'View' } }); }}
                                                            >
                                                            <i className="icofont-eye-alt"></i>
                                                            </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-lg btn-brand-1 bg-danger mr-3"
                                                            style={{ paddingBottom: 30, marginLeft: "23px" }}
                                                            onClick={() => updateGoStatus('COMPLETED', res.id)}
                                                            disabled={goStatusButtonLoader == 'COMPLETED' ? true : false}
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Parsing process is being run. This process extracts information from the RFx and populates the data. You can click on Stop and Go if you need to move the RFx further, urgently. But you will have to manually enter the data."
                                                            data-html="true" // Add this to enable HTML content in the tooltip
                                                            >
                                                            {goStatusButtonLoader == 'COMPLETED' && (
                                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                            )}
                                                            Stop and Go
                                                            </button>





            
                                                        </>}
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    }) : null
                                }
                            </tbody>
                        </table>
                        {
                            getListData.length === 0 && loading === false && global.new_key ?
                                <SearchNoDataFound message={'No RFx Found'} />
                                : getListData.length === 0 && loading === false ? <SearchNoDataFound message={'No RFx Found'} /> : null
                        }
                        {/* {
                            <Loader
                                isLoading={loading}
                            />
                        } */}
                    </div>
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">
                            <Pagination
                                pages={pageCount}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                            />
                        </nav>
                    </div>

                </div>
            </div>


            {/* Filter Modal Box */}
            <ReactModal
                initWidth={750}
                initHeight={630}
                top={137.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Filter Search RFxs</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Business Unit</label>
                                <select id="disabledSelect" className="form-control custom-select" onChange={handleChange} name="businessUnitId" value={formFields['businessUnitId']}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        businessUnitData.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Client / Agency</label>
                                <input type="text" className="form-control" onChange={handleChange} name="rfxClient" value={formFields['rfxClient']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Title</label>
                                <input type="text" className="form-control" onChange={handleChange} name="rfxTitle" value={formFields['rfxTitle']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Number</label>
                                <input type="text" className="form-control" onChange={handleChange} name="rfxNumber" value={formFields['rfxNumber']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Status</label>
                                <select id="disabledSelect" className="form-control custom-select " onChange={handleChange} name="rfxStatus" value={formFields['rfxStatus']}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        RFxStatusData.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Category</label>
                                <select id="disabledSelect" className="form-control custom-select " onChange={handleChange} name="rfxCategory" value={formFields['rfxCategory']}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        RFxCategoryData.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Closing From Date</label>
                                <DatePicker
                                    className="form-control"
                                    selected={formFields.fromDate}
                                    onChange={(date) => setFromDate(date)}
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    popperPlacement="top"
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Closing To Date </label>
                                <DatePicker
                                    className="form-control"
                                    selected={formFields.toDate}
                                    onChange={(date) => dateValidation(date)}
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    popperPlacement="top"
                                    minDate={formFields.fromDate}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="checkbox_1" onChange={() => setProposalTemplate(!proposalTemplate)} value={proposalTemplate} defaultChecked={proposalTemplate} />
                                    <label className="custom-control-label" for="checkbox_1">Proposal Template Exists but not Proposal</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="checkbox_2" onChange={() => setProposalExists(!proposalExists)} value={proposalExists} defaultChecked={proposalExists} />
                                    <label className="custom-control-label" for="checkbox_2">Proposal Exists</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="checkbox_3" onChange={() => setAwardExists(!awardExists)} value={awardExists} defaultChecked={awardExists} />
                                    <label className="custom-control-label" for="checkbox_3">Award Exists</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="checkbox_4" onChange={() => setContractExists(!contractExists)} value={contractExists} defaultChecked={contractExists} />
                                    <label className="custom-control-label" for="checkbox_4">Contract Exists</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button className="btn btn-lg btn-brand-1" onClick={() => _onResetFilter()}>Reset</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onFilter()}>Search</button>
                </div>
            </ReactModal>
        </>
    )
}
export default RfxList;