import React, { useEffect, useState } from "react";
import Leftnavbar from '../../../../components/Leftnavbar';
import { Breadcrumb, Tabs, Tab, Nav, Row, Col, Modal } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css"
import Form from 'react-bootstrap/Form'
import { NavLink, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useToasts } from "react-toast-notifications";
import { APIMethod } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import RfxData from '../../../../components/RfxData';
import RawRfx from '../../../../components/RawRFx';
import SectionizedRFx from '../../../../components/SectionizedRFx';


const GenerateProposalMp = () => {
    // Upload modal box
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [startDateTime, setStartDateTime] = useState(new Date());
    const [rfxData, setRfxData] = useState([]);
    const [preRfxData, setPreRfxData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const location = useLocation();
    const [url, setUrl] = useState("");
    const [formFields, setFormFields] = useState({ businessUnit: '', rfxNumber: '', rfxTitle: '', rfxType: '', url: '' })
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }

    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    //---Date Time picker
    //coollapse menu - 1
    const CollapseMenu1 = () => {
        var element = document.getElementById("PraposalTemp");
        element.classList.toggle("collapse-tab");
    }

    useEffect(() => {
        getRfxData()
        getPreRfxData()
    }, [])

    const getRfxData = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/document-section/${location.state.detail}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRfxData(data)
                    setUrl(`https://docs.google.com/gview?url=${data.url}&embedded=true`);
                    setLoading(false)
                    //  setFlag(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }
    const getPreRfxData = (key) => {
        setLoading(true)
        const new_key = key === undefined ? '' : key
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/${location.state.detail}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setPreRfxData(data)
                    setLoading(false);

                } else {
                }

            })

            .catch(error => {
                alert(error)
                setLoading(false);
            })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }


    const _onSendForApproval = () => {
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/action/event/PROPOSAL_SEND_FOR_APPROVAL/${location.state.detail}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    addToast(data.message, { appearance: 'success', autoDismiss: true });
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                alert(error)
            })
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item >Proposal Templates</Breadcrumb.Item>
                            <Breadcrumb.Item >Add Proposal Templates</Breadcrumb.Item>
                            <Breadcrumb.Item active>Generate Proposal</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <h2 className="page-title ">Generate Proposal </h2>
                    <div className="mb-3">
                        <a href="javascript:void(0)" onClick={CollapseMenu1}>
                            <img src="images/collapse-icon.svg" width="27" />
                        </a>
                    </div>
                    <div className="text-right my-3">
                        <a className="btn btn-brand-1 btn-sm mr-3 px-4" href="/add-proposal-template">Edit Template</a>
                        <a className="btn btn-brand-1 btn-sm mr-3 px-4" href="/generate-word-template">Generate Word Proposal </a>
                        <a className="btn btn-brand-1 btn-sm mr-3 px-4" href="/rfx-list">Format Resume</a>
                    </div>
                    <div className="d-flex overflow-auto pb-4" >
                        <div className="mb-4" id="PraposalTemp">
                            <Tab.Container id="controlled-tab-example" defaultActiveKey="SearchRep">
                                <Row>
                                    <div className="tab-style-1 col-12 d-flex">
                                        <Nav className="d-flex  nav nav-tabs ">
                                            <Nav.Item className="mr-3">
                                                <Nav.Link eventKey="SearchRep">Search Content Repo</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="RfxData" className="mr-3">RFx Data</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="ViewRaw">View RAW RFx</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="ViewSectionizedRfx">View Sectionized RFx</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="PreDefineSectionsofRfx">Pre-Define Sections of RFx</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <Col sm={12} className="tab-style-2">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="SearchRep">
                                                <h4 className="font-16">Content Library Search</h4>
                                                <div className="form-wraper form-style mb-4">
                                                    <div className="row">
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label>Topic/Question</label>
                                                                <input type="text" className="form-control" value="" disabled />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label>Rfx Category</label>
                                                                <select id="disabledSelect" className="form-control custom-select" name="contractStatus" >
                                                                    <option value="" disabled selected>Staff Aug</option>
                                                                    <option>--</option>
                                                                    <option>--</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label>Keywords/Tags:</label>
                                                                <input type="text" className="form-control" name="contractNumber" />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label>Content Entered From Date</label>
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                    className="form-control"
                                                                    placeholder="select Date"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    minDate={new Date()}
                                                                    scrollableYearDropdown
                                                                    showYearDropdown
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label>Content Entered To Date</label>
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                    className="form-control"
                                                                    placeholder="select Date"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    minDate={new Date()}
                                                                    scrollableYearDropdown
                                                                    showYearDropdown
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 text-center mt-4">
                                                            <button type="button" className="btn btn-lg btn-brand-1 mr-3">Search</button>
                                                        </div>

                                                        <div className="col-12 my-3">
                                                            <table className="table table-style-1 table-responsive-lg mb-4 table ">
                                                                <tr>
                                                                    <th>Question</th>
                                                                    <th>Answer</th>
                                                                </tr>

                                                                <tr>
                                                                    <td>--</td>
                                                                    <td>--</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div className="col-10 my-3">
                                                            <table className="table table-style-1 table-responsive-lg mb-4">
                                                                <tr>
                                                                    <th>File Name</th>
                                                                    <th>RFP Title</th>
                                                                    <th>Client Name</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>--</td>
                                                                    <td>--</td>
                                                                    <td>--</td>
                                                                </tr>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className=" text-center mt-4">
                                                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={goBack}>Save for latter</button>
                                                    <button type="button" className="btn btn-lg btn-brand-1 mr-3">Save</button>
                                                    <button className="btn btn-brand-1  px-4" onClick={() => _onSendForApproval()}>Send for Approval <span className="ml-2 font-22 align-middle"><i className="icofont-long-arrow-right "></i></span></button>
                                                    {/* <NavLink to="/pre-rfx-approvals-filters" className="btn btn-brand-1  px-4">Send for Approval <span className="ml-2 font-22 align-middle"><i className="icofont-long-arrow-right "></i></span></NavLink> */}
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="RfxData">
                                                <RfxData />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="ViewRaw">
                                                <RawRfx
                                                    url={url}
                                                />
                                                <div className="text-center mt-4">
                                                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3">Save for Latter</button>
                                                    <button type="button" className="btn btn-lg btn-brand-1 mr-3">Save</button>
                                                    <a className="btn btn-brand-1  px-4" href="/pre-rfx-approvals-filters">Send for Approval <span className="ml-2 font-22 align-middle"><i className="icofont-long-arrow-right "></i></span></a>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="ViewSectionizedRfx">
                                                <SectionizedRFx />
                                                <div className="text-center mt-4">
                                                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3">Save for Latter</button>
                                                    <button type="button" className="btn btn-lg btn-brand-1 mr-3">Save</button>
                                                    <a className="btn btn-brand-1  px-4" href="/pre-rfx-approvals-filters">Send for Approval <span className="ml-2 font-22 align-middle"><i className="icofont-long-arrow-right "></i></span></a>
                                                </div>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="PreDefineSectionsofRfx">
                                                <h4 className="font-16">View Pre-Defned Sections</h4>
                                                <div className="form-wraper form-style mb-4">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <select id="disabledSelect" className="form-control custom-select" name="RFxStatus">
                                                                    <option value="" disabled="">Scope</option>
                                                                    <option>requirements check list</option>
                                                                    <option>Proposal Strucute</option>
                                                                    <option>Submission instructions</option>
                                                                    <option>Evalution Criteria</option>
                                                                    <option>Contract</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="border p-2">
                                                                    The Information Technology Management Ofce (ITMO) is soliciting bids to establish fxed price contracts for IT Temporary Staf Augmentation Services. As a fxed price bid, Suppliers may be added on a semi-annual basis during the course of
                                                                    the contract. See Section VI. Award Criteria for more information regarding awarding contracts for bids received after initial award. The fxed price IT Temporary Staf Augmentation Services contract will be managed by TAPFIN Process Solutions, a vendor contracted by the State. Suppliers and Hiring Managers will be trained on and use an online vendor management system (VMS) tool known as Beeline.
                                                                    Current Suppliers whose contracts were awarded pursuant to the previous solicitation’s 5400000822, 5400001129, 5400001177, 5400001342 must submit a bid
                                                                    response to this solicitation and be awarded a new contract to be able to provide IT Temporary Staf Augmentation Services to the state of South Carolina. See Section
                                                                    III, regarding current suppliers with consultants on assignments.

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="text-center mt-4">
                                                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3">Save for Latter</button>
                                                    <button type="button" className="btn btn-lg btn-brand-1 mr-3">Save</button>
                                                    <a className="btn btn-brand-1  px-4" href="/pre-rfx-approvals-filters">Send for Approval <span className="ml-2 font-22 align-middle"><i className="icofont-long-arrow-right "></i></span></a>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                        <div style={{ minWidth: "930px" }}>
                            <h4 className="font-16 mb-4">Proposal Sections</h4>
                            <div className="row">
                                <div className="col-md-5 pr-0">
                                    <table className="table table-style-1 table-responsive-lg mb-4 table-striped shadow-none border">
                                        <tr>
                                            <th className="bg-light border-0">Section/Subsection/Question </th>
                                        </tr>
                                        <tr>
                                            <th>Cover Letter </th>
                                        </tr>
                                        <tr>
                                            <td>Company Background</td>
                                        </tr>
                                        <tr>
                                            <td><span className="ml-4">many years the company has been in business?</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="ml-4">Company History</span></td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="col-md-6 p-0">
                                    <table className="table table-style-1 table-responsive-lg mb-4 shadow-none border">
                                        <tr>
                                            <th className="bg-light border-0">Description /Answer</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <textarea className="form-control h-auto mb-3">
                                                </textarea>
                                                <textarea className="form-control h-auto">
                                                </textarea>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="col-md-1 pl-0">
                                    <table className="table table-style-1 table-responsive-lg mb-4 shadow-none border">
                                        <tr>
                                            <th className="bg-light border-0">Action</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <button type="button" className="btn btn-action edit mr-1"><i className="icofont-pencil-alt-2"></i></button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="mt-3">
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-end">

                                <button type="button" className="btn btn-brand-1 mb-2" onClick={handleShow}>Upload</button>
                            </div>

                            <div className="col-12 my-3">
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">

                                    <tr>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        <th>Version</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>

                                    <tr>
                                        <td>RFx</td>
                                        <td>1.0</td>
                                        <td>Rfx_Attachment</td>
                                        <td>First document</td>
                                        <td>
                                            <button type="button" className="btn btn-action edit mr-1"><i className="icofont-pencil-alt-2"></i></button>
                                            <button type="button" className="btn btn-action delete"><i className="icofont-trash"></i></button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h4 className="font-16 mb-0">Proposal Approvals Comment</h4>
                            </div>
                            <div className="card-body bg-white">
                                <div className="bg-light p-2 rounded mb-2">
                                    <p className="mb-1">
                                        <span className="font-medium mr-2">Rehan gmail approval</span>
                                        <span className="text-secondary font-12 mr-3">24-Dec-2021 4:22PM</span>
                                        <span className="badge badge-warning">Pushed Back</span>
                                    </p>
                                    <p className="font-14 mb-0 ">Pushing Back...</p>
                                </div>
                                <div className="bg-light p-2 rounded mb-2">
                                    <p className="mb-1">
                                        <span className="font-medium mr-2">Rehan gmail approval</span>
                                        <span className="text-secondary font-12 mr-3">24-Dec-2021 4:22PM</span>
                                        <span className="badge badge-warning">Pushed Back</span>
                                    </p>
                                    <p className="font-14 mb-0 ">Pushing Back...</p>
                                </div>
                                <div className="form-group form-style">
                                    <label>Add Comment</label>
                                    <textarea className="form-control h-auto mb-2" rows="2" placeholder="Write here.."></textarea>
                                    <button type="button" className="btn btn-brand-1 btn-sm">Post <i className="icofont-paper-plane"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Upload Modal Box */}
            <Modal show={show} onHide={handleClose}

                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="modal-header px-md-5">
                    <h5 className="modal-title" id=""> Upload Proposal</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>

                </div>
                <Modal.Body>
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Document Type</label>
                                <select id="disabledSelect" className="form-control custom-select ">
                                    <option>--</option>
                                    <option>--</option>
                                    <option>--</option>
                                    <option>--</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Name</label>
                                <input type="text" className="form-control" name="description" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Version</label>
                                <input type="text" className="form-control" name="description" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group mr-3">
                                <label>Upload Proposal:</label>
                                <Form.File
                                    id="custom-file"
                                    label="Custom file input"
                                    custom
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description </label>
                                <input type="text" className="form-control" name="description" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger">Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1">Save</button>
                </div>
            </Modal>
        </>
    )
}

export default GenerateProposalMp;