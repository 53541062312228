import React, { useState, useEffect } from "react";
import Leftnavbar from "../../../../components/Leftnavbar";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { NavLink } from "react-router-dom";
import { APIMethod } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import ModalPopup from '../../../../components/ModalPopUp';
import Pagination from '../../../../components/Pagination';
import { useHistory } from "react-router";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";

const ProposalAwards = (props) => {
    const [loading, setLoading] = useState(false);
    const [getListData, setGetListData] = useState([]);
    // Add modal box
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // Edit
    const [edit, setEdit] = useState('');
    const [id, setId] = useState('');
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    // page
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const [adding, setAdding] = useState();
    const [editing, setEditing] = useState();
    const [deleting, setDeleting] = useState();

    useEffect(() => {
        getProposalAwardsList(1)
        var data = JSON.parse(localStorage.getItem("user_Role"))
        checkPermission(data)
    }, [])

    const checkPermission = (data) => {
        const resObject = data.find(item => item.name === "Manage Proposals & Awards")
        const valObject = resObject.access.find(item => item.name === "Proposal Awards")
        setAdding(valObject.is_create)
        setEditing(valObject.is_update)
        setDeleting(valObject.is_delete)
    }

    const getProposalAwardsList = (page, key) => {
        setLoading(true)
        const new_key = key === undefined ? '' : key
        global.new_key = key === undefined ? '' : key
        var method = 'GET'
        const apifetcherObj = APIMethod(`/proposal-award/list/${page}?keyword=` + new_key, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                } else {

                }
            })
            .catch(error => {
                alert(error)
                setLoading(false)
            })
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const Search = (key) => {
        getProposalAwardsList(1, key)
    }

    const nextPage = (pageNumber) => {
        getProposalAwardsList(pageNumber)
    }

    const _onEdit = (res) => {
        history.push({
            pathname: '/edit-proposal-awards',
            state: { detail: res, page: 'Edit', from: 'Proposal Awards' }
        })
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Proposal Awards</h2>
                        </div>
                        <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search by RFx Title" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                        </div>
                    </div>

                    <table className="table table-style-1 table-responsive-lg mb-4 table reports" id="reportTable">
                        <thead>
                            <tr>

                                <th scope="col" style={{ textTransform: 'none' }}>RFx NUMBER</th>
                                <th scope="col" style={{ textTransform: 'none' }}>RFx TITLE</th>
                                <th scope="col">Client</th>
                                <th scope="col">State/Province </th>
                                <th scope="col">Awarded? </th>
                                <th scope="col">Award Notice?</th>
                                <th scope="col" className="text-center" width="110">Action</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/proposal-award/'}
                            updateList={getProposalAwardsList}
                        ></ModalPopup>
                        <tbody>
                            {
                                getListData.map((res, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{res.rfx_number}</td>
                                            <td title={res.rfx_title}>{res.rfx_title}</td>
                                            <td>{res.client_name}</td>
                                            <td>{res.state_name}</td>
                                            <td>{res.is_awarded === false ? "N" : "Y"}</td>
                                            <td>{res.is_award_notice === false ? "N" : "Y"}</td>
                                            <td className="text-center">
                                                {editing === true ? <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button> : null}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {
                        getListData.length === 0 && loading === false && global.new_key ?
                            <SearchNoDataFound message={'No Proposal Award Found'} />
                            : getListData.length === 0 && loading === false ?<SearchNoDataFound message={'No Proposal Award Found'} /> : null
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">
                            <Pagination
                                pages={pageCount}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                            />
                        </nav>
                    </div>
                </div>
            </div>


            {/* Filter Modal Box */}
            <Modal show={show} onHide={handleClose}
                {...props}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="modal-header px-md-5">
                    <h5 className="modal-title" id="">Filter Search Proposals Awards</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <Modal.Body>
                    <div className="row form-style">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Business Unit</label>
                                <select id="disabledSelect" className="form-control custom-select">
                                    <option>US</option>
                                    <option>CANADA</option>
                                    <option></option>
                                    <option></option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Client</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Number</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Title</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Proposal Template Status</label>
                                <select id="disabledSelect" className="form-control custom-select ">
                                    <option>Pending</option>
                                    <option>Go</option>
                                    <option>No-Go</option>
                                    <option>Pushback</option>
                                </select>
                            </div>
                        </div>


                    </div>
                </Modal.Body>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1">Save</button>
                </div>
            </Modal>
        </>
    )
}
export default ProposalAwards;