import React, { useEffect, useState } from "react";
import { Breadcrumb, Modal } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css"
import Form from 'react-bootstrap/Form';
import { useToasts } from 'react-toast-notifications';
import { APIMethod, FileUploadMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import ReactModal from 'react-modal-resizable-draggable';
import { useLocation } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal";
import ModalPopup from "../../../../components/ModalPopUp";
import chatIcon from '../../../../assets/css/images/chat.png';
import Chat from "../../../../components/Chat";
import moment from "moment";


const AddProposaAwards = (props) => {
    const [formFields, setFormFields] = useState({
        businessUnit: '', RfpNumber: '', RfpTitle: '', clientName: '', sourceURL: '', reason: '', id: '',
        version: '', description: '', document_type: '', id: ''
    })
    const [flag, setFlag] = useState(false);
    const [isAwardNotice, setIsAwardNotice] = useState(false);
    const [isAwarded, setIsAwarded] = useState(false);
    const [buttonLoader, setButtonLoader] = useState('')
    const [data, setData] = useState()
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [show, setShow] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);
    const [modalButtonLoader, SetModalButtonLoader] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [addDocuments, setAddDocuments] = useState([]);
    // Toast
    const { addToast } = useToasts();
    const location = useLocation();
    // file
    const [file, setFile] = useState();
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [deleteIndex, setDeleteIndex] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showChatModal, setShowChatModal] = useState(false)
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }

    useEffect(() => {
        check()
        getBusinessUnitList()
        getDocumentTypeList()
    }, [])

    const check = () => {
        if (props.location.state === null) {
            null
        } else {
            setData(props.location.state.detail)
            formFields.businessUnit = props.location.state.detail.business_unit ? props.location.state.detail.business_unit : ''
            formFields.RfpNumber = props.location.state.detail.rfx_number
            formFields.RfpTitle = props.location.state.detail.title ? props.location.state.detail.title : props.location.state.detail.rfx_title
            formFields.clientName = props.location.state.detail.rfx_client_agency ? props.location.state.detail.rfx_client_agency : props.location.state.detail.client_name
            formFields.sourceURL = props.location.state.detail.client_url ? props.location.state.detail.client_url : props.location.state.detail.source_url
            window.location.pathname == '/edit-proposal-awards' ? getAwardsDetails(props.location.state.detail.proposal_award_id ? props.location.state.detail.proposal_award_id : props.location.state.detail.id) : null
        }
    }

    const getBusinessUnitList = () => {
        const userId = localStorage.getItem('userId')
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/user-business/list/${userId}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitData(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const getDocumentTypeList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentList(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const getAwardsDetails = (id) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/proposal-award/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setAddDocuments(data.attachments)
                    setIsAwardNotice(data.is_award_notice)
                    setIsAwarded(data.is_awarded)
                    setFormFields({ ...formFields, reason: data.reason_for_no_award, id: data.id })
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const save = () => {
        if (formFields.businessUnit === '' || (isAwarded && formFields.reason === '')) {
            setFlag(true)
            addToast('Please fill all the fields', { appearance: 'error', autoDismiss: true });
        } else {
            setButtonLoader(true)
            var method = window.location.pathname == '/edit-proposal-awards' ? 'PUT' : 'POST'
            var params = JSON.stringify({
                id: formFields.id,
                business_unit_id: parseInt(formFields.businessUnit),
                rfx_title: formFields.RfpTitle,
                rfx_number: formFields.RfpNumber,
                client_name: formFields.clientName,
                source_url: formFields.sourceURL,
                reason_for_no_award: formFields.reason,
                is_awarded: isAwarded,
                is_award_notice: isAwardNotice,
                pre_rfx_id: data.id,
                attachments: addDocuments,
                reward: moment(new Date()).format('DD/MM/YYYY')
            })
            const apifetcherObj = APIMethod('/proposal-award', params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { appearance: 'success', autoDismiss: true });
                        formFields.id ? null : proposalAwardFileUpload(data.id)
                        setButtonLoader(false)
                        setFlag(false)
                    } else {
                        addToast(data.message, { appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    addToast(error, { appearance: 'error', autoDismiss: true });
                    setButtonLoader(false)
                })
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const onSwitchAction = () => {
        setIsAwardNotice(!isAwardNotice);
    };

    const onSwitchActionAwarded = () => {
        setIsAwarded(!isAwarded);
    };

    const filehandler = event => {
        setFile(event.target.files[0])
    }

    const proposalAwardFileUpload = (id) => {
        var method = 'POST'
        var form = new FormData();
        form.append('file', file);
        const apifetcherObj = FileUploadMethod(`/proposal_award/file/${id}`, form, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    addToast("File Uploaded successfully", { appearance: 'success', autoDismiss: true });
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
            })
    }

    const onCancelModal = () => {
        setShow(false)
        setFormFields({ ...formFields, version: '', description: '', document_type: '' })
        setEditIndex(-1)
        setFile()
    }

    const _onAddDocument = () => {
        if (formFields.version === '' || formFields.description === '' || file === undefined || formFields.document_type === '') {
            setModalFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            if (file.type) {
                SetModalButtonLoader(true)
                var method = 'POST'
                var form = new FormData();
                form.append('file', file);
                const apifetcherObj = FileUploadMethod(`/document/attachment/upload`, form, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]
                        var name = ''
                        if (statusCode === 200) {
                            for (var i in documentList) {
                                if (documentList[i].id == formFields.document_type) {
                                    name = documentList[i].name
                                }
                            }
                            var obj = {
                                document_id: formFields.document_type,
                                document_type_name: name,
                                file_name: file.name,
                                version: formFields.version,
                                file: data.file,
                                description: formFields.description
                            }

                            if (editIndex >= 0) {
                                for (var index in addDocuments) {
                                    if (index == editIndex) {
                                        addDocuments.splice(editIndex, 1);
                                        addDocuments.splice(editIndex, 0, obj);
                                        setEditIndex(-1)
                                        setAddsetAddDocumentsDocuments(addDocuments)
                                        break;
                                    }
                                }
                            } else {
                                addDocuments.push(obj)
                                setAddDocuments(addDocuments)
                            }
                            setShow(!show)
                            setEditIndex(-1)
                            setFile('')
                            setFormFields({ ...formFields, version: '', description: '', document_type: '' })
                            SetModalButtonLoader(false)
                        } else {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        }
                    })
                    .catch(error => {
                        SetModalButtonLoader(false)
                    })
            } else {
                var name = ''
                for (var i in documentList) {
                    if (documentList[i].id == formFields.document_type) {
                        name = documentList[i].name
                    }
                }
                for (var i in addDocuments) {
                    if (i == editIndex) {
                        addDocuments[i].document_id = formFields.document_type,
                            addDocuments[i].document_type_name = name,
                            addDocuments[i].file_name = file.name,
                            addDocuments[i].version = formFields.version,
                            addDocuments[i].description = formFields.description
                    }
                }
                setShow(!show)
                setEditIndex(-1)
                setFormFields({ ...formFields, version: '', description: '', document_type: '' })
                setFile('')
            }
        }
    }

    const _onEditDocument = (index, data) => {
        setFormFields({ ...formFields, document_type: data.document_id, version: data.version, description: data.description })
        setFile({ name: data.file_name })
        setShow(!show)
        setEditIndex(index)
    }

    const _onDelete = (id, insex) => {
        if (id) {
            setDeleteId(id)
            isShowPopup(true)
        } else {
            setDeleteModal(!deleteModal)
        }
    }

    const isDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const deleteLocalDocument = () => {
        setDeleteIndex(deleteIndex)
        addDocuments.splice(deleteIndex, 1);
        var temp = [];
        for (var j in addDocuments) {
            temp.push(addDocuments[j])
            if (j == addDocuments.length - 1) {
                setAddDocuments(temp)
                setDeleteModal(!deleteModal)
            }
        }
        if (addDocuments.length == 0) {
            setAddDocuments(temp)
            setDeleteModal(!deleteModal)
        }
        setDeleteIndex('')

    }

    const getData = () => {
        getAwardsDetails(data.id)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    return (
        <>

            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>{location.state.from}</Breadcrumb.Item>
                            <Breadcrumb.Item active>{props.location.state.page == 'Edit' ? 'Edit' : props.location.state.page == 'View' ? 'View' : 'Add'} Proposal Award</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center">
                        <span className="add-title ">{props.location.state.page == 'Edit' ? 'Edit' : props.location.state.page == 'View' ? 'View' : 'Add'} Proposal Award</span>
                    </div>
                    <div className="form-wraper form-style mb-4">
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Business Unit</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['businessUnit']} name="businessUnit" onChange={handleChange} disabled>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            businessUnitData.map((res, index) => {
                                                return (
                                                    <option key={index} value={res.business_unit_id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.businessUnit === '' ? 'Please Enter Business Unit' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Number</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="RfpNumber" value={formFields['RfpNumber']} disabled />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label>RFx Title</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="RfpTitle" value={formFields['RfpTitle']} disabled />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label>Client Name</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="clientName" value={formFields['clientName']} disabled />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label>Source URL</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="sourceURL" value={formFields['sourceURL']} disabled />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Award Notice Given by Client?</lable><br />
                                    <Form.Check
                                        onChange={onSwitchAction}
                                        checked={isAwardNotice}
                                        disabled={props.location.state.page == 'View' ? true : false}
                                        type="switch"
                                        id="custom-switch"
                                        label=" "
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Awarded?</lable><br />
                                    <Form.Check
                                        onChange={onSwitchActionAwarded}
                                        checked={isAwarded}
                                        disabled={props.location.state.page == 'View' ? true : false}
                                        type="switch"
                                        id="custom-switch2"
                                        label=" "
                                    />
                                </div>
                            </div>

                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <label>Award / No-Award Comments</label>
                                    <textarea rows="5" className="form-control h-auto" onChange={handleChange} name="reason" value={formFields['reason']}></textarea>
                                    <span className="text-danger">{flag === true && isAwarded && formFields.reason === '' ? 'Please Enter Reason' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12 row">
                                <div className="col-12 text-right">
                                    {
                                        props.location.state.page == 'View' ? null :
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => setShow(!show)}>Add</button>
                                    }
                                </div>
                            </div>


                            <div className="col-12 my-3">
                                {/* Commenting the following code for WOII-73. Divya. September 04 2023.. Code Start */}
                                {/*<p className="font-medium">Attachments</p>*/}
                                {/* Commenting end. Divya. September 04 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 04 2023.. Code Start */}
                                <p className="font-20 font-bold">Attachments</p>
                                {/* Divya. September 04 2023. Code End */}
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">
                                    <tr>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        <th>Version</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                    {
                                        addDocuments.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{res.document_type_name}</td>
                                                    <td>{res.file_name}</td>
                                                    <td>{res.version}</td>
                                                    <td>{res.description}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-action" onClick={() => window.open(res.file, "_blank")}><i className="icofont-eye-alt"></i></button>
                                                        <button type="button" className="btn btn-action edit mr-1 ml-1" onClick={() => _onEditDocument(i, res)}><i className="icofont-pencil-alt-2"></i></button>
                                                        <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id, i)}><i className="icofont-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                            {
                                <ModalPopup
                                    showModalPopup={showModalPopup}
                                    onPopupClose={isShowPopup}
                                    id={deleteId}
                                    endPoint={'/proposal-award/document/'}
                                    updateList={getData}
                                />
                            }
                            {
                                <DeleteModal
                                    showModalPopup={deleteModal}
                                    onPopupClose={isDeleteModal}
                                    index={deleteIndex}
                                    deleteData={deleteLocalDocument}
                                />
                            }
                            <div className="col-12 text-right mt-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={goBack}>Cancel</button>
                                {
                                    props.location.state.page == 'View' ? null :
                                        <button type="button" className="btn btn-lg btn-brand-1 mr-3 bg-success" onClick={() => save()} disabled={buttonLoader}>
                                            {buttonLoader && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}
                                            <span>Save</span>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL */}
            <ReactModal
                initWidth={550}
                initHeight={580}
                top={545.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setShow(!show)}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{editIndex != '-1' ? 'Edit' : 'Add'} Documents</h5>
                    <button type="button" className="close" onClick={() => onCancelModal()}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Version</label>
                                <input type="text" className="form-control" onChange={handleChange} name="version" value={formFields['version']} />
                                <span className="text-danger">{modalFlag === true && formFields.version === '' ? 'Please Enter Version' : null}</span>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="description" value={formFields['description']}></textarea>
                                    <span className="text-danger">{modalFlag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Document Type</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['document_type']} name="document_type" onChange={handleChange}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        documentList.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{modalFlag === true && formFields.document_type_id === '' ? 'Please Select Document Type' : null}</span>
                            </div>
                            <div className="form-group">
                                <label>Upload File</label>
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="inputGroupFile02" onChange={filehandler} />
                                    <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file === undefined ? 'Choose File' : file.name}</label>
                                    <span className="text-danger">{modalFlag === true && file === undefined ? 'Please Select file' : null}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => onCancelModal()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onAddDocument()} disabled={modalButtonLoader}>
                        {modalButtonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>
            {
                showChatModal == false && data?.id &&
                <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                    <img src={chatIcon} />
                </div>
            }
            {
                showChatModal &&
                <Chat
                    id={data?.id}
                    title={formFields.RfpTitle}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default AddProposaAwards;