import React, { useEffect, useState } from "react";
import { NavLink, generatePath } from "react-router-dom";
import { APIMethod } from "../../../../API/APIClient";
import ModalPopup from '../../../../components/ModalPopUp';
import Pagination from '../../../../components/Pagination';
import Loader from '../../../../components/Loader';
import { useHistory } from "react-router";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";

const ManageUsers = () => {
    const [loading, setLoading] = useState(false);
    const [getListData, setGetListData] = useState([]);
    // Delete
    const [deleteId, setDeleteId] = useState('');
    // Delete PopUp
    const [showModalPopup, setShowModalPopup] = useState(false);
    // Pagination
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const [adding, setAdding] = useState();
    const [editing, setEditing] = useState();
    const [deleting, setDeleting] = useState();

    useEffect(() => {
        getUserList(1)
        var data = JSON.parse(localStorage.getItem("user_Role"))
        checkPermission(data)
    }, [])

    const checkPermission = (data) => {
        const resObject = data.find(item => item.name === "Manage Security")
        const valObject = resObject.access.find(item => item.name === "Users")
        setAdding(valObject.is_create)
        setEditing(valObject.is_update)
        setDeleting(valObject.is_delete)
    }

    const getUserList = (page, key) => {
        setLoading(true)
        const new_key = key === undefined ? '' : key
        global.new_key = key === undefined ? '' : key
        var method = 'GET'
        const apifetcherObj = APIMethod(`/users/list/${page}?keyword=` + new_key, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const nextPage = (pageNumber) => {
        getUserList(pageNumber)
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    }

    const Search = (key) => {
        getUserList(1, key)
    }

    const _onEdit = (val) => {
        val && history.push(generatePath("/edit-user-role/:val", { val }));
    }

    const getData = () => {
        getUserList(1)
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Users </h2>
                        </div>
                        <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search by user" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            {
                                adding === true ?
                                    <NavLink to="/add-user" className="btn btn-brand-1 bg-success px-4">+ Add</NavLink> : null
                            }
                        </div>
                    </div>


                    <table className="table table-style-1 table-responsive-md mb-4 table reports">
                        <thead>
                            <tr>
                                <th scope="col">User Id</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Default BU</th>
                                <th scope="col">Role </th>
                                <th scope="col">Default Category</th>
                                <th scope="col" className="text-center" width="110">{editing === true || deleting === true ? 'Action' : null}</th>
                            </tr>
                        </thead>

                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/users/'}
                            updateList={getData}
                        />
                        <tbody>
                            {
                                loading === false ? getListData.map((res, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{res.id}</td>
                                            <td>{res.first_name} {res.last_name}</td>
                                            <td>{res.email}</td>
                                            <td>{
                                                res.user_business_unit.map((buss) => {
                                                    return (
                                                        buss.is_checked === false ? null : buss.name
                                                    )
                                                })
                                            }</td>
                                            <td style={{width: 10}}>{
                                                res.user_roles.map((role, i) => {
                                                    if (i == 0) {
                                                        return role.name;
                                                    } else {
                                                        return (
                                                            "," + role.name
                                                        )
                                                    }
                                                })
                                            }</td>
                                            <td>{
                                                res.rfx_category_list.map((cat) => {
                                                    return (
                                                        cat.is_checked === false ? null : cat.name
                                                    )
                                                })
                                            }</td>
                                            <td className="text-center">
                                                {
                                                    editing === true ?
                                                    res.id === 217 ? null :
                                                        <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res.id)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                }
                                                {
                                                    deleting === true ?
                                                    res.id === 217 ? null :
                                                        <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-trash"></i></button> : null
                                                }
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                    {
                        getListData.length === 0 && loading === false && global.new_key ?
                            <SearchNoDataFound message={'No Manage User Found'} />
                            : getListData.length === 0 && loading === false ? <NoData /> : null
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">
                            <Pagination
                                pages={pageCount}
                                endPoint={'/users/list/'}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                            />
                        </nav>
                    </div>

                </div>
            </div>

        </>
    )
}
export default ManageUsers;