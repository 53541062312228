import { useState } from 'react'
import { APIMethod } from '../API/APIClient'
import { Row } from 'react-bootstrap'

const Chat = (props) => {
    const [message, setMessage] = useState('')
    const [chats, setChats] = useState([])
    const [isLoading, setLoading] = useState(false)

    const messageStyle={
        fontWeight:'bold',
        color: '#044661',
    }

    const onSend = () => {
        setLoading(true)
        console.log(message)
        console.log(props.id)
        var method = 'POST'
        var params = JSON.stringify({
            question: message,
        })
        const apifetcherObj = APIMethod(`/pre-rfx/chat-bot/${props.id}`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()])})
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                console.log(data)

                if (statusCode === 200) {
                    console.log(data.answer)
                    chats.push({ message: message, side: 'RIGHT' })
                    chats.push({ message: data.answer, side: 'LEFT' })
                    chats.push({ message: data.reference, side: 'RLEFT' })
                    setChats([...chats])
                    setMessage('')
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }
    return (
        <>
            <div className='chat-box'>
                <div className='chat-box-header'>
                    <div className='chat-title text-center'>{props.title}</div>
                    <div className='mt-2 mr-3' onClick={() => props.onClose()}>
                        <img src={require('../assets/css/images/cross.png').default} alt="No image found" id="img_close" width="20" />
                    </div>
                </div>
                <div className='msg-box'>
                    {
                        chats.map((res) => {
                            return (
                                <div>
                                    {
                                        res.side == 'LEFT' &&
                                        <div class="msg-container">
                                            <div class="arrow">
                                                <div class="outer"></div>
                                                <div class="inner"></div>
                                            </div>
                                            <div class="message-body">
                                            <b  style={messageStyle}>Answer:</b>
                                                <p>{res.message}</p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        res.side == 'RLEFT' &&
                                        <div class="msg-container">
                                            <div class="arrow">
                                                <div class="outer"></div>
                                                <div class="inner"></div>
                                            </div>
                                            <div class="message-body">
                                            <b style={messageStyle}>Reference:</b>
                                                <p>{res.message}</p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        res.side == 'RIGHT' &&
                                        <div class="msg-container-right">
                                            <div class="arrow-right">
                                                <div class="outer-right"></div>
                                                <div class="inner-right"></div>
                                            </div>
                                            <div class="message-body-right">
                                                
                                                <b  style={messageStyle}>Question:</b>
                                                <p>{res.message}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        chats.length == 0 &&
                        <div className='text-center'>No Messages Found!</div>
                    }
                </div>

                <div className='chat-bottom-view'>
                    <input className="form-control w-100 chat-input" value={message} onChange={(e) => setMessage(e.target.value)} />
                    <button className='chat-send-button' onClick={() => onSend()} disabled={isLoading}>
                        {isLoading && (
                            <img src={require('../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginRight: 5 }} />
                        )}Send</button>
                </div>
            </div >
        </>
    )
}

export default Chat;