import React, { useState, useEffect } from "react";
import Leftnavbar from '../../../..//components/Leftnavbar';
import { Breadcrumb, Modal, Button } from 'react-bootstrap';
import RfxCategories from '../../Organizational_Setup/RFx_Categories/RfxCategories';
import { APIMethod, FileUploadMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import { useToasts } from 'react-toast-notifications';
import Loader from '../../../../components/Loader';
import ModalPopup from '../../../../components/ModalPopUp';

const AddDoc = (props) => {
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [formFields, setFormFields] = useState({ documentId: '', rfxId: '', rfxCategoryId: '', title: '', clientName: '' })
    const [RFxTypeData, setRFxTypeData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [documentData, setDocumentData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [id, setId] = useState();
    // file
    const [file, setFile] = useState();
    const [fileResponse, setFileResponse] = useState();
    // Toast
    const { addToast } = useToasts();
    const [cancelModal, setCancelModal] = useState(false);
    const [showModalPopup, setShowModalPopup] = useState(false);

    const handleModal = () => {
        setCancelModal(!cancelModal)
    }
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }

    useEffect(() => {
        getRFxTypeList()
        getRfxCategoryList()
        getDocumentType()
        check()
    }, [])

    const check = () => {
        if (props.location.state === null) {
        } else {
            setId(props.location.state.details.id)
            setFormFields({
                ...formFields, title: props.location.state.details.title, clientName: props.location.state.details.name,
                documentId: props.location.state.details.rfx_document_type_id, rfxCategoryId: props.location.state.details.rfx_category_id,
                rfxId: props.location.state.details.rfx_type_id
            })
            props.location.state.details.file && props.location.state.details.file_name && setFileResponse({file_name : props.location.state.details.file_name , file : props.location.state.details.file})
        }
    }

    const getRFxTypeList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-types/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxTypeData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getRfxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }


    const getDocumentType = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const save = () => {
        if (formFields.title === '' || formFields.documentId === '' || formFields.rfxId === '' || formFields.rfxCategoryId === '' || formFields.clientName === '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT,appearance: 'error', autoDismiss: true });
        } else {
            if (id === undefined) {
                var method = 'POST'
                var params = JSON.stringify({
                    name: formFields.clientName,
                    title: formFields.title,
                    rfx_document_type_id: formFields.documentId,
                    rfx_type_id: formFields.rfxId,
                    rfx_category_id: formFields.rfxCategoryId,
                })
            } else {
                var method = 'PUT'
                var params = JSON.stringify({
                    id: id,
                    name: formFields.clientName,
                    title: formFields.title,
                    rfx_document_type_id: formFields.documentId,
                    rfx_type_id: formFields.rfxId,
                    rfx_category_id: formFields.rfxCategoryId,
                })
            }
            setButtonLoader(true)
            const apifetcherObj = APIMethod(`/document`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setFormFields({ ...formFields, title: '', documentId: '', rfxId: '', rfxCategoryId: '', clientName: '' })
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT,appearance: 'success', autoDismiss: true });
                        sendFile(data.id)
                        setButtonLoader(false)
                        setFlag(false)

                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT,appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        }
    }

    const sendFile = (id) => {
        var form = new FormData();
        form.append('file', file);
        var method = 'POST'
        const apifetcherObj = FileUploadMethod(`/document/file/` + id, form, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    goBack();
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const onChangeHandler = event => {
        setFile(event.target.files[0])
        setLoading(true)
        var method = 'POST'
        var form = new FormData();
        form.append('file', event.target.files[0]);
        const apifetcherObj = FileUploadMethod(`/document/attachment/upload`, form, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    addToast("File Uploaded successfully", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                    setFileResponse(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT,appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }


    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const modalCheck = () => {
        if (formFields.title === '' && formFields.documentId === '' && formFields.rfxId === '' && formFields.rfxCategoryId === '' && formFields.clientName === '') {
            goBack();
        } else {
            goBack();
        }
    }

    const _onDelete = (data) => {
        if (id) {
            isShowPopup(true)
        } else {
            handleModal()
        }
    }

    const deleteAddFile = () => {
        setFileResponse()
        handleModal()
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const updateAttachmentlist = () => {
        setFileResponse()
    }


    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <ModalPopup
                        showModalPopup={showModalPopup}
                        onPopupClose={isShowPopup}
                        id={id}
                        endPoint={'/document/attachment/'}
                      updateList={updateAttachmentlist}
                    ></ModalPopup>
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Content Library Setup</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>
                                Document Entries
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{id ? 'Edit Document' : 'Add Document'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center">
                        <span className="add-title ">{id ? 'Edit Document' : 'Add Document'}</span>
                    </div>
                    <div className="form-wraper form-style mb-4">
                        <div className="row">
                            <div className="col-12 text-center mb-4 bg-light py-2">
                                {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                {/*<h3 className="font-20 font-medium">Document Base Content</h3>*/}
                                {/* Commenting end. Divya. September 07 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                <h2 className="font-20 font-bold">Document Base Content</h2>
                                {/* Divya. September 07 2023. Code End */}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document Type</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['documentId']} name="documentId" onChange={handleChange}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            documentData.map((res) => {
                                                return (
                                                    <option value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.documentId === '' ? 'Please Select Document Type' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document Title</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="title" value={formFields['title']} />
                                    <span className="text-danger">{flag === true && formFields.title === '' ? 'Please Enter Document Title' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>RFx Type</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['rfxId']} name="rfxId" onChange={handleChange}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            RFxTypeData.map((res) => {
                                                return (
                                                    <option value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.rfxId === '' ? 'Please Select RFX Type' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>RFx Category</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['rfxCategoryId']} name="rfxCategoryId" onChange={handleChange}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            RFxCategoryData.map((res) => {
                                                return (
                                                    <option value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.rfxCategoryId === '' ? 'Please Select RFX Category' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Client Name</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="clientName" value={formFields['clientName']} />
                                    <span className="text-danger">{flag === true && formFields.clientName === '' ? 'Please Enter Client Name' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Attachment</label>
                                    <div className="input-group mb-0">
                                        <div className="custom-file">
                                            <input type="file" accept=".doc, .docx,.txt,.pdf" className="custom-file-input" id="inputGroupFile02" onChange={onChangeHandler} />
                                            <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file === undefined ? 'Choose File' : file.name}</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <table className="table table-style-1 table-responsive-md mb-4 table reports">
                                    <thead>
                                        <tr>
                                            <th scope="col">File Name</th>
                                            <th scope="col" className="text-center">View</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fileResponse === undefined ?
                                                ''
                                                :
                                                <tr>
                                                    <td>{fileResponse.file_name ? fileResponse.file_name : fileResponse}</td>
                                                    <td className="text-center">
                                                        <button className="btn btn-action" onClick={() => window.open(fileResponse.file ? fileResponse.file : fileResponse, "_blank")}>
                                                            <i className="icofont-eye-alt"></i>
                                                        </button>
                                                        <button type="button" className="ml-2 btn btn-action delete" onClick={() => _onDelete(fileResponse)}><i className="icofont-trash"></i></button>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                                {
                                    fileResponse === undefined ?
                                        <div className="col-12 text-center">
                                            <span>No Document Added</span>
                                        </div>
                                        : null
                                }
                            </div>

                            <div className="col-12 text-right mt-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={() => modalCheck()}>Cancel</button>
                                <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save()} disabled={buttonLoader}>
                                    {buttonLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal show={cancelModal} onHide={() => handleModal()}  {...props}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="sign-in-title">
                        Alert
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ alignSelf: 'center' }}>
                    <div className="ml-5 mr-5">
                        <img src={require('../../../../assets/css/images/cross.png').default} style={{ marginLeft: '37%' }} alt="No image found" id="img_close" width="80" />
                        <p style={{ fontSize: 20, textAlign: 'center' }} className="mt-4">Are You Sure ?</p>
                        <p style={{ textAlign: 'center' }}>Do you really want to delete these records? This process cannot be undone.</p>
                        <button type="button" className="btn btn-light mr-1" style={{ marginLeft: '30%' }} onClick={() => handleModal()}> Cancel</button>
                        <button type="button" className="btn btn-danger ml-3" onClick={() => deleteAddFile()}> Delete</button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}
export default AddDoc;