import React, { useEffect, useState } from "react";
import Leftnavbar from '../../../../components/Leftnavbar';
import { Breadcrumb, Modal } from 'react-bootstrap';
import ModalPopup from '../../../../components/ModalPopUp';
import "react-datepicker/dist/react-datepicker.css"
import Form from 'react-bootstrap/Form'
import { NavLink } from "react-router-dom";
import { APIMethod, FileUploadMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import ReactModal from 'react-modal-resizable-draggable';
import Pagination from '../../../../components/Pagination';
import moment from "moment";

const ParseRfx = () => {
    const [loading, setLoading] = useState(false);
    const [typeList, setTypeList] = useState([]);
    const [parseData, setParseData] = useState([]);
    const [parseHistory, setParseHistory] = useState([]);
    const [formFields, setFormFields] = useState({ documentType: '', description: '', name: '', version: '', uploadProposal: '' });
    const [flag, setFlag] = useState(false);
    const [parsingId, setParsingId] = useState('');
    const [file, setFile] = useState();
    const { addToast } = useToasts();
    const [buttonLoader, setButtonLoader] = useState(false);
    const [parseButtonLoader, setParseButtonLoader] = useState(false);
    // page
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const history = useHistory();
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    // Upload modal box
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setFlag(false);
        setFormFields({ ...formFields, documentType: '', name: '', version: '', description: '' })
        setFile()
    }
    const handleShow = () => setShow(true);
    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    //---Date Time picker
    const [startDateTime, setStartDateTime] = useState(new Date());

    useEffect(() => {
        getDocumentTypeList()
        getParseList()
        getParseHistory(1)
    }, [])

    const getDocumentTypeList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod('/rfx-document-type/all/list', null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setTypeList(data)
                }
                setFlag(false)
            })
            .catch(error => {
            })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const save = () => {
        if (formFields.version === '' || formFields.description === '' || file === undefined) {
            setFlag(true);
            addToast('Please fill all the fields', { appearance: 'error', autoDismiss: true });
        } else {
            setButtonLoader(true)
            var method = 'POST'
            var form = new FormData();
            form.append('file', file);
            form.append('version', formFields.version);
            form.append('description', formFields.description);
            form.append('file_name', file.name);
            form.append('documentId', '15');
            const apifetcherObj = FileUploadMethod(`/rfx-parse/upload`, form, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setFlag(false);
                        setFormFields({ ...formFields, documentType: '', name: '', version: '', description: '' })
                        setFile()
                        handleClose()
                        addToast("Document uploaded successfully", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        getParseList()
                        setParsingId(data.id)
                        setButtonLoader(false)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setButtonLoader(false)
                })
        }

    }

    const filehandler = event => {
        setFile(event.target.files[0])
    }

    const getParseList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-parse/list/1`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    console.log("data.list", data.list)
                    setParseData(data.list)
                }
            })
            .catch(error => {
            })
    }

    const getParseHistory = (page) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-parse/history/${page}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setParseHistory(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                }
            })
            .catch(error => {
            })
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const _onParsing = () => {
        if (parsingId === '') {
            addToast('Please upload RFP document', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
        } else {
            setParseButtonLoader(true)
            setTimeout(() => {
                setParseButtonLoader(false)
                getParseList()
                setParsingId('')
                getParseHistory(currentPage)
            }, 1000 * 30);
            var method = 'GET'
            const apifetcherObj = APIMethod(`/rfx-parse/parse/${parsingId}`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        getParseList()
                        setParsingId('')
                        history.push({ pathname: "/parse-rfx-detail", state: { detail: data.id } })
                        setParseButtonLoader(false)
                        getParseHistory(currentPage)

                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setParseButtonLoader(false)
                        getParseHistory(currentPage)

                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setParseButtonLoader(false)
                    // getParseHistory(currentPage)
                })

        }
    }

    const handleShowError = () => {
        addToast("You can't upload more than one document", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
    }

    const onUpload = () => {
        if (parseData.length >= 1) {
            addToast("You can't upload more than one document", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            handleShow()
        }
    }

    const nextPage = (pageNumber) => {
        getParseHistory(pageNumber)
    }

    const getData = () => {
        setParsingId('')
        setParseData([])
        getParseList()
    }

    return (
        <>

            <div className="page-wrapper">
                <div className="page-content">
                    <h2 className="page-title ">Parse RFxs</h2>

                    <div className="form-wraper form-style mb-4">
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-right">
                                {
                                    parsingId ?
                                        <button type="button" className="btn btn-brand-1 mb-2" onClick={() => handleShowError()}>Upload</button>
                                        :
                                        <button type="button" className="btn btn-brand-1 mb-2" onClick={() => onUpload()}>Upload</button>
                                }

                            </div>
                            <ModalPopup
                                showModalPopup={showModalPopup}
                                onPopupClose={isShowPopup}
                                id={deleteId}
                                endPoint={'/rfx-parse/'}
                                updateList={getData}
                            ></ModalPopup>
                            <div className="col-12 my-3">
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">

                                    <tr>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        <th>Version</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                    {
                                        parseData.map((res) => {
                                            return (
                                                <tr>
                                                    <td>{res.document_name}</td>
                                                    <td>{res.file_name}</td>
                                                    <td>{res.version}</td>
                                                    <td>{res.description}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-action mr-1" onClick={() => window.open(res.file, "_blank")}><i className="icofont-eye-alt"></i></button>
                                                        <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>

                            <div className="col-12 text-center mt-4">
                                <button type="button" className="btn btn-lg btn-brand-1" onClick={() => _onParsing()} disabled={parseButtonLoader}>
                                    {parseButtonLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}
                                    <span>Parse</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 my-3">
                        <div className="d-flex flex-row justify-content-center">
                            <h2 className="page-title mb-md-3">Parse History</h2>
                        </div>
                        <table className="table table-style-1 table-responsive-lg mb-4 table ">
                            <tr>
                                <th>Parse Run Date</th>
                                <th>User</th>
                                <th>File Name</th>
                                <th>Action</th>
                            </tr>
                            {
                                parseHistory.map((res, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{moment(res.created_date).format('MM-DD-YYYY')}</td>
                                            <td>{res.user_name}</td>
                                            <td>{res.file_name}</td>
                                            <td>
                                                <button type="button" className="btn btn-action" onClick={() => window.open(res.file, "_blank")}><i className="icofont-eye-alt"></i></button>
                                                {/* <button type="button" className="btn btn-action edit mr-1 ml-1" onClick={() => _onEditDocument(i, res)}><i className="icofont-pencil-alt-2"></i></button>
                                                <button type="button" className="btn btn-action delete"><i className="icofont-trash"></i></button> */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                        <div className="text-md-right">
                            <nav aria-label="Page navigation">
                                <Pagination
                                    pages={pageCount}
                                    nextPage={nextPage}
                                    currentPage={currentPage}
                                    pageCount={pageCount}
                                />
                            </nav>
                        </div>
                    </div>
                </div>
            </div>


            {/* Upload Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={490}
                top={132.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id=""> Upload RFx</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        {/* <div className="col-md-12">
                            <div className="form-group">
                                <label>Document Type</label>
                                <select id="disabledSelect" className="form-control custom-select " onChange={handleChange} name="documentType" value={formFields['documentType']}>
                                    <option value="" disabled selected>Select your document type</option>
                                    {
                                        typeList.map((res) => {
                                            return (
                                                <option value={res.id}>{res.name}</option>
                                            )
                                        })


                                    }
                                </select>
                                <span className="text-danger">{flag === true && formFields.documentType === '' ? 'Please Enter Document Type' : null}</span>
                            </div>
                        </div> */}
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Version</label>
                                <input type="text" className="form-control" onChange={handleChange} name="version" value={formFields['version']} />
                                <span className="text-danger">{flag === true && formFields.version === '' ? 'Please enter version' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Upload Proposal</label>
                                <div className="input-group">
                                    <input type="file" accept=".doc, .docx,.txt,.pdf" className="custom-file-input" id="inputGroupFile02" onChange={filehandler} />
                                    <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file === undefined ? 'Choose File' : file.name}</label>
                                    <span className="text-danger">{flag === true && file === undefined ? 'Please select file' : null}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description </label>
                                <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="description" value={formFields['description']}></textarea>
                                <span className="text-danger">{flag === true && formFields.description === '' ? 'Please enter description' : null}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-right">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save()} disabled={buttonLoader}>
                        {buttonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>
        </>
    )
}
export default ParseRfx;