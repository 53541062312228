import React, { useEffect, useState } from "react";
import ModalPopup from '../../../../components/ModalPopUp';
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import Loader from '../../../../components/Loader';
import Pagination from '../../../../components/Pagination';
import { APIMethod, FileUploadMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import { useHistory } from "react-router";
import moment from "moment";

const Compliance = () => {
    const [loading, setLoading] = useState(false);
    const [getListData, setGetListData] = useState([]);
    // Delete PopUp
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    // Pagination
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();

    useEffect(() => {
        getCompliance(1)
    }, [])

    const getCompliance = (page, key) => {
        setLoading(true)
        const new_key = key === undefined ? '' : key
        global.new_key = key === undefined ? '' : key
        var method = 'GET'
        const apifetcherObj = APIMethod(`/contract-compliance/list/${page}?keyword=` + new_key, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    }

    const nextPage = (pageNumber) => {
        getCompliance(pageNumber)
    }

    const _onEdit = (val) => {
        history.push({
            pathname: '/edit-compliance',
            state: { details: val }
        })
    }

    const Search = (key) => {
        getCompliance(1, key)
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Compliances</h2>
                        </div>
                        <div className="col-lg-4 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search by Compliance Name" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                        </div>
                    </div>
                    <table className="table table-style-1 table-responsive-lg mb-4 table reports" id="reportTable">
                        <thead>
                            <tr>
                                <th scope="col">BU</th>
                                <th scope="col">Contract</th>
                                <th scope="col">Compliance Code</th>
                                <th scope="col">Compliance Name</th>
                                <th scope="col">Compliance Due Date</th>
                                <th scope="col" className="text-center" width="110">Action</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/word-template/'}
                            updateList={getCompliance}
                        />
                        <tbody>
                            {
                                loading === false ? getListData.map((res, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{res.business_unit_name}</td>
                                            <td>{res.contract_id}</td>
                                            <td>{res.code}</td>
                                            <td>{res.compliance_name}</td>
                                            <td>{res.due_date ? moment(res.due_date, 'YYYY-MM-DD').format('MM-DD-YYYY') : null}</td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button>
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                    {
                        getListData.length === 0 && loading === false && global.new_key ?
                            <SearchNoDataFound message={'No Compliance Found'} />
                            : getListData.length === 0 && loading === false ? <NoData /> : null
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">
                            <Pagination
                                pages={pageCount}
                                endPoint={'/word-template/list/'}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                            />
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Compliance;