import React from "react";
import logo from '../assets/css/images/loader.gif';
import loader from '../assets/css/images/ajax-loader.gif'


const CustomLoader = ({ isLoading, type }) => {
    return (
        <div>
            {
                type != 'FULLPAGE' &&
                <div className="col-12" style={{ alignItems: 'center', textAlign: 'center', justifyContent: 'center', backgroundColor: 'white', marginTop: '-22px', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                    {
                        isLoading == true ?
                            <img src={logo} alt="loading..." /> : null
                    }
                </div>
            }
            {
                type == 'FULLPAGE' && isLoading  &&
                <div className="resultLoading">
                    <div className='bg' style={{ backgroundColor: '#000000' }}></div>
                    <div style={{ textAlign: 'center', marginTop: '22%' }}>
                        <img style={{ position: 'relative' }} src={loader} alt="No loader found" />
                        <div style={{ position: 'relative', color: 'white' }}>Loading...</div>
                    </div>
                </div >
            }
        </div>
    )
}

export default CustomLoader;