import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Tab, Nav, Row, Col, } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css"
import { useLocation, useHistory, useParams } from "react-router-dom";
import { APIMethod, tiny_key, TOAST_TIMEOUT } from "../../../../API/APIClient";
import { useToasts } from 'react-toast-notifications';
import RawRFx from "../../../../components/RawRFx";
import SectionizedRFx from "../../../../components/SectionizedRFx";
import { checkEmptyString, isEdit, MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_TEMPLATE_APPROVAL, roleHelper } from "../../../../utility/utility";
import moment from "moment";
import ReactModal from 'react-modal-resizable-draggable';
import Loader from '../../../../components/Loader';
import { Editor } from '@tinymce/tinymce-react';
import Chat from "../../../../components/Chat";
import chatIcon from '../../../../assets/css/images/chat.png';
import saveIcon from '../../../../assets/css/images/save.png';
import { act } from "react-dom/test-utils";

const AddProposalTemplate = (props) => {
    const descriptionRef = useRef(null);
    const proposalStructureRef = useRef(null);
    const { id } = useParams()
    const [loading, setLoading] = useState(false);
    const [contentData, setContentData] = useState([]);
    const [actualData, setActualData] = useState([]);
    const [saveTemplateLoader, setSaveTemplateLoader] = useState(false);
    const [approveLoader, setAprroveLoader] = useState(false);
    const [pushbackLoader, setPushbackLoader] = useState(false);
    const [sendForApprovalLoader, setSendForApprovalLoader] = useState(false);
    const [rfxData, setRfxData] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [approverData, setApproverData] = useState([]);
    const [url, setUrl] = useState("");
    const { addToast } = useToasts();
    const location = useLocation();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [flag, setFlag] = useState(false);
    const editIndex = useRef('');
    const [addIndex, setAddIndex] = useState('');
    const [pageComing, setPageComing] = useState('');
    const [modalType, setModalType] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [preRfxData, setPreRfxData] = useState();
    const [proposalButton, setProposalButton] = useState(false)
    const [formFields, setFormFields] = useState({
        comment: '', id: '', commentAddLoader: false, heading: '', description: '', modalButtonLoader: false, isComment: false, proposalStructure: ''
    })

    const [formUpdated,setFormUpdated]=useState(false)

    const [changeDes,setChangeDes]=useState(false);
    const [changeHed,setChangeHed]=useState(false);
    const [changeType,setChangeType]=useState(false);
    const [showChatModal, setShowChatModal] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [postLoader, setPostLoader] = useState(false);
    var access_proposal_actions = roleHelper(MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_TEMPLATE_APPROVAL)

    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    //coollapse menu
    const CollapseMenu = () => {
        var element = document.getElementById("PraposalTemp");
        element.classList.toggle("collapse-tab");

        for (var i = 0; i < 3; i++) {
            var hideIcon = document.getElementById(`hideCollapseButton${i + 1}`);
            hideIcon.classList.toggle("show-coll-menu-page");
            var hideText = document.getElementById(`hideCollapseText${i + 1}`);
            hideText.classList.toggle("hide-coll-menu-page");
        }
    }

    useEffect(() => {
        getTemplateContentList()
        getRfxData()
        isEdit(access_proposal_actions) ? getApproverVersion() : null
        getTemplateContentComments()
        checkPage()
        getAiImageSegmentation()
    }, [])

    const getTemplateContentList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/template-content/list/1?pre_rfx_id=${id}&is_approver=false`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    if (data.list.length == 0) {
                        contentData.push({ title: '', description: '', section: '', priority: '' })
                    } else {
                        setActualData(data.list)
                        setContentData(data.list)
                        
                    }
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getApproverVersion = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/template-content/list/1?pre_rfx_id=${id}&is_approver=true`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setApproverData(data.list)
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRfxData = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/document-section/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRfxData(data)
                    setUrl(data.url);
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const checkPage = () => {
        if (props.location.state == null) {
        } else {
            setPageComing(props.location.state.page)
        }
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/${id}?rfx_type=AI`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setPreRfxData(data)
                    setLoading(false);

                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }

    const getAiImageSegmentation = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfp-image-segmentation/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setFormFields({ ...formFields, proposalStructure: data.proposal_structure })
                    setLoading(false);
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                    setLoading(false);
                }
            })
            .catch(error => {
                alert(error)
                setLoading(false);
            })
    }

    const _onGenerateProposal = () => {
        if (proposalButton == false) {
            addToast('Please update the template as per the Approver’s Suggestion and click on Save. If no changes, still click on Save button.', { appearance: 'error', autoDismiss: true });
        } else {
            setButtonLoader(true)
            var method = 'PUT'
            const apifetcherObj = APIMethod(`/action/event/GENERATE_PROPOSAL/${id}`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { appearance: 'success', autoDismiss: true });
                        history.push({ pathname: `/mp-genrate-proposal/${id}`, state: { detail: id, rfx_number: props.location.state.rfx_number, rfx_title: props.location.state.title, page: 'rfx', from: 'Proposal Template' } })
                        _onSendForApproval('GENERATE_PROPOSAL', 'none')
                        setButtonLoader(false)
                    } else {
                        addToast(data.message, { appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        }
    }

    const _onAddTemplateContent = (index) => {
        setModalType('Add')
        setAddIndex(index)
        setShowModal(!showModal)
        formFields.heading = '';
        descriptionRef.current = '';
    }

    const _onDeleteTemplateContent = (index) => {
        var array = contentData;
        array.splice(index, 1)
        setContentData([])
        setContentData(array)
        setContentData([...contentData])
        setFormUpdated(true)
        

    }

    const onSectionChange = (index, value, type) => {
        const updatedContentData = [...contentData];
        updatedContentData[index] = { ...updatedContentData[index], [type]: value };
        
        if (pageComing === 'template_approval') {
            updatedContentData[index].is_checked = true;
        }
        
        setContentData(updatedContentData);
    }
    

    const _onSaveTemplate = (type) => {
        if (formFields.isComment === false && type != undefined && type != null) {
            addToast('Please Enter Comment First', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        }

        else {
            if (props.location.state.status == 'PTA' && type == null) {
                addToast('Make sure you have updated your template as per Approvers Version', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
            }
            if (contentData.length > 0) {
                for (var i in contentData) {
                    contentData[i].priority = parseInt(i)
                    contentData[i].total_sections = contentData.length
                    if (!contentData[i].title) {
                        addToast('Please add all the titles', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        return;
                    }
                    if (!contentData[i].description) {
                        addToast('Please add all the descriptions', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        return;
                    }
                    if (!contentData[i].section) {
                        addToast('Please select section levels', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        return;
                    }
                }
                var method = 'PUT'
                console.log(contentData)
                var params = JSON.stringify(contentData)
                if (type != 'PENDING_APPROVAL') {
                    setSaveTemplateLoader(true)
                }
                if (type == 'APPROVED') {
                    setAprroveLoader(true)
                }
                if (type == 'PUSH_BACK') {
                    setPushbackLoader(true)
                }
                if (type == 'PENDING_APPROVAL') {
                    setSendForApprovalLoader(true)
                }
                const apifetcherObj = APIMethod(`/template-content/${location.state.detail}?is_approver=${pageComing === 'template_approval' ? true : pageComing === 'template' ? false : false}`, params, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]

                        if (statusCode === 200) {
                            setProposalButton(true)
                            if (type == 'PENDING_APPROVAL') {
                                addToast('Sent for Approval', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                                _onSendForApproval('PENDING_APPROVAL')
                            } else if (type == 'APPROVED') {
                                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                                _onSendForApproval('APPROVED')
                            } else if (type == 'PUSH_BACK') {
                                onUpdateStatus('TEMPLATE_PUSHBACK')
                            } else {
                                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                                _onSendForApproval('PENDING', 'none')
                                setSaveTemplateLoader(false)
                            }
                            if (window.location.pathname == '/add-proposal-template') {
                                onUpdateStatus('GENERATE_PROPOSAL_TEMPLATE')
                            }
                        } else {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                            if (type != 'PENDING_APPROVAL') {
                                setSaveTemplateLoader(false)
                            }
                        }
                    })
                    .catch(error => {
                        if (type != 'PENDING_APPROVAL') {
                            setSaveTemplateLoader(false)
                        }
                    })
            }
        }
    }

    const onUpdateStatus = (key) => {
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/action/event/${key}/${location.state.detail}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    formFields.isComment = false;
                    setAprroveLoader(false)
                    setPushbackLoader(false)
                    if (key != 'UPDATE_OR_APPROVE_TEMPLATE') {
                        goBack()
                    }
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
            })
    }

    const getTemplateContentComments = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/template-content/comments/list/${id}?comment_type=TEMPLATE_CONTENT`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCommentData(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }


    const _onSendForApproval = (status, type) => {
        if (formFields.isComment == false && type != 'none') {
            addToast('Please Enter Comment First', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            var request_url = '/pre-rfx'
            if (contentData.length > 0) {
                var method = 'PUT'
                if (status == 'PENDING') {
                    params = JSON.stringify({
                        id: location.state.detail,
                        is_proposal_template_exist_but_not_proposal: true
                    })
                } else if (status == 'PENDING_APPROVAL') {
                    params = JSON.stringify({
                        id: location.state.detail,
                        template_status: status,
                        is_proposal_template_exist_but_not_proposal: true
                    })
                } else if (status == 'GENERATE_PROPOSAL') {
                    params = JSON.stringify({
                        id: location.state.detail,
                        proposal_status: 'PENDING',
                        is_proposal_exist: true,
                        proposal_start_date: moment(new Date()).format('DD/MM/YYYY')
                    })
                }
                else {
                    var params = JSON.stringify({
                        id: location.state.detail,
                        template_status: status,
                    })
                }
                const apifetcherObj = APIMethod(`${request_url}`, params, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]

                        if (statusCode === 200) {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                            if (status == 'PENDING_APPROVAL') {
                                onUpdateStatus('SEND_FOR_APPROVAL_FOR_TEMPLATE')
                            } else if (status == 'APPROVED') {
                                onUpdateStatus('UPDATE_OR_APPROVE_TEMPLATE')
                            } else if (status == 'PUSH_BACK') {
                                onUpdateStatus('TEMPLATE_PUSHBACK')
                            }
                        } else {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        }
                    })
                    .catch(error => {
                        addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    })
            }
        }
    }

    const addComment = () => {
        if (formFields.comment == '') {
            addToast('Please enter comment', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setPostLoader(true)
            var method = 'POST'
            var params = JSON.stringify({
                comment: formFields.comment,
                pre_rfx_id: location.state.detail,
                type: 'TEMPLATE_CONTENT'
            })
            const apifetcherObj = APIMethod(`/template-content/post/comment`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode == 200) {
                        // addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                        getTemplateContentComments()
                        formFields.comment = ''
                        setPostLoader(false)
                        formFields.isComment = true
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setPostLoader(false)
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setPostLoader(false)
                })
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const onEdit = (res, index, type) => {
        setFormFields({ ...formFields, heading: res.title })
        // setEditIndex(index)
        descriptionRef.current = '';
        editIndex.current = `${index}_${res.title}`
        if (type == 'EDIT') {
            setShow(!show)
        } else {
            setShowModal(!showModal)
        }
        setModalType('Edit')
    }

    const handleClose = () => {
        setShowModal(false)
        editIndex.current = '';
        formFields.heading = '';
        descriptionRef.current = '';
        setModalType('')
    }

    const onsubmit = () => {
        let temp = editIndex.current.split("_")
        
        if (!(changeDes || changeHed || changeType)) {
            addToast('Please Fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            setFlag(true)
        } else {
            addToast('Changes saved!', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
            setFormUpdated(true)
            setChangeDes(false)
            setChangeHed(false)
            setChangeType(false)
            if (modalType == 'Edit') {
                formFields.modalButtonLoader = true
                for (var i in contentData) {
                    if (i == temp[0]) {
                        contentData[i].title = formFields.heading
                        pageComing === 'template_approval' ? contentData[i].is_checked = true : false
                    }
                }
                setContentData([...contentData])

                for (var i in contentData) {
                    if (i == temp[0]) {
                        contentData[i].description = descriptionRef.current
                        pageComing === 'template_approval' ? contentData[i].is_checked = true : false
                    }
                }
                setContentData([...contentData])
                formFields.modalButtonLoader = false
                handleClose()
                editIndex.current = '';
                formFields.heading = '';
                descriptionRef.current = '';
            } else {
                contentData.splice(addIndex + 1, 0, { title: formFields.heading, description: descriptionRef.current, section: '', priority: '' })
                setContentData([...contentData])
                handleClose()
                formFields.heading = '';
                descriptionRef.current = '';
                setAddIndex('')
            }
        }
    }


    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <Loader
                        isLoading={buttonLoader}
                        type={'FULLPAGE'}
                    />
                    <div className="mb-3">
                        {
                            location.state != undefined &&
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={goBack}>{location.state != undefined ? location.state.from : ''}</Breadcrumb.Item>
                                <Breadcrumb.Item active>{pageComing === 'template_approval' || pageComing === 'template' || window.location.pathname === '/edit-proposal-template' ? 'Edit' : 'Add'} Proposal Templates</Breadcrumb.Item>
                            </Breadcrumb>
                        }
                    </div>

                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-9 col-md-9 mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <div className="row col-12">
                                <div className="col-5">
                                    <h2 className="page-title">{pageComing === 'template_approval' ? 'Proposal Template Approval' : 'Proposal Template'}</h2>
                                </div>
                                <div className="col-7 row">
                                    <h4 className="font-16 font-medium">RFx No. : <span className="text-secondary">{preRfxData?.rfx_number ? preRfxData?.rfx_number : '-'}</span></h4>
                                    <h4 className="font-16 font-medium">RFx Title : <span className="text-secondary " title={preRfxData?.title} style={{ wordBreak: 'break-all', width: 50 }}>{preRfxData?.title}</span></h4>
                                </div>
                            </div>
                            <div>
                                <a onClick={CollapseMenu}>
                                    <img src="/images/collapse-icon.svg" width="27" />
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 text-right d-md-flex align-items-end justify-content-end">
                            <button type="button" className="btn btn-brand-1 bg-success px-4" onClick={() => _onGenerateProposal()} disabled={pageComing === 'Approval' || pageComing === 'View' || pageComing === 'Add' || preRfxData?.template_status != 'APPROVED' ? true : false}>Generate Proposal</button>
                        </div>
                    </div>

                    <div className="col px-0 d-flex overflow-auto pb-4 mb-4" style={{ height: "78vh" }}>
                        <div className="">

                            <div className=" mb-4 mr-4" id="PraposalTemp">
                                <Tab.Container id="controlled-tab-example" defaultActiveKey="RowRFX" >
                                    <Row>
                                        <div className="tab-style-1 col-12 d-flex">
                                            <Nav className="d-flex  nav nav-tabs ">
                                                <Nav.Item className="mr-3">
                                                    <img src={require('../../../../assets/css/images/view-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton1' title="Raw RFX" width={30} height={30} />
                                                    <div id='hideCollapseText1'>
                                                        <Nav.Link eventKey="RowRFX">Raw RFX</Nav.Link>
                                                    </div>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <img src={require('../../../../assets/css/images/section-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton2' title="Sectionized RFx" width={30} height={30} />
                                                    <div id='hideCollapseText2'>
                                                        <Nav.Link eventKey="SectionizedRFx" className="mr-3"> Sectionized RFx</Nav.Link>
                                                    </div>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <img src={require('../../../../assets/css/images/structure.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2 mb-3' id='hideCollapseButton3' title="Proposal Structure" width={30} height={30} />
                                                    <div id='hideCollapseText3'>
                                                        <Nav.Link eventKey="ProposalStructure">Proposal Structure</Nav.Link>
                                                    </div>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <Col sm={12} className="tab-style-2">
                                            <Tab.Content>
                                                {
                                                    url ?
                                                        <RawRFx
                                                            url={url}
                                                        /> : null
                                                }
                                                <SectionizedRFx
                                                    page={'/view-parse-rfx-detail'}
                                                    rfx_id={id} 
                                                    // Added this code for  WOII-72. Divya. 11 September 2023.. Code Start
                                                    height={'1000'}
                                                    //Divya. September 11 2023. Code End
                                                    />
                                                <Tab.Pane eventKey="ProposalStructure">
                                                    <div className="form-group">
                                                        {
                                                            formFields.proposalStructure ?
                                                                // <textarea className="form-control h-auto" rows="25" value={formFields['proposalStructure']} name="proposalStructure" disabled={true} />
                                                                <Editor
                                                                    apiKey={tiny_key}
                                                                    onInit={(evt, editor) => proposalStructureRef.current = editor}
                                                                    initialValue={formFields.proposalStructure}
                                                                    disabled={true}
                                                                    init={{
                                                                        height: 500,
                                                                        menubar: 'file edit view insert format tools table tc help',
                                                                        selector: 'textarea#full-featured',
                                                                        plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table ',
                                                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                    }}
                                                                />
                                                                :
                                                                <div className="col-12 d-flex justify-content-center mt-3">
                                                                    <span>No Proposal Structure Found</span>
                                                                </div>
                                                        }
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                        <div className="">
                            <div className="d-flex">
                                <div style={{ minWidth: "695" }}>
                                    <div className="d-flex justify-content-between mb-3 align-items-center">
                                        <h3 className="page-title mb-0">Template Content</h3>
                                    </div>

                                    <div style={{ width: 960, backgroundColor: 'white' }} className="mb-4">
                                        <Tab.Container id="left-tab-example" defaultActiveKey="0">
                                            <Row >
                                                <table className="table table-style-1 mb-4 table-md-responsive" id="" style={{ width: '97.2%', marginLeft: 14 }}>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Heading/Title</th>
                                                            <th scope="col">Description</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                                {/* Commenting the following code for WOII-72. Divya. September 11 2023.. Code Start */}
                                                {/*<Col md={4} style={{ height: 450, overflow: 'scroll', marginTop: 10, marginLeft: 20 }}>*/}
                                                {/* Commenting end. Divya. September 11 2023.. */}
                                                {/*Added this code for  WOII-72. Divya. September 11 2023.. Code Start*/}
                                                <Col md={4} style={{ height: 540, overflow: 'scroll', marginTop: 10, marginLeft: 20 }}>
                                                 {/*Divya. September 11 2023. Code End*/}
                                                    <Nav variant="pills" className="flex-column vertical-tabs">
                                                        {
                                                            contentData?.map((res, i) => {
                                                                let temp = editIndex.current.split("_")
                                                                if (res.section=='' && i>0){
                                                                    res.section=contentData[i-1].section;
                                                                }
                                                                else if(res.section=='' && i==0){
                                                                    res.section='Section-Level 1';
                                                                }
                                                                return (
                                                                    <Nav.Item key={i} style={{ flexDirection: 'row' }}>
                                                                        <Nav.Link eventKey={i} className="mt-2">{res.title}</Nav.Link>
                                                                        <div style={{ flexDirection: 'row' }}>
                                                                            <button type="button" className="btn btn-action edit mr-1" onClick={() => onEdit(res, i, 'EDIT')} disabled={pageComing === 'View' ? true : false}><i className="icofont-pencil-alt-2"></i></button>
                                                                            <button type="button" className="btn" style={{ padding: 0 }} onClick={() => _onAddTemplateContent(i)} disabled={pageComing === 'View' ? true : false}><i className="icofont-plus-square"></i></button>
                                                                            {<button type="button" className="btn" onClick={() => _onDeleteTemplateContent(i)} disabled={pageComing === 'View' ? true : false}><i className="icofont-minus-square"></i></button>}
                                                                            <button type="button" className="btn" disabled={(changeDes || changeHed || changeType) ? (temp[0] == i ? false : true) : true}><img src={saveIcon} height={20} width={20} onClick={() => onsubmit()} /></button>
                                                                            <select id="disabledSelect" className="font-14 ml-2" style={{ backgroundColor: 'white', height: 40, borderColor: '#e6e6e6', borderRadius: 6 }} 
                                                                            value={res.section}
                                                                            name={res.section} 
                                                                            onChange={(e) => {
                                                                                
                                                                                var avt=actualData[i].section
                                                                                if(e.target.value!=actualData[i].section){
                                                                                    setChangeType(true)
                                                                                }
                                                                                else{
                                                                                    setChangeType(false)
                                                                                }
                                                                                
                                                                                onSectionChange(i, e.target.value, 'section');
                                                                            }}
                                                                            
                                                                            
                                                                            disabled={editIndex.current != "" ? (temp[0] == i ? false : true) : true}
                                                                            >
                                                                                <option value="" disabled selected>Select your option</option>
                                                                                <option value={'Section-Level 1'}>Section-Level 1</option>
                                                                                <option value={'Section-Level 2'}>Section-Level 2</option>
                                                                                <option value={'Section-Level 3'}>Section-Level 3</option>
                                                                                <option value={'Section-Level 4'}>Section-Level 4</option>
                                                                                <option value={'Question'}>Question</option>
                                                                            </select>
                                                                        </div>
                                                                    </Nav.Item>
                                                                )
                                                            })
                                                        }
                                                    </Nav>
                                                </Col>
                                                <Col sm={7}>
                                                    <Tab.Content className="bg-white py-4 px-lg-4 px-md-4 border rounded-theme mt-4 h-auto mb-4" >
                                                        {
                                                            contentData?.map((res, j) => {
                                                                let temp = editIndex.current.split("_")
                                                                descriptionRef.current=res.description;
                                                                return (
                                                                    <Tab.Pane eventKey={j} key={j}>
                                                                        {/* <div style={{ overflow: 'scroll', height: 390 }} dangerouslySetInnerHTML={{ __html: (res.description && res.description.length > 200 ? `${res.description}` : res.description) }}></div> */}
                                                                        {editIndex.current != "" ? (temp[0] == j ? <input type="text" className="form-control mb-4" 
                                                                        onChange={(e)=>{
                                                                            
                                                                            handleChange(e);
                                                                            if (e.target.value!=actualData[j].title){
                                                                                setChangeHed(true);
                                                                            }
                                                                            else{
                                                                                setChangeHed(false);
                                                                            }
                                                                            
                                                                        }} 
                                                                        name="heading" 
                                                                        value={formFields['heading']} 
                                                                        /> : null) : null}
                                                                        <Editor
                                                                            apiKey={tiny_key}
                                                                            onEditorChange={(evt, editor) => {
                                                                                descriptionRef.current = evt;
                                                                                if (evt !=res.description){
                                                                                    setChangeDes(true);
                                                                                }
                                                                                else{
                                                                                    setChangeDes(false);
                                                                                }
                                                                            
                                                                            }}
                                                                            initialValue={res.description}
                                                                            disabled={editIndex.current != "" ? (temp[0] == j ? false : true) : true}
                                                                            init={{
                                                                                height: 500,
                                                                                menubar: 'file edit view insert format tools table tc help',
                                                                                selector: 'textarea#full-featured',
                                                                                plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table ',
                                                                                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                            }}
                                                                        />
                                                                    </Tab.Pane>
                                                                )
                                                            })
                                                        }
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                                {
                                    pageComing === 'template_approval' ? null : approverData.length > 0 ?
                                        <div className="ml-3" style={{ minWidth: "695px" }}>
                                            <div className="d-flex justify-content-between mb-3 align-items-center">
                                                <h3 className="page-title mb-0">Approver's Version</h3>
                                            </div>
                                            <div style={{ width: 960, backgroundColor: 'white' }} className="mb-4">
                                                <Tab.Container id="left-tab-example" defaultActiveKey="0">
                                                    <Row >
                                                        <table className="table table-style-1 mb-4 table-md-responsive" id="" style={{ width: '97.2%', marginLeft: 14 }}>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Heading/Title</th>
                                                                    <th scope="col">Description</th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                        {/* Commenting the following code for WOII-72. Divya. September 11 2023.. Code Start */}
                                                        {/*<Col md={4} style={{ height: 450, overflow: 'scroll', marginTop: 10, marginLeft: 20 }}>*/}
                                                        {/* Commenting end. Divya. September 11 2023.. */}
                                                        {/* Added this code for  WOII-72. Divya. 11 September 2023.. Code Start*/}
                                                        <Col md={4} style={{ height: 540, overflow: 'scroll', marginTop: 10, marginLeft: 20 }}>
                                                        {/*Divya. September 11 2023. Code End*/}
                                                            <Nav variant="pills" className="flex-column vertical-tabs">
                                                                {
                                                                    approverData?.map((res, i) => {
                                                                        return (
                                                                            <Nav.Item key={i} style={{ flexDirection: 'row' }}>
                                                                                <Nav.Link eventKey={i}>{res.title}</Nav.Link>
                                                                                <div style={{ flexDirection: 'row' }}>
                                                                                    <select id="disabledSelect" className="font-14 mb-2" style={{ backgroundColor: 'white', height: 40, borderColor: '#e6e6e6', borderRadius: 6 }} value={res.section} name={res.section} disabled={true}>
                                                                                        <option value="" disabled selected>Select your option</option>
                                                                                        <option value={'Section-Level 1'}>Section-Level 1</option>
                                                                                        <option value={'Section-Level 2'}>Section-Level 2</option>
                                                                                        <option value={'Section-Level 3'}>Section-Level 3</option>
                                                                                        <option value={'Section-Level 4'}>Section-Level 4</option>
                                                                                        <option value={'Question'}>Question</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Nav.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </Nav>
                                                        </Col>
                                                        <Col sm={7}>
                                                            <Tab.Content className="bg-white py-4 px-lg-4 px-md-4 border rounded-theme mt-4 h-auto mb-4" >
                                                                {
                                                                    approverData?.map((res, j) => {
                                                                        return (
                                                                            <Tab.Pane eventKey={j} key={j}>
                                                                                {/* Commenting the following code for WOII-72. Divya. September 11 2023.. Code Start */}
                                                                                {/*<div style={{ overflow: 'scroll', height: 390 }} dangerouslySetInnerHTML={{ __html: (res.description && res.description.length > 200 ? `${res.description}` : res.description) }}></div>*/}
                                                                                {/* Commenting end. Divya. September 11 2023.. */}
                                                                                {/* Added this code for  WOII-72. Divya. 11 September 2023.. Code Start*/}
                                                                                <div style={{ overflow: 'scroll', height: 500 }} dangerouslySetInnerHTML={{ __html: (res.description && res.description.length > 200 ? `${res.description}` : res.description) }}></div>
                                                                                {/*Divya. September 11 2023. Code End*/}
                                                                            </Tab.Pane>
                                                                        )
                                                                    })
                                                                }
                                                            </Tab.Content>
                                                        </Col>
                                                    </Row>
                                                </Tab.Container>
                                            </div>
                                        </div> : null
                                }
                            </div>
                            {
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="font-16 mb-0">Comments</h4>
                                        </div>
                                        <div className="card-body bg-white">
                                            {
                                                commentData.map((res, index) => {
                                                    return (
                                                        <div key={index} className="bg-light p-2 rounded mb-2">
                                                            <p className="mb-1">
                                                                <span className="font-medium mr-2">{res.user.first_name}{res.user.last_name}</span>
                                                                <span className="text-secondary font-12 mr-3">{moment(res.created_date).format("D-MMM-YYYY h:mm a")}</span>
                                                                {/* <span className="badge badge-warning">Pushed Back</span> */}
                                                            </p>
                                                            <p className="font-14 mb-0 ">{res.comment}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                <div className="form-group form-style">
                                                    <label>Add Comment</label>
                                                    <textarea className="form-control h-auto mb-2" rows="2" placeholder="Write here.." onChange={handleChange} name="comment" value={formFields['comment']}></textarea>
                                                    <button type="button" className="btn btn-brand-1 btn-sm" onClick={() => addComment()} disabled={postLoader || pageComing === 'View'}>
                                                        {postLoader && (
                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                        )}
                                                        Post <i className="icofont-paper-plane"></i></button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className=" text-center mt-4 mb-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={goBack}>Cancel</button>
                                {
                                    pageComing === 'template_approval' || pageComing === 'View' || preRfxData?.template_status == 'Template Sent for Approval' ? null :
                                        <button type="button" className="btn btn-lg btn-brand-1 mr-3 ml-3 bg-success" onClick={() => _onSaveTemplate()} disabled={saveTemplateLoader}>
                                            {saveTemplateLoader && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}Save</button>
                                }
                                {
                                    (preRfxData?.template_status === 'Template Sent for Approval' || preRfxData?.template_status === 'Proposal Template Approved' || pageComing === 'template_approval' || pageComing === 'View') ||
                                    (preRfxData?.template_status === 'APPROVED') ? null : (
                                      <button type="button" className="btn btn-lg btn-brand-1 mr-3" onClick={() => _onSaveTemplate('PENDING_APPROVAL')} disabled={sendForApprovalLoader}>
                                        {sendForApprovalLoader && (
                                          <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                        )}
                                        Send for Approval
                                      </button>
                                    )
                                    
                                }
                                {pageComing === 'template_approval' ? preRfxData?.template_status == 'Template Sent for Approval (PTS)' || preRfxData?.template_status == 'APPROVED' ? null : <button type="button" className="btn btn-brand-1 bg-success px-4 ml-3" onClick={() => _onSaveTemplate('APPROVED')} disabled={approveLoader}>{approveLoader && (
                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                )}Approve</button> : null}
                                {pageComing === 'template_approval' ? preRfxData?.template_status == 'Template Sent for Approval (PTS)' || preRfxData?.template_status == 'APPROVED' ? null : <button type="button" className="btn btn-brand-1 px-4 ml-3" onClick={() => _onSaveTemplate('PUSH_BACK')} disabled={pushbackLoader}>{pushbackLoader && (
                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                )}Push Back</button> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div >


            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={70.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={showModal}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{modalType} Template Content</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Heading / Title</label>
                                <input type="text" className="form-control" onChange={handleChange} name="heading" value={formFields['heading']} />
                                <span className="text-danger">{flag === true && formFields.heading === '' ? 'Please Enter Heading' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description</label>
                                <Editor
                                    apiKey={tiny_key}
                                    onEditorChange={(evt, editor) => {
                                        descriptionRef.current = evt;
                                        setChangeDes(true)
                                    
                                    }}
                                    initialValue={formFields.description}
                                    init={{
                                        height: 200,
                                        menubar: 'file edit view insert format tools table tc help',
                                        selector: 'textarea#full-featured',
                                        plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table ',
                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                    }}
                                />
                                <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => handleClose()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => onsubmit()} disabled={formFields.modalButtonLoader}>
                        {formFields.modalButtonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}Save</button>
                </div>
            </ReactModal>
            {
                showChatModal == false &&
                <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                    <img src={chatIcon} />
                </div>
            }
            {
                showChatModal &&
                <Chat
                    id={preRfxData.id}
                    title={preRfxData?.title}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default AddProposalTemplate;