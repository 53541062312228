import React, { useEffect, useState, useRef } from "react";
import Leftnavbar from "../../../../components/Leftnavbar";
import { Row, Col, Modal, Nav, Tab } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { APIMethod, tiny_key, TOAST_TIMEOUT } from "../../../../API/APIClient";
import { useLocation, useHistory } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import ReactModal from 'react-modal-resizable-draggable';
import ModalPopup from "../../../../components/ModalPopUp";
import Loader from '../../../../components/Loader';
import { Editor } from '@tinymce/tinymce-react';
import { checkEmptyString } from "../../../../utility/utility";
import chatIcon from '../../../../assets/css/images/chat.png';
import Chat from "../../../../components/Chat";
import saveIcon from '../../../../assets/css/images/save.png';

const ParseContract = (props) => {
    const descriptionRef = useRef(null);
    const [isLoading, setLoading] = useState(false)
    // Add modal box
    const [show, setShow] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const location = useLocation();
    const { addToast } = useToasts();
    const [parseContractData, setParseContractData] = useState([]);
    const [formFields, setFormFields] = useState({ heading: '', description: '' })
    const [flag, setFlag] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false)
    const [id, setId] = useState()
    const editIndex = useRef('');
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [showChatModal, setShowChatModal] = useState(false);
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }

    useEffect(() => {
        getContractList()
    }, [])

    const getContractList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/contract/section/list/${location.state.detail.id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setParseContractData(data)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
                setLoading(false)
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const onsubmit = () => {
        if (formFields.heading === '' || checkEmptyString(descriptionRef.current)) {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setButtonLoader(true)
            var method = id ? 'PUT' : 'POST'
            var params = JSON.stringify({
                id: id,
                pre_rfx_id: location.state.detail.id,
                description: descriptionRef.current,
                title: formFields.heading,
                priority: "1"
            })
            const apifetcherObj = APIMethod(`/contract/section`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { appearance: 'success', autoDismiss: true });
                        setButtonLoader(false)
                        setFlag(false)
                        setFormFields({ ...formFields, heading: '', description: '' })
                        setAddModal(false)
                        editIndex.current = '';
                        getContractList()
                        setId('')
                    } else {
                        addToast(data.message, { appearance: 'error', autoDismiss: true });
                        setAddModal(false)
                        editIndex.current = '';
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    addToast(error.message, { appearance: 'error', autoDismiss: true });
                    setButtonLoader(false)
                })
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const handleModelChange = (model) => {
        formFields.description = model;
    };

    const onEdit = (res, index, type) => {
        setFormFields({ ...formFields, heading: res.title })
        descriptionRef.current = '';
        editIndex.current = `${index}_${res.title}`
        if (type == 'EDIT') {
            setShow(!show)
        } else {
            setAddModal(!addModal)
        }
        setId(res.id)
    }

    const onCloseModal = () => {
        setFormFields({ ...formFields, heading: '', description: '' })
        setId('')
        setAddModal(!addModal)
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    return (
        <>
            <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={'/contract/section/'}
                updateList={getContractList}>
            </ModalPopup>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Parsed Contract</h2>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-lg btn-brand-1 bg-danger mb-3" onClick={() => goBack()}>Back to contract page</button>
                    </div>
                    <div className="card bg-white">
                        <div className="card-header text-center d-flex justify-content-between align-items-center">
                            <p className="mb-0 font-medium">Sectionized Contract</p>
                            <div>
                                {parseContractData.length > 0 ? null : <button className="btn btn-brand-1 bg-success px-4 mr-3" onClick={() => setAddModal(!addModal)}>Add</button>}
                                <NavLink to={{ pathname: "/create-compliance", state: { detail: props.location.state.detail } }} className="btn btn-brand-1  px-4 mr-3">Create Compliance</NavLink>
                                <NavLink to={{ pathname: "/view-compliance", state: { detail: props.location.state.detail } }} className="btn btn-brand-1  px-4">View Compliance</NavLink>
                            </div>
                        </div>
                        <div className="card-body ">
                            <Loader isLoading={isLoading} />
                            {parseContractData.length > 0 && isLoading == false &&
                                <Tab.Container id="left-tab-example" defaultActiveKey="0">
                                    <Row >
                                        <table className="table table-style-1 mb-4 table-md-responsive" id="" style={{ width: '97.2%', marginLeft: 14 }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Heading/Title</th>
                                                    <th scope="col">Description</th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <Col md={4} style={{ height: 450, overflow: 'scroll', marginTop: 10, marginLeft: 20 }}>
                                            <Nav variant="pills" className="flex-column vertical-tabs">
                                                {
                                                    parseContractData?.map((res, i) => {
                                                        let temp = editIndex.current.split("_")
                                                        return (
                                                            <Nav.Item style={{ flexDirection: 'row' }}>
                                                                <Nav.Link eventKey={i}>{res.title}</Nav.Link>
                                                                <div style={{ flexDirection: 'row' }}>
                                                                    <button type="button" className="btn btn-action edit mr-1" onClick={() => onEdit(res, i, 'EDIT')}><i className="icofont-pencil-alt-2"></i></button>
                                                                    <button type="button" className="btn" style={{ padding: 0 }} onClick={() => setAddModal(!addModal)} ><i className="icofont-plus-square"></i></button>
                                                                    <button type="button" className="btn" onClick={() => _onDelete(res.id)}><i className="icofont-minus-square"></i></button>
                                                                    <button type="button" className="btn mb-1" disabled={editIndex.current != "" ? (temp[0] == i ? false : true) : true}><img src={saveIcon} height={20} width={20} onClick={() => onsubmit()} /></button>
                                                                </div>
                                                            </Nav.Item>
                                                        )
                                                    })
                                                }
                                            </Nav>
                                        </Col>
                                        <Col sm={7}>
                                            <Tab.Content className="bg-white py-4 px-lg-4 px-md-4 border rounded-theme mt-4 mb-4" style={{ height: '89%' }}>
                                                {
                                                    parseContractData?.map((res, j) => {
                                                        let temp = editIndex.current.split("_")
                                                        return (
                                                            <Tab.Pane eventKey={j}>
                                                                {/* <div style={{ overflow: 'scroll', height: 410 }} dangerouslySetInnerHTML={{ __html: (res.description && res.description.length > 200 ? `${res.description}` : res.description) }}></div> */}
                                                                {editIndex.current != "" ? (temp[0] == j ? <input type="text" className="form-control mb-4" onChange={handleChange} name="heading" value={formFields['heading']} /> : null) : null}
                                                                <Editor
                                                                    apiKey={tiny_key}
                                                                    onEditorChange={(evt, editor) => descriptionRef.current = evt}
                                                                    initialValue={res.description}
                                                                    disabled={(temp[0] == j ? false : true)}
                                                                    init={{
                                                                        height: 500,
                                                                        menubar: 'file edit view insert format tools table tc help',
                                                                        selector: 'textarea#full-featured',
                                                                        plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                    }}
                                                                />
                                                            </Tab.Pane>
                                                        )
                                                    })
                                                }
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            }
                        </div>
                    </div>

                </div>
            </div>


            {/* Add Modal Box  */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={70.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setAddModal(!addModal)}
                isOpen={addModal}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{id ? 'Edit' : 'Add'} Sectionized Contract</h5>
                    <button type="button" className="close" onClick={() => onCloseModal()}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Heading</label>
                                <input type="text" className="form-control" onChange={handleChange} name="heading" value={formFields['heading']} />
                                <span className="text-danger">{flag === true && formFields.heading === '' ? 'Please Enter Heading' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description</label>
                                <Editor
                                    apiKey={tiny_key}
                                    onInit={(evt, editor) => descriptionRef.current = editor}
                                    initialValue={formFields.description}
                                    init={{
                                        height: 500,
                                        menubar: 'file edit view insert format tools table tc help',
                                        selector: 'textarea#full-featured',
                                        plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists ',
                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                    }}
                                />
                                <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => onCloseModal()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => onsubmit()} disabled={buttonLoader}>
                        {buttonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}Save</button>
                </div>
            </ReactModal>
            {
                showChatModal == false && location.state.detail.id &&
                <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                    <img src={chatIcon} />
                </div>
            }
            {
                showChatModal &&
                <Chat
                    id={location.state.detail.id}
                    title={location.state.rfx_data.title}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default ParseContract;