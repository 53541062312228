import React, { useEffect, useState, Fragment, useRef } from "react";
import { Breadcrumb, Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import Form from 'react-bootstrap/Form'
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { APIMethod, FileUploadMethod, tiny_key, TOAST_TIMEOUT } from "../../../../API/APIClient";
import moment from "moment";
import ModalPopup from '../../../../components/ModalPopUp';
import Comment from "../../../../components/Comment";
import PhoneInput from 'react-phone-number-input';
import ReactModal from 'react-modal-resizable-draggable';
import Toggle from 'react-toggle';
import { checkEmptyString } from "../../../../utility/utility";
import { Editor } from '@tinymce/tinymce-react';
import chatIcon from '../../../../assets/css/images/chat.png';
import Chat from "../../../../components/Chat";

const AddPreRfx = (props) => {
    const editorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [approvalButtonLoader, setApprovalButtonLoader] = useState(false);
    const [modalButtonLoader, SetModalButtonLoader] = useState(false);
    const [pageComing, setPageComing] = useState('');
    // file
    const [file, setFile] = useState();
    // Add modal box
    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(!show);
    }
    const [RFxTypeData, setRFxTypeData] = useState([]);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [editData, setEditData] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [addDocuments, setAddDocuments] = useState([]);
    const [deleteIndex, setDeleteIndex] = useState('');
    const [editIndex, setEditIndex] = useState(-1);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [showDeleteModalPopup, setShowDeleteModalPopup] = useState(false);
    const [role, setRole] = useState('');
    const [bid, setBid] = useState(false);
    const [vendors, setVendors] = useState(false);
    const [licenses, setLicenses] = useState(false);
    const [conditions, setConditions] = useState(false);
    const [constraints, setconstraints] = useState(false);
    const [business, setBusiness] = useState(false);
    const [formFields, setFormFields] = useState({
        businessUnit: '', RFxNumber: '', RFxTitle: '', RFxType: '', RFxStatus: '', RFxCategory: '', RFxClientAgency: '',
        description: '', sourceURL: '', state: '', submissionFormat: '', buyerName: '', buyerTitle: '', buyerContact: '',
        buyerEmail: '', id: '', comment: '', fileName: '', document_type_id: '', version: '', documentDescription: '',
        countryId: '', stateId: '', thirdPartySourceUrl: '', nicasCode: '', bidData: '', vendorData: '', licenseData: '',
        conditionData: '', constraintsData: '', businessData: '', rfx_constraints_id: '', qa_release_date: undefined,
        isComment: false
    })
    const [buyerContact, setBuyerContact] = useState('')
    const [flag, setFlag] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);
    // Toast
    const { addToast } = useToasts();
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    //---Date Time picker
    const [startDateTime, setStartDateTime] = useState(new Date());
    const history = useHistory();
    const { id } = useParams();
    const [showChatModal, setShowChatModal] = useState(false)
    const handleClose = () => {
        setShow(!show)
        setEditIndex(-1)
        setFormFields({ ...formFields, document_type_id: '', fileName: '', version: '', documentDescription: '' })
    }


    useEffect(() => {
        getBusinessUnitList()
        getRFxTypeList()
        getRFxCategoryList()
        getDocumentTypeList()
        getCountryList()
        check('detail', id)
    }, [])


    const check = (type, id) => {
        const userRole = localStorage.getItem('userRole');
        setRole(userRole)
        if (props.location.state == null) {
        } else {
            setPageComing(props.location.state.page)
        }
        if (id == null) {
        } else {
            setLoading(true)
            var method = 'GET'
            const apifetcherObj = APIMethod(`/pre-rfx/${id}`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setEditData(data)
                        formFields.id = data.id,
                            formFields.businessUnit = data.business_unit ? data.business_unit : '',
                            formFields.RFxNumber = data.rfx_number,
                            formFields.RFxTitle = data.title,
                            formFields.RFxCategory = data.rfx_category ? data.rfx_category : '',
                            formFields.RFxClientAgency = data.rfx_client_agency,
                            formFields.description = data.scope,
                            formFields.sourceURL = data.client_url,
                            formFields.RFxType = data.rfx_type_id,
                            formFields.RFxStatus = data.rfx_status_id,
                            formFields.submissionFormat = data.subbmission_format,
                            formFields.buyerName = data.buyer_name,
                            formFields.buyerTitle = data.buyer_title,
                            formFields.buyerEmail = data.buyer_email,
                            formFields.thirdPartySourceUrl = data.third_ps_url,
                            formFields.nicasCode = data.naics,
                            formFields.stateId = data.state_id,
                            formFields.countryId = data.country_id
                        if (data.rfx_constraints != undefined) {
                            formFields.rfx_constraints_id = data.rfx_constraints.id,
                                formFields.businessData = data.rfx_constraints.small_firms,
                                formFields.constraintsData = data.rfx_constraints.other_constraints,
                                formFields.conditionData = data.rfx_constraints.financial_condition,
                                formFields.licenseData = data.rfx_constraints.certification_license_needed,
                                formFields.vendorData = data.rfx_constraints.local_vendor_eligible,
                                formFields.bidData = data.rfx_constraints.conference_mandatory
                            setVendors(data.rfx_constraints.is_local_vendor_eligible)
                            setBid(data.rfx_constraints.is_conference_mandatory)
                            setLicenses(data.rfx_constraints.is_certification_license_needed)
                            setConditions(data.rfx_constraints.is_financial_condition)
                            setconstraints(data.rfx_constraints.is_other_constraints)
                            setBusiness(data.rfx_constraints.is_small_firms)
                        }

                        setBuyerContact(data.buyer_contact)
                        setAddDocuments(data.documents)
                        data.published ? setStartDate(moment(data.published, 'DD/MM/YYYY').toDate()) : null
                        data.due ? setStartDateTime(moment(data.due, 'DD/MM/YYYY HH:mm:ss').toDate()) : null
                        setLoading(false)
                        setFlag(false)
                        if (formFields.id) {
                            getComments()
                        }
                        if (data.country_id) {
                            getStateList(data.country_id)
                        }

                        if (type == 'setApprovalButtonLoader') {
                            _onSendApproval('PENDING_APPROVAL', data.id)
                        } else if (type == 'setButtonLoader') {
                            _onSendApproval('PENDING', data.id)
                        }
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setLoading(false)
                })
        }
    }


    const getBusinessUnitList = () => {
        const userId = localStorage.getItem('userId')
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/user-business/list/${userId}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitData(data)
                    for (var i in data) {
                        if (data[i].is_checked === true) {
                            formFields.businessUnit = data[i].business_unit_id
                        }
                    }
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getDocumentTypeList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentList(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRFxTypeList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-types/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxTypeData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRFxCategoryList = () => {
        const userId = localStorage.getItem('userId')
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/user-category/list/${userId}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    for (var i in data) {
                        if (data[i].is_checked === true) {
                            formFields.RFxCategory = data[i].rfx_category_id
                        }
                    }
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getCountryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/region/country/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCountryData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getStateList = (id) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/region/state/list/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setStateData(data)
                    if (editData) {
                        formFields.stateId = editData.state_id
                    }
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })

    }

    const getComments = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/comments/${formFields.id}?comment_type=PRE_RFX`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCommentData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const save = (type) => {
        let newdate = moment(startDateTime).format(
            "DD/MM/YYYY HH:mm:ss"
        );
        var qa_release_date = formFields.qa_release_date == null ? '' : moment(formFields.qa_release_date).format('YYYY-MM-DD')
        var isRfx = false;
        if (checkEmptyString(formFields.businessUnit) || checkEmptyString(formFields.RFxNumber) || checkEmptyString(formFields.RFxTitle) || checkEmptyString(formFields.RFxType) ||
            checkEmptyString(formFields.RFxCategory) || checkEmptyString(formFields.RFxClientAgency) || checkEmptyString(editorRef.current.getContent()) || checkEmptyString(formFields.sourceURL) ||
            checkEmptyString(formFields.submissionFormat) || checkEmptyString(formFields.buyerName) || checkEmptyString(formFields.buyerTitle) || checkEmptyString(formFields.buyerEmail) ||
            checkEmptyString(formFields.thirdPartySourceUrl) || checkEmptyString(formFields.nicasCode) || checkEmptyString(formFields.countryId)) {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (!Regex.test(formFields.sourceURL)) {
            addToast('Please enter valid source Url', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            setFlag(true)
        } else if (!Regex.test(formFields.thirdPartySourceUrl)) {
            addToast('Please enter valid 3rd Party Source URL', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            setFlag(true)
        } else if (addDocuments.length > 0) {
            for (var i in addDocuments) {
                if (addDocuments[i].document_id == '15') {
                    isRfx = true;
                    break;
                }
            }
            if (isRfx) {
                if (type == 'setButtonLoader') {
                    setButtonLoader(true)
                } else {
                    setApprovalButtonLoader(true)
                }
                if (type == 'setApprovalButtonLoader' && formFields.isComment == false) {
                    addToast('Please Enter Comment First', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setApprovalButtonLoader(false)
                } else {
                    type + (true)
                    var method = formFields.id ? 'PUT' : 'POST'
                    var params = JSON.stringify({
                        id: formFields.id,
                        business_unit: formFields.businessUnit,
                        rfx_number: formFields.RFxNumber,
                        title: formFields.RFxTitle,
                        published: formatPublishDate('Sun May 11,2014'),
                        due: newdate,
                        rfx_category: formFields.RFxCategory,
                        rfx_client_agency: formFields.RFxClientAgency,
                        scope: editorRef.current.getContent(),
                        client_url: formFields.sourceURL,
                        third_ps_url: formFields.sourceURL,
                        country_id: formFields.countryId,
                        state_id: formFields.stateId,
                        rfx_type_id: formFields.RFxType,
                        subbmission_format: formFields.submissionFormat,
                        buyer_name: formFields.buyerName,
                        buyer_title: formFields.buyerTitle,
                        buyer_contact: buyerContact,
                        buyer_email: formFields.buyerEmail,
                        third_ps_url: formFields.thirdPartySourceUrl,
                        naics: formFields.nicasCode,
                        documents: addDocuments,
                        rfx_constraints: {
                            is_conference_mandatory: bid,
                            is_local_vendor_eligible: vendors,
                            is_certification_license_needed: licenses,
                            is_financial_condition: conditions,
                            is_other_constraints: constraints,
                            is_small_firms: business,
                            conference_mandatory: formFields.bidData,
                            local_vendor_eligible: formFields.vendorData,
                            certification_license_needed: formFields.licenseData,
                            financial_condition: formFields.conditionData,
                            other_constraints: formFields.constraintsData,
                            small_firms: formFields.businessData
                        },
                        rfx_data: {
                            qa_release_date: qa_release_date,
                        }
                    })
                    const apifetcherObj = APIMethod(`/pre-rfx`, params, method)
                    apifetcherObj
                        .then(response => { return Promise.all([response.status, response.json()]) })
                        .then(res => {
                            let statusCode = res[0]
                            let data = res[1]

                            if (statusCode === 200) {
                                formFields.id = data.id;
                                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                                check(type, data.id)
                            } else {
                                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                                if (type == 'setButtonLoader') {
                                    setButtonLoader(false)
                                } else {
                                    setApprovalButtonLoader(false)
                                }
                            }
                        })
                        .catch(error => {
                            if (type == 'setButtonLoader') {
                                setButtonLoader(false)
                            } else {
                                setApprovalButtonLoader(false)
                            }
                        })
                }
            } else {
                addToast(`Please Add RFP Document it's mandatory`, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            }
        } else {
            addToast(`Please Add RFP Document it's mandatory`, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        }
    }

    const formatPublishDate = () => {
        var d = startDate,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
        if (name === 'countryId') {
            getStateList(event.target.value)
        }
    }
    const filehandler = event => {
        setFile(event.target.files[0])
    }

    const fileUpload = () => {
        if (file.type) {
            SetModalButtonLoader(true)
            var method = 'POST'
            var form = new FormData();
            form.append('file', file);
            const apifetcherObj = FileUploadMethod(`/document/attachment/upload`, form, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]
                    var name = ""
                    if (statusCode === 200) {
                        for (var i in documentList) {
                            if (documentList[i].id == formFields.document_type_id) {
                                name = documentList[i].name
                            }
                        }
                        var obj = {
                            id: data.id,
                            document_id: formFields.document_type_id,
                            document_type_name: name,
                            file_name: file.name,
                            version: formFields.version,
                            file: data.file,
                            description: formFields.documentDescription
                        }

                        if (editIndex >= 0) {
                            for (var index in addDocuments) {
                                if (index == editIndex) {
                                    addDocuments.splice(editIndex, 1);
                                    addDocuments.splice(editIndex, 0, obj);
                                    setEditIndex(-1)
                                    setAddDocuments(addDocuments)
                                    break;
                                }
                            }
                        } else {
                            if (addDocuments.length > 0) {
                                if (obj.document_id == 15) {
                                    for (var j in addDocuments) {
                                        if (addDocuments[j].document_id == obj.document_id) {
                                            alert('you can not add this with document type')
                                            SetModalButtonLoader(false)
                                            break;
                                        } else {
                                            addDocuments.push(obj)
                                            setAddDocuments(addDocuments)
                                            break;
                                        }
                                    }
                                } else {
                                    addDocuments.push(obj)
                                    setAddDocuments(addDocuments)
                                }
                            } else {
                                addDocuments.push(obj)
                                setAddDocuments(addDocuments)
                            }
                        }

                        handleClose()
                        setEditIndex(-1)
                        setLoading(false)
                        setFormFields({ ...formFields, document_type_id: '', fileName: '', version: '', documentDescription: '' })
                        setFile('')
                        SetModalButtonLoader(false)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    }
                })
                .catch(error => {
                    SetModalButtonLoader(false)
                })
        } else {
            var name = ""
            for (var i in documentList) {
                if (documentList[i].id == formFields.document_type_id) {
                    name = documentList[i].name
                }
            }
            for (var i in addDocuments) {
                if (i == editIndex) {
                    addDocuments[i].document_id = formFields.document_type_id,
                        addDocuments[i].document_type_name = name,
                        addDocuments[i].file_name = file.name,
                        addDocuments[i].version = formFields.version,
                        addDocuments[i].description = formFields.documentDescription
                }
            }
            handleClose()
            setEditIndex(-1)
            setLoading(false)
            setFormFields({ ...formFields, document_type_id: '', fileName: '', version: '', documentDescription: '' })
            setFile('')
        }
    }

    const _onSendApproval = (val, preRfxId) => {
        if (formFields.isComment == false && val != 'PENDING') {
            addToast('Please Enter Comment First', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            setApprovalButtonLoader(false)
        } else {
            setLoading(true)
            var method = 'PUT'
            var params = JSON.stringify({
                id: preRfxId,
                rfx_status: val
            })
            const apifetcherObj = APIMethod(`/pre-rfx/status`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setLoading(false)
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        setApprovalButtonLoader(false)
                        setButtonLoader(false)
                        goBack()
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const _onAddDocument = () => {
        if (formFields.document_type_id === '' || formFields.version === '' || file === undefined || formFields.documentDescription === '') {
            setModalFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            fileUpload()
        }
    }

    const removeFile = () => {
        addDocuments.splice(deleteIndex, 1);
        var temp = [];
        for (var j in addDocuments) {
            temp.push(addDocuments[j])
            if (j == addDocuments.length - 1) {
                setAddDocuments(temp)
                setDeleteModal(!showDeleteModal)
            }
        }
        if (addDocuments.length == 0) {
            setAddDocuments(temp)
            setDeleteModal(!showDeleteModal)
        }
    }

    const deleteDocument = (index) => {
        setDeleteModal(!showDeleteModal)
        setDeleteIndex(index)
    }

    const deleteData = () => {
        for (var i in addDocuments) {
            if (i == deleteIndex && addDocuments[i].id) {
                var method = 'DELETE'
                const apifetcherObj = APIMethod(`/pre-rfx/document/${addDocuments[i].id}`, null, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]

                        if (statusCode === 200) {
                            removeFile()
                            check('detail')
                            setDeleteModal(!showDeleteModal)
                        }
                    })
                    .catch(error => {
                    })
            } else {
                {/* UnCommenting the following code for WOII-87. Divya. September 12 2023.. Code Start */}
                 removeFile()
                {/* UnCommenting end. Divya. September 12 2023.. */}

            }
        }
    }

    const _onEdit = (index, data) => {
        setFormFields({ ...formFields, document_type_id: data.document_id, fileName: data.file_name, version: data.version, documentDescription: data.description })
        setShow(!show)
        setEditIndex(index)
        setFile({ name: data.file_name })
    }

    const onApproved = (key, statusKey) => {
        if (statusKey === 'SEND_FOR_APPROVAL') {
            save('setApprovalButtonLoader')
        } else {
            _onStatusUpdate(key, statusKey)
        }
    }

    const _onStatusUpdate = (key, statusKey, preRfxId) => {
        var method = 'PUT'
        var id = formFields.id ? formFields.id : preRfxId
        const apifetcherObj = APIMethod(`/action/event/${statusKey}/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    addToast(data.message, { appearance: 'success', autoDismiss: true });
                    _onSendApproval(key, id)
                    goBack()
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
            })
    }

    let Regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

    const onSetComment = () => {
        formFields.isComment = true;
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowDeletePopup(true)
    }

    const isShowDeletePopup = (status) => {
        setShowDeleteModalPopup(status)
    };


    return (
        <>
            <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={'/pre-rfx/document/'}
                updateList={check}
            ></ModalPopup>
            <ModalPopup
                showModalPopup={showDeleteModalPopup}
                onPopupClose={isShowDeletePopup}
                id={id}
                endPoint={'/pre-rfx/'}
                updateList={goBack}
            ></ModalPopup>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3" >
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>{pageComing === 'RFx' ? 'RFx' : 'Pre-RFx'}</Breadcrumb.Item>
                            <Breadcrumb.Item active>{pageComing === 'RFx' ? formFields.id ? 'Edit RFx' : 'Add RFx' : formFields.id ? 'Edit Pre-RFx' : 'Add Pre-RFx'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center">
                        <span className="add-title ">{pageComing === 'RFx' ? formFields.id ? 'Edit RFx' : 'Add RFx' : formFields.id ? 'Edit Pre-RFx' : 'Add Pre-RFx'}</span>
                    </div>
                    <div className="form-wraper form-style mb-4" >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                    {/*<p className="font-medium">RFx Information</p>*/}
                                    {/* Commenting end. Divya. September 01 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                    <p className="font-20 font-bold">RFx Information</p>
                                    {/* Divya. September 01 2023. Code End */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Business Unit</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['businessUnit']} name="businessUnit" onChange={handleChange} defaultValue={""}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            businessUnitData.map((res, index) => {
                                                return (
                                                    <option key={index} value={res.business_unit_id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.businessUnit) ? 'Please Enter Business Unit' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Number</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="RFxNumber" value={formFields['RFxNumber']} />
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.RFxNumber) ? 'Please Enter RFx Number' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label>RFx Title</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="RFxTitle" value={formFields['RFxTitle']} />
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.RFxTitle) ? 'Please Enter RFx Title' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>RFx Type</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['RFxType']} name="RFxType" onChange={handleChange} defaultValue={""}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            RFxTypeData.map((res, index) => {
                                                return (
                                                    <option key={index} value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.RFxType) ? 'Please Select RFx Type' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Publish on</label>
                                    <DatePicker
                                        onChangeRaw={(e) => e.preventDefault()}
                                        selected={startDate}
                                        onChange={(date) => { setStartDate(date), date > startDateTime && setStartDateTime(date) }}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Due Date & Time</label>
                                    <DatePicker
                                        onChangeRaw={(e) => e.preventDefault()}
                                        className="form-control"
                                        selected={startDateTime}
                                        onChange={(date) => setStartDateTime(date)}
                                        minDate={startDate}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        placeholderText="Select Date & Time"
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Category</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['RFxCategory']} name="RFxCategory" onChange={handleChange} defaultValue={""}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            RFxCategoryData.map((res, index) => {
                                                return (
                                                    <option key={index} value={res.rfx_category_id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.RFxCategory) ? 'Please Select RFx Category' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Client Agency</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="RFxClientAgency" value={formFields['RFxClientAgency']} />
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.RFxClientAgency) ? 'Please Enter RFx Client Agency' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                    {/*<label>Description/Scope</label>*/}
                                    {/* Commenting end. Divya. September 01 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                    <p className="font-20 font-bold">Description/Scope</p>
                                    {/* Divya. September 01 2023. Code End */}
                                    <Editor
                                        apiKey={tiny_key}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={formFields.description}
                                        init={{
                                            height: 500,
                                            menubar: 'file edit view insert format tools table tc help',
                                            selector: 'textarea#full-featured',
                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                        }}
                                    />
                                    <span className="text-danger">{flag === true && checkEmptyString(editorRef.current) ? 'Please Enter Description/Scope' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Country</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['countryId']} name="countryId" onChange={handleChange} defaultValue={""}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            countryData.map((val, index) => {
                                                return (
                                                    <option key={index} value={val.id}>{val.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.countryId) ? 'Please Select Country' : null}</span>
                                </div>
                            </div>
                            {
                                formFields.countryId ?
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label>State/Province</label>
                                            <select id="disabledSelect" className="form-control custom-select" value={formFields['stateId']} name="stateId" onChange={handleChange} defaultValue={""}>
                                                <option value="" disabled selected>Select your option</option>
                                                {
                                                    stateData.map((res, index) => {
                                                        return (
                                                            <option key={index} value={res.id}>{res.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{flag === true && checkEmptyString(formFields.stateId) ? 'Please Select State/Province' : null}</span>
                                        </div>
                                    </div> : null
                            }

                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Client/Source URL</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="sourceURL" value={formFields['sourceURL']} />
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.sourceURL) ? 'Please Enter Client/Source URL' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Submission Format</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['submissionFormat']} name="submissionFormat" onChange={handleChange}>
                                        <option value="" disabled selected>Select your option</option>
                                        <option>Online</option>
                                        <option>Email</option>
                                        <option>Mail</option>
                                        <option>Email and Mail</option>
                                    </select>
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.submissionFormat) ? 'Please Select Submission Format' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>3rd Party Source URL</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="thirdPartySourceUrl" value={formFields['thirdPartySourceUrl']} />
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.thirdPartySourceUrl) ? 'Please Enter 3rd Party Source URL' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>NAICS Codes</label>
                                    <input type="text" className="form-control" onChange={handleChange}
                                        // onKeyPress={(event) => {
                                        //     if (!/[0-9]/.test(event.key)) {
                                        //         event.preventDefault();
                                        //         return false;
                                        //     }

                                        //     return true;
                                        // }} 
                                        onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                        }}
                                        onCopy={(e) => {
                                            e.preventDefault();
                                            return false;
                                        }} name="nicasCode" value={formFields['nicasCode']} />
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.nicasCode) ? 'Please Enter NIACS Codes' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                {/*<p className="font-medium">RFx Constraints</p>*/}
                                {/* Commenting end. Divya. September 01 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                <p className="font-20 font-bold">RFx Constraints</p>
                                {/* Divya. September 01 2023. Code End */}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Pre-Bid Conference mandatory? </lable><br />
                                    <Toggle
                                        checked={bid}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setBid(!bid)} />
                                    {
                                        bid === true ?
                                            <div className="form-style mt-2">
                                                {/* Commenting the following code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="bidData" value={formFields['bidData']} />*/}
                                                {/* Commenting end. Divya. September 05 2023.. */}
                                                {/* Added this code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                <textarea className="form-control" style={{ height: "100px",overflow:"auto" }} onChange={handleChange} name="bidData" value={formFields['bidData']} />
                                                {/* Divya. September 05 2023. Code End */}
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Only local vendors eligible?</lable><br />
                                    <Toggle
                                        checked={vendors}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setVendors(!vendors)} />
                                    {
                                        vendors === true ?
                                            <div className="form-style mt-2">
                                                {/* Commenting the following code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="vendorData" value={formFields['vendorData']} />*/}
                                                {/* Commenting end. Divya. September 05 2023.. */}
                                                {/* Added this code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                <textarea className="form-control" style={{ height: "100px",overflow:"auto" }} onChange={handleChange} name="vendorData" value={formFields['vendorData']} />
                                                {/* Divya. September 05 2023. Code End */}
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Any Certification/licenses needed?</lable><br />
                                    <Toggle
                                        checked={licenses}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setLicenses(!licenses)} />
                                    {
                                        licenses === true ?
                                            <div className="form-style mt-2">
                                                {/* Commenting the following code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="licenseData" value={formFields['licenseData']} />*/}
                                                {/* Commenting end. Divya. September 05 2023.. */}
                                                {/* Added this code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                <textarea className="form-control" style={{ height: "100px",overflow:"auto" }} onChange={handleChange} name="licenseData" value={formFields['licenseData']} />
                                                {/* Divya. September 05 2023. Code End */}
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Any financial conditions?</lable><br />
                                    <Toggle
                                        checked={conditions}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setConditions(!conditions)} />
                                    {
                                        conditions === true ?
                                            <div className="form-style mt-2">
                                                {/* Commenting the following code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="conditionData" value={formFields['conditionData']} />*/}
                                                {/* Commenting end. Divya. September 05 2023.. */}
                                                {/* Added this code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                <textarea className="form-control" style={{ height: "100px",overflow:"auto" }} onChange={handleChange} name="conditionData" value={formFields['conditionData']} />
                                                {/* Divya. September 05 2023. Code End */}
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Any other constraints</lable><br />
                                    <Toggle
                                        checked={constraints}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setconstraints(!constraints)} />
                                    {
                                        constraints === true ?
                                            <div className="form-style mt-2">
                                                {/* Commenting the following code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="constraintsData" value={formFields['constraintsData']} />*/}
                                                {/* Commenting end. Divya. September 05 2023.. */}
                                                {/* Added this code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                <textarea className="form-control" style={{ height: "100px",overflow:"auto" }} onChange={handleChange} name="constraintsData" value={formFields['constraintsData']} />
                                                {/* Divya. September 05 2023. Code End */}
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Set aside for small business/minority firms?</lable><br />
                                    <Toggle
                                        checked={business}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setBusiness(!business)} />
                                    {
                                        business === true ?
                                            <div className="form-style mt-2">
                                                {/* Commenting the following code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                {/*<input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="businessData" value={formFields['businessData']} />*/}
                                                {/* Commenting end. Divya. September 05 2023.. */}
                                                {/* Added this code for WOII-68. Divya. September 05 2023.. Code Start */}
                                                <textarea className="form-control" style={{ height: "100px",overflow:"auto" }} onChange={handleChange} name="businessData" value={formFields['businessData']} />
                                                {/* Divya. September 05 2023. Code End */}
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                {/*<p className="font-medium">Buyer Information</p>*/}
                                {/* Commenting end. Divya. September 01 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                <p className="font-20 font-bold">Buyer Information</p>
                                {/* Divya. September 01 2023. Code End */}
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Name</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="buyerName" value={formFields['buyerName']} />
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.buyerName) ? 'Please Enter Buyer Name' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Title</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="buyerTitle" value={formFields['buyerTitle']} />
                                    <span className="text-danger">{flag === true && checkEmptyString(formFields.buyerTitle) ? 'Please Enter Buyer Title' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Contact</label>
                                    <PhoneInput
                                        placeholder="Enter phone number"
                                        value={buyerContact}
                                        className="form-control"
                                        name={"buyerContact"}
                                        onChange={setBuyerContact}
                                        countrySelectProps={{ unicodeFlags: true }}
                                        defaultCountry="US"
                                        international
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Email</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="buyerEmail" value={formFields['buyerEmail']} />
                                    <span className="text-danger">{flag === true && (checkEmptyString(formFields.buyerEmail) || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.buyerEmail)) ? 'Please Enter Valid Buyer Email' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                {/*<p className="font-medium">RFx Attachments</p>*/}
                                {/* Commenting end. Divya. September 01 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                <p className="font-20 font-bold">RFx Attachments</p>
                                {/* Divya. September 01 2023. Code End */}
                            </div>
                            <div className="col-12 text-right">
                                {
                                    editData.rfx_status == "PENDING" || editData.rfx_status == "PUSH_BACK" || editData.rfx_status == "OPEN" || formFields.id === '' ?
                                        <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => handleShow()}>Add</button>
                                        :
                                        null
                                }

                            </div>

                            <div className="col-12 my-3">
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">

                                    <tr>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        <th>Version</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                    {
                                        addDocuments.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{res.document_type_name}</td>
                                                    <td>{res.file_name}</td>
                                                    <td>{res.version}</td>
                                                    <td title={res.description} width="500px">{res.description && res.description.length > 145 ? `${res.description.substring(0, 145)}...` : res.description}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-action" onClick={() => window.open(res.file, "_blank")}><i className="icofont-eye-alt"></i></button>
                                                        <button type="button" className="btn btn-action edit mr-1 ml-1" onClick={() => _onEdit(i, res)}><i className="icofont-pencil-alt-2"></i></button>
                                                        <button type="button" className="btn btn-action delete" onClick={() => deleteDocument(i)}><i className="icofont-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                            {
                                formFields.id ?
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="font-16 mb-0">Pre-RFx Comments</h4>
                                            </div>
                                            <div className="card-body bg-white">
                                                {
                                                    commentData.map((res, index) => {
                                                        return (
                                                            <div key={index} className="bg-light p-2 rounded mb-2">
                                                                <p className="mb-1">
                                                                    <span className="font-medium mr-2">{res.user.first_name} {res.user.last_name}</span>
                                                                    <span className="text-secondary font-12 mr-3">{moment(res.created_date).format("D-MMM-YYYY h:mm a")}</span>
                                                                </p>
                                                                <div dangerouslySetInnerHTML={{ __html: res.comment }}></div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    editData.rfx_status === "PENDING_APPROVAL" ? null :
                                                        <Comment
                                                            id={formFields.id}
                                                            updateCommentList={getComments}
                                                            enableComment={onSetComment}
                                                            editor={true}
                                                        />
                                                }
                                            </div>
                                        </div>
                                    </div> : null
                            }


                            {
                                role == 'Approver' ?
                                    editData.rfx_status === "PENDING_APPROVAL" ?
                                        <div className="col-12 text-center mt-4">
                                            <button type="button" className="btn btn-lg mr-3" style={{ backgroundColor: '#ffc107', fontSize: 16 }} onClick={() => onApproved('PUSH_BACK', 'PUSHBACK')}>Pushback</button>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={() => onApproved('DECLINED', 'DENY')}>No-Go</button>
                                            <button type="button" className="btn btn-lg btn-brand-1 mr-3 bg-success" onClick={() => onApproved('APPROVED', 'UPDATE_OR_APPROVE')}>Go</button>
                                        </div>
                                        :
                                        null
                                    :
                                    <div className="col-10 d-flex">
                                        {
                                            id && <div className="col-2 text-left mt-4">
                                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={() => _onDelete(id)}>Delete</button>
                                            </div>
                                        }
                                        <div className="col-12 text-right mt-4">
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={goBack}>Cancel</button>
                                            {
                                                editData.rfx_status == "PENDING" || editData.rfx_status == "PUSH_BACK" || editData.rfx_status == "OPEN" || formFields.id === '' ?
                                                    <button type="button" className="btn btn-lg btn-brand-1 mr-3 bg-success" onClick={() => save('setButtonLoader')} disabled={buttonLoader}>
                                                        {buttonLoader && (
                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                        )}
                                                        <span>Save</span>
                                                    </button>
                                                    :
                                                    null
                                            }

                                            {
                                                pageComing === 'RFx' ?
                                                    null
                                                    :
                                                    editData.rfx_status == "PENDING" || editData.rfx_status == "PUSH_BACK" || editData.rfx_status == "OPEN" || window.location.pathname === '/add-pre-rfx' ?
                                                        <button type="button" className="btn btn-brand-1  px-4" onClick={() => onApproved('PENDING_APPROVAL', 'SEND_FOR_APPROVAL',)} disabled={approvalButtonLoader}>
                                                            {approvalButtonLoader && (
                                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                            )}Send for Approval</button>
                                                        :
                                                        null
                                            }
                                        </div>


                                    </div>

                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={1550}
                className={"contained-modal-title-vcenter modl"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{editIndex === -1 ? 'Add' : 'Edit'} Documents</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Version</label>
                                <input type="text" className="form-control" onChange={handleChange} name="version" value={formFields['version']} />
                                <span className="text-danger">{modalFlag === true && formFields.version === '' ? 'Please Enter Version' : null}</span>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="documentDescription" value={formFields['documentDescription']}></textarea>
                                    <span className="text-danger">{modalFlag === true && formFields.documentDescription === '' ? 'Please Enter Description' : null}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Document Type</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['document_type_id']} name="document_type_id" onChange={handleChange} defaultValue={""}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        documentList.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{modalFlag === true && formFields.document_type_id === '' ? 'Please Select Document Type' : null}</span>
                            </div>
                            <div className="form-group">
                                <label>Upload File</label>
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="inputGroupFile02" onChange={filehandler} />
                                    <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file === undefined ? 'Choose File' : file.name}</label>
                                    <span className="text-danger">{modalFlag === true && file === undefined ? 'Please Select file' : null}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => setShow(!show)}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onAddDocument()} disabled={modalButtonLoader}>
                        {modalButtonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>

            <Fragment>
                <Modal show={showDeleteModal} onHide={() => setDeleteModal(!showDeleteModal)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="sign-in-title">
                            Alert
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ alignSelf: 'center' }}>
                        <div className="ml-5 mr-5">
                            <img src={require('../../../../assets/css/images/cross.png').default} style={{ marginLeft: '37%' }} alt="No image found" id="img_hoder_1" width="80" />
                            <p style={{ fontSize: 20, textAlign: 'center' }} className="mt-4">Are You Sure ?</p>
                            <p style={{ textAlign: 'center' }}>Do you really want to delete these records? This process cannot be undone.</p>
                            <button type="button" className="btn btn-light mr-1" style={{ marginLeft: '30%' }} onClick={() => setDeleteModal(!showDeleteModal)}> Close</button>
                            <button type="button" className="btn btn-danger ml-3" onClick={() => deleteData()}> Delete</button>
                        </div>
                    </Modal.Body>

                </Modal >
            </Fragment >
            {
                showChatModal == false && id &&
                <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                    <img src={chatIcon} />
                </div>
            }
            {
                showChatModal &&
                <Chat
                    id={id}
                    title={formFields.RFxTitle}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default AddPreRfx;