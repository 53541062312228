import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { APIMethod } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import ModalPopup from '../../../../components/ModalPopUp';
import Pagination from '../../../../components/Pagination';
import { useHistory } from "react-router";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from 'react-modal-resizable-draggable';

const DocumentEntries = () => {
    const [getListData, setGetListData] = useState([]);
    const [documentData, setDocumentData] = useState([]);
    const [loading, setLoading] = useState(false);
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    // page
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const [adding, setAdding] = useState();
    const [editing, setEditing] = useState();
    const [deleting, setDeleting] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [formFields, setFormFields] = useState({
        document_name: '', document_type: '', client_name: ''
    })

    useEffect(() => {
        getDocumentEntriesList(1)
        getDocumentType()
        var data = JSON.parse(localStorage.getItem("user_Role"))
        checkPermission(data)
    }, [])

    const checkPermission = (data) => {
        const resObject = data.find(item => item.name === "Content Library Setup")
        const valObject = resObject.access.find(item => item.name === "Document Entries")
        setAdding(valObject.is_create)
        setEditing(valObject.is_update)
        setDeleting(valObject.is_delete)
    }

    const getDocumentEntriesList = (page, key) => {
        setLoading(true)
        const new_key = key === undefined ? '' : key
        global.new_key = key === undefined ? '' : key
        var method = 'GET'
        const apifetcherObj = APIMethod(`/document/list/${page}?document_type_id=${formFields.document_type}&client_name=${formFields.client_name}&keyword=` + (new_key || formFields.document_name), null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getDocumentType = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const nextPage = (pageNumber) => {
        getDocumentEntriesList(pageNumber)
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const Search = (key) => {
        getDocumentEntriesList(1, key)
    }

    const _onEdit = (val) => {
        history.push({
            pathname: '/add-doc',
            state: { details: val }
        })
    }

    const getData = () => {
        getDocumentEntriesList(1)
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const _onResetFilter = (type) => {
        if (type === 'DOCUMENT_NAME') {
            formFields.document_name = "";
        } else if (type === 'DOCUMENT_TYPE') {
            formFields.document_type = "";
        } else if (type === 'CLIENT_NAME') {
            formFields.client_name = "";
        } else {
            formFields.document_name = "";
            formFields.document_type = "";
            formFields.client_name = "";
            handleClose()
        }
        getDocumentEntriesList(1, '')
    }

    const _onFilter = () => {
        getDocumentEntriesList(1, '')
        handleClose()
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-4 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Document Entries </h2>
                        </div>
                        <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search by document name" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            <button type="button" className="btn btn-brand-1 btn-h-40 mr-3" onClick={() => setShow(true)}><i className="icofont-filter mr-2"></i> Filter</button>
                            {
                                adding === true ?
                                    <NavLink to="/add-doc" className="btn btn-brand-1 bg-success px-4">+ Add</NavLink> : null
                            }
                        </div>
                    </div>

                    <div className="mb-2 filter-by">
                        {
                            formFields.document_name ?
                                <div className="badge">
                                    <span className="lable">Document Name</span>
                                    <span className="value">{formFields.document_name}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('DOCUMENT_NAME')}></i>
                                </div> : null
                        }
                        {
                            formFields.document_type ?
                                <div className="badge">
                                    <span className="lable">Document Type</span>
                                    {
                                        documentData.map((res, index) => {
                                            return (
                                                <span key={index} className="value">{formFields.document_type == res.id ? res.name : null}</span>
                                            )
                                        })
                                    }
                                    <i className="icofont-close" onClick={() => _onResetFilter('DOCUMENT_TYPE')}></i>
                                </div> : null
                        }
                        {
                            formFields.client_name ?
                                <div className="badge">
                                    <span className="lable">Client/Source Name</span>
                                    <span className="value">{formFields.client_name}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('CLIENT_NAME')}></i>
                                </div> : null
                        }
                    </div>


                    <table className="table table-style-1 table-responsive-md mb-4 table reports">
                        <thead>
                            <tr>
                                <th scope="col">Document Name</th>
                                <th scope="col">Document Type</th>
                                <th scope="col">Client/Source Name </th>
                                <th scope="col">File Name</th>
                                <th scope="col" className="text-center" width="110">{editing === true || deleting === true ? 'Action' : null}</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/document/'}
                            updateList={getData}
                        ></ModalPopup>
                        <tbody>
                            {
                                loading === false ? getListData.map((res) => {
                                    return (
                                        <tr>
                                            <td>{res.title}</td>
                                            <td>{res.rfx_document_type_name}</td>
                                            <td>{res.name}</td>
                                            <td>{res.file === undefined ? null : res.file.substring(res.file.lastIndexOf('/') + 1).replace(/\-/, '|').split('|')[1]}</td>
                                            <td className="text-center">
                                                {
                                                    editing === true ?
                                                        <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                }
                                                {
                                                    deleting === true ?
                                                        <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-trash"></i></button> : null
                                                }
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                    {
                        getListData.length === 0 && loading === false && global.new_key ?
                            <SearchNoDataFound message={'No Document Entrie Found'} />
                            : getListData.length === 0 && loading === false ? <NoData /> : null
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">
                            <Pagination
                                pages={pageCount}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                            />
                        </nav>
                    </div>

                </div>
            </div>


            {/* Filter Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={137.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Filter Q&As</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Document Name</label>
                                <input type="text" className="form-control" onChange={handleChange} name="document_name" value={formFields['document_name']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Document Type</label>
                                <select id="disabledSelect" className="form-control custom-select" onChange={handleChange} name="document_type" value={formFields['document_type']} >
                                    <option value="" disabled>Select your option</option>
                                    {
                                        documentData.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Client/Source Name</label>
                                <input type="text" className="form-control" onChange={handleChange} name="client_name" value={formFields['client_name']} />
                            </div>
                        </div>



                    </div>
                </div>
                <div className="modal-footer ">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button className="btn btn-lg btn-brand-1" onClick={() => _onResetFilter()}>Reset</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onFilter()}>Search</button>
                </div>
            </ReactModal>
        </>
    )
}
export default DocumentEntries;