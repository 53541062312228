import React, { useState } from "react";
import { Style } from "react-style-tag";
import { APIMethod, TOAST_TIMEOUT } from '../../../API/APIClient'
import { useHistory } from "react-router";
import { useToasts } from 'react-toast-notifications';
import PhoneInput from 'react-phone-number-input';
import { NavLink } from "react-router-dom";

const Signup = (props) => {
    // Form Validation
    const [formFields, setFormFields] = useState({ email: '', firstName: '', lastName: '', password: '', confirmPassword: '' })
    const [flag, setFlag] = useState(false);
    // Loader
    const [isLoading, setLoading] = useState(false);
    // Toast
    const { addToast } = useToasts();
    // Phone Number 
    const [phoneNumber, setPhoneNumber] = useState('')

    const history = useHistory();


    const goBack = () => {
        window.history.back();
        setFlag(false)
    }


    const submit = () => {
        if (formFields.email == '' || formFields.firstName == '' || formFields.lastName == '' || formFields.phoneNumber == '' || formFields.password == '' || formFields.confirmPassword == '') {
            setFlag(true)
            addToast('Please fill all the fields', { appearance: 'error', autoDismiss: true });
        } else if (formFields.email === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.email)) {
            addToast('Please Enter vaild E-mail', { appearance: 'error', autoDismiss: true })
            setLoading(false)
        } else if (formFields.password != formFields.confirmPassword) {
            addToast('Password and confirm password does not match', { appearance: 'error', autoDismiss: true })
            setLoading(false)
        } else {
            setLoading(true)
            var params = JSON.stringify({
                name: formFields.name,
                user_name: formFields.username,
                email: formFields.email,
                phone_no: phoneNumber,
                first_name: formFields.firstName,
                last_name: formFields.lastName,
                password: formFields.password
            })
            var method = 'POST'
            const apifetcherObj = APIMethod('/users/sign-up', params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode == 200) {
                        setFormFields({ ...formFields, name: '', email: '', username: '', firstName: '', lastName: '', password: '', confirmPassword: '' })
                        setPhoneNumber('')
                        onLogin(formFields.email, formFields.password)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }

    const onLogin = (email, password) => {
        var params = JSON.stringify({
            username: email,
            password: password,
            grant_type: "password",
            role: "SUPER_ADMIN"
        })
        var method = 'POST'
        const apifetcherObj = APIMethod('/oauth/token', params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    localStorage.setItem('token', data.access_token);
                    userDetail()
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
            })
    }

    const userDetail = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod('/users/me', null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    if (data.role_id === undefined) {
                        addToast('Thanks for Sign Up. Administrator will get back to you shortly.', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'info', autoDismiss: true });
                        localStorage.removeItem('token')
                        setLoading(false)
                        goBack()
                    } else {
                        // getUserRole(data.role_id)
                    }
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setLoading(false)
                }
            })
            .catch(error => {
            })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    return (
        <>
            <div className="" id="main-wraper" >
                <Style>{`
                .header{
                    display: none;
                }
                .login-img{
                    height: 100vh;width: 100%;object-fit: cover;
                }
                }
                `}</Style>
                <div className="login-page">
                    <div className="row mx-0 mt-md-0 mt-5 bg-white">
                        <div className="col-md-5 pl-0 d-md-block d-none">
                            <img src="/images/login-img.png" className="login-img img-fluid" />
                        </div>
                        <div className="col-md-7 align-self-center">
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <img src="/images/logo.png" alt="Logo" width="200" className="mb-4" />
                                        <h2 className="font-24 font-medium text-secondary mb-3">Welcome to Winopps </h2>
                                        <p className="font-18 text-secondary">Please Singup to your account and start</p>
                                    </div>

                                    <div className=" login-form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" className="form-control" name="firstName" onChange={handleChange} value={formFields['firstName']} />
                                                    <span className="text-danger">{flag === true && formFields.firstName === '' ? 'Please Enter First Name' : null}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" className="form-control" name="lastName" onChange={handleChange} value={formFields['lastName']} />
                                                    <span className="text-danger">{flag === true && formFields.lastName === '' ? 'Please Enter Last Name' : null}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Email</label>
                                            <input type="text" className="form-control" onChange={handleChange} name="email" value={formFields['email']} />
                                            <span className="text-danger">{flag === true && (formFields.email === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.email)) ? 'Please Enter Email Address' : null}</span>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Phone</label>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                value={phoneNumber}
                                                className="form-control"
                                                onChange={setPhoneNumber}
                                                countrySelectProps={{ unicodeFlags: true }}
                                                defaultCountry="US"
                                                international
                                            />
                                            <span className="text-danger">{flag === true && formFields.phone === '' ? 'Please Enter Phone' : null}</span>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Password</label>
                                            <input type="password" className="form-control" onChange={handleChange} name="password" value={formFields['password']} />
                                            <span className="text-danger">{flag === true && formFields.password === '' ? 'Please Enter Password' : null}</span>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Confirm Password</label>
                                            <input type="password" className="form-control" onChange={handleChange} name="confirmPassword" value={formFields['confirmPassword']} />
                                            <span className="text-danger">{flag === true && formFields.confirmPassword === '' ? 'Please Enter Confirm Password' : null}</span>
                                        </div>
                                        <div className="form-group row ">
                                            <div className="col-md-12 mb-2">
                                                <button type="button" className="btn btn-brand-1 btn-block" onClick={() => submit()} disabled={isLoading}>
                                                    {isLoading && (
                                                        <img src={require('../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                    )}
                                                    <span>Signup</span>
                                                </button>
                                            </div>
                                            <div className="col-md-12 text-center">
                                                <span className="mr-2">Do you have an Account?</span> <NavLink to="/login">Login Now</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default Signup;