
import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { APIMethod } from '../API/APIClient'
import '../assets/css/password-req.scss'


import { useToasts } from 'react-toast-notifications'

function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts()
        return <Component {...props} {...toastFuncs} />;
    }
}


class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showModalPopup: false,
            buttonLoader: false,
            loading: false,
            flag: false,
            showPassword:false,
            showConfirmPassword:false,
            value: '',
            new_password: '',
            confirm_password: '',
            valid:false,
            id: ''


        };

        this.handleChange = this.handleChange.bind(this);


    }
    componentDidMount() {
        this.setState({ showModal: this.props.showModalPopup })
    }
    handlePassword = (event) => {
        this.setState({ new_password: event.target.value })
    }

    handleConfirmPassword = (event) => {
        this.setState({ confirm_password: event.target.value })
    }
    isShowModal = (status) => {
        this.handleClose();
        this.setState({ showModal: status });
    }

    handleClose = () => {
        this.props.onPopupClose(false);
    }
    handleChange(event) {
        this.setState({ value: event.target.value });
    }
    handleshowPassword=()=>{
        this.setState({ showPassword : !this.state.showPassword})
    }
    handleshowConfirmPassword=()=>{
        this.setState({ showConfirmPassword : !this.state.showConfirmPassword})

    }

    update = () => {
       // this.setState({ buttonLoader: !this.state.buttonLoader });
        if (this.state.new_password === '') {
            this.props.addToast('Please enter password', { appearance: 'error', autoDismiss: true })
         //  this.setState({ buttonLoader: !this.state.buttonLoader });
            return false;
        } else if (this.state.confirm_password === '') {
            this.props.addToast('Please enter confirm password', { appearance: 'error', autoDismiss: true })
          //  this.setState({ buttonLoader: !this.state.buttonLoader });
            return false;
        } else if (this.state.new_password === '' || !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/.test(this.state.new_password)) {
            this.props.addToast('Enter a valid password', { appearance: 'error', autoDismiss: true })
          //  this.setState({ buttonLoader: !this.state.buttonLoader });
            return false;
        } else if (this.state.new_password != this.state.confirm_password) {
            this.props.addToast('Password not match, please check', { appearance: 'error', autoDismiss: true })
         //   this.setState({ buttonLoader: !this.state.buttonLoader });
            return false;
        } else {
            this.setState({ loading: true });
            this.setState({ flag: true });
            this.setState({ buttonLoader: !this.state.buttonLoader });

            var method = 'PUT'
            var params = JSON.stringify({
                id: this.props.id,
                password: this.state.new_password,

            })
            const apifetcherObj = APIMethod(`/users/change-password`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        this.props.addToast(data.message, { appearance: 'success', autoDismiss: true })
                        this.setState({ loading: false });
                        this.handleClose()
                        this.setState({ buttonLoader: !this.state.buttonLoader });
                    }
                })
                .catch(error => {
                    this.setState({ loading: false })
                    this.setState({ buttonLoader: !this.state.buttonLoader });
                })
        }
       this.setState({ buttonLoader: !this.state.buttonLoader });
    }

    render() {

        return (
            <Fragment>
                <Modal show={this.props.showModalPopup} onHide={this.handleClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Header closeButton>
                        <Modal.Title id="sign-in-title">
                            Change Password
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div class="row form-style px-3">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label className="mb-3">Password</label>
                                    <button className={`icofont-${this.state.showPassword ? 'eye-blocked' : 'eye-alt'}`} onClick={this.handleshowPassword} style={{background:'none',border:'none',padding:"2px",float:"right",marginRight:"10px"}}>
                                        {this.state.showPassword ? 'Hide' : 'Show'} 
                                    </button>
                                    <input type={this.state.showPassword ? 'text' : 'password'} className="form-control" id="" value={this.state.new_password} name={this.state.new_password} onChange={this.handlePassword} placeholder='Please enter password' />
                                    {/* <span className="text-danger">{this.flag===true || (this.state.new_password === '' || !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/.test(this.state.new_password)) ? 'Please enter valid password' : null}</span> */}
                                    {/* <span className="text-danger">{flag === true && formFields.forgotPasswordEmail === '' ? 'Please Enter Email' : null}</span> */}

                                </div>
                                <div className="password-requirements">
                                    <p> Password must contain:</p>
                                    <ul>
                                    {this.state.new_password.length >= 0 && 
                                    <li className={this.state.new_password.length >= 8 ? 'valid' : 'invalid'}>
                                        Minimum 8 characters
                                    </li>
                                    }

                                    {this.state.new_password.length >= 0 && 
                                    <li className={/[a-z]/.test(this.state.new_password) ? 'valid' : 'invalid'}>
                                         One lowercase letter
                                    </li>
                                    }

                                    {this.state.new_password.length >= 0 &&
                                    <li className={/[A-Z]/.test(this.state.new_password) ? 'valid' : 'invalid'}>
                                        One uppercase letter  
                                    </li>
                                    }

                                    {this.state.new_password.length >= 0 &&
                                    <li className={/\d/.test(this.state.new_password) ? 'valid' : 'invalid'}>
                                        One number 
                                    </li> 
                                    }
                                    
                                    </ul>
                                    </div>

                                    

                                <div class="form-group">
                                    <label className="mb-3">Confirm Password</label>
                                    <button className={`icofont-${this.state.showConfirmPassword ? 'eye-blocked' : 'eye-alt'}`} onClick={this.handleshowConfirmPassword} style={{background:'none',border:'none',padding:"2px",float:"right",marginRight:"10px",size:"1px"}}>
                                        <span >{this.state.showConfirmPassword ? 'Hide' : 'Show'}</span> 
                                    </button>
                                    <input type={this.state.showConfirmPassword ? 'text' : 'password'} className="form-control" id="" onChange={this.handleConfirmPassword} placeholder='Please re-enter your password' />
                                    {/* <span className="text-danger">{this.flag === true || (this.state.confirm_password === '' || !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/.test(this.state.confirm_password)) ? 'Please enter valid confirm password' : null}</span> */}
                                    {/* <span className="text-danger">{flag === true && formFields.forgotPasswordEmail === '' ? 'Please Enter Email' : null}</span> */}
                                </div>
                                <div class="form-group">
                                <span className="text-danger">{this.flag==true||(this.state.new_password === this.state.confirm_password || this.state.confirm_password==="") ? null : 'Passwords do not match'}</span>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <div class="modal-footer text-center d-block px-4">
                        <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => this.update()} disabled={this.state.buttonLoader} >
                            {this.state.buttonLoader && (
                                <img src={require('../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                            )}
                            <span>Change Password</span>
                            </button>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

export default withToast(ChangePassword);
