import React, { useState, useEffect, useRef, useMemo } from "react";
import { Breadcrumb, Modal, Button, Alert } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { APIMethod, tiny_key } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import { useToasts } from 'react-toast-notifications';
import moment from "moment";
import { Editor } from '@tinymce/tinymce-react';
import { checkEmptyString } from "../../../../utility/utility";

const AddQa = (props) => {
    const answerRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getListData, setGetListData] = useState([]);
    const [formFields, setFormFields] = useState({ topic: '', keywords: '', RfxCategoryId: '', id: '' })
    const [answer, setAnswer] = useState('');
    const [flag, setFlag] = useState(false);
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    // Toast
    const { addToast } = useToasts();
    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    const [cancelModal, setCancelModal] = useState(false);

    const editor = useRef(null)

    const handleModal = () => {
        setCancelModal(!cancelModal)
    }

    useEffect(() => {
        getRFxCategoryList()
        check()
    }, [])

    const check = () => {
        if (props.location.state === null) {
        } else {
            setFormFields({ ...formFields, topic: props.location.state.details.question, keywords: props.location.state.details.keyword, RfxCategoryId: props.location.state.details.rfx_category_id, id: props.location.state.details.id })
            setAnswer(props.location.state.details.answer)
            setStartDate(new Date(props.location.state.details.start_date))
        }
    }

    const getRFxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const formatDate = () => {
        var d = startDate,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    const save = () => {
        let newdate = moment(startDate).format(
            "YYYY-MM-DD"
        );
        if (formFields.topic === '' || formFields.keywords === '' || checkEmptyString(answerRef.current.getContent()) || formFields.RfxCategoryId === '') {
            setFlag(true)
            addToast('Please fill all the fields', { appearance: 'error', autoDismiss: true });
        } else {
            setButtonLoader(true)
            if (formFields.id === '') {
                var method = 'POST'
                var params = JSON.stringify({
                    question: formFields.topic,
                    keyword: formFields.keywords,
                    answer: answerRef.current.getContent(),
                    start_date: formatDate('Sun May 11,2014'),
                    rfx_category_id: formFields.RfxCategoryId,
                })
            } else {
                var method = 'PUT'
                var params = JSON.stringify({
                    id: formFields.id,
                    question: formFields.topic,
                    keyword: formFields.keywords,
                    answer: answerRef.current.getContent(),
                    start_date: newdate,
                    rfx_category_id: formFields.RfxCategoryId,
                })
            }
            const apifetcherObj = APIMethod(`/qna/`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { appearance: 'success', autoDismiss: true });
                        setButtonLoader(false)
                        setFlag(false)
                        goBack()
                    } else {
                        addToast(data.message, { appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const modalCheck = () => {
        if (formFields.topic === '' && formFields.keywords === '' && answer === '' && formFields.RfxCategoryId === '') {
            goBack();
        } else {
            goBack();
        }
    }


    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <Modal show={cancelModal} onHide={() => handleModal()}  {...props}
                        size=""
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header closeButton>Alert</Modal.Header>
                        <Modal.Body>Are you sure you want to cancel?</Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-lg btn-brand-1" onClick={() => handleModal()}>No</Button>
                            <Button className="btn btn-lg btn-brand-1 bg-danger" onClick={goBack}>Yes</Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Content Library Setup</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>
                                Content Entries
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{formFields.id ? 'Edit Q & A' : 'Add Q & A'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center">
                        <span className="add-title ">{formFields.id ? 'Edit Q&A' : 'Add Q&A'}</span>
                    </div>
                    <div className="form-wraper form-style mb-4">
                        <div className="row">
                            <div className="col-md-6">
                                {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                {/*<h4 className="font-20 font-medium">Topic</h4>*/}
                                {/* Commenting end. Divya. September 07 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                <h2 className="font-20 font-bold">Topic</h2>
                                {/* Divya. September 07 2023. Code End */}
                                <div className="form-group">
                                    <label>Topic/Question</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="topic" value={formFields['topic']} />
                                    <validate
                                        flag={true}
                                        name={formFields.topic}
                                        error={'Please Enter Topic/Question'}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Creation Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                                <div className="form-group">
                                    <label>RFx Category</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['RfxCategoryId']} name="RfxCategoryId" onChange={handleChange}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            getListData.map((res) => {
                                                return (
                                                    <option value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.RfxCategoryId === '' ? 'Please Enter Topic/Question' : null}</span>
                                </div>
                                <div className="form-group">
                                    <label>Keywords/Tag</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="keywords" value={formFields['keywords']} />
                                    <span className="text-danger">{flag === true && formFields.keywords === '' ? 'Please Enter Keywords/Tag' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                {/*<h4 className="font-20 font-medium">Content</h4>*/}
                                {/* Commenting end. Divya. September 07 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                <h2 className="font-20 font-bold">Content</h2>
                                {/* Divya. September 07 2023. Code End */}
                                <Editor
                                    apiKey={tiny_key}
                                    onInit={(evt, editor) => answerRef.current = editor}
                                    initialValue={answer}
                                    init={{
                                        height: 500,
                                        menubar: 'file edit view insert format tools table tc help',
                                        selector: 'textarea#full-featured',
                                        plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists ',
                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                    }}
                                />
                                <span className="text-danger">{flag === true && answer === '' ? 'Please Enter Content' : null}</span>
                            </div>
                            <div className="col-12 text-right mt-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={() => modalCheck()}>Cancel</button>
                                <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save()} disabled={buttonLoader}>
                                    {buttonLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}
export default AddQa;