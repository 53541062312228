import React, { useEffect, useState } from "react";
import Leftnavbar from "../../../../components/Leftnavbar";
import { NavLink } from "react-router-dom";
import { APIMethod } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import ModalPopup from '../../../../components/ModalPopUp';
import Pagination from '../../../../components/Pagination';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router";
import moment from "moment";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from 'react-modal-resizable-draggable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

const SetupQa = (props) => {
    const [getListData, setGetListData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    // Edit
    const [edit, setEdit] = useState('');
    const [id, setId] = useState('');
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    // page
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // Toast
    const { addToast } = useToasts();
    const history = useHistory();
    const [adding, setAdding] = useState();
    const [editing, setEditing] = useState();
    const [deleting, setDeleting] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [formFields, setFormFields] = useState({
        fromDate: undefined, toDate: undefined, question: '', answer: '', rfxCategory: ''
    })

    useEffect(() => {
        getQAList(1)
        getRfxCategoryList()
        var data = JSON.parse(localStorage.getItem("user_Role"))
        checkPermission(data)
    }, [])

    const checkPermission = (data) => {
        const resObject = data.find(item => item.name === "Content Library Setup")
        const valObject = resObject.access.find(item => item.name === "Q&As")
        setAdding(valObject.is_create)
        setEditing(valObject.is_update)
        setDeleting(valObject.is_delete)
    }

    const getQAList = (page, key, type) => {
        setLoading(true)
        const new_key = key === undefined ? '' : key
        global.new_key = key === undefined ? '' : key
        var filter_start_date = ''
        if (formFields.fromDate) {
            filter_start_date = moment(formFields.fromDate).format('YYYY-MM-DD') == moment(formFields.toDate).format('YYYY-MM-DD') ? '' : moment(formFields.fromDate).format('YYYY-MM-DD')
        }
        const filter_end_date = formFields.toDate === undefined ? '' : moment(formFields.toDate).format('YYYY-MM-DD')
        var method = 'GET'
        const apifetcherObj = APIMethod(`/qna/list/${page}?question=${formFields.question}&answer=${formFields.answer}&from_date=${filter_start_date}&to_date=${filter_end_date}&rfx_category_id=${formFields.rfxCategory}&question_answer=` + new_key, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getRfxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const nextPage = (pageNumber) => {
        getQAList(pageNumber)
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const Search = (key) => {
        getQAList(1, key)
    }

    const _onEdit = (val) => {
        history.push({
            pathname: '/edit-q-a/:id',
            state: { details: val }
        })
    }

    const getData = () => {
        getQAList(1)
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const _onResetFilter = (type) => {
        if (type === 'QUESTION') {
            formFields.question = "";
        } else if (type === 'ANSWER') {
            formFields.answer = "";
        } else if (type === 'RFX_CATEGORY') {
            formFields.rfxCategory = "";
        } else if (type === 'FROM_DATE') {
            formFields.fromDate = undefined;
        } else if (type === 'TO_DATE') {
            formFields.toDate = undefined;
        } else {
            formFields.question = "";
            formFields.answer = "";
            formFields.rfxCategory = "";
            formFields.fromDate = undefined;
            formFields.toDate = undefined;
            handleClose()
        }
        getQAList(1, '', 'FILTER')
    }

    const _onFilter = () => {
        getQAList(1, '', 'FILTER')
        handleClose()
    }

    const setFromDate = (date) => {
        const formFieldsObject = {
            ...formFields,
            fromDate: date
        }
        setFormFields(formFieldsObject);
    }

    const dateValidation = (date) => {
        if (formFields.fromDate) {
            const formFieldsObject = {
                ...formFields,
                toDate: date
            }
            setFormFields(formFieldsObject);
        } else {
            addToast('Please select RFx closing from date first', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
        }
    }

    return (
        <>
            <div className="page-wrapper">

                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Q&As</h2>
                        </div>
                        <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search by question and answer" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            <button type="button" className="btn btn-brand-1 btn-h-40 mr-3" onClick={() => setShow(true)}><i className="icofont-filter mr-2"></i> Filter</button>
                            {
                                adding === true ?
                                    <NavLink to="/add-q-a" className="btn btn-brand-1 bg-success px-4">+ Add</NavLink> : null
                            }
                        </div>
                    </div>



                    <div className="mb-2 filter-by">
                        {
                            formFields.question ?
                                <div className="badge">
                                    <span className="lable">Question</span>
                                    <span className="value">{formFields.question}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('QUESTION')}></i>
                                </div> : null
                        }
                        {
                            formFields.answer ?
                                <div className="badge">
                                    <span className="lable">Answer</span>
                                    <span className="value">{formFields.answer}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('ANSWER')}></i>
                                </div> : null
                        }
                        {
                            formFields.rfxCategory ?
                                <div className="badge">
                                    <span className="lable">RFx Category</span>
                                    {
                                        RFxCategoryData.map((res, index) => {
                                            return (
                                                <span key={index} className="value">{formFields.category == res.id ? res.name : null}</span>
                                            )
                                        })
                                    }
                                    <i className="icofont-close" onClick={() => _onResetFilter('RFX_CATEGORY')}></i>
                                </div> : null
                        }
                        {
                            formFields.fromDate ?
                                <div className="badge">
                                    <span className="lable">Q&As From Date</span>
                                    <span className="value">{moment(formFields.fromDate).format('MM-DD-YYYY')}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('FROM_DATE')}></i>
                                </div> : null
                        }
                        {
                            formFields.toDate ?
                                <div className="badge">
                                    <span className="lable">Q&As To Date</span>
                                    <span className="value">{moment(formFields.toDate).format('MM-DD-YYYY')}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('TO_DATE')}></i>
                                </div> : null
                        }
                    </div>
                    <table className="table table-style-1 table-responsive-lg mb-4 table reports">
                        <thead>
                            <tr>
                                <th scope="col">QAID</th>
                                <th scope="col">Question/Topic</th>
                                <th scope="col">Answer</th>
                                <th scope="col">RFx Category</th>
                                <th scope="col">Creation Date</th>
                                <th scope="col" className="text-center" width="80">{editing === true || deleting === true ? 'Action' : null}</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/qna/'}
                            updateList={getData}
                        ></ModalPopup>
                        <tbody>
                            {
                                loading === false ? getListData.map((res) => {
                                    return (
                                        <tr>
                                            <td>QA{res.id}</td>
                                            <td title={res.question}>{res.question.replace(/(.{50})..+/, "$1…")}</td>
                                            <td title={res.answer.replace(/<[^>]+>/g, '')}>{res.answer.replace(/<[^>]+>/g, '').replace(/(.{40})..+/, "$1…")}</td>
                                            <td>{res.rfx_category_name}</td>
                                            <td>{res.start_date ? moment(res.start_date).format('MM-DD-YYYY') : null}</td>
                                            <td className="text-center">
                                                {
                                                    editing === true ?
                                                        <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                }
                                                {
                                                    deleting === true ?
                                                        <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-trash"></i></button> : null
                                                }
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                    {
                        getListData.length === 0 && loading === false && global.new_key ?
                            <SearchNoDataFound message={'No Q&A Entrie Found'} />
                            : getListData.length === 0 && loading === false ? <NoData /> : null
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">
                            <Pagination
                                pages={pageCount}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                            />
                        </nav>
                    </div>

                </div>
            </div>

            {/* Filter Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={137.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Filter Q&As</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Question/Topic</label>
                                <input type="text" className="form-control" onChange={handleChange} name="question" value={formFields['question']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Answer</label>
                                <input type="text" className="form-control" onChange={handleChange} name="answer" value={formFields['answer']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Category</label>
                                <select id="disabledSelect" className="form-control custom-select" onChange={handleChange} name="rfxCategory" value={formFields['rfxCategory']} >
                                    <option value="" disabled>Select your option</option>
                                    {
                                        RFxCategoryData.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Q&As From Date</label>
                                <DatePicker
                                    selected={formFields.fromDate}
                                    onChange={(date) => setFromDate(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Q&As To Date </label>
                                <DatePicker
                                    selected={formFields.toDate}
                                    onChange={(date) => dateValidation(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    minDate={formFields.fromDate}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer ">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button className="btn btn-lg btn-brand-1" onClick={() => _onResetFilter()}>Reset</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onFilter()}>Search</button>
                </div>
            </ReactModal>

        </>
    )
}
export default SetupQa;