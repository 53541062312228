import React, { useState, useEffect, Fragment, useRef } from "react";
import { Tab, Nav, Row, Col, Modal } from "react-bootstrap";
import { APIMethod, tiny_key, TOAST_TIMEOUT } from "../../../../API/APIClient";
import { useToasts } from 'react-toast-notifications';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import NoData from "../../../../components/NoDataFound";
import PhoneInput from 'react-phone-number-input';
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import { Editor } from '@tinymce/tinymce-react';
import { checkEmptyString } from "../../../../utility/utility";
let Regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

const htmlTag = /(<([^>]+)>)/ig;

const CompanyDetails = (props) => {
    const editorRef = useRef(null);
    const companyHistoryRef = useRef(null);
    const revenueGrowthRef = useRef(null);
    const companyKeyProfileRef = useRef(null);
    const companyReferenceProjectRef = useRef(null);
    const companyClientContractRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [businessUnitTypeData, setBusinessUnitTypeData] = useState([]);
    const [CompanyDetails, setCompanyDetails] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [deleteEndPoint, setDeleteEndPoint] = useState('');
    const [commonData, setCommonData] = useState([]);
    const [count, setCount] = useState('');
    const [keyStaffProfileLoader, setKeyStaffProfileLoader] = useState(false);
    const [formFields, setFormFields] = useState({
        id: '', companyName: '', legalStructure: '', fein: '', url: '', companyContractName: '', companyContractTitle: '', companyContractPhone: '',
        companyContractEmail: '', streetNumber: '', streetName: '', additionalInfo: '', city: '', zipCode: '', companyBackground: '', companyHistory: '',
        revenueGrowth: '', companyKeyName: '', companyKeyTitle: '', companyKeyPhone: '', companyKeyEmail: '', companyKeyProfile: '', companyRefernceName: '',
        companyRefernceContact: '', companyRefernceContactTitle: '', companyRefernceEmail: '', companyRefernceProject: '', companyClientName: '',
        companyClientContract: '', businessUnitId: '', businessUnitTypeId: '', business_unit_type_id_name: '', code: '', countryId: '', stateId: '',
        companyReferenceId: '', contractScopeId: '', profileId: ''
    });
    const [flag, setFlag] = useState(false);
    // Toast
    const { addToast } = useToasts();
    const goBack = () => {
        window.history.back();
    }
    const { id } = useParams();
    const history = useHistory();


    useEffect(() => {
        getBusinessUnitList()
        getCommonData()
        check()
        getCountryList()
        getBusinessUnitTypeList()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const check = () => {
        if (id === undefined) {

        } else {
            setLoading(true)
            var method = 'GET'
            const apifetcherObj = APIMethod(`/company-details/${id}`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setCompanyDetails(data)
                        setFormFields({
                            ...formFields, companyName: data.name, businessUnitId: data.business_id, businessUnitTypeId: data.business_unit_type_id,
                            id: data.id, legalStructure: data.legal_structure, fein: data.fein, revenueGrowth: data.revenue_groth,
                            business_unit_type_id_name: data.business_unit_type_id_name, code: data.code, url: data.website, companyContractName: data.contact_name,
                            companyContractPhone: data.contact_phone, companyContractTitle: data.contact_title, companyContractEmail: data.contact_email,
                            streetNumber: data.street_no, streetName: data.address_name, additionalInfo: data.address_info, city: data.city, zipCode: data.zip,
                            countryId: data.country_id, stateId: data.state_id, companyBackground: data.background, companyHistory: data.history,
                            revenueGrowth: data.revenue_groth
                        })
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }

    const getCountryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/region/country/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCountryData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getBusinessUnitList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getBusinessUnitTypeList = (event) => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-unit-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitTypeData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getCommonData = (endPoint) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(endPoint, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCommonData(data.list)
                    setCount(data.count)
                    setKeyStaffProfileLoader(false)
                }
            })
            .catch(error => {
                setKeyStaffProfileLoader(false)
            })
    }

    const save = (event) => {
        if (event == 'tab_1') {
            if (formFields.businessUnitId === '' || formFields.companyName === '' || formFields.businessUnitTypeId === '' || formFields.legalStructure === '' ||
                formFields.fein === '' || formFields.url === '') {
                setFlag(true)
                addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                return false;
            } else if (!Regex.test(formFields.url)) {
                addToast('Please enter valid company website url', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            } else if (formFields.companyName !== '') {
                setButtonLoader(true)
                if (formFields.id === '' || formFields.businessUnitId === '') {
                    var method = 'POST'
                    var params = JSON.stringify({
                        business_unit: formFields.businessUnitId,
                        name: formFields.companyName,
                        business_unit_type_id: formFields.businessUnitTypeId,
                        legal_structure: formFields.legalStructure,
                        fein: formFields.fein,
                        website: formFields.url,
                    })

                    const apifetcherObj = APIMethod(`/company-details`, params, method)
                    apifetcherObj
                        .then(response => { return Promise.all([response.status, response.json()]) })
                        .then(res => {
                            let statusCode = res[0]
                            let data = res[1]

                            if (statusCode === 200) {
                                setFormFields({ ...formFields, id: data.id })
                                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                                setButtonLoader(false)
                                setFlag(false)
                            } else {
                                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                                setButtonLoader(false)
                            }
                        })
                        .catch(error => {
                            setButtonLoader(false)
                        })
                } else {
                    setButtonLoader(false)
                    companyAPI()
                }
            }
        } else if (event == 'tab_2') {
            if (formFields.legalStructure === '') {
                addToast('Please Enter legal structure', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                setButtonLoader(false)
            } else {
                companyAPI()
            }
        } else if (event == 'tab_3') {
            if (formFields.fein === '') {
                addToast('Please Enter FEIN', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                setButtonLoader(false)
            } else {
                companyAPI()
            }
        } else if (event == 'tab_4') {
            if (formFields.url === '') {
                addToast('Please Enter Company Website URL', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                setButtonLoader(false)
            } else if (!Regex.test(formFields.url)) {
                addToast('Please enter valid company website url', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            } else {
                companyAPI()
            }
        } else if (event == 'tab_5') {
            if (formFields.companyContractName === '' || formFields.companyContractTitle === '' || formFields.companyContractPhone === '' || formFields.companyContractEmail === '') {
                addToast('Please Fill all the fields of Company Contact', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                setButtonLoader(false)
                return false;
            } else if (formFields.companyContractEmail === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.companyContractEmail)) {
                addToast('Please Enter vaild E-mail', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                setButtonLoader(false)
            } else {
                companyAPI()
            }
        } else if (event == 'tab_6') {
            if (formFields.streetNumber === '' || formFields.streetName === '' || formFields.additionalInfo === '' || formFields.zipCode === '', formFields.countryId === '' || formFields.stateId === '' || formFields.city === '') {
                addToast('Please Fill all the fields of Company Address', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                setButtonLoader(false)
            } else {
                companyAPI()
            }
        } else if (event == 'tab_7') {
            if (checkEmptyString(editorRef.current.getContent())) {
                addToast('Please Enter Company Background', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                setButtonLoader(false)
            } else {
                companyAPI()
            }
        } else if (event == 'tab_8') {
            if (checkEmptyString(companyHistoryRef.current.getContent())) {
                addToast('Please Enter Company History', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                setButtonLoader(false)
            } else {
                companyAPI()
            }
        } else if (event == 'tab_9') {
            if (checkEmptyString(revenueGrowthRef.current.getContent())) {
                addToast('Please Enter Revenue Growth', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                setButtonLoader(false)
            } else {
                companyAPI()
            }
        }
    }

    const companyAPI = () => {
        setButtonLoader(true)
        var method = 'PUT'
        var params = JSON.stringify({
            id: formFields.id,
            business_unit: formFields.businessUnitId,
            name: formFields.companyName,
            legal_structure: formFields.legalStructure,
            fein: formFields.fein,
            website: formFields.url,
            background: editorRef.current.getContent(),
            history: companyHistoryRef.current.getContent(),
            revenue_groth: revenueGrowthRef.current.getContent(),
            contact_name: formFields.companyContractName,
            contact_phone: formFields.companyContractPhone,
            contact_title: formFields.companyContractTitle,
            contact_email: formFields.companyContractEmail,
            street_no: formFields.streetNumber,
            address_name: formFields.streetName,
            address_info: formFields.additionalInfo,
            city: formFields.city,
            zip: formFields.zipCode,
            country_id: parseInt(formFields.countryId),
            state_id: parseInt(formFields.stateId),
            business_unit_type_id: formFields.businessUnitTypeId,
        })
        const apifetcherObj = APIMethod(`/company-details`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setFormFields({ ...formFields, id: data.id })
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                    setButtonLoader(false)
                    setFlag(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                    setButtonLoader(false)
                }
            })
            .catch(error => {
                setButtonLoader(false)
            })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
        if (name === 'countryId') {
            getStateList(event.target.value)
        }
    }

    const getStateList = (id) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/region/state/list/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setStateData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })

    }

    const almsg = () => {
        addToast('Please save the Company Name', { autoDismissTimeout: TOAST_TIMEOUT, autoDismiss: true })
    }

    const commonApi = (call, data, endPoint) => {
        var method = call
        var params = data
        setKeyStaffProfileLoader(true)
        const apifetcherObj = APIMethod(endPoint, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                    getCommonData(`${endPoint}/list/${formFields.id}/1`);
                    setFormFields({
                        ...formFields, companyKeyName: '', companyKeyTitle: '', companyKeyEmail: '', companyKeyPhone: '', companyKeyProfile: '', companyRefernceName: '',
                        companyRefernceContact: '', companyRefernceContactTitle: '', companyRefernceEmail: '', companyRefernceProject: '', companyClientName: '',
                        companyClientContract: '', companyReferenceId: '', profileId: '', contractScopeId: ''
                    })
                    setKeyStaffProfileLoader(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                    setKeyStaffProfileLoader(false)
                }
            })
            .catch(error => {
                setKeyStaffProfileLoader(false)
            })
    }

    const addKeyStaff = () => {
        if (formFields.id === '' || formFields.companyKeyName === '' || formFields.companyKeyTitle === '' || formFields.companyKeyEmail === '' || checkEmptyString(companyKeyProfileRef.current.getContent()) || formFields.companyKeyPhone === '') {
            addToast('Please Enter all the company key staff profiles fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
            //  setButtonLoader(false)
        } else if (formFields.companyKeyEmail === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.companyKeyEmail)) {
            addToast('Please Enter vaild E-mail', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
        } else {
            commonApi(formFields.profileId ? 'PUT' : 'POST', JSON.stringify({
                id: formFields.profileId,
                company_id: formFields.id,
                name: formFields.companyKeyName,
                title: formFields.companyKeyTitle,
                email: formFields.companyKeyEmail,
                profile: companyKeyProfileRef.current.getContent(),
                phone: formFields.companyKeyPhone
            }), `/company-key-staff-profles`)
        }

    }

    const addReference = () => {
        if (formFields.id === '' || formFields.companyRefernceName === '' || formFields.companyRefernceContact === '' || formFields.companyRefernceContactTitle === '' || formFields.companyRefernceEmail === '' || checkEmptyString(companyReferenceProjectRef.current.getContent())) {
            addToast('Please Enter all the company reference fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
        } else if (formFields.companyRefernceEmail === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.companyRefernceEmail)) {
            addToast('Please Enter vaild E-mail', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
        } else {
            commonApi(formFields.companyReferenceId ? 'PUT' : 'POST', JSON.stringify({
                id: formFields.companyReferenceId,
                company_id: formFields.id,
                name: formFields.companyRefernceName,
                contact: formFields.companyRefernceContact,
                title: formFields.companyRefernceContactTitle,
                email: formFields.companyRefernceEmail,
                project: companyReferenceProjectRef.current.getContent(),
            }), `/company-refrences`)
        }
    }

    const addClient = () => {
        commonApi(formFields.contractScopeId ? 'PUT' : 'POST', JSON.stringify({
            id: formFields.contractScopeId,
            company_id: formFields.id,
            client_name: formFields.companyClientName,
            contract_scope: companyClientContractRef.current.getContent(),
        }), `/company-clients`)
    }

    const deleteItem = () => {
        setLoading(true)
        var method = 'DELETE'
        const apifetcherObj = APIMethod(`${deleteEndPoint}/${deleteId}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                    getCommonData(`${deleteEndPoint}/list/${formFields.id}/1`);
                    setShowModal(false)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const handleClo = () => {
        setShowModal(false)
    }

    const _onDelete = (endPoint, id) => {
        setDeleteEndPoint(endPoint)
        setDeleteId(id)
        setShowModal(true)
    }

    const _onUpdateCapability = () => {
        history.push({
            pathname: "/edit-company-capability",
            state: { company_name: formFields.companyName, business_unit_type: formFields.business_unit_type_id_name, code: formFields.code, company_id: formFields.id }
        })
        localStorage.setItem('companyId', formFields.id)
        localStorage.removeItem("tab")
    }

    const handlePhone = (event, type) => {
        const formFieldsObject = {
            ...formFields,
            [type]: event
        }
        setFormFields(formFieldsObject);
    }

    const _onEdit = (data, type) => {
        if (type == 'reference') {
            setFormFields({
                ...formFields, companyReferenceId: data.id,
                companyRefernceName: data.name,
                companyRefernceContact: data.contact.toString(),
                companyRefernceContactTitle: data.title,
                companyRefernceEmail: data.email,
                companyRefernceProject: data.project
            })
        } else if (type == 'scope') {
            setFormFields({
                ...formFields, contractScopeId: data.id,
                companyClientName: data.client_name,
                companyClientContract: data.contract_scope
            })
        } else if (type == 'profile') {
            setFormFields({
                ...formFields, profileId: data.id,
                companyKeyName: data.name,
                companyKeyTitle: data.title,
                companyKeyEmail: data.email,
                companyKeyProfile: data.profile,
                companyKeyPhone: data.phone.toString()
            })
        }
    }

    const onEditorChange = (data, key) => {
        const formFieldsObject = {
            ...formFields,
            [key]: data
        }
        setFormFields(formFieldsObject);
    }


    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <Fragment>
                        <Modal show={showModal} onHide={handleClo}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="sign-in-title">
                                    Alert
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ alignSelf: 'center' }}>
                                <div className="ml-5 mr-5">
                                    <img src={require('../../../../assets/css/images/cross.png').default} style={{ marginLeft: '37%' }} alt="No image found" id="img_close" width="80" />
                                    <p style={{ fontSize: 20, textAlign: 'center' }} className="mt-4">Are You Sure ?</p>
                                    <p style={{ textAlign: 'center' }}>Do you really want to delete these records? This process cannot be undone.</p>
                                    <button type="button" className="btn btn-light mr-1" style={{ marginLeft: '30%' }} onClick={() => handleClo()}> Close</button>
                                    <button type="button" className="btn btn-danger ml-3" onClick={() => deleteItem()}> Delete</button>
                                </div>
                            </Modal.Body>

                        </Modal >
                    </Fragment >
                    <div className="row mb-4 align-items-center justify-content-between">
                        <div className="col-md-3 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0 ">Company Details</h2>
                        </div>
                        <div className="col-md-9 text-md-right d-flex align-items-center justify-content-end">
                            <select id="disabledSelect" className=" custom-select table-filter-dd mr-3 col-lg-4" value={formFields['businessUnitId']} name="businessUnitId" onChange={handleChange}>
                                <option value="" disabled selected>Select Business Unit</option>
                                {
                                    businessUnitData.map((val) => {
                                        return (
                                            <option value={val.id}>{val.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <select id="disabledSelect" className=" custom-select table-filter-dd mr-3 col-lg-4" value={formFields['businessUnitTypeId']} name="businessUnitTypeId" onChange={handleChange}>
                                <option value="" disabled selected>Select Business Unit Type</option>
                                {
                                    businessUnitTypeData.map((val) => {
                                        return (
                                            <option value={val.id}>{val.name}</option>
                                        )
                                    })
                                }
                            </select>
                            {
                                formFields.id ?
                                    <button type="button" className="btn btn-brand-1 btn-h-40 bg-success px-4" onClick={() => _onUpdateCapability()}>{window.location.pathname === '/add-company-details' ? 'Create' : 'Update'} Company Capability </button>
                                    :
                                    null
                            }
                        </div>
                    </div>


                    <div className="row mb-2 align-items-center justify-content-between" style={{ marginRight: 5 }}>
                        <div className="col-9 text-md-right d-flex align-items-center justify-content-end">
                            <span className="text-danger">{flag === true && formFields.businessUnitId === '' ? 'Please Select Business Unit' : null}</span>
                        </div>
                        <div className="col-3 text-md-right d-flex align-items-center justify-content-end">
                            <span className="text-danger">{flag === true && formFields.businessUnitTypeId === '' ? 'Please Select Business Unit Type' : null}</span>
                        </div>
                    </div>


                    {/* Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start */}
                    {/*<Tab.Container id="left-tabs-example" defaultActiveKey="tab_1">*/}
                    { /* Commenting end. Divya. September 19 2023.. */}
                    { /* Added this code for  WOII-74. Divya. September 19 2023.. Code Start */}
                    <Tab.Container id="controlled-tab-example" defaultActiveKey="tab_1">
                    {/* Divya. September 19 2023. Code End */}
                        <Row>
                            {/* Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start */}
                            {/*<Col md={3} >
                                <Nav variant="pills" className="flex-column vertical-tabs">*/}
                            { /* Commenting end. Divya. September 19 2023.. */}
                            { /* Added this code for  WOII-74. Divya. September 19 2023.. Code Start */}        
                            <div className="tab-style-1 col-12 d-flex">
                                <Nav className="d-flex  nav nav-tabs  ">
                            {/* Divya. September 19 2023. Code End */}
                                    <Nav.Item>
                                        {/* Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start */}
                                        {/*<Nav.Link eventKey="tab_1">Company Info</Nav.Link>*/}
                                        { /* Commenting end. Divya. September 19 2023.. */}
                                        { /* Added this code for  WOII-74. Divya. September 19 2023.. Code Start */}
                                        <Nav.Link eventKey="tab_1">Corp. Info</Nav.Link>
                                        {/* Divya. September 19 2023. Code End */}
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        {
                                            formFields.id === '' ?
                                                <Nav.Link onClick={() => almsg()}>Company Legal Structure</Nav.Link> : <Nav.Link eventKey="tab_2">Company Legal Structure</Nav.Link>
                                        }
                                    </Nav.Item>
                                    <Nav.Item>
                                        {
                                            formFields.id === '' ?
                                                <Nav.Link onClick={() => almsg()}>FEIN</Nav.Link> : <Nav.Link eventKey="tab_3">FEIN</Nav.Link>
                                        }
                                    </Nav.Item>
                                    <Nav.Item>
                                        {
                                            formFields.id === '' ?
                                                <Nav.Link onClick={() => almsg()}>Company Website URL</Nav.Link> : <Nav.Link eventKey="tab_4">Company Website URL</Nav.Link>
                                        }
                                    </Nav.Item> */}
                                    <Nav.Item>
                                        {
                                            formFields.id === '' ?
                                                //Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start
                                                //<Nav.Link onClick={() => almsg()}>Company Contact</Nav.Link> : <Nav.Link eventKey="tab_5">Company Contact</Nav.Link>
                                                //Commenting end. Divya. September 19 2023.. 
                                                //Added this code for  WOII-74. Divya. September 19 2023.. Code Start 
                                                <Nav.Link onClick={() => almsg()}>Contact Info.</Nav.Link> : <Nav.Link eventKey="tab_5">Contact Info.</Nav.Link>
                                                //Divya. September 19 2023. Code End 
                                        }
                                    </Nav.Item>
                                    <Nav.Item>
                                        {
                                            formFields.id === '' ?
                                                //Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start
                                                //<Nav.Link onClick={() => almsg()}>Company Address</Nav.Link> : <Nav.Link eventKey="tab_6">Company Address</Nav.Link>
                                                //Commenting end. Divya. September 19 2023.. 
                                                //Added this code for  WOII-74. Divya. September 19 2023.. Code Start 
                                                <Nav.Link onClick={() => almsg()}>Address</Nav.Link> : <Nav.Link eventKey="tab_6">Address</Nav.Link>
                                                //Divya. September 19 2023. Code End 
                                        }

                                    </Nav.Item>
                                    <Nav.Item>
                                        {
                                            formFields.id === '' ?
                                                //Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start
                                                //<Nav.Link onClick={() => almsg()}>Company Background</Nav.Link> : <Nav.Link eventKey="tab_7">Company Background</Nav.Link>
                                                //Commenting end. Divya. September 19 2023.. 
                                                //Added this code for  WOII-74. Divya. September 19 2023.. Code Start 
                                                <Nav.Link onClick={() => almsg()}>Background</Nav.Link> : <Nav.Link eventKey="tab_7">Background</Nav.Link>
                                                //Divya. September 19 2023. Code End 
                                        }

                                    </Nav.Item>
                                    <Nav.Item>
                                        {
                                            formFields.id === '' ?
                                                //Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start
                                                //<Nav.Link onClick={() => almsg()}>Company History</Nav.Link> : <Nav.Link eventKey="tab_8">Company History</Nav.Link>
                                                //Commenting end. Divya. September 19 2023.. 
                                                //Added this code for  WOII-74. Divya. September 19 2023.. Code Start 
                                                <Nav.Link onClick={() => almsg()}>History</Nav.Link> : <Nav.Link eventKey="tab_8">History</Nav.Link>
                                                //Divya. September 19 2023. Code End 
                                        }
                                    </Nav.Item>
                                    <Nav.Item>
                                        {
                                            formFields.id === '' ?
                                                //Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start
                                                //<Nav.Link onClick={() => almsg()}>Revenue Growth</Nav.Link> : <Nav.Link eventKey="tab_9">Revenue Growth</Nav.Link>
                                                //Commenting end. Divya. September 19 2023.. 
                                                //Added this code for  WOII-74. Divya. September 19 2023.. Code Start 
                                                <Nav.Link onClick={() => almsg()}>Growth</Nav.Link> : <Nav.Link eventKey="tab_9">Growth</Nav.Link>
                                                //Divya. September 19 2023. Code End 
                                        }
                                    </Nav.Item>
                                    <Nav.Item>
                                        {
                                            formFields.id === '' ?
                                                //Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start
                                                //<Nav.Link onClick={() => almsg()}>Company Key Staf Profiles</Nav.Link> : <Nav.Link eventKey="tab_10" onClick={() => getCommonData(`/company-key-staff-profles/list/${formFields.id}/1`)}>Company Key Staff Profiles</Nav.Link>
                                                //Commenting end. Divya. September 19 2023.. 
                                                //Added this code for  WOII-74. Divya. September 19 2023.. Code Start 
                                                <Nav.Link onClick={() => almsg()}>Key Staf Profiles</Nav.Link> : <Nav.Link eventKey="tab_10" onClick={() => getCommonData(`/company-key-staff-profles/list/${formFields.id}/1`)}>Key Staff Profiles</Nav.Link>
                                                //Divya. September 19 2023. Code End 
                                        }
                                    </Nav.Item>
                                    <Nav.Item>
                                        {
                                            formFields.id === '' ?
                                                //Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start
                                                //<Nav.Link onClick={() => almsg()}>Company References</Nav.Link> : <Nav.Link eventKey="tab_11" onClick={() => getCommonData(`/company-refrences/list/${formFields.id}/1`)}>Company References</Nav.Link>
                                                //Commenting end. Divya. September 19 2023.. 
                                                //Added this code for  WOII-74. Divya. September 19 2023.. Code Start 
                                                <Nav.Link onClick={() => almsg()}>References</Nav.Link> : <Nav.Link eventKey="tab_11" onClick={() => getCommonData(`/company-refrences/list/${formFields.id}/1`)}>References</Nav.Link>
                                                //Divya. September 19 2023. Code End 
                                        }
                                    </Nav.Item>
                                    <Nav.Item>
                                        {
                                            formFields.id === '' ?
                                                //Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start
                                                //<Nav.Link onClick={() => almsg()}>Company Clients</Nav.Link> : <Nav.Link eventKey="tab_12" onClick={() => getCommonData(`/company-clients/list/${formFields.id}/1`)}>Company Clients</Nav.Link>
                                                //Commenting end. Divya. September 19 2023.. 
                                                //Added this code for  WOII-74. Divya. September 19 2023.. Code Start 
                                                <Nav.Link onClick={() => almsg()}>Clients</Nav.Link> : <Nav.Link eventKey="tab_12" onClick={() => getCommonData(`/company-clients/list/${formFields.id}/1`)}>Clients</Nav.Link>
                                                //Divya. September 19 2023. Code End 
                                        }
                                    </Nav.Item>
                                </Nav>
                            {/* Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start */}
                            {/*</Col>*/}
                            { /* Commenting end. Divya. September 19 2023.. */}
                            { /* Added this code for  WOII-74. Divya. September 19 2023.. Code Start */}
                            </div>
                            {/* Divya. September 19 2023. Code End */}
                            {/* Commenting the following code for WOII-74. Divya. September 19 2023.. Code Start */}
                            {/*<Col sm={9}>*/}
                            { /* Commenting end. Divya. September 19 2023.. */}
                            { /* Added this code for  WOII-74. Divya. September 19 2023.. Code Start */}
                            <Col sm={12} className="tab-style-2">
                            {/* Divya. September 19 2023. Code End */}

                                <Tab.Content className="bg-white py-4 px-lg-5 px-md-4 border rounded-theme h-auto form-style">
                                    <Tab.Pane eventKey="tab_1">
                                        <div className="form-group">
                                            <label>Company Name</label>
                                            <input type="text" className="form-control" name="companyName" value={formFields['companyName']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.companyName === '' ? 'Please Enter Company Name' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Company Legal Structure</label>
                                            <input type="text" className="form-control h-auto" id="" onChange={handleChange} name="legalStructure" value={formFields['legalStructure']} />
                                            <span className="text-danger">{flag === true && formFields.legalStructure === '' ? 'Please Enter Company Legal Structure' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>FEIN</label>
                                            <input type="text" className="form-control" name="fein" value={formFields['fein']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.fein === '' ? 'Please Enter FEIN' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Company Website URL</label>
                                            <input type="text" className="form-control" name="url" value={formFields['url']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.url === '' ? 'Please Enter Company Website URL' : null}</span>
                                        </div>
                                        <div className="text-right" style={{ bottom: 20, right: 30 }}>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_1")} disabled={buttonLoader}>
                                                {buttonLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}
                                                <span> Save</span>
                                            </button>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_2">
                                        <div className="form-group">
                                            <input type="text" className="form-control h-auto" id="" onChange={handleChange} name="legalStructure" value={formFields['legalStructure']} />
                                            <div className="d-flex flex-column">
                                                <span className="text-danger">{flag === true && formFields.legalStructure === '' ? 'Please Enter Company Legal Structure' : null}</span>
                                            </div>
                                            <div className="text-right" style={{ bottom: 20, right: 30 }}>
                                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                                <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_2")} disabled={buttonLoader}>
                                                    {buttonLoader && (
                                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                    )}
                                                    <span> Save</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="fein" value={formFields['fein']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.fein === '' ? 'Please Enter FEIN' : null}</span>
                                            <div className="text-right" style={{ position: 'absolute', bottom: 20, right: 30 }}>
                                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                                <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_3")} disabled={buttonLoader}>
                                                    {buttonLoader && (
                                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                    )}
                                                    <span> Save</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_4">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="url" value={formFields['url']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.url === '' ? 'Please Enter Company Website URL' : null}</span>
                                            <div className="text-right" style={{ position: 'absolute', bottom: 20, right: 30 }}>
                                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                                <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_4")} disabled={buttonLoader}>
                                                    {buttonLoader && (
                                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                    )}
                                                    <span> Save</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_5">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" name="companyContractName" value={formFields['companyContractName']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.companyContractName === '' ? 'Please Enter Name' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input type="text" className="form-control" name="companyContractTitle" value={formFields['companyContractTitle']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.companyContractTitle === '' ? 'Please Enter Title' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                value={formFields.companyContractPhone}
                                                className="form-control"
                                                onChange={e => handlePhone(e, "companyContractPhone")}
                                                countrySelectProps={{ unicodeFlags: true }}
                                                defaultCountry="US"
                                                international
                                            />
                                            <span className="text-danger">{flag === true && formFields.companyContractPhone === '' ? 'Please Enter Phone' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" className="form-control" name="companyContractEmail" value={formFields['companyContractEmail']} onChange={handleChange} />
                                            {
                                                /* eslint-disable-next-line */
                                                <span className="text-danger">{flag === true && (formFields.companyContractEmail === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.companyContractEmail)) ? 'Please Enter Valid Email' : null} </span>
                                            }
                                        </div>
                                        <div className="text-right" style={{ bottom: 20, right: 30 }}>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_5")} disabled={buttonLoader}>
                                                {buttonLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}
                                                <span> Save</span>
                                            </button>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_6">
                                        <div className="form-group">
                                            <label>Street Number</label>
                                            <input type="text" className="form-control" onChange={handleChange}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        return false;
                                                    }

                                                    return true;
                                                }} name="streetNumber" value={formFields['streetNumber']} />
                                            <span className="text-danger">{flag === true && formFields.streetNumber === '' ? 'Please Enter Street Number' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Street Name</label>
                                            <input type="text" className="form-control" name="streetName" value={formFields['streetName']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.streetName === '' ? 'Please Enter Street Name' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Additional Add. Info</label>
                                            <input type="text" className="form-control" name="additionalInfo" value={formFields['additionalInfo']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.additionalInfo === '' ? 'Please Enter Additional Add. Info' : null}</span>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input type="text" className="form-control" name="city" value={formFields['city']} onChange={handleChange} />
                                                    <span className="text-danger">{flag === true && formFields.city === '' ? 'Please Enter City' : null}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Country</label>
                                                    <select id="disabledSelect" className="custom-select form-control" value={formFields['countryId']} name="countryId" onChange={handleChange}>
                                                        <option value="" disabled selected>Select your option</option>
                                                        {
                                                            countryData.map((val, index) => {
                                                                return (
                                                                    <option key={index} value={val.id}>{val.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                formFields.countryId ?
                                                    <div className="col-md-6 ">
                                                        <div className="form-group">
                                                            <label>State/Province</label>
                                                            <select id="disabledSelect" className="form-control custom-select" value={formFields['stateId']} name="stateId" onChange={handleChange}>
                                                                <option value="" disabled selected>Select your option</option>
                                                                {
                                                                    stateData.map((res, index) => {
                                                                        return (
                                                                            <option key={index} value={res.id}>{res.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            <span className="text-danger">{flag === true && formFields.stateId === '' ? 'Please Select State/Province' : null}</span>
                                                        </div>
                                                    </div> : null
                                            }
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Postal Code/Zip</label>
                                                    <input type="text" className="form-control" name="zipCode" value={formFields['zipCode']} onChange={handleChange} />
                                                    <span className="text-danger">{flag === true && formFields.zipCode === '' ? 'Please Enter Postal Code/Zip' : null}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 text-right" style={{ position: 'relative', top: 10, right: 10, }}>
                                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                                <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_6")} disabled={buttonLoader}>
                                                    {buttonLoader && (
                                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                    )}
                                                    <span> Save</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_7">
                                        <div className="form-group">
                                            <Editor
                                                apiKey={tiny_key}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                initialValue={formFields.companyBackground}
                                                init={{
                                                    height: 500,
                                                    menubar: 'file edit view insert format tools table tc help',
                                                    selector: 'textarea#full-featured',
                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                }}
                                            />
                                            <span className="text-danger">{flag === true && formFields.companyBackground === '' ? 'Please Enter Company Background' : null}</span>
                                        </div>
                                        <div className="text-right" style={{ bottom: 20, right: 30 }}>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_7")} disabled={buttonLoader}>
                                                {buttonLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}
                                                <span> Save</span>
                                            </button>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_8">
                                        <div className="form-group">
                                            <Editor
                                                apiKey={tiny_key}
                                                onInit={(evt, editor) => companyHistoryRef.current = editor}
                                                initialValue={formFields.companyHistory}
                                                init={{
                                                    height: 500,
                                                    menubar: 'file edit view insert format tools table tc help',
                                                    selector: 'textarea#full-featured',
                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                }}
                                            />
                                            <span className="text-danger">{flag === true && formFields.companyHistory === '' ? 'Please Enter Company History' : null}</span>
                                        </div>
                                        <div className="text-right" style={{ bottom: 20, right: 30 }}>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_8")} disabled={buttonLoader}>
                                                {buttonLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}
                                                <span> Save</span>
                                            </button>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_9">
                                        <div className="form-group">
                                            <Editor
                                                apiKey={tiny_key}
                                                onInit={(evt, editor) => revenueGrowthRef.current = editor}
                                                initialValue={formFields.revenueGrowth}
                                                init={{
                                                    height: 500,
                                                    menubar: 'file edit view insert format tools table tc help',
                                                    selector: 'textarea#full-featured',
                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                }}
                                            />
                                            <span className="text-danger">{flag === true && formFields.revenueGrowth === '' ? 'Please Enter Revenue Growth' : null}</span>
                                        </div>
                                        <div className="text-right" style={{ bottom: 20, right: 30 }}>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_9")} disabled={buttonLoader}>
                                                {buttonLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}
                                                <span> Save</span>
                                            </button>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_10">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" name="companyKeyName" value={formFields['companyKeyName']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.companyKeyName === '' ? 'Please Enter Name' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input type="text" className="form-control" name="companyKeyTitle" value={formFields['companyKeyTitle']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.companyKeyTitle === '' ? 'Please Enter Title' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                value={formFields.companyKeyPhone}
                                                className="form-control"
                                                onChange={e => handlePhone(e, "companyKeyPhone")}
                                                countrySelectProps={{ unicodeFlags: true }}
                                                defaultCountry="US"
                                                international
                                            />
                                            <span className="text-danger">{flag === true && formFields.companyKeyPhone === '' ? 'Please Enter Phone' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" className="form-control" name="companyKeyEmail" value={formFields['companyKeyEmail']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && (formFields.companyKeyEmail === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.companyKeyEmail)) ? 'Please Enter Valid Email' : null} </span>
                                        </div>
                                        <div className="form-group">
                                            <label>Profile</label>
                                            <Editor
                                                apiKey={tiny_key}
                                                onInit={(evt, editor) => companyKeyProfileRef.current = editor}
                                                initialValue={formFields.companyKeyProfile}
                                                init={{
                                                    height: 500,
                                                    menubar: 'file edit view insert format tools table tc help',
                                                    selector: 'textarea#full-featured',
                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                }}
                                            />
                                            <span className="text-danger">{flag === true && formFields.companyKeyProfile === '' ? 'Please Enter Profile' : null}</span>
                                        </div>
                                        <div className="text-center mb-4">
                                            <button type="button" className="btn btn-brand-1" onClick={() => addKeyStaff()} disabled={keyStaffProfileLoader}>
                                                {keyStaffProfileLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}{formFields.profileId ? 'Update' : 'Add'}</button>
                                        </div>
                                        <table className="table table-style-1 table-responsive-md mb-0 table font-14">

                                            <tr>
                                                <th scope="col"> Name</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Profile</th>
                                                <th scope="col" className="text-center" width="110">Action</th>
                                            </tr>

                                            <tbody>
                                                {
                                                    commonData.map((res) => {
                                                        return (
                                                            <tr>
                                                                <td>{res.name}</td>
                                                                <td>{res.title}</td>
                                                                <td>{res.phone}</td>
                                                                <td>{res.email}</td>
                                                                <td title={res.profile}>{res.profile && <div dangerouslySetInnerHTML={{ __html: res.profile }}></div>}</td>

                                                                <td className="text-center">
                                                                    <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res, 'profile')}><i className="icofont-pencil-alt-2"></i></button>
                                                                    <button type="button" className="btn btn-action delete" onClick={() => _onDelete(`/company-key-staff-profles`, res.id)}><i className="icofont-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            count === 0 ?
                                                <div className="mt-4">
                                                    <SearchNoDataFound message={'No Company Key Staff Profiles Found'} />
                                                </div> : null
                                        }
                                        <div className="text-right" style={{ bottom: 20, right: 30, marginTop: 20 }}>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                            {/* <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_10")} disabled={buttonLoader}>
                                                {buttonLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}
                                                <span> Save</span>
                                            </button> */}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_11">
                                        <div className="form-group">
                                            <label>Client Name</label>
                                            <input type="text" className="form-control" name="companyRefernceName" value={formFields['companyRefernceName']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.companyRefernceName === '' ? 'Please Enter Client Name' : null}</span>

                                        </div>

                                        <div className="form-group">
                                            <label>Client Contact</label>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                value={formFields.companyRefernceContact}
                                                className="form-control"
                                                onChange={e => handlePhone(e, "companyRefernceContact")}
                                                countrySelectProps={{ unicodeFlags: true }}
                                                defaultCountry="US"
                                                international
                                            />
                                            <span className="text-danger">{flag === true && formFields.companyRefernceContact === '' ? 'Please Enter Client Contact' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Contact Title</label>
                                            <input type="text" className="form-control" name="companyRefernceContactTitle" value={formFields['companyRefernceContactTitle']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.companyRefernceContactTitle === '' ? 'Please Enter Contact Title' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" className="form-control" name="companyRefernceEmail" value={formFields['companyRefernceEmail']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && (formFields.companyRefernceEmail === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.companyRefernceEmail)) ? 'Please Enter Valid Email' : null} </span>
                                        </div>
                                        <div className="form-group">
                                            <label>Project/Contract</label>
                                            <Editor
                                                apiKey={tiny_key}
                                                onInit={(evt, editor) => companyReferenceProjectRef.current = editor}
                                                initialValue={formFields.companyRefernceProject}
                                                init={{
                                                    height: 500,
                                                    menubar: 'file edit view insert format tools table tc help',
                                                    selector: 'textarea#full-featured',
                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                }}
                                            />
                                            <span className="text-danger">{flag === true && formFields.companyRefernceProject === '' ? 'Please Enter Project/Contract' : null}</span>
                                        </div>
                                        <div className="text-center mb-4">
                                            <button type="button" className="btn btn-brand-1" onClick={() => addReference()} disabled={keyStaffProfileLoader}>
                                                {keyStaffProfileLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}{formFields.companyReferenceId == '' ? 'Add' : 'Update'}</button>
                                        </div>
                                        <table className="table table-style-1 table-responsive-md mb-0 table font-14">

                                            <tr>
                                                <th scope="col"> Client Name</th>

                                                <th scope="col">Client Phone</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Project/Contract</th>
                                                <th scope="col" className="text-center" width="110">Action</th>
                                            </tr>

                                            <tbody>
                                                {
                                                    commonData.map((res) => {
                                                        return (
                                                            <tr>
                                                                <td>{res.name}</td>
                                                                <td>{res.contact}</td>
                                                                <td>{res.email}</td>
                                                                <td title={res.project}>{res.project && <div dangerouslySetInnerHTML={{ __html: res.project }}></div>}</td>
                                                                <td className="text-center">
                                                                    <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res, 'reference')}><i className="icofont-pencil-alt-2"></i></button>
                                                                    <button type="button" className="btn btn-action delete"><i className="icofont-trash" onClick={() => _onDelete(`/company-refrences`, res.id)}></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                        {
                                            count === 0 ?
                                                <div className="mt-4">
                                                    <SearchNoDataFound message={'No Company References Found'} />
                                                </div> : null
                                        }
                                        <div className="text-right" style={{ right: 30, marginTop: 20 }}>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                            {/* <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_11")} disabled={buttonLoader}>
                                                {buttonLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}
                                                <span> Save</span>
                                            </button> */}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_12">
                                        <div className="form-group">
                                            <label>Client Name</label>
                                            <input type="text" className="form-control" name="companyClientName" value={formFields['companyClientName']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.companyClientName === '' ? 'Please Enter Client Name' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Contract Scope</label>
                                            <Editor
                                                apiKey={tiny_key}
                                                onInit={(evt, editor) => companyClientContractRef.current = editor}
                                                initialValue={formFields.companyClientContract}
                                                init={{
                                                    height: 500,
                                                    menubar: 'file edit view insert format tools table tc help',
                                                    selector: 'textarea#full-featured',
                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                }}
                                            />
                                            <span className="text-danger">{flag === true && formFields.companyClientContract === '' ? 'Please Enter Contract Scope' : null}</span>
                                        </div>

                                        <div className="text-center mb-3">
                                            <button type="button" className="btn btn-brand-1" onClick={() => addClient()} disabled={keyStaffProfileLoader}>
                                                {keyStaffProfileLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}{formFields.contractScopeId ? 'Update' : 'Add'}</button>
                                        </div>

                                        <table className="table table-style-1 table-responsive-md mb-0 table font-14">
                                            <tr>
                                                <th scope="col"> Client Name</th>
                                                <th scope="col">Contract Scope</th>
                                                <th scope="col" className="text-center" width="110">Action</th>
                                            </tr>
                                            <tbody>
                                                {
                                                    commonData.map((res) => {
                                                        return (
                                                            <tr>
                                                                <td>{res.client_name}</td>

                                                                <td title={res.contract_scope}>{res.contract_scope && <div dangerouslySetInnerHTML={{ __html: res.contract_scope }}></div>}</td>

                                                                <td className="text-center">
                                                                    <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res, 'scope')}><i className="icofont-pencil-alt-2"></i></button>
                                                                    <button type="button" className="btn btn-action delete"><i className="icofont-trash" onClick={() => _onDelete(`/company-clients`, res.id)}></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            count === 0 ?
                                                <div className="mt-4">
                                                    <SearchNoDataFound message={'No Company Clients Found'} />
                                                </div> : null
                                        }
                                        <div className="text-right" style={{ bottom: 20, right: 30, marginTop: 20 }}>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-4" onClick={() => goBack()}>Cancel</button>
                                            {/* <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save("tab_12")} disabled={buttonLoader}>
                                                {buttonLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}
                                                <span> Save</span>
                                            </button> */}
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>

                            </Col>

                        </Row>
                    </Tab.Container>
                </div>
            </div>

        </>
    )
}

export default CompanyDetails;