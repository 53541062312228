import React, { useEffect, useState, useRef } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { APIMethod, tiny_key, TOAST_TIMEOUT } from "../../../../../API/APIClient";
import Loader from '../../../../../components/Loader';
import { useToasts } from 'react-toast-notifications';
import { Modal } from "react-bootstrap";
import ModalPopup from '../../../../../components/ModalPopUp';
import NoData from '../../../../../components/NoDataFound';
import SearchNoDataFound from '../../../../../components/SearchNoDataFound';
import ReactModal from 'react-modal-resizable-draggable';
import { Editor } from '@tinymce/tinymce-react';
import { checkEmptyString } from '../../../../../utility/utility';

const AddProcess = (props) => {
    const processDetailsRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [moduleLoader, setModuleLoader] = useState(false);
    const [id, setId] = useState();
    const [moduleData, setModuleData] = useState([]);
    const [formFields, setFormFields] = useState({
        processName: '', processFunction: '', processDetails: '', nigp: '', processModuleName: '', processModuleFunction: '',
        processModuleCode: '', processModuleDescription: '', companyId: ''
    });
    // Edit
    const [edit, setEdit] = useState('');
    const [editId, setEditId] = useState('');
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [flag, setFlag] = useState(false);
    // Add modal box
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setEditId('')
        setFormFields({
            ...formFields, processModuleName: '', processModuleFunction: '',
            processModuleCode: '', processModuleDescription: ''
        })
        setShow(false);
    }
    const handleShow = () => setShow(true);
    // Toast
    const { addToast } = useToasts();
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }

    useEffect(() => {
        getModuleList()
        check()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const check = () => {
        if (props.location.state === null) {
        } else {
            if (props.location.state.details == undefined) {
                setFormFields({ ...formFields, companyId: props.location.state.company_id })
            } else {
                setId(props.location.state.details.id)
                setFormFields({ ...formFields, processName: props.location.state.details.name, processFunction: props.location.state.details.function, nigp: props.location.state.details.code, processDetails: props.location.state.details.details, companyId: props.location.state.company_id })
                getModuleList(props.location.state.details.id)
            }
        }
    }

    const getModuleList = (id) => {
        if (id === undefined) {
        } else {
            setLoading(true)
            var method = 'GET'
            const apifetcherObj = APIMethod(`/module/list/PROCESS/${id}/1`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setModuleData(data.list)
                        setLoading(false)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }

    const save = () => {
        if (formFields.processName === '' || formFields.processFunction === '' || checkEmptyString(processDetailsRef.current.getContent()) || formFields.nigp === '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setButtonLoader(true)
            if (props.location.state.details == undefined) {
                var method = 'POST'
                var params = JSON.stringify({
                    name: formFields.processName,
                    code: formFields.nigp,
                    details: processDetailsRef.current.getContent(),
                    function: formFields.processFunction,
                    company_id: formFields.companyId
                })
            } else {
                method = 'PUT'
                params = JSON.stringify({
                    id: props.location.state.details.id,
                    name: formFields.processName,
                    code: formFields.nigp,
                    details: processDetailsRef.current.getContent(),
                    function: formFields.processFunction,
                    company_id: formFields.companyId
                })
            }
            const apifetcherObj = APIMethod(`/process`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setId(data.id)
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        setButtonLoader(false)
                        setFlag(false)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const AddModule = () => {
        if (formFields.processModuleName === '' || formFields.processModuleFunction === '' || formFields.processModuleCode === '' || formFields.processModuleDescription === '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setModuleLoader(true)
            if (edit === '/') {
                var method = 'PUT'
                var params = JSON.stringify({
                    id: editId,
                    name: formFields.processModuleName,
                    description: formFields.processModuleDescription,
                    code: formFields.processModuleCode,
                    refference: id,
                    type: "PROCESS",
                    function: formFields.processModuleFunction,
                })
            } else {
                method = 'POST'
                params = JSON.stringify({
                    name: formFields.processModuleName,
                    description: formFields.processModuleDescription,
                    code: formFields.processModuleCode,
                    refference: id,
                    type: "PROCESS",
                    function: formFields.processModuleFunction,
                })
            }
            const apifetcherObj = APIMethod(`/module`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        setFormFields({ ...formFields, processModuleName: '', processModuleFunction: '', processModuleCode: '', processModuleDescription: '' })
                        setModuleLoader(false)
                        handleClose()
                        getModuleList(id)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setModuleLoader(false)
                    }
                })
                .catch(error => {
                    setModuleLoader(false)
                })
        }
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const _onEdit = (val) => {
        handleShow()
        setFormFields({ ...formFields, processModuleName: val.name, processModuleFunction: val.function, processModuleCode: val.code, processModuleDescription: val.description })
        setEdit('/')
        setEditId(val.id)
    }

    const showError = () => addToast("Please Add Process First", { autoDismissTimeout: TOAST_TIMEOUT, autoDismiss: true });

    const onEditorChange = (data, key) => {
        const formFieldsObject = {
            ...formFields,
            [key]: data
        }
        setFormFields(formFieldsObject);
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Org. Setup</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>
                                Company Capabilities
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>
                                Process
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{id ? 'Edit' : 'Add'} Prcocess</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center" >
                        <span className="add-title ">{id ? 'Edit Process' : 'Add Process'}</span>
                    </div>
                    <div className="form-wraper form-style mb-4">
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Process Name</label>
                                    <input type="text" className="form-control" name="processName" value={formFields['processName']} onChange={handleChange} />
                                    <span className="text-danger">{flag === true && formFields.processName === '' ? 'Please Enter Process Name' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Process Function</label>
                                    <textarea className="form-control h-auto" id="" cols="30" rows="3" name="processFunction" value={formFields['processFunction']} onChange={handleChange}></textarea>
                                    <span className="text-danger">{flag === true && formFields.processFunction === '' ? 'Please Enter Process Function' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>NIGP/UNPSC Codes</label>
                                    <input type="text" class="form-control" name="nigp" value={formFields['nigp']} onChange={handleChange} />
                                    <span className="text-danger">{flag === true && formFields.nigp === '' ? 'Please Enter NIGP/UNPSC Code' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Process  Details</label>
                                    <Editor
                                        apiKey={tiny_key}
                                        onInit={(evt, editor) => processDetailsRef.current = editor}
                                        initialValue={formFields.processDetails}
                                        init={{
                                            height: 500,
                                            menubar: 'file edit view insert format tools table tc help',
                                            selector: 'textarea#full-featured',
                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                        }}
                                    />
                                    <span className="text-danger">{flag === true && formFields.processDetails === '' ? 'Please Enter Process Details' : null}</span>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger " onClick={goBack}>Cancel</button>
                            <button type="button" className="btn btn-lg btn-brand-1 mx-3 bg-success" onClick={() => save()} disabled={buttonLoader}>
                                {buttonLoader && (
                                    <img src={require('../../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                )}
                                <span>Save</span>
                            </button>
                            <button type="button" className="btn btn-brand-1 btn-h-40 bg-success px-4" onClick={id === undefined ? showError : handleShow}>+ Add Process Module </button>
                        </div>
                    </div>
                    {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                    {/*<h2 className="font-18 font-medium">Process Modules Added</h2>*/}
                    {/* Commenting end. Divya. September 07 2023.. */}
                    {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                    <h2 className="font-20 font-bold">Process Modules Added</h2>
                    {/* Divya. September 07 2023. Code End */}
                    <table className="table table-style-1 table-responsive-lg mb-4 table reports" id="reportTable">
                        <thead>
                            <tr>
                                <th scope="col">Process Module</th>
                                <th scope="col">Function</th>
                                <th scope="col">Description</th>
                                <th scope="col" className="text-center" width="110">Action</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/module/'}
                            updateList={getModuleList}
                        ></ModalPopup>
                        <tbody>
                            {
                                loading === false ? moduleData.map((res) => {
                                    return (
                                        <tr>
                                            <td>{res.name}</td>
                                            <td>{res.function}</td>
                                            <td>{res.description}</td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button>
                                                <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                    {
                        moduleData.length === 0 && loading === false ?
                            <SearchNoDataFound message={'No Process Modules Found'} />
                            : ''
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                </div>
            </div>

            {/* Add Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={116.5}
                disableKeystroke={true}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{editId ? 'Edit' : 'Add'} Process Module</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">

                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Process Module Name</label>
                                <input type="text" className="form-control" onChange={handleChange} name="processModuleName" value={formFields['processModuleName']} />
                                <span className="text-danger">{flag === true && formFields.processModuleName === '' ? 'Please Enter Process Module Name' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Process Module Function</label>
                                <input type="text" className="form-control" onChange={handleChange} name="processModuleFunction" value={formFields['processModuleFunction']} />
                                <span className="text-danger">{flag === true && formFields.processModuleFunction === '' ? 'Please Enter Process Module Function' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>NIGP/UNSPSC Codes</label>
                                <input type="text" className="form-control" onChange={handleChange} name="processModuleCode" value={formFields['processModuleCode']} />
                                <span className="text-danger">{flag === true && formFields.processModuleCode === '' ? 'Please Enter NIGP/UNSPSC Codes' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Process Module Description</label>
                                <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="processModuleDescription" value={formFields['processModuleDescription']}></textarea>
                                <span className="text-danger">{flag === true && formFields.processModuleDescription === '' ? 'Please Enter Process Module Description' : null}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => AddModule()} disabled={moduleLoader}>
                        {moduleLoader && (
                            <img src={require('../../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>

        </>
    )
}
export default AddProcess;