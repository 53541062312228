import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Tabs, Tab, Nav, Row, Col, Modal, Button, Container } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css"
import { useLocation, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import SectionizedRFx from "../../../../components/SectionizedRFx";
import RawRFx from "../../../../components/RawRFx";
import RfxData from "../../../../components/RfxData";
import { APIMethod, TOAST_TIMEOUT, FileUploadMethod, tiny_key } from "../../../../API/APIClient";
import moment from "moment";
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router";
import ReactModal from 'react-modal-resizable-draggable';
import ModalPopup from '../../../../components/ModalPopUp';
import { Editor } from '@tinymce/tinymce-react';
import sendComment from '../../../../assets/css/images/send-comment.png';
import { MultiSelect } from "react-multi-select-component";
import chatIcon from '../../../../assets/css/images/chat.png';
import Chat from "../../../../components/Chat";
// import { Configuration, OpenAIApi } from 'openai';
  
const regex = /(<([^>]+)>)/ig;

const GenerateProposalMp = (props) => {
    const answerRef = useRef(null);
    const [isLoding, setLoading] = useState(false);
    const { id } = useParams()
    const [SearchbuttonLoader, setSearchButtonLoader] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [documentData, setDocumentData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [approverData, setApproverData] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [addDocuments, setAddDocuments] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [aiSegmentationData, setAiSegmentationData] = useState();
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [commentModal, setCommentModal] = useState(false);
    const [formFields, setFormFields] = useState({ rfxCategory: '', question: '', tag: '', preRfxId: '', sendForApprovalLoader: false, page: '', keyword: '', modalQuestion: '', modalAnswer: '', comment: '', commentAddLoader: false, isComment: false, document_type: '', version: '', description: '', predefine_sections: '', submitButtonLoader: false, saveButtonLoader: false, })
    {/* Added this code for WI-2. Divya. June 19 2023.. Code Start */}
    const [isCollapsed, setIsCollapsed] = useState(true);
    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
      };
    {/* Divya. June 19 2023. Code End */}
    //Goto back
    const goBack = () => {
        window.history.back();
    }
    // Toast
    const { addToast } = useToasts();
    const location = useLocation();
    //--------date-Piicker----------
    const [startDate, setStartDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [show, setShow] = useState(false);
    const [addDocumentShow, setAddDocumentShow] = useState(false);
    const [answerModal, setAnswerModal] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);
    const [modalButtonLoader, SetModalButtonLoader] = useState(false);
    const [aiSearchButtonLoader, setAiSearchButtonLoader] = useState(false);
    const [EditTemplateModal, setEditTemplateModal] = useState(false);
    const [aiSearchData, setAiSearchData] = useState([]);
    const [answer, setAnswer] = useState('')
    const [prompt, setPrompt] = useState('')
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);
    // const [text, setText] = useState('As a way of preparing a response to '+preRfxData?.title ? preRfxData?.title : '-'+' RFP, provide answers to the following RFP Questions. RFP is issued by Solomons International. Provide this proposal on behalf of Solomons International.\nRFP question:')
    const [editAnswerIndex, setEditAnswerIndex] = useState('')
    const [preRfxData, setPreRfxData] = useState();
    const [preRfxTitle, setPreRfxTitle] = useState("");
    const [url, setUrl] = useState("");
    // file
    const [file, setFile] = useState();
    const [editIndex, setEditIndex] = useState(-1);
    const [editDocumentId, setEditDocumentId] = useState('');
    const sectionId = useRef('');
    const sectionTitle = useRef('');
    //---Date Time picker
    //coollapse menu - 1
    const CollapseMenu1 = () => {
        var element = document.getElementById("PraposalTemp");
        element.classList.toggle("collapse-tab");

        for (var i = 0; i < 5; i++) {
            var hideIcon = document.getElementById(`hideCollapseButton${i + 1}`);
            hideIcon.classList.toggle("show-coll-menu-page");
            var hideText = document.getElementById(`hideCollapseText${i + 1}`);
            hideText.classList.toggle("hide-coll-menu-page");
        }
    }
    const [proposalDocumentDeleteId, setProposalDocumentDeleteId] = useState('')
    const history = useHistory();
    const [selected, setSelected] = useState([]);
    const [showChatModal, setShowChatModal] = useState(false)
    const [users, setUsers] = useState([])
    const [commentsData, setCommentsData] = useState([])


    const options = [
        { label: "Grapes 🍇", value: "grapes" },
        { label: "Mango 🥭", value: "mango" },
        { label: "Strawberry 🍓", value: "strawberry", disabled: true },
    ];

    useEffect(() => {
        getRfxData()
        getRFxCategoryList()
        checkPage()
        getApproverVersion()
        getProposalSectionComments()
        getDocumentTypeList()
        getProposalDocuments()
        getAiImageSegmentation()
        getContractData()
        getUserList()
    }, [])


    const getRfxData = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/document-section/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setUrl(data.url);
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
            })
    }

    const getRFxCategoryList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                } else {
                }
            })
            .catch(error => {
            })
    }

    const checkPage = () => {
        if (props.location.state == null) {
        } else {
            formFields.preRfxId = props.location.state.detail
            formFields.page = props.location.state.page
        }
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/${id}?rfx_type=AI`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setPreRfxData(data)
                    let title= data.title ? data.title:"<Rfx_Title>"
                    setPrompt('As a way of preparing a response to '+title+', provide answers to the following RFP Questions. RFP is issued by Solomons International. Provide this proposal on behalf of Solomons International.\nRFP question:')
                    setLoading(false);

                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }

    const getApproverVersion = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/template-content/list/1?pre_rfx_id=${id}&is_approver=false`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    for (var i in data.list) {
                        if (data.list[i].is_check == false) {
                            data.list[i].is_check = false;
                        } else {
                            data.list[i].is_check = true;
                        }
                        if (data.list[i].answer) {
                            data.list[i].answer = data.list[i].answer.replaceAll("<strong", "<strong style='font-weight:bold'");
                        }
                        if (data.list[i].description) {
                            data.list[i].description = data.list[i].description.replaceAll("<strong", "<strong style='font-weight:bold'");
                        }

                    }
                    setApproverData(data.list)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
            })
    }

    const getProposalDocuments = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/proposal/document/list/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setAddDocuments(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const getAiImageSegmentation = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfp-image-segmentation/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setAiSegmentationData(data)
                    // formFields.aiScope = data.scope
                    // formFields.aiProposalStructure = data.proposal_structure
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                alert(error)
            })
    }

    const _onSearch = () => {
        if (formFields.keyword === '') {
            addToast('Please Fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
        } else {
            setSearchButtonLoader(true)
            let new_from_date = fromDate ? moment(fromDate).format(
                "yyyy-MM-DD"
            ) : '';
            let new_to_date = toDate ? moment(toDate).format(
                "yyyy-MM-DD"
            ) : '';
            setSearchData([])
            var method = 'GET'
            const apifetcherObj = APIMethod(`/qna/list/1?question_answer=${formFields.keyword}&tag=${formFields.tag}&rfx_category_id=${formFields.rfxCategory}&from_date=${new_from_date}&to_date=${new_to_date}`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setSearchData(data.list)
                        getDocumentList()
                        setSearchButtonLoader(false)
                    } else {
                        setSearchButtonLoader(false)
                    }
                })
                .catch(error => {
                    alert(error)
                    setSearchButtonLoader(false)
                })
        }
    }

    const getDocumentList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/document/list/1?keyword=${formFields.keyword}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentData(data.list)
                    setSearchButtonLoader(false)
                } else {
                    setSearchButtonLoader(false)
                }
            })
            .catch(error => {
                alert(error)
                setSearchButtonLoader(false)
            })
    }

    const getProposalSectionComments = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/template-content/comments/list/${id}?comment_type=PROPOSAL_COMMENT`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCommentData(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const getDocumentTypeList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentList(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const _onSendForApproval = () => {
        if (formFields.isComment == false) {
            addToast("Please Enter Comment First", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
        } else {
            formFields.sendForApprovalLoader = true;
            var method = 'PUT'
            var params = JSON.stringify({
                id: id,
                proposal_status: 'PENDING_APPROVAL'
            })
            const apifetcherObj = APIMethod(`/pre-rfx`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast('Sent for Approval', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                        formFields.sendForApprovalLoader = false;
                        onUpdateStatus("SEND_FOR_APPROVAL_FOR_PROPOSAL")
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        formFields.sendForApprovalLoader = false;
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    formFields.sendForApprovalLoader = false;
                })
        }
    }

    const onViewQA = (data) => {
        formFields.modalQuestion = data.question
        formFields.modalAnswer = data.answer
        setShow(true)
    }

    const addComment = () => {
        if (formFields.comment == '') {
            addToast('Please enter comment', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            formFields.commentAddLoader = true
            var method = 'POST'
            var params = JSON.stringify({
                comment: formFields.comment,
                pre_rfx_id: id,
                type: "PROPOSAL_COMMENT"
            })
            const apifetcherObj = APIMethod(`/template-content/post/comment`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode == 200) {
                        // addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                        getProposalSectionComments()
                        formFields.commentAddLoader = false
                        formFields.isComment = true
                        formFields.comment = ''
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        formFields.commentAddLoader = false
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    formFields.commentAddLoader = false
                })
        }
    }

    const onUpdateStatus = (key) => {
        if (formFields.isComment == false && key != 'PROPOSAL_COMPLETED') {
            addToast('Please enter comment', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (key == 'PROPOSAL_COMPLETED') {
            var showMessage = false;
            for (var i in approverData) {
                if (approverData[i].is_check == false) {
                    showMessage = true;
                    break;
                }
            }
            if (showMessage === true) {
                addToast('Before Submiting all checkbox should be checked.', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            } else {
                sendEvent(key)
            }
            return;
        } else {
            sendEvent(key)
        }
    }

    const sendEvent = (key) => {
        if (key == 'PROPOSAL_COMPLETED') {
            formFields.submitButtonLoader = true;
        }
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/action/event/${key}/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    formFields.isComment = false;
                    if (key == 'PROPOSAL_COMPLETED' || key == 'SEND_FOR_APPROVAL_FOR_PROPOSAL') {
                        formFields.submitButtonLoader = false;
                        onSubmit()
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        formFields.submitButtonLoader = false;
                        onSaveProposal()
                        goBack()
                    }
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    formFields.submitButtonLoader = false;
                }
            })
            .catch(error => {
            })
    }

    const onSubmit = (type) => {
        var method = 'PUT'
        var params = JSON.stringify(approverData)
        if (!type) {
            formFields.saveButtonLoader = true;
        }
        const apifetcherObj = APIMethod(`/template-content/${id}?is_approver=false`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                    if (addDocuments.length > 0 && props.location.state.page != 'Approval') {
                        onSaveProposal()
                    }
                    if (type == 'PROPOSAL_APPROVED') {
                        onUpdateStatus('PROPOSAL_APPROVED')
                    }
                    if (type == 'PUSHBACK') {
                        onUpdateStatus('PUSHBACK')
                    } else {
                        formFields.saveButtonLoader = false;
                    }
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    formFields.saveButtonLoader = false;
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                formFields.saveButtonLoader = false;
            })
    }

    const onCheckboxClick = (index) => {
        var temp = [];
        for (var i in approverData) {
            if (index == i) {
                approverData[i].is_check = !approverData[i].is_check;
            }
            temp.push(approverData[i])
        }
        setApproverData(temp);
    }

    const onCancelModal = () => {
        setFormFields({ ...formFields, document_type: "", version: "", description: "" })
        setEditDocumentId('')
        setFile()
        setAddDocumentShow(!addDocumentShow)
    }

    const filehandler = event => {
        setFile(event.target.files[0])
    }

    const _onAddDocument = () => {
        if (formFields.version === '' || formFields.description === '' || file === undefined || formFields.document_type === '') {
            setModalFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            if (file.type) {
                SetModalButtonLoader(true)
                var method = 'POST'
                var form = new FormData();
                form.append('file', file);
                const apifetcherObj = FileUploadMethod(`/document/attachment/upload`, form, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]
                        var name = ''
                        if (statusCode === 200) {
                            for (var i in documentList) {
                                if (documentList[i].id == formFields.document_type) {
                                    name = documentList[i].name
                                }
                            }
                            var obj = {
                                document_id: formFields.document_type,
                                document_type_name: name,
                                file_name: file.name,
                                version: formFields.version,
                                file: data.file,
                                description: formFields.description
                            }

                            if (editIndex >= 0) {
                                for (var index in addDocuments) {
                                    if (index == editIndex) {
                                        addDocuments.splice(editIndex, 1);
                                        addDocuments.splice(editIndex, 0, obj);
                                        setEditIndex(-1)
                                        setAddDocuments(addDocuments)
                                        break;
                                    }
                                }
                            } else {
                                addDocuments.push(obj)
                                setAddDocuments(addDocuments)
                            }
                            setAddDocumentShow(!addDocumentShow)
                            setEditIndex(-1)
                            setFile('')
                            setEditDocumentId('')
                            setFormFields({ ...formFields, version: '', description: '', document_type: '' })
                            SetModalButtonLoader(false)
                        } else {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        }
                    })
                    .catch(error => {
                        SetModalButtonLoader(false)
                    })
            } else {
                var name = ''
                for (var i in documentList) {
                    if (documentList[i].id == formFields.document_type) {
                        name = documentList[i].name
                    }
                }
                for (var i in addDocuments) {
                    if (i == editIndex) {
                        addDocuments[i].document_id = formFields.document_type,
                            addDocuments[i].document_type_name = name,
                            addDocuments[i].file_name = file.name,
                            addDocuments[i].version = formFields.version,
                            addDocuments[i].description = formFields.description
                    }
                }
                setAddDocumentShow(!addDocumentShow)
                setEditIndex(-1)
                setFormFields({ ...formFields, document_type: '', name: '', version: '', description: '' })
                setFile('')
            }
        }
    }

    const onSaveProposal = () => {
        if (addDocuments.length == 0) {
            addToast("Please add documents", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            var method = 'POST'
            var params = JSON.stringify(addDocuments)
            const apifetcherObj = APIMethod(`/proposal/document/${id}`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        getProposalDocuments()
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    }
                })
                .catch(error => {
                    SetModalButtonLoader(false)
                })
        }
    }

    const getContractData = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/contract/section/list/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setContentData(data)
                } else {

                }
            })

            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
            })
    }

    const getUserList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/users/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    var array = [];
                    for (var i in data) {
                        array.push({ label: data[i].first_name + " " + data[i].last_name, value: data[i].id, object: data[i] })
                    }
                    setUsers(array)
                } else {

                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
            })
    }

    const getUserComments = (id) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/users/chat/comment/${id}/0`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    setCommentsData(data)
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
            })
    }

    const deleteProposalDocument = (data) => {
        isShowPopup(true)
        setProposalDocumentDeleteId(data.id)
    }

    const _onEditDocument = (index, data) => {
        setEditDocumentId(data.id)
        setFormFields({ ...formFields, version: data.version, description: data.description, document_type: data.document_id })
        setFile({ name: data.file_name })
        setAddDocumentShow(!addDocumentShow)
        setEditIndex(index)
    }

    const aiQuestionSearch = () => {
        setAiSearchData([])
        setAiSearchButtonLoader(true)
        var method = 'POST'
        var params = JSON.stringify({
            question: formFields.question,
            id: id
        })
        const apifetcherObj = APIMethod(`/proposal/question`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    for (var i in data) {
                        if (data[i].answer) {
                            data[i].answer = data[i].answer.replaceAll("<strong", "<strong style='font-weight:bold'");
                        }
                    }
                    setAiSearchData(data)
                    setAiSearchButtonLoader(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setAiSearchButtonLoader(false)
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setAiSearchButtonLoader(false)
            })
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const onEditAnswer = (data, index) => {
        setAnswer(data)
        answerRef.current = data
        setEditAnswerIndex(index)
        setAnswerModal(!answerModal)
    }

    const onSubmitAnswer = () => {
        for (var i in approverData) {
            if (editAnswerIndex == i) {
                approverData[i].answer = answerRef.current.getContent();
            }
        }
        setApproverData([...approverData])
        setEditAnswerIndex('')
        setAnswerModal(!answerModal)
    }
    const handlePromptChange = (e) => {
        setPrompt(e.target.value);
    }

    const handleGenerate = async () => {
        setProcessing(true);
        if(!prompt.trim()) {
            setError('Prompt text is required⚠️');
            setProcessing(false);
            return;
        }
        
        setError('')
        const p = prompt;
        
        try {
            const response = await fetchOpenAI(p);
            setError('');
            setAnswer(response);
            
          } catch (e) {
            setError('Error generating response⚠️');
            console.log(e);
          }
        setProcessing(false);
    };

    const fetchOpenAI = async (prompt) => {
        const OPENAI_API_KEY = "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"
        const response = await fetch('https://api.openai.com/v1/engines/text-davinci-003/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${OPENAI_API_KEY}`
          },
          body: JSON.stringify({
              prompt: prompt,
              temperature: 0.7,
              max_tokens: 4000,
              top_p: 1,
              frequency_penalty: 0,
              presence_penalty: 0
          })
        });
        
        const data = await response.json();
        console.log(data);
        return data.choices[0].text;
    } 


      

    const onSubmitComment = () => {
        if (selected.length == 0) {
            addToast('Please Select Users', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (formFields.comment == '') {
            addToast('Please Enter Comment', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            formFields.saveButtonLoader = true;
            var array = [];
            for (var i in selected) {
                array.push({ id: selected[i].value, email: selected[i].object.email })
            }
            var method = 'POST'
            var params = JSON.stringify({
                pre_rfx_id: id,
                comment: formFields.comment,
                users: array,
                section_id: sectionId.current,
                subject: `Edit comment on ${sectionTitle.current} (${preRfxData?.title}) `
            })
            const apifetcherObj = APIMethod(`/users/chat/comment`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode == 200) {
                        setSelected([])
                        formFields.comment = ''
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        getUserComments(sectionId.current)
                        formFields.saveButtonLoader = false
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setAiSearchButtonLoader(false)
                    formFields.saveButtonLoader = false
                })
        }
    }
    return (
        <>
            <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={proposalDocumentDeleteId}
                endPoint={'/proposal/document/'}
                updateList={getProposalDocuments}
            ></ModalPopup>

            <div className="page-wrapper form-style">
                <div className="page-content">
                    <div className="mb-3">
                        {
                            location.state &&
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => goBack()}>{location.state.from}</Breadcrumb.Item>
                                <Breadcrumb.Item active>Generate Proposal</Breadcrumb.Item>
                            </Breadcrumb>
                        }
                    </div>
                    <div className="row col-12">
                        <div className="col-3">
                            {location.state && <h2 className="page-title">{location.state.from == 'Proposal Approvals' ? 'Proposal Approval' : 'Proposal'}</h2>}
                        </div>
                        <div className="col-9 row">
                            <h4 className="font-16 font-medium">RFx No. : <span className="text-secondary">{preRfxData?.rfx_number ? preRfxData?.rfx_number : '-'}</span></h4>
                            <h4 className="font-16 font-medium ml-5">RFx Title : <span className="text-secondary">{preRfxData?.title ? preRfxData?.title : '-'}</span></h4>
                        </div>
                    </div>
                    <div className="mb-3">
                        <a href="javascript:void(0)" onClick={CollapseMenu1}>
                            <img src="/images/collapse-icon.svg" width="27" />
                        </a>
                    </div>
                    <div className="text-right my-3">
                        <button className="btn btn-brand-1 btn-sm mr-3 px-4" onClick={() => setEditTemplateModal(!EditTemplateModal)} disabled={preRfxData?.proposal_status == 'PENDING' || preRfxData?.proposal_status == 'PENDING_APPROVAL' ? false : true}>Edit Template</button>
                        <button className="btn btn-brand-1 btn-sm mr-3 px-4" onClick={() => history.push({ pathname: '/generate-word-template', state: { id: id } })} >Generate Proposal Document <span className="ml-2 font-22 align-middle"></span></button>
                    </div>
                    <div className="d-flex overflow-auto pb-4" >
                        <div className="tab-style-2 mb-4" id="PraposalTemp">
                            <Tab.Container id="controlled-tab-example2" defaultActiveKey="SearchRep">
                                <Row>
                                    <div className="tab-style-1  col-12 d-flex">
                                        <Nav className="d-flex  nav nav-tabs ">
                                            <Nav.Item className="mr-3">
                                                <img src={require('../../../../assets/css/images/search.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton1' title="Search Content Repo" width={30} height={30} />
                                                <div id='hideCollapseText1'>
                                                    <Nav.Link eventKey="SearchRep" >Search Content Repo</Nav.Link>
                                                </div>
                                            </Nav.Item>
                                            <Nav.Item className="mr-3">
                                                <img src={require('../../../../assets/css/images/data-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton2' title="RFx Data" width={30} height={30} />
                                                <div id='hideCollapseText2'>
                                                    <Nav.Link eventKey="RfxData" >RFx Data</Nav.Link>
                                                </div>
                                            </Nav.Item>
                                            <Nav.Item className="mr-3">
                                                <img src={require('../../../../assets/css/images/view-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton3' title="View RAW RFx" width={30} height={30} />
                                                <div id='hideCollapseText3'>
                                                    <Nav.Link eventKey="RawRFx">View RAW RFx</Nav.Link>
                                                </div>
                                            </Nav.Item>
                                            <Nav.Item className="mr-3">
                                                <img src={require('../../../../assets/css/images/section-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton4' title="View Sectionized RFx" width={30} height={30} />
                                                <div id='hideCollapseText4'>
                                                    <Nav.Link eventKey="ViewSectionizedRfx">View Sectionized RFx</Nav.Link>
                                                </div>
                                            </Nav.Item>
                                            <Nav.Item className="mr-3">
                                                <img src={require('../../../../assets/css/images/pre-define-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2 mb-3' id='hideCollapseButton5' title="Pre-Define Sections of RFx" width={30} height={30} />
                                                <div id='hideCollapseText5'>
                                                    <Nav.Link eventKey="PreDefineSectionsofRfx">Pre-Defined Sections of RFx</Nav.Link>
                                                </div>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <Col sm={12} className="tab-style-2">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="SearchRep">
                                                <h4 className="font-16">Content Library Search</h4>
                                                <div className="form-wraper form-style mb-4">

                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-12  mb-4">
                                                            <div className="form-group">
                                                                <label className="font-bold">Topic/Question</label>
                                                                <input type="text" className="form-control mb-4" value={formFields['question']} name="question" onChange={handleChange} />

                                                            </div>
                                                            <div className="text-center">
                                                                <button type="button" className="btn btn-lg btn-brand-1  mx-auto" onClick={() => aiQuestionSearch()} disabled={aiSearchButtonLoader || formFields.page == 'View' ? true : false}> {aiSearchButtonLoader && (
                                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                                )}Search</button>
                                                            </div>
                                                            <div className="col-12 my-3" style={{ position: 'relative', right: 27 }}>
                                                                <p className="mb-0 text-primary">Search result matching the Question</p>
                                                                <table className="table table-style-1 table-responsive-lg mb-4 table " style={{ position: 'relative', right: 27 }}>
                                                                    <tr>
                                                                        <th>Question</th>
                                                                        <th>Answer</th>
                                                                        <th>Score</th>
                                                                    </tr>
                                                                    {
                                                                        aiSearchData.map((res) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{res.question}</td>
                                                                                    <td><div dangerouslySetInnerHTML={{ __html: res.answer }}></div></td>
                                                                                    <td>{res.percentage}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </table>
                                                                {
                                                                    searchData.length === 0 ?
                                                                        <div className="col-12" style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                                                            <span>No Search Data Found</span>
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="font-bold mb-0">Search by Keyword</label>
                                                                <input type="text" className="form-control" value={formFields['keyword']} name="keyword" onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <label>RFx Category</label>
                                                                <select id="disabledSelect" className="form-control custom-select" value={formFields['rfxCategory']} name="rfxCategory" onChange={handleChange}>
                                                                    <option value="" disabled selected>Select your option</option>
                                                                    {
                                                                        RFxCategoryData.map((res) => {
                                                                            return (
                                                                                <option value={res.id}>{res.name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <label>Keywords/Tags</label>
                                                                <input type="text" className="form-control" value={formFields['tag']} name="tag" onChange={handleChange} />
                                                            </div>
                                                        </div> */}

                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label>Content Entered From Date</label>
                                                                <DatePicker
                                                                    selected={fromDate}
                                                                    onChange={(date) => setFromDate(date)}
                                                                    className="form-control"
                                                                    placeholder="select Date"
                                                                    dateFormat="MM-dd-yyyy"
                                                                    scrollableYearDropdown
                                                                    showYearDropdown
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label>Content Entered To Date</label>
                                                                <DatePicker
                                                                    selected={toDate}
                                                                    onChange={(date) => setToDate(date)}
                                                                    className="form-control"
                                                                    placeholder="select Date"
                                                                    dateFormat="MM-dd-yyyy"
                                                                    scrollableYearDropdown
                                                                    showYearDropdown
                                                                />
                                                            </div>
                                                        </div>



                                                        <div className="col-12 text-center mt-4">
                                                            <button type="button" className="btn btn-lg btn-brand-1 mr-3" onClick={() => _onSearch()} disabled={SearchbuttonLoader || formFields.page == 'View' ? true : false}>
                                                                {SearchbuttonLoader && (
                                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                                )}
                                                                <span>Search</span>
                                                            </button>
                                                        </div>


                                                        <div className="col-12 my-3">
                                                            <p className="mb-0 text-primary">Search result matching keyword in Question Repository</p>
                                                            <table className="table table-style-1 table-responsive-lg mb-4 table ">
                                                                <tr>
                                                                    <th>Question</th>
                                                                    <th>Answer</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                {
                                                                    searchData.map((res) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{res.question.replace(/(.{20})..+/, "$1…")}</td>
                                                                                <td><div dangerouslySetInnerHTML={{ __html: res.answer }}></div></td>
                                                                                <td>
                                                                                    <button className="btn btn-action" onClick={() => onViewQA(res)}>
                                                                                        <i className="icofont-eye-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </table>
                                                            {
                                                                searchData.length === 0 ?
                                                                    <div className="col-12" style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                                                        <span>No Search Data Found</span>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                        <div className="col-10 my-3">
                                                            <table className="table table-style-1 table-responsive-lg mb-4">
                                                                <tr>
                                                                    <th>File Name</th>
                                                                    <th>RFP Title</th>
                                                                    <th>Client Name</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                {
                                                                    documentData.map((res) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{res.file === undefined ? null : res.file.substring(res.file.lastIndexOf('/') + 1).replace(/\-/, '|').split('|')[1]}</td>
                                                                                <td>{res.title.replace(/(.{20})..+/, "$1…")}</td>
                                                                                <td>{res.name.replace(/(.{30})..+/, "$1…")}</td>
                                                                                <td>
                                                                                    <button className="btn btn-action" onClick={() => window.open(res.file, "_blank")}>
                                                                                        <i className="icofont-eye-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </table>
                                                            {
                                                                documentData.length === 0 ?
                                                                    <div className="col-12" style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                                                        <span>No Search Document Found</span>
                                                                    </div> : null
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="RfxData">
                                                <RfxData
                                                    page={props.location.state && props.location.state.page == 'View' ? '/view-parse-rfx-detail' : null}
                                                    type={'AI'}
                                                    id={props.location.state && id} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="RawRFx">
                                                {
                                                    url ?
                                                        <RawRFx
                                                            url={url}
                                                        /> : null
                                                }
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="ViewSectionizedRfx">
                                                {props.location.state && <SectionizedRFx
                                                    rfx_id={id}
                                                   //Added this code for  WOII-72. Divya. September 11 2023.. Code Start
                                                    height={'1000'}
                                                    //Divya. September 11 2023. Code End
                                                    page={props.location.state && formFields.page == 'View' ? '/view-parse-rfx-detail' : null} />}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="PreDefineSectionsofRfx">
                                                <h4 className="font-16">View Pre-Defned Sections</h4>
                                                <div className="form-wraper form-style mb-4">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <select id="disabledSelect" className="form-control custom-select" value={formFields['predefine_sections']} name="predefine_sections" onChange={handleChange}>
                                                                    <option value="" disabled selected>Select your option</option>
                                                                    <option value="scope">Scope</option>
                                                                    <option>Requirements check list</option>
                                                                    <option value="proposal_structure">Proposal Structure</option>
                                                                    <option value="submission_structure">Submission instructions</option>
                                                                    <option value="evaluation_criteria">Evalution Criteria</option>
                                                                    <option value="contract">Contract</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                {/* {aiSegmentationData?.[formFields.predefine_sections] ? aiSegmentationData?.[formFields.predefine_sections] : `No Data Available`} */}
                                                                <Editor
                                                                    apiKey={tiny_key}
                                                                    // onInit={(evt, editor) => proposalStructureRef.current = editor}
                                                                    initialValue={aiSegmentationData?.[formFields.predefine_sections] ? aiSegmentationData?.[formFields.predefine_sections] : `No Data Available`}
                                                                    disabled={true}
                                                                    init={{
                                                                        height: 500,
                                                                        menubar: 'file edit view insert format tools table tc help',
                                                                        selector: 'textarea#full-featured',
                                                                        plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                        <div style={{ minWidth: "1020px", marginLeft: 50 }}>

                            <div className="tab-style-2 mb-4" id="PraposalTemp">
                                <Tab.Container id="controlled-tab-example2" defaultActiveKey="ProposalStructure">
                                    <Row>
                                        <div className="tab-style-1  col-12 d-flex">
                                            <Nav className="d-flex  nav nav-tabs ">
                                                <Nav.Item className="mr-3">
                                                    <Nav.Link eventKey="ProposalStructure">Proposal Structure</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <Col sm={12} className="tab-style-2">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="ProposalStructure">
                                                    <div style={{ width: 'auto', backgroundColor: 'white' }} className="mb-4">
                                                        <Tab.Container id="left-tab-example" defaultActiveKey="0">
                                                            <Row >
                                                                <table className="table table-style-1 mb-4 table-md-responsive" id="" style={{ width: '97.2%', marginLeft: 14 }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Heading/Title</th>
                                                                            <th scope="col">Description</th>
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                                <Col md={4} style={{ marginTop: 10, marginLeft: 20 }} className='h-auto'>
                                                                    <Nav variant="pills" className="flex-column vertical-tabs">
                                                                        {/* Commenting the following code for WOII-72. Divya. September 11 2023.. Code Start */}
                                                                        {/*<div style={{ overflow: 'scroll', height: 410 }}>*/}
                                                                        {/* Commenting end. Divya. September 11 2023.. */}
                                                                        {/*Added this code for  WOII-72. Divya. September 11 2023.. Code Start*/}
                                                                        <div style={{ overflow: 'scroll', height: 510 }}>
                                                                        {/*Divya. September 11 2023. Code End*/}
                                                                            {
                                                                                approverData?.map((res, i) => {
                                                                                    return (
                                                                                        <Nav.Item style={{ flexDirection: 'row' }}>
                                                                                            <Nav.Link eventKey={i}>{res.title}</Nav.Link>
                                                                                            <div className="col-12 row" >
                                                                                                <div className="col-4">
                                                                                                    <span>Completed</span>
                                                                                                    <input type="checkbox" className="ml-1" value={res.is_check} defaultChecked={res.is_check} onChange={() => onCheckboxClick(i)} disabled={(props.location.state && props.location.state.page == 'View') || (props.location.state && props.location.state.status == 'PIC') || (props.location.state && formFields.page == 'Approval') ? true : false} />
                                                                                                </div>
                                                                                                <div className="col-8" >
                                                                                                    <select id="disabledSelect" className="font-14 ml-4 mb-2" style={{ backgroundColor: 'white', height: 40, borderColor: '#e6e6e6', borderRadius: 6 }} value={res.section} name={res.section} onChange={e => onSectionChange(i, e.target.value, 'section')} disabled={true}>
                                                                                                        <option value="" disabled selected>Select your option</option>
                                                                                                        <option value={'Section-Level 1'}>Section-Level 1</option>
                                                                                                        <option value={'Section-Level 2'}>Section-Level 2</option>
                                                                                                        <option value={'Section-Level 3'}>Section-Level 3</option>
                                                                                                        <option value={'Section-Level 4'}>Section-Level 4</option>
                                                                                                        <option value={'Question'}>Question</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Nav.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </Nav>
                                                                </Col>
                                                                <Col style={{ width: '50%' }}>
                                                                    <Tab.Content>
                                                                        {
                                                                            approverData?.map((res, j) => {
                                                                                return (
                                                                                    <Tab.Pane eventKey={j}>
                                                                                        {
                                                                                            approverData ?
                                                                                                <div>
                                                                                                    {/* <div className="bg-white py-4 px-lg-4 px-md-4 border rounded-theme " style={{ height: 150 }}>
                                                                                                        <div className="form-group" style={{ overflow: 'scroll', height: 120 }} dangerouslySetInnerHTML={{ __html: res.description }} ></div>
                                                                                                    </div> */}
                                                                                                    <Editor
                                                                                                        apiKey={tiny_key}
                                                                                                        // onInit={(evt, editor) => proposalStructureRef.current = editor}
                                                                                                        initialValue={res.description}
                                                                                                        disabled={true}
                                                                                                        init={{
                                                                                                            height: 500,
                                                                                                            menubar: 'file edit view insert format tools table tc help',
                                                                                                            selector: 'textarea#full-featured',
                                                                                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                                                        }}
                                                                                                    />
                                                                                                    {/*Commenting the following code for WOII-95. Divya. September 20 2023.. Code Start */}
                                                                                                    {/*<div className="d-flex row justify-content-between">
                                                                                                        <h3 className="page-title" style={{ marginTop: 30 }}>Answer</h3>
                                                                                                        <div>
                                                                                                            <button type="button" className="btn btn-action edit mt-2 mr-3" onClick={() => { setCommentModal(true), getUserComments(res.id), sectionId.current = res.id, sectionTitle.current = res.title }}><img src={sendComment} style={{ marginTop: 5 }} /></button>
                                                                                                            <button type="button" className="btn btn-action edit mt-3 mr-3" style={{ backgroundColor: '#3c7bfd', height: 33, color: '#fff', width: 33, padding: 0 }} disabled={(props.location.state && props.location.state.page == 'View') || (props.location.state && formFields.page == 'Approval') || (props.location.state && props.location.state.status == 'PIC') ? true : false} onClick={() => onEditAnswer(res.answer, j)}><i className="icofont-pencil-alt-2"></i></button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <div className="bg-white px-lg-4 px-md-4 border rounded-theme mt-1" > */}
                                                                                                    {/* <div className="form-group" style={{ overflow: 'scroll', height: 400 }} dangerouslySetInnerHTML={{ __html: res.answer }}></div>
                                                                                                    <Editor
                                                                                                        apiKey={tiny_key}
                                                                                                        // onInit={(evt, editor) => proposalStructureRef.current = editor}
                                                                                                        initialValue={res.answer}
                                                                                                        disabled={true}
                                                                                                        init={{
                                                                                                            height: 500,
                                                                                                            menubar: 'file edit view insert format tools table tc help',
                                                                                                            selector: 'textarea#full-featured',
                                                                                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                                                        }}
                                                                                                    />
                                                                                                    {/* </div> */} 
                                                                                                    {/*Commenting end. Divya. September 20 2023.. */}
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </Tab.Pane>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Tab.Content>

                                                                </Col>
                                                                {/*Added this code for  WOII-95. Divya. September 20 2023.. Code Start */}
                                                                <Row className="mt-4 ml-2">
                                                                    <Tab.Content>
                                                                        {
                                                                            approverData?.map((res, j) => {
                                                                                return (
                                                                                    <Tab.Pane eventKey={j}>
                                                                                        {
                                                                                            approverData ? 
                                                                                                <div> 
                                                                                                    <div className="row d-flex justify-content-between">
                                                                                                        <h3 className="page-title" style={{ marginLeft:400,marginBottom:30}}>Answer</h3>
                                                                                                        <div>
                                                                                                            <button type="button" className="btn btn-action edit mr-3" onClick={() => { setCommentModal(true), getUserComments(res.id), sectionId.current = res.id, sectionTitle.current = res.title }}><img src={sendComment}/></button>
                                                                                                            <button type="button" className="btn btn-action edit mr-4" style={{ backgroundColor: '#3c7bfd', height: 33, color: '#fff', width: 33, padding: 0 }} disabled={(props.location.state && props.location.state.page == 'View') || (props.location.state && formFields.page == 'Approval') || (props.location.state && props.location.state.status == 'PIC') ? true : false} onClick={() => onEditAnswer(res.answer, j)}><i className="icofont-pencil-alt-2"></i></button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <Editor
                                                                                                        apiKey={tiny_key}
                                                                                                        initialValue={res.answer}
                                                                                                        disabled={true}
                                                                                                        init={{
                                                                                                            height: 500,
                                                                                                            menubar: 'file edit view insert format tools table tc help',
                                                                                                            selector: 'textarea#full-featured',
                                                                                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                                                        }}
                                                                                                    />
                                                                                                </div> 
                                                                                                :
                                                                                                null
                                                                                        } 
                                                                                    </Tab.Pane> 
                                                                                )
                                                                            })
                                                                        }
                                                                    </Tab.Content>          
                                                                </Row>
                                                                {/*Divya. September 20 2023. Code End*/}
                                                            </Row>
                                                        </Tab.Container>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                            {
                                <div className="col-12">
                                    <div className="card">
                                    {/* Commenting the following code for WI-2. Divya. June 19 2023.. Code Start */}
                                        {/*<div className="card-header">*/}
                                    {/* Commenting end. Divya. June 19 2023.. */}
                                    {/* Added this code for WI-2. Divya. June 19 2023.. Code Start */}
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                    {/* Divya. June 19 2023. Code End */}
                                            <h4 className="font-16 mb-0">Comments</h4>
                                            {/* Added this code for WI-2. Divya. June 19 2023.. Code Start */}
                                            <button className="btn btn-sm" type="button" style={{fontSize: '20px',border:'solid'}} onClick={handleToggleCollapse}>{isCollapsed ? "+":"-"}</button>
                                            {/* Divya. June 19 2023. Code End */}
                                            </div>
                                        {/*added this "!isCollapsed && (" line for WOII-69 on August 21 2023 Divya*/}
                                        {!isCollapsed && ( 
                                        //Divya August 21 2023 code end
                                        <div className="card-body bg-white">
                                            {
                                                commentData.map((res, index) => {
                                                    return (
                                                        <div key={index} className="bg-light p-2 rounded mb-2">
                                                            <p className="mb-1">
                                                                <span className="font-medium mr-2">{res.user.first_name} {res.user.last_name}</span>
                                                                <span className="text-secondary font-12 mr-3">{moment(res.created_date).format("D-MMM-YYYY h:mm a")}</span>
                                                            </p>
                                                            <p className="font-14 mb-0 ">{res.comment}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/*added this "!isCollapsed && (" line for WI-2 0n JUNE 19 2023 Divya*/}  
                                            {/* Commenting the following code for WOII-69. Divya. August 21 2023.. Code Start */}                                                                                 
                                            {/*{!isCollapsed && ( */}  
                                            {/* Commenting end. Divya. August 21 2023.. */}        
                                                <div className="form-group form-style">
                                                    <label>Add Comment</label>
                                                    <textarea className="form-control h-auto mb-2" rows="5" placeholder="Write here.." onChange={handleChange} name="comment" value={formFields['comment']}></textarea>
                                                    <button type="button" className="btn btn-brand-1 btn-sm" onClick={() => addComment()} disabled={formFields.commentAddLoader || (props.location.state && props.location.state.page == 'View')}>
                                                        {formFields.commentAddLoader && (
                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                        )}
                                                        Post <i class="icofont-paper-plane"></i></button>
                                                </div>
                                            {/* Commenting the following code for WOII-69. Divya. August 21 2023.. Code Start */}                                         
                                           {/* )} */}
                                            {/* Commenting end. Divya. August 21 2023.. */} 
                                        </div>
                                        //added this code for WOII-69 on August 21 2023 Divya
                                        )}
                                        {/*Divya August 21 2023 code end*/}
                                    </div>
                                </div>
                            }


                            <div className="col-12 my-3">
                                <div className="d-flex flex-row justify-content-between">
                                    {/* Commenting the following code for WOII-73. Divya. September 04 2023.. Code Start */}
                                    {/*<p className="font-medium">Upload Proposal</p>*/}
                                    {/* Commenting end. Divya. September 04 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 04 2023.. Code Start */}
                                    <p className="font-20 font-bold">Upload Proposal</p>
                                    {/* Divya. September 04 2023. Code End */}
                                    <button type="button" className="btn btn-brand-1 btn-h-40 bg-success px-4 mb-4" disabled={props.location.state && props.location.state.page == 'View'} onClick={() => setAddDocumentShow(!addDocumentShow)}>+ Add</button>
                                </div>
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">
                                    <tr>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        <th>Version</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                    {
                                        addDocuments.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{res.document_type_name}</td>
                                                    <td>{res.file_name}</td>
                                                    <td>{res.version}</td>
                                                    <td>{res.description}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-action" onClick={() => window.open(res.file, "_blank")}><i className="icofont-eye-alt"></i></button>
                                                        {(props.location.state && props.location.state.page == 'Approval') || (props.location.state && props.location.state.page == 'View') ? null : <button type="button" className="btn btn-action edit mr-1 ml-1" onClick={() => _onEditDocument(i, res)}><i className="icofont-pencil-alt-2"></i></button>}
                                                        {(props.location.state && props.location.state.page == 'Approval') || (props.location.state && props.location.state.page == 'View') ? null : <button type="button" className="btn btn-action delete" onClick={() => deleteProposalDocument(res)}><i className="icofont-trash"></i></button>}

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </table>
                            </div>
                            <div className="d-flex justify-content-center">

                                <div className="">
                                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger ml-3" onClick={() => goBack()}>Cancel</button>
                                    {(props.location.state && props.location.state.status == 'PIA') ? <button type="button" className="btn btn-lg btn-brand-1 ml-3 bg-success" disabled={props.location.state && props.location.state.page == 'View'} onClick={() => onUpdateStatus('PROPOSAL_COMPLETED')}> {formFields.submitButtonLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}Submit</button> : null}
                                    {(props.location.state && props.location.state.status == 'PIA') || (props.location.state && props.location.state.status == 'Proposal Sent for Approval') ? null : <button type="button" className="btn btn-lg btn-brand-1 ml-3 mr-3 bg-success" disabled={(props.location.state && props.location.state.page == 'Approval') || (props.location.state && props.location.state.status) == 'PIA' || (props.location.state && props.location.state.status) == 'PIC' || formFields.saveButtonLoader || (props.location.state && props.location.state.page == 'View') ? true : false} onClick={() => onSubmit()}>{formFields.saveButtonLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}Save</button>}
                                    {(props.location.state && (formFields.page == 'Proposal' || formFields.page == 'Edit')) && (props.location.state && props.location.state.status) != 'PIA' ?
                                        <button className="btn btn-brand-1  px-4" href="/pre-rfx-approvals-filters" onClick={() => _onSendForApproval()} disabled={formFields.sendForApprovalLoader || (props.location.state && props.location.state.status) == 'PIA' || (props.location.state && props.location.state.status) == 'PIC' || (props.location.state && props.location.state.status) == 'PSA'}>
                                            {formFields.sendForApprovalLoader && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}Send for Approval</button> : null}
                                    {(props.location.state && formFields.page == 'Approval') ? <button type="button" className="btn btn-lg btn-brand-1" onClick={() => onSubmit('PUSHBACK')}>PushBack</button> : null}
                                    {(props.location.state && formFields.page == 'Approval') ? <button type="button" className="btn btn-lg btn-brand-1 bg-success ml-3" onClick={() => onSubmit('PROPOSAL_APPROVED')}>Approve</button> : null}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* Add Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={439}
                top={665}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setShow(false)}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Details</h5>
                    <button type="button" className="close" onClick={() => setShow(false)}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">

                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Question</label>
                                <textarea className="form-control h-auto" id="" cols="30" rows="2" name="modalQuestion" value={formFields['modalQuestion']}></textarea>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Answer</label>
                                <textarea className="form-control h-auto" id="" cols="30" rows="3" name="modalAnswer" value={formFields['modalAnswer'].replace(regex, '')}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => setShow(false)}>Cancel</button>
                </div>
            </ReactModal>


            {/* MODAL */}
            <ReactModal
                initWidth={500}
                initHeight={'auto'}
                top={900}
                disableKeystroke={true}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setAddDocumentShow(!addDocumentShow)}
                isOpen={addDocumentShow}
                disableResize={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{editDocumentId ? 'Edit' : 'Add'} Documents</h5>
                    <button type="button" className="close" onClick={() => onCancelModal()}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Version</label>
                                <input type="text" className="form-control" onChange={handleChange} name="version" value={formFields['version']} />
                                <span className="text-danger">{modalFlag === true && formFields.version === '' ? 'Please Enter Version' : null}</span>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="description" value={formFields['description']}></textarea>
                                    <span className="text-danger">{modalFlag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Document Type</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['document_type']} name="document_type" onChange={handleChange}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        documentList.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{modalFlag === true && formFields.document_type_id === '' ? 'Please Select Document Type' : null}</span>
                            </div>
                            <div className="form-group">
                                <label>Upload File</label>
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="inputGroupFile02" onChange={filehandler} />
                                    <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file === undefined ? 'Choose File' : file.name}</label>
                                    <span className="text-danger">{modalFlag === true && file === undefined ? 'Please Select file' : null}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => onCancelModal()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onAddDocument()} disabled={modalButtonLoader}>
                        {modalButtonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>



            <Modal show={EditTemplateModal} onHide={() => setEditTemplateModal(!EditTemplateModal)}

                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Alert</h5>
                </div>
                <Modal.Body>
                    <div>
                        <span>If you click OK, you will loose Answers you might have entered and template will have to go through approval again.</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditTemplateModal(!EditTemplateModal)} style={{ width: 100 }}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => history.push({ pathname: `/edit-proposal-template/${id}`, state: { detail: formFields.preRfxId, title: props.location.state.rfx_title, rfx_number: props.location.state.rfx_number } })} style={{ width: 100 }}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Answer Modal */}
            <ReactModal
                initHeight={'auto'}
                top={505}
                left={400}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setAnswerModal(false)}
                isOpen={answerModal}
                disableResize={true}
                disableKeystroke={true}>
                <Container className="px-4">
                    <div className="modal-header">
                        <h5 className="modal-title" id="">Edit/ Generate Answer</h5>
                        <button type="button" className="close" onClick={() => setAnswerModal(false)}>
                            <i className="icofont-close"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row form-style">

                            <div className="form-group" style={{ width: "950px", marginLeft: 25 }}>
                                <Row>
                                    <Col md={12} lg={12} sm={12} className="form-group">
                                        <label for="exampleFormControlTextarea1"><h6 style={{display:"inline", fontWeight:"bold", color:"brown"}}>Your Prompt </h6><span>(Create your prompt for the model to create different answer.)</span></label>
                                        {error && <p className="error alert alert-danger" role="alert" style={{fontWeight:"bold"}}>{error}</p>}
                                        {/* <textarea className="form-control border-light" id="exampleFormControlTextarea1" rows="5" style={{height:"170px",borderWidth: '3px'}} onChange={handlePromptChange} value={'As a way of preparing a resonse to '+ preRfxData?.title ? preRfxData?.title : '-'+' RFP, provide answers to the following RFP Questions. RFP is issued by Solomons International. Provide this proposal on behalf of Solomons International.\nRFP question:'}></textarea> */}
                                        <textarea className="form-control border-light" id="exampleFormControlTextarea1" rows="5" style={{height:"170px",borderWidth: '3px'}} onChange={handlePromptChange} value={prompt} required></textarea>
                                    </Col>
                                </Row>
                                <Row className="justify-content-end">
                                    <Col md={3} lg={3} sm={6}>
                                        <button type="button" className="btn btn-brand-1 bg-success mb-4" onClick={handleGenerate} disabled={processing} style={{'&:focus': {backgroundColor: '#218838' }}}>{processing ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                        ) : (
                                        'Generate'
                                        )}
                                        </button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} lg={12} sm={12}>
                                        <label><h6 style={{color:"brown", fontWeight:"bold"}}>Model Response</h6></label>
                                        <Editor
                                            apiKey={tiny_key}
                                            onInit={(evt, editor) => answerRef.current = editor}
                                            initialValue={answer}
                                            init={{
                                                height: 500,
                                                menubar: 'file edit view insert format tools table tc help',
                                                selector: 'textarea#full-featured',
                                                plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => setAnswerModal(!answerModal)}>Cancel</button>
                        <button type="button" className="btn btn-lg btn-brand-1 ml-3 mr-3 bg-success" onClick={() => onSubmitAnswer()}>{formFields.saveButtonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}Save</button>
                    </div>
                </Container>
            </ReactModal>



            {/* Comment Modal */}
            <ReactModal
                initWidth={1000}
                initHeight={'auto'}
                top={200}
                left={400}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setCommentModal(false)}
                isOpen={commentModal}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Comments</h5>
                    <button type="button" className="close" onClick={() => setCommentModal(false)}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">

                        <div className="form-group" style={{ width: "950px", marginLeft: 25 }}>
                            <label>Send Comments to</label>
                            <MultiSelect
                                options={users}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Select"
                            />
                            <label className="mt-2">Comments</label>
                            <textarea className="form-control h-auto mb-2" rows="5" placeholder="Write here.." onChange={handleChange} name="comment" value={formFields['comment']}></textarea>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => setCommentModal(false)}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 ml-3 mr-3 bg-success" onClick={() => onSubmitComment()}>{formFields.saveButtonLoader && (
                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                    )}Send</button>
                </div>
                <div className="comment-history-view">
                    <div className="comment-history-top-view">Comments History</div>
                    <div className="d-flex proposal-comment-view">
                        {
                            commentsData.length > 0 && commentsData.map((res) => {
                                return (
                                    <div className="comment-msg-box">
                                        <div>{res.name} {moment(res.created_date).format('MM-DD-YYYY')}</div>
                                        <div>{res.comment}</div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </ReactModal>
            {
                showChatModal == false && preRfxData?.id &&
                <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                    <img src={chatIcon} />
                </div>
            }
            {
                showChatModal &&
                <Chat
                    id={preRfxData?.id}
                    title={preRfxData?.title}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default GenerateProposalMp;