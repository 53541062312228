import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { APIMethod } from '../API/APIClient'
import CustomLoader from './Loader';

class ModalPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            loading: false
        };
    }

    isShowModal = (status) => {
        this.handleClose();
        this.setState({ showModal: status });
    }

    handleClose = () => {
        this.props.onPopupClose(false);
    }

    delete = () => {
        this.setState({ loading: true });
        var method = 'DELETE'
        const apifetcherObj = APIMethod(this.props.endPoint + this.props.id, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    this.props.updateList('1');
                    this.isShowModal(false)
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                this.setState({ loading: false });
            })
    }

    render() {
        return (
            <>
                <Fragment>
                    <Modal show={this.props.showModalPopup} onHide={this.handleClose}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="sign-in-title">
                                Alert
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ alignSelf: 'center' }}>
                            <div className="ml-5 mr-5">
                                <img src={require('../assets/css/images/cross.png').default} style={{ marginLeft: '37%' }} alt="No image found" id="img_close" width="80" />
                                <p style={{ fontSize: 20, textAlign: 'center' }} className="mt-4">Are You Sure ?</p>
                                <p style={{ textAlign: 'center' }}>Do you really want to delete these records? This process cannot be undone.</p>
                                <button type="button" className="btn btn-light mr-1" style={{ marginLeft: '30%' }} onClick={() => this.isShowModal(false)}> Cancel</button>
                                <button type="button" className="btn btn-danger ml-3" onClick={() => this.delete()}> Delete</button>
                            </div>
                        </Modal.Body>

                    </Modal >
                </Fragment >
            </>
        );
    }
}

export default (ModalPopup);