import React, { useEffect, useState } from 'react';
import './assets/icofont/icofont.min.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/css/main.scss'


import Header from './components/Header'
import { Route, Switch, Redirect } from "react-router-dom";
import { generatePath } from "react-router";

import Login from "./pages/Dashboard/Login/Login";
import Signup from "./pages/Dashboard/Login/Signup";
import SetPassword from "./pages/Dashboard/Login/SetPassword";
import BusninessUnitType from "./pages/Dashboard/Organizational_Setup/Business_Unit_Types/BusninessUnitType";
import BusinessUnit from "./pages/Dashboard/Organizational_Setup/Business_Units/BusninessUnit";
import CompanyCapability from "./pages/Dashboard/Organizational_Setup/Company_Capabilities/CompanyCapability";
import CompanyCapabilityEdit from "./pages/Dashboard/Organizational_Setup/Company_Capabilities/CompanyCapabilityEdit";
import WordTemplates from "./pages/Dashboard/Organizational_Setup/Word_Templates/WordTemplates";
import Companies from "./pages/Dashboard/Organizational_Setup/Companies/Companies";
import CompanyDetails from "./pages/Dashboard/Organizational_Setup/Companies/CompanyDetails";
import AddProduct from "./pages/Dashboard/Organizational_Setup/Company_Capabilities/Products/AddProduct";
import AddService from "./pages/Dashboard/Organizational_Setup/Company_Capabilities/Services/AddService";
import AddProcess from "./pages/Dashboard/Organizational_Setup/Company_Capabilities/Process/AddProcess";
import AddMethodology from "./pages/Dashboard/Organizational_Setup/Company_Capabilities/Methodology/AddMethodology";
import AddQc from "./pages/Dashboard/Organizational_Setup/Company_Capabilities/Quality Control/AddQc";
import AddSecurity from "./pages/Dashboard/Organizational_Setup/Company_Capabilities/Security/AddSecurity";
import RfxDocTypes from "./pages/Dashboard/Organizational_Setup/RFx_Document_Types/RfxDocTypes";
import RfxCategories from "./pages/Dashboard/Organizational_Setup/RFx_Categories/RfxCategories";
import RfxTypes from "./pages/Dashboard/Organizational_Setup/RFx_Types/RfxTypes";
import RfxStatuses from "./pages/Dashboard/Organizational_Setup/RFx_Statuses/RfxStatuses";
import ProposalStatuses from "./pages/Dashboard/Manage_Proposal_Awards/ RFxs-Pending-Award/ProposalStatuses";
import ProposalTemplateStatus from './pages/Dashboard/Organizational_Setup/Proposal_Template_Status/ProposalTemplateStatus';
import SetupQa from "./pages/Dashboard/Content_Library_Setup/Setup_QA/SetupQa";
import AddQa from "./pages/Dashboard/Content_Library_Setup/Setup_QA/AddQa";
import DocumentEntries from "./pages/Dashboard/Content_Library_Setup/Document_Entries/DocumentEntries";
import AddDoc from "./pages/Dashboard/Content_Library_Setup/Document_Entries/AddDoc";
import KeywordEntries from "./pages/Dashboard/Content_Library_Setup/Keyword_Exclusions/KeywordEntries";
import AddKeyword from "./pages/Dashboard/Content_Library_Setup/Keyword_Exclusions/AddKeyword";
import ManageUsers from "./pages/Dashboard/Manage_Security/Manage_Users/ManageUsers";
import AddUser from "./pages/Dashboard/Manage_Security/Manage_Users/AddUser";
import AddUserRoll from "./pages/Dashboard/Manage_Security/Manage_Users/addUserRoll";
import AddNewUser from './pages/Dashboard/Manage_Security/Manage_Users/AddNewUser';
import Role from "./pages/Dashboard/Manage_Security/Manage_Roles/Role"
import AddRole from './pages/Dashboard/Manage_Security/Manage_Roles/AddRole';
import PreRfx from './pages/Dashboard/Manage_Oppurtunities/Pre-RFxs/PreRfxs';
import AddPreRfx from './pages/Dashboard/Manage_Oppurtunities/Pre-RFxs/AddPreRfx';
import PreRfxsApprovals from './pages/Dashboard/Manage_Oppurtunities/Pre-RFxs/PreRfxsApprovals';
import ProposalTemplates from './pages/Dashboard/Manage_Proposal_Awards/Manage-Proposal-Templates/ProposalTemplates';
import ManageProposal from './pages/Dashboard/Manage_Proposal_Awards/Manage-Proposal/ManageProposal';
import AddProposalTemplate from './pages/Dashboard/Manage_Proposal_Awards/Manage-Proposal-Templates/AddProposalTemplate';
import CreateProposalTemplateApproval from './pages/Dashboard/Manage_Proposal_Awards/Manage-Proposal-Templates/CreateProposalTemplateApproval';

import GenerateProposal from './pages/Dashboard/Manage_Proposal_Awards/Manage-Proposal-Templates/GenerateProposal';
import GenerateProposalMp from './pages/Dashboard/Manage_Proposal_Awards/Manage-Proposal/GenerateProposalMp';
import ManageProposalApproval from './pages/Dashboard/Manage_Proposal_Awards/Manage-Proposal-Approvals/ManageProposalApproval';
import ManageProposalTemplateApproval from './pages/Dashboard/Manage_Proposal_Awards/Manage-Proposal-Templates/ManageProposalTemplateApproval';
import ProposalAwards from './pages/Dashboard/Manage_Proposal_Awards/Manage-Proposal-Awards/ProposalAwards';
import AddProposaAwards from './pages/Dashboard/Manage_Proposal_Awards/Manage-Proposal-Awards/AddProposaAwards';
import EditPreRfxApproval from './pages/Dashboard/Manage_Oppurtunities/Pre-RFxs/EditPreRfxApproval';
import RfxList from './pages/Dashboard/Manage_Oppurtunities/RFx/RfxList';
import ParseRfx from './pages/Dashboard/Manage_Oppurtunities/Parse-RFx/ParseRfx';
import RfxParsedDetails from './pages/Dashboard/Manage_Oppurtunities/Parse-RFx/RfxParsedDetails';

import Contracts from './pages/Dashboard/Manage_Contracts/Contracts/Contracts';
import AddContract from './pages/Dashboard/Manage_Contracts/Contracts/AddContract';
import AddTaskOrder from './pages/Dashboard/Manage_Contracts/Contracts/AddTaskOrder';
import EditTaskOrder from './pages/Dashboard/Manage_Contracts/Contracts/EditTaskOrder';
import ParseContract from './pages/Dashboard/Manage_Contracts/Parse-Contract/ParseContract';
import EditCompliance from './pages/Dashboard/Manage_Contracts/Parse-Contract/EditCompliance';
import CreateCompliance from './pages/Dashboard/Manage_Contracts/Parse-Contract/CreateCompliance';
import ViewCompliances from './pages/Dashboard/Manage_Contracts/Parse-Contract/ViewCompliances';
import Compliance from './pages/Dashboard/Manage_Contracts/Parse-Contract/Compliance';
import MyProfile from './pages/Dashboard/Profile/MyProfile';
import GenerateWordTemplate from './pages/Dashboard/Manage_Proposal_Awards/Manage-Proposal-Templates/GenerateWordTemplate';

import Leftnavbar from './components/Leftnavbar';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import EmptyPage from './pages/EmptyPage/EmptyPage';
import { APIMethod } from './API/APIClient';
import Dashboard from './pages/Dashboard/Home/Dashboard';
import ContractApproval from './pages/Dashboard/Manage_Contracts/ContractApproval/ContractApproval';

// import { Token } from './firebase';

function App(props) {
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('token') !== null ? true : false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });

  useEffect(() => {
    signInSuccessHandler();
    window.process = {
      ...window.process,
    };
    // Token(setTokenFound)
    // const onMessageListener = () =>
    // new Promise((resolve) => {
    //   onMessage(messaging, (payload) => {
    //     resolve(payload);
    //   });
    // });
  }, []);


  const signInSuccessHandler = () => {
    const token = localStorage.getItem('token');
    setLoggedIn((token !== null) ? true : false);
  }

  const onLogout = () => {
    localStorage.removeItem('token');
    //  removePushToken()
    setLoggedIn(false);
  }

  const removePushToken = () => {
    var method = 'DELETE'
    const apifetcherObj = APIMethod(`/device-token/${'1234'}`, null, method)
    apifetcherObj
      .then(response => { return Promise.all([response.status, response.json()]) })
      .then(res => {
        let statusCode = res[0]
        let data = res[1]

        if (statusCode === 200) {

        } else {
          addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        }
      })
      .catch(error => {
        addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
      })
  }


  return (
    <>
      <main role="main">
        {
          isLoggedIn ?
            <div className="d-lg-flex" id="main-wraper">
              <Leftnavbar logoutHandler={onLogout} />
              <Header toShow={true} logoutHandler={onLogout} />
              <Switch>
                <Route exact path="/business-unit-type" component={BusninessUnitType}></Route>
                <Route exact path="/business-unit" component={BusinessUnit}></Route>
                <Route exact path="/companies" component={Companies}></Route>
                <Route exact path="/add-company-details" component={CompanyDetails}></Route>
                <Route exact path="/edit-company-details/:id" component={CompanyDetails}></Route>
                <Route path="/company-capability" component={CompanyCapability}></Route>
                <Route path="/edit-company-capability" component={CompanyCapabilityEdit}></Route>
                <Route path="/add-product" component={AddProduct}></Route>
                <Route path="/edit-product/:id" component={AddProduct}></Route>
                <Route path="/add-service" component={AddService}></Route>
                <Route path="/edit-service/:id" component={AddService}></Route>
                <Route path="/add-process" component={AddProcess}></Route>
                <Route path="/edit-process/:id" component={AddProcess}></Route>
                <Route path="/add-methodology" component={AddMethodology}></Route>
                <Route path="/edit-methodology/:id" component={AddMethodology}></Route>
                <Route path="/add-qc" component={AddQc}></Route>
                <Route path="/edit-qc/:id" component={AddQc}></Route>
                <Route path="/add-security" component={AddSecurity}></Route>
                <Route path="/edit-security/:id" component={AddSecurity}></Route>
                <Route path="/rfx-cocument-types" component={RfxDocTypes}></Route>
                <Route path="/rfx-categories" component={RfxCategories}></Route>
                <Route path="/rfx-types" component={RfxTypes}></Route>
                <Route path="/rfx-statuses" component={RfxStatuses}></Route>
                <Route path="/proposal-statuses" component={ProposalStatuses}></Route>
                <Route path="/proposal-template-status" component={ProposalTemplateStatus}></Route>
                <Route path="/setup-q-a" component={SetupQa}></Route>
                <Route path="/add-q-a" component={AddQa}></Route>
                <Route path="/edit-q-a/:id" component={AddQa}></Route>
                <Route path="/document-entries" component={DocumentEntries}></Route>
                <Route path="/add-doc" component={AddDoc}></Route>
                <Route path="/keyword-entries" component={KeywordEntries}></Route>
                <Route path="/add-keyword" component={AddKeyword}></Route>
                <Route path="/manage-users" component={ManageUsers}></Route>
                <Route path="/add-user" component={AddNewUser}></Route>
                <Route path="/add-user-role" component={AddNewUser}></Route>
                <Route path="/edit-user-role/:id" component={AddNewUser}></Route>

                <Route path="/role" component={Role}></Route>
                <Route path="/add-role" component={AddRole}></Route>
                <Route path="/edit-role/:id" component={AddRole}></Route>


                <Route path="/pre-rfx" component={PreRfx}></Route>
                <Route path="/add-pre-rfx" component={AddPreRfx}></Route>
                <Route path="/edit-pre-rfx/:id" component={AddPreRfx}></Route>
                <Route path="/approve-pre-rfx/:id" component={AddPreRfx}></Route>
                <Route path="/edit-pre-rfx-approval/:id" component={EditPreRfxApproval}></Route>
                <Route path="/rfx-list" component={RfxList}></Route>
                <Route path="/parse-rfx" component={ParseRfx}></Route>
                <Route path="/parse-rfx-detail" component={RfxParsedDetails}></Route>
                <Route path="/view-parse-rfx-detail" component={RfxParsedDetails}></Route>


                <Route path="/manage-proposal-templates" component={ProposalTemplates}></Route>
                <Route path="/manage-proposal" component={ManageProposal}></Route>
                <Route path="/manage-proposal-approval" component={ManageProposalApproval}></Route>
                <Route path="/manage-proposal-templates-approval" component={ManageProposalTemplateApproval}></Route>
                <Route path="/add-proposal-template/:id" component={AddProposalTemplate}></Route>
                <Route path="/edit-proposal-template/:id" component={AddProposalTemplate}></Route>
                <Route path="/edit-approval-proposal-template/:id" component={AddProposalTemplate}></Route>
                <Route path="/create-proposal-template-approval" component={CreateProposalTemplateApproval}></Route>
                <Route path="/genrate-proposal" component={GenerateProposal}></Route>
                <Route path="/generate-word-template" component={GenerateWordTemplate}></Route>
                <Route path="/mp-genrate-proposal/:id" component={GenerateProposalMp}></Route>
                <Route path="/mp-approval-genrate-proposal/:id" component={GenerateProposalMp}></Route>
                <Route path="/manage-proposal-awards" component={ProposalAwards}></Route>
                <Route path="/add-proposal-awards" component={AddProposaAwards}></Route>
                <Route path="/edit-proposal-awards" component={AddProposaAwards}></Route>

                <Route path="/contracts" component={Contracts}></Route>
                <Route path="/contracts-approvals" component={ContractApproval}></Route>
                <Route path="/add-contract" component={AddContract}></Route>
                <Route path="/edit-contract" component={AddContract}></Route>
                <Route path="/add-task-order" component={AddTaskOrder}></Route>
                <Route path="/edit-task-order" component={EditTaskOrder}></Route>
                <Route path="/parse-contract" component={ParseContract}></Route>
                <Route path="/create-compliance" component={CreateCompliance}></Route>
                <Route path="/edit-compliance" component={EditCompliance}></Route>
                <Route path="/view-compliance" component={ViewCompliances}></Route>
                <Route path="/Compliance" component={Compliance}></Route>
                <Route path="/pre-rfx-approvals-filters" component={PreRfxsApprovals}></Route>


                <Route path="/dashboard" component={Dashboard}></Route>

                <Route path="/word-template" component={WordTemplates}></Route>
                <Route path="/my-profile" component={MyProfile}></Route>
                <Redirect from="/login" to={{ pathname: '/Error-page' }} />
                <Route path="/error-page" component={ErrorPage} />
                <Redirect from="/" to="/business-unit-type" />

              </Switch>
            </div>
            :
            <Switch>
              <Route exact path="/login">
                <Login signInSuccess={signInSuccessHandler} />
              </Route>
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/set-password" component={SetPassword} />
              <Redirect from="/" to="/login" />
            </Switch>
        }
      </main>
    </>
  );
}

export default App; 
