import React, { useState, useEffect } from "react";
import { Style } from "react-style-tag";
import { useToasts } from 'react-toast-notifications';
import { APIMethod, TOAST_TIMEOUT } from '../../../API/APIClient';
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";

const SetPassword = () => {
    const [formFields, setFormFields] = useState({ password: '', confirmPassword: '' })
    const [linkExpired, setLinkExpired] = useState(false);
    const [token, setToken] = useState('');
    const [message, setMesssage] = useState('');
    const [flag, setFlag] = useState(false);
    // Loader
    const [isLoading, setLoading] = useState(false);
    // Toast
    const { addToast } = useToasts();
    const history = useHistory();

    useEffect(() => {
        getVerifyToken()
    }, [])

    const getVerifyToken = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        setToken(token)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/users/verify/token?token=${token}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setLoading(false)
                } else {
                    setLinkExpired(true)
                    setMesssage(data.message)
                }
            })
            .catch(error => {
                setLinkExpired(true)
                setLoading(false)
            })
    }


    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const submit = () => {
        if (formFields.password == '' || formFields.confirmPassword == '') {
            setFlag(true)
            addToast('Please fill all the fields', { appearance: 'error', autoDismiss: true });
        } else if (formFields.password != formFields.confirmPassword) {
            addToast('Password and confirm password does not match', { appearance: 'error', autoDismiss: true })
            setLoading(false)
        } else {
            setLoading(true)
            var params = JSON.stringify({
                token: token,
                password: formFields.password
            })
            var method = 'PUT'
            const apifetcherObj = APIMethod('/users/set-password', params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode == 200) {
                        setLoading(false)
                        setFormFields({ ...formFields, password: '', confirmPassword: '' })
                        history.push({
                            pathname: '/',
                        })
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }

    return (
        <div className="" id="main-wraper">
            <Style>{`
                .header{
                    display: none;
                }
                .login-img{
                    height: 100vh;width: 100%;object-fit: cover;
                }
                }
                `}</Style>
            <div className="login-page">
                <div className="row mx-0 mt-md-0 mt-5 bg-white">
                    <div className="col-md-5 pl-0 d-md-block d-none">
                        <img src="/images/login-img.png" className="login-img img-fluid" />
                    </div>
                    <div className="col-md-7 align-self-center">
                        {
                            linkExpired == true ?
                                <div className="row justify-content-center">
                                    <div className="col-md-6">
                                        <div className="text-center">
                                            <img src="/images/logo.png" alt="Logo" width="200" className="mb-4" />
                                            <h3>{message}</h3>
                                            <span className="mr-2"></span> <NavLink to="/login" className="border-bottom border-primary">Back to login</NavLink>
                                        </div>
                                    </div>
                                </div> 
                                :
                                <div className="row justify-content-center">
                                    <div className="col-md-6">
                                        <div className="text-center">
                                            <img src="/images/logo.png" alt="Logo" width="200" className="mb-4" />
                                            <h2 className="font-24 font-medium text-secondary mb-3">Welcome to Winopps </h2>
                                            <p className="font-18 text-secondary">Set Your Password</p>
                                        </div>

                                        <div className=" login-form">
                                            <div className="form-group mb-2">
                                                <label>Password</label>
                                                <input type="password" className="form-control" onChange={handleChange} name="password" value={formFields['password']} />
                                                <span className="text-danger">{flag === true && formFields.password === '' ? 'Please Enter Password' : null}</span>
                                            </div>
                                            <div className="form-group mb-2">
                                                <label>Confirm Password</label>
                                                <input type="password" className="form-control" onChange={handleChange} name="confirmPassword" value={formFields['confirmPassword']} />
                                                <span className="text-danger">{flag === true && formFields.confirmPassword === '' ? 'Please Enter Confirm Password' : null}</span>
                                            </div>
                                            <div className="form-group row ">
                                                <div className="col-md-12 mb-2">
                                                    <button type="button" className="btn btn-brand-1 btn-block mt-2" onClick={() => submit()} disabled={isLoading}>
                                                        {isLoading && (
                                                            <img src={require('../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                        )}
                                                        <span>Save</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetPassword;