import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import ModalPopup from '../../../../components/ModalPopUp';
import Pagination from '../../../../components/Pagination';
import { useToasts } from 'react-toast-notifications';
import moment from "moment";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from 'react-modal-resizable-draggable';
import { MENU_BUSINESS_UNITS, MENU_ORGANIZATIONAL_SETUP, roleHelper } from "../../../../utility/utility";


const BusinessUnit = (props) => {
    const [getListData, setGetListData] = useState([]);
    const [formFields, setFormFields] = useState({ businessUnitName: '', businessUnitCode: '', businessUnitType: '', description: '' })
    const [filterOption, setFilterOption] = useState({ businessUnitName: '', businessUnitType: '', fromDate: undefined, toDate: undefined })
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [flag, setFlag] = useState(false);
    const [codeCount, setCodeCount] = React.useState(0);



    const { addToast } = useToasts();
    // Add modal box
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    // Edit
    const [edit, setEdit] = useState('');
    const [id, setId] = useState('');
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    // page
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // Filter modal box
    const [showFilter, setShowFilter] = useState(false);
    const handleCloseFilter = () => {
        setShowFilter(false);
    }
    const handleShowFilter = () => setShowFilter(true);
    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    // cancel Modal
    const [cancelModal, setCancelModal] = useState(false);
    const [adding, setAdding] = useState();
    const [editing, setEditing] = useState();
    const [deleting, setDeleting] = useState();

    useEffect(() => {
        getBusinessUnitList(1)
        var data = JSON.parse(localStorage.getItem("user_Role"))
        checkPermission(data)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const checkPermission = (data) => {
        var valObject = roleHelper(MENU_ORGANIZATIONAL_SETUP, MENU_BUSINESS_UNITS)
        setAdding(valObject.is_create)
        setEditing(valObject.is_update)
        setDeleting(valObject.is_delete)
    }

    const getBusinessUnitList = (page, key) => {
        setLoading(true)
        const new_key = key === undefined ? '' : key
        global.new_key = key === undefined ? '' : key
        const filter_start_date = filterOption.fromDate === undefined ? '' : moment(filterOption.fromDate).format('DD-MM-YYYY')
        const filter_end_date = filterOption.toDate === undefined ? '' : moment(filterOption.toDate).format('DD-MM-YYYY')
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/list/${page}?from_date=${filter_start_date}&to_date=${filter_end_date}&keyword=${new_key || filterOption.businessUnitName}&type_id=${filterOption.businessUnitType}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const formatDate = () => {
        var d = startDate,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    const save = () => {
        if (formFields.description === '' || formFields.businessUnitName === '' || formFields.businessUnitCode === '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        }
        else {
            setButtonLoader(true)
            if (edit === '/') {
                var params = JSON.stringify({
                    id: id,
                    name: formFields.businessUnitName,
                    description: formFields.description,
                    code: formFields.businessUnitCode,
                    start_date: formatDate('Sun May 11,2014'),
                })
                var method = 'PUT'
            } else {
                params = JSON.stringify({
                    name: formFields.businessUnitName,
                    description: formFields.description,
                    code: formFields.businessUnitCode,
                    start_date: formatDate('Sun May 11,2014'),
                })
                method = 'POST'
            }
            const apifetcherObj = APIMethod('/business-units', params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        setShow(false);
                        setFormFields({ ...formFields, businessUnitName: '', description: '', businessUnitCode: '', businessUnitType: '' })
                        getBusinessUnitList(currentPage)
                        setButtonLoader(false)
                        setFlag(false)
                    }
                })
                .catch(error => {
                    addToast(error.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setButtonLoader(false)
                })
        }
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };


    const _onEdit = (val) => {
        handleShow()
        setFormFields({ ...formFields, businessUnitName: val.name, businessUnitCode: val.code, description: val.description, businessUnitType: val.business_unit_type })
        setEdit('/')
        setCodeCount(val.code.length)
        setId(val.id)
    }

    const Search = (key) => {
        getBusinessUnitList(1, key)
    }

    const nextPage = (pageNumber) => {
        getBusinessUnitList(pageNumber)
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const handleFilterChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...filterOption,
            [name]: value
        }
        setFilterOption(formFieldsObject);
    }

    const inputChange = (event) => {
        const value = event.target.value;
        setFormFields({ ...formFields, businessUnitCode: value.split(" ").join("") })
    }

    const onFilterSave = () => {
        getBusinessUnitList(1)
        handleCloseFilter()
    }

    const handleClose = () => {
        if (formFields.businessUnitType === '' || formFields.description === '' || formFields.businessUnitName === '' || formFields.businessUnitCode === '') {
            setShow(false);
            setFlag(false)
            setId('');
            setEdit('');
            setCodeCount(0)
            setFormFields({ ...formFields, businessUnitType: '', description: '', businessUnitName: '', businessUnitCode: '' })
        } else {
            setShow(false);
            setFlag(false)
            setId('');
            setEdit('');
            setCodeCount(0)
            setFormFields({ ...formFields, businessUnitType: '', description: '', businessUnitName: '', businessUnitCode: '' })
        }
    }

    const handleModal = () => {
        setShow(true);
        setCancelModal(!cancelModal)
    }

    const onModalYesClick = () => {
        setCancelModal(!cancelModal)
        setFormFields({ ...formFields, businessUnitName: '', description: '', businessUnitCode: '', businessUnitType: '' })
    }

    const _onResetFilter = (type) => {
        if (type === 'BUSINESS_UNIT_TYPE') {
            filterOption.businessUnitType = "";
        } else if (type === 'BUSINESS_UNIT_NAME') {
            filterOption.businessUnitName = "";
        } else if (type === 'FROM_DATE') {
            filterOption.fromDate = undefined;
        } else if (type === 'TO_DATE') {
            filterOption.toDate = undefined;
        } else {
            filterOption.businessUnitType = "";
            filterOption.businessUnitName = "";
            filterOption.fromDate = undefined;
            filterOption.toDate = undefined;
            handleCloseFilter()
        }
        getBusinessUnitList(1)
    }

    const setFromDate = (date) => {
        const formFieldsObject = {
            ...filterOption,
            fromDate: date
        }
        setFilterOption(formFieldsObject);
    }

    const dateValidation = (date) => {
        if (filterOption.fromDate) {
            const formFieldsObject = {
                ...filterOption,
                toDate: date
            }
            setFilterOption(formFieldsObject);
        } else {
            addToast('Please select closing from date first', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
        }
    }

    const getData = () => {
        getBusinessUnitList(1)
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <Modal show={cancelModal} onHide={() => handleModal()}  {...props}
                            size=""
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <Modal.Header closeButton>Alert</Modal.Header>
                            <Modal.Body>Are you sure you want to cancel?</Modal.Body>
                            <Modal.Footer>
                                <Button className="btn btn-lg btn-brand-1" onClick={() => handleModal()}>No</Button>
                                <Button className="btn btn-lg btn-brand-1 bg-danger" onClick={() => onModalYesClick()}>Yes</Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Business Units</h2>
                        </div>
                        <div className="col-lg-6 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search by code, name and description" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            <button type="button" className="btn btn-brand-1 btn-h-40 mr-3" onClick={handleShowFilter}><i className="fa fa-filter mr-2" aria-hidden="true"></i> Filter</button>
                            {
                                adding === true ?
                                    <button type="button" className="btn btn-brand-1 btn-h-40 bg-success px-4" onClick={handleShow}>+ Add</button> : null
                            }
                        </div>
                    </div>

                    <div className="mb-2 filter-by">
                        {
                            filterOption.businessUnitName ?
                                <div className="badge">
                                    <span className=" lable">Business Unit Name</span>
                                    <span className="value">{filterOption.businessUnitName}</span>
                                    <i class="icofont-close" onClick={() => _onResetFilter('BUSINESS_UNIT_NAME')}></i>
                                </div> : null
                        }
                        {
                            filterOption.fromDate ?
                                <div className="badge">
                                    <span className=" lable">Date From </span>
                                    <span className="value">{moment(filterOption.fromDate).format('MM-DD-YYYY')}</span>
                                    <i class="icofont-close" onClick={() => _onResetFilter('FROM_DATE')}></i>
                                </div> : null
                        }
                        {
                            filterOption.toDate ?
                                <div className="badge">
                                    <span className=" lable">Date To </span>
                                    <span className="value">{moment(filterOption.toDate).format('MM-DD-YYYY')}</span>
                                    <i class="icofont-close" onClick={() => _onResetFilter('TO_DATE')}></i>
                                </div> : null
                        }


                    </div>
                    <table className="table table-style-1 table-responsive-lg mb-4 table reports" id="reportTable">
                        <thead>
                            <tr>
                                <th scope="col">Code</th>
                                <th scope="col">Name</th>
                                <th scope="col">Effective Date</th>
                                <th scope="col">Description</th>
                                <th scope="col" className="text-center" width="110">{editing === true || deleting === true ? 'Action' : null}</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/business-units/'}
                            updateList={getData}
                        ></ModalPopup>
                        <tbody>
                            {
                                loading === false ? getListData.map((res, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{res.code}</td>
                                            <td>{res.name}</td>
                                            <td>{res.start_date ? moment(res.start_date, 'YYYY-MM-DD').format('MM-DD-YYYY') : null}</td>
                                            <td title={res.description}>{res.description.length > 135 ? `${res.description.substring(0, 135)}...` : res.description}</td>
                                            <td className="text-center">
                                                {
                                                    editing === true ?
                                                        <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                }
                                                {
                                                    deleting === true ?
                                                        <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-trash"></i></button> : null
                                                }
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                    {
                        getListData.length === 0 && loading === false && global.new_key ?
                            <SearchNoDataFound message={'No Business Unit Found'} />
                            : getListData.length === 0 && loading === false ? <SearchNoDataFound message={'No Business Unit Found'} /> : null
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">
                            <Pagination
                                pages={pageCount}
                                endPoint={'/business-units/'}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                            />
                        </nav>
                    </div>

                </div>
            </div>


            {/* Add Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={70.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{id ? 'Edit' : 'Add'} Business Units</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Business Unit Name</label>
                                <input type="text" className="form-control" onChange={handleChange} name="businessUnitName" value={formFields['businessUnitName']} />
                                <span className="text-danger">{flag === true && formFields.businessUnitName === '' ? 'Please Enter Business Unit Name' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Business Unit Code</label>
                                <input type="text" className="form-control" onChange={inputChange} onKeyUp={e => setCodeCount(e.target.value.length)} maxLength={5} name="businessUnitCode" value={formFields['businessUnitCode']} />
                                <div className="d-flex flex-column">
                                    <span className="character-count">{codeCount}/5</span>
                                    <span className="text-danger">{flag === true && formFields.businessUnitCode === '' ? 'Please Enter Business Unit Code' : null}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Effective Start Date</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    minDate={new Date()}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description</label>
                                <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="description" value={formFields['description']}></textarea>
                                <div className="d-flex flex-column">

                                    <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save()} disabled={buttonLoader}>
                        {buttonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>


            {/* Filter Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={186.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleCloseFilter}
                isOpen={showFilter}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Filters</h5>
                    <button type="button" className="close" onClick={handleCloseFilter}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Business Unit Name</label>
                                <input type="text" className="form-control" onChange={handleFilterChange} name="businessUnitName" value={filterOption['businessUnitName']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Date From</label>
                                <DatePicker
                                    selected={filterOption.fromDate}
                                    onChange={(date) => setFromDate(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Date To</label>
                                <DatePicker
                                    selected={filterOption.toDate}
                                    onChange={(date) => dateValidation(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    minDate={filterOption.fromDate}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleCloseFilter}>Cancel</button>
                    <button className="btn btn-lg btn-brand-1" onClick={() => _onResetFilter()}>Reset</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => onFilterSave()} disabled={buttonLoader}>
                        {buttonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Search</span>
                    </button>
                </div>
            </ReactModal>
        </>
    )
}
export default BusinessUnit;