import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import BusinessUnit from '../../Organizational_Setup/Business_Units/BusninessUnit';
import RfxCategories from '../../Organizational_Setup/RFx_Categories/RfxCategories';
import { Style } from 'react-style-tag';
import Role from '../Manage_Roles/Role';
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import { useToasts } from 'react-toast-notifications';
import { useParams } from "react-router-dom";
import ChangePassword from "../../../../components/ChangePassword";
import PhoneInput from 'react-phone-number-input';


const AddNewUser = () => {
    const { id } = useParams();
    const [buttonLoader, setButtonLoader] = useState(false);
    const [formFields, setFormFields] = useState({ firstName: '', lastName: '', email: '', password: '', phone: '', id: '' });
    const [flag, setFlag] = useState(false);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [rolesData, setRolesData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    // Business
    const [business, setBusinees] = useState('')
    const [businessId, setBusinessId] = useState('')
    const [businessIndex, setBusinessIndex] = useState('')
    // Business Default
    const [businessNameDefault, setBusineesNameDefault] = useState('')
    const [businessIdDefault, setBusineesIdDefault] = useState('')
    const [businessIndexDefault, setBusinessIndexDefault] = useState('')
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([])

    // Role
    const [role, setRole] = useState('')
    const [roleId, setRoleId] = useState('')
    const [roleIndex, setRoleIndex] = useState('')
    // Role Default
    const [roleDefault, setRoleDefault] = useState('')
    const [roleIdDefault, setRoleIdDefault] = useState('')
    const [roleIndexDefault, setRoleIndexDefault] = useState('')
    const [selectedRole, setSelectedRole] = useState([])



    // Category
    const [category, setCategory] = useState('')
    const [categoryId, setCategoryId] = useState('')
    const [categoryIndex, setCategoryIndex] = useState('')
    // Category Default
    const [categoryDefault, setCategoryDefault] = useState('')
    const [categoryIdDefault, setCategoryIdDefault] = useState('')
    const [categoryIndexDefault, setCategoryIndexDefault] = useState('')
    const [selectedCategory, setSelectedCategory] = useState([])

    const [busineesCheckbox, setBusinessCheckbox] = useState(false)
    const [categoryCheckbox, setCategoryCheckbox] = useState(false)

    const [showModalPopup, setShowModalPopup] = useState(false);
    const { addToast } = useToasts();
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    useEffect(() => {
        check()
    }, [])


    const check = () => {
        if (window.location.pathname === '/add-user') {
            getBusinessUnitList()
            getRolesList()
            getRFxCategoryList()
        } else {
            var method = 'GET'
            const apifetcherObj = APIMethod(`/users/detail/${id}`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setFormFields({ firstName: data.first_name, lastName: data.last_name, email: data.email, phone: data.phone_no, id: data.id, })
                        setSelectedBusinessUnit(data.user_business_unit)
                        setSelectedRole(data.user_roles)
                        setSelectedCategory(data.rfx_category_list)
                        getBusinessUnitList(data)
                        getRolesList(data)
                        getRFxCategoryList(data)
                    }
                })
                .catch(error => {
                })
        }
    }

    const getBusinessUnitList = (details) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    if (window.location.pathname === '/add-user') {
                        setBusinessUnitData(data)
                    } else {
                        const results = data.filter(({ id: id1 }) => !details.user_business_unit.some(({ business_unit_id: id2 }) => id2 === id1));
                        setBusinessUnitData(results)
                    }
                }
            })
            .catch(error => {
            })
    }

    const getRolesList = (details) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/roles/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    if (window.location.pathname === '/add-user') {
                        setRolesData(data)
                    } else {
                        const results = data.filter(({ id: id1 }) => !details.user_roles.some(({ role_id: id2 }) => id2 === id1));
                        setRolesData(results)
                    }
                }
            })
            .catch(error => {
            })
    }

    const getRFxCategoryList = (details) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    if (window.location.pathname === '/add-user') {
                        setRFxCategoryData(data)
                    } else {
                        const results = data.filter(({ id: id1 }) => !details.rfx_category_list.some(({ rfx_category_id: id2 }) => id2 === id1));
                        setRFxCategoryData(results)
                    }
                }
            })
            .catch(error => {
            })
    }

    const onBusinessUnitTransfer = (name, id, index, type) => {
        setBusinessIndex(index)
        if (type === 'ONLY_PUSH') {
            setBusinees(name)
            setBusinessId(id)
        } else {
            var array = []
            setBusinees(name)
            setBusinessId(id)
            if (business && businessId) {
                selectedBusinessUnit.push({ name: business, business_unit_id: businessId, is_checked: false })
                businessUnitData.splice(businessIndex, 1)
                for (var obj in businessUnitData) {
                    array.push(businessUnitData[obj]);
                }
                setBusinessUnitData(array)
            }
        }
    }

    const onBusinessUnitDefaultTransfer = (name, id, index, type) => {
        setBusinessIndexDefault(index)
        if (type === 'ONLY_PUSH_DEFAULT') {
            setBusineesNameDefault(name)
            setBusineesIdDefault(id)
        } else {
            var array = []
            setBusineesNameDefault(name)
            setBusineesIdDefault(id)
            if (businessNameDefault && businessIdDefault) {
                businessUnitData.push({ name: businessNameDefault, id: businessIdDefault })
                selectedBusinessUnit.splice(businessIndexDefault, 1)
                for (var obj in selectedBusinessUnit) {
                    array.push(selectedBusinessUnit[obj]);
                }
                setSelectedBusinessUnit(array)
            }
        }
    }


    const onRoleTransfer = (name, id, index, type) => {
        setRoleIndex(index)
        if (type === 'ONLY_PUSH') {
            setRole(name)
            setRoleId(id)
        } else {
            var array = []
            setRole(name)
            setRoleId(id)
            if (role && roleId) {
                selectedRole.push({ name: role, role_id: roleId })
                rolesData.splice(roleIndex, 1)
                for (var obj in rolesData) {
                    array.push(rolesData[obj]);
                }
                setRolesData(array)
            }
        }
    }

    const onRoleDefaultTransfer = (name, id, index, type) => {
        setRoleIndexDefault(index)
        if (type === 'ONLY_PUSH_DEFAULT') {
            setRoleDefault(name)
            setRoleIdDefault(id)
        } else {
            var array = []
            setRoleDefault(name)
            setRoleIdDefault(id)
            if (roleDefault && roleIdDefault) {
                rolesData.push({ name: roleDefault, id: roleIdDefault })
                selectedRole.splice(roleIndexDefault, 1)
                for (var obj in selectedRole) {
                    array.push(selectedRole[obj]);
                }
                setSelectedRole(array)
            }
        }
    }


    const onCategoryTransfer = (name, id, index, type) => {
        setCategoryIndex(index)
        if (type === 'ONLY_PUSH') {
            setCategory(name)
            setCategoryId(id)
        } else {
            var array = []
            setCategory(name)
            setCategoryId(id)
            if (category && categoryId) {
                selectedCategory.push({ name: category, rfx_category_id: categoryId, is_checked: false })
                RFxCategoryData.splice(categoryIndex, 1)
                for (var obj in RFxCategoryData) {
                    array.push(RFxCategoryData[obj]);
                }
                setRFxCategoryData(array)
            }
        }
    }

    const onCategoryDefaultTransfer = (name, id, index, type) => {
        setCategoryIndexDefault(index)
        if (type === 'ONLY_PUSH_DEFAULT') {
            setCategoryDefault(name)
            setCategoryIdDefault(id)
        } else {
            var array = []
            setCategoryDefault(name)
            setCategoryIdDefault(id)
            if (categoryDefault && categoryIdDefault) {
                RFxCategoryData.push({ name: categoryDefault, id: categoryIdDefault })
                selectedCategory.splice(categoryIndexDefault, 1)
                for (var obj in selectedCategory) {
                    array.push(selectedCategory[obj]);
                }
                setSelectedCategory(array)
            }
        }
    }


    const buinessChecked = (index) => {
        for (var i in selectedBusinessUnit) {
            if (index == i) {
                selectedBusinessUnit[i].is_checked = true;
            } else {
                selectedBusinessUnit[i].is_checked = false;
            }
        }
    }


    const categoryChecked = (index) => {
        for (var i in selectedCategory) {
            if (index == i) {
                selectedCategory[i].is_checked = true;
            } else {
                selectedCategory[i].is_checked = false;
            }
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const save = () => {
        let checker = arr => arr.every(v => v.is_checked === false);
        if (formFields.firstName === '' || formFields.lastName === '' || formFields.email === '' || formFields.password === '' || formFields.phone === '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (formFields.email === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.email)) {
            addToast('Please enter valid E-mail', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (selectedBusinessUnit.length === 0) {
            addToast('Please add atleast one Business Unit', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (selectedRole.length === 0) {
            addToast('Please add atleast one Role', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (selectedCategory.length === 0) {
            addToast('Please add atleast one RFx Category', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (checker(selectedBusinessUnit) === true) {
            addToast('Please select one default business unit', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (checker(selectedCategory) === true) {
            addToast('Please select one default RFx category', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            if (formFields.id === '') {
                var method = 'POST'
                var params = JSON.stringify({
                    first_name: formFields.firstName,
                    last_name: formFields.lastName,
                    phone_no: formFields.phone,
                    email: formFields.email,
                    password: formFields.password,
                    user_business_unit: selectedBusinessUnit,
                    user_roles: selectedRole,
                    rfx_category_list: selectedCategory
                })
            } else {
                method = 'PUT'
                params = JSON.stringify({
                    id: formFields.id,
                    first_name: formFields.firstName,
                    last_name: formFields.lastName,
                    phone_no: formFields.phone,
                    email: formFields.email,
                    password: formFields.password,
                    user_business_unit: selectedBusinessUnit,
                    user_roles: selectedRole,
                    rfx_category_list: selectedCategory
                })
            }
            setButtonLoader(true)
            const apifetcherObj = APIMethod('/users', params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                        setFormFields({ ...formFields, firstName: '', lastName: '', email: '', password: '', phone: '' })
                        goBack()
                        setFlag(false)
                        setButtonLoader(false)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        }
    }

    
    const handlePhone = (event, type) => {
        const formFieldsObject = {
            ...formFields,
            [type]: event
        }
        setFormFields(formFieldsObject);
    }


    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Manage Security</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>
                                Manage Users
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{window.location.pathname === '/add-user' ? 'Add' : 'Edit'} User</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center">
                        <span className="add-title ">{window.location.pathname === '/add-user' ? 'Add' : 'Edit'} User</span>
                    </div>

                    <div className="form-wraper form-style mb-4">
                        <div className="row">
                            {
                                formFields.id == '' ? null :
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>User ID</label>
                                            <input type="text" className="form-control" style={{ color: 'grey' }} name="id" value={formFields['id']} disabled={true} />
                                        </div>
                                    </div>
                            }
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" name="firstName" value={formFields['firstName']} onChange={handleChange} />
                                    <span className="text-danger">{flag === true && formFields.firstName === '' ? 'Please Enter First Name' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" name="lastName" value={formFields['lastName']} onChange={handleChange} />
                                    <span className="text-danger">{flag === true && formFields.lastName === '' ? 'Please Enter Last Name' : null}</span>
                                </div>
                            </div>
                            {
                                formFields.id == '' ?
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" className="form-control" name="password" value={formFields['password']} onChange={handleChange} />
                                            <span className="text-danger">{flag === true && formFields.password === '' ? 'Please Enter Password' : null}</span>
                                        </div>
                                    </div> : null
                            }

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Email Id</label>
                                    <input type="email" className="form-control" name="email" value={formFields['email']} onChange={handleChange} />
                                    <span className="text-danger">{flag === true && (formFields.email === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.email)) ? 'Please Enter Valid Email Id' : null}</span>
                                    {
                                        formFields.id == '' ? null :
                                            <div className="pull-right">
                                                <a className="change-password" onClick={() => setShowModalPopup(!showModalPopup)}>Change Password</a>
                                            </div>

                                    }
                                </div>
                            </div>
                            <ChangePassword
                                showModalPopup={showModalPopup}
                                onPopupClose={isShowPopup}
                                id={formFields.id}

                            />
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Phone</label>
                                    {/* <input type="text" className="form-control" name="phone" value={formFields['phone']} onChange={handleChange} /> */}
                                    <PhoneInput
                                        placeholder="Enter phone number"
                                        value={formFields.phone}
                                        className="form-control"
                                        onChange={e => handlePhone(e, "phone")}
                                        countrySelectProps={{ unicodeFlags: true }}
                                        defaultCountry="US"
                                        international
                                    />
                                    <span className="text-danger">{flag === true && formFields.phone === '' ? 'Please Enter Phone' : null}</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <hr />
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label>Business Unit</label>
                                    <div className='card card-body slect-items-wrap'>
                                        {
                                            businessUnitData.map((res, i) => {
                                                return (

                                                    business === res.name ?
                                                        <label style={{ backgroundColor: '#034d68', borderRadius: 5, paddingLeft: 5, color: 'white' }}>{res.name}</label>
                                                        :
                                                        <label className='select-item' onClick={() => onBusinessUnitTransfer(res.name, res.id, i, "ONLY_PUSH")}>{res.name}</label>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1 '>
                                <div className='mb-3'>
                                    <button type='button' className='btn btn-brand-1' style={{ minWidth: 50 }} onClick={() => onBusinessUnitTransfer()}>
                                        <i class="icofont-long-arrow-right font-30"></i>
                                    </button>
                                </div>
                                <div>
                                    <button type='button' className='btn btn-outline-brand-1' style={{ minWidth: 50 }} onClick={() => onBusinessUnitDefaultTransfer()}>
                                        <i class="icofont-long-arrow-left font-30"></i>
                                    </button>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label>Assigned Business Unit - Default</label>
                                    <div className='card card-body slect-items-wrap'>
                                        {
                                            selectedBusinessUnit.map((res, j) => {
                                                return (
                                                    businessNameDefault === res.name ?
                                                        <div class="custom-control custom-radio">
                                                            <input type="radio" class="custom-control-input" id={res.name} name="busineesCheckbox" value={busineesCheckbox} onChange={() => buinessChecked(j)} />
                                                            <label class="custom-control-label" for={res.name} style={{ backgroundColor: '#034d68', borderRadius: 5, paddingLeft: 5, color: 'white', width: '100%' }}>
                                                                {res.name}</label>
                                                        </div>
                                                        :
                                                        <div class="custom-control custom-radio">
                                                            <input type="radio" class="custom-control-input" id={res.name} checked={res.is_checked} name="busineesCheckbox" value={busineesCheckbox} onChange={() => buinessChecked(j)} />
                                                            <label class="custom-control-label" for={res.name} onClick={() => onBusinessUnitDefaultTransfer(res.name, res.business_unit_id, j, "ONLY_PUSH_DEFAULT")}>
                                                                {res.name}</label>
                                                        </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label>Role</label>
                                    <div className='card card-body slect-items-wrap'>
                                        {
                                            rolesData.map((res, i) => {
                                                return (
                                                    role === res.name ?
                                                        <label style={{ backgroundColor: '#034d68', borderRadius: 5, paddingLeft: 5, color: 'white' }}>{res.name}</label>
                                                        :
                                                        <label className='select-item' onClick={() => onRoleTransfer(res.name, res.id, i, "ONLY_PUSH")}>{res.name}</label>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1 '>
                                <div className='mb-3'>
                                    <button type='button' className='btn btn-brand-1' style={{ minWidth: 50 }} onClick={() => onRoleTransfer()}>
                                        <i class="icofont-long-arrow-right font-30"></i>
                                    </button>
                                </div>
                                <div>
                                    <button type='button' className='btn btn-outline-brand-1' style={{ minWidth: 50 }} onClick={() => onRoleDefaultTransfer()}>
                                        <i class="icofont-long-arrow-left font-30"></i>
                                    </button>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label>Assigned Role</label>
                                    <div className='card card-body slect-items-wrap'>
                                        {
                                            selectedRole.map((res, j) => {
                                                return (
                                                    roleDefault === res.name ?
                                                        <label style={{ backgroundColor: '#034d68', borderRadius: 5, paddingLeft: 5, color: 'white' }}>{res.name}</label>
                                                        :
                                                        <label className='select-item' onClick={() => onRoleDefaultTransfer(res.name, res.role_id, j, "ONLY_PUSH_DEFAULT")}>{res.name}</label>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label>RFx Category</label>
                                    <div className='card card-body slect-items-wrap'>
                                        {
                                            RFxCategoryData.map((res, i) => {
                                                return (

                                                    category === res.name ?
                                                        <label style={{ backgroundColor: '#034d68', borderRadius: 5, paddingLeft: 5, color: 'white' }}>{res.name}</label>
                                                        :
                                                        <label className='select-item' onClick={() => onCategoryTransfer(res.name, res.id, i, "ONLY_PUSH")}>{res.name}</label>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1 '>
                                <div className='mb-3'>
                                    <button type='button' className='btn btn-brand-1' style={{ minWidth: 50 }} onClick={() => onCategoryTransfer()}>
                                        <i class="icofont-long-arrow-right font-30"></i>
                                    </button>
                                </div>
                                <div>
                                    <button type='button' className='btn btn-outline-brand-1' style={{ minWidth: 50 }} onClick={() => onCategoryDefaultTransfer()}>
                                        <i class="icofont-long-arrow-left font-30"></i>
                                    </button>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label>Assigned Category - Default</label>
                                    <div className='card card-body slect-items-wrap'>
                                        {
                                            selectedCategory.map((res, j) => {
                                                return (
                                                    categoryDefault === res.name ?
                                                        <div class="custom-control custom-radio">
                                                            <input type="radio" class="custom-control-input" id={res.name} name="categoryCheckbox" value={categoryCheckbox} onChange={() => categoryChecked(j)} />
                                                            <label class="custom-control-label" for={res.name} style={{ backgroundColor: '#034d68', borderRadius: 5, paddingLeft: 5, color: 'white', width: '100%' }}>
                                                                {res.name}</label>
                                                        </div>
                                                        :
                                                        <div class="custom-control custom-radio">
                                                            <input type="radio" class="custom-control-input" id={res.name} checked={res.is_checked} name="categoryCheckbox" value={categoryCheckbox} onChange={() => categoryChecked(j)} />
                                                            <label class="custom-control-label" for={res.name} onClick={() => onCategoryDefaultTransfer(res.name, res.rfx_category_id, j, "ONLY_PUSH_DEFAULT")}>
                                                                {res.name}</label>
                                                        </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">


                            <div className="col-12 text-center mt-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={() => goBack()}>Cancel</button>
                                <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save()} disabled={buttonLoader}>
                                    {buttonLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewUser;