import React from "react";
import { Tab } from "react-bootstrap";
const RawRFx = (props) => {
    var url = `https://docs.google.com/gview?url=${props.url}&embedded=true`
    return (
        <Tab.Pane eventKey="RowRFX" style={{ height: 1950 }}>
            <iframe src={url} width={'100%'} height={1950} className="holds-the-iframe"></iframe>
            {/* <object data="http://africau.edu/images/default/sample.pdf" type="application/pdf" width="100%" height="100%">
                <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p>
            </object> */}
        </Tab.Pane>
    )
}
export default RawRFx;