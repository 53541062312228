import React from 'react'

const Pagination = (props) => {

    const pageLinks = []

    const PageChange = (pageNumber) => {
        props.nextPage(pageNumber);
    }



    let currentPage = props.currentPage ? props.currentPage : 1;
    let totalPages = parseInt(props.pages / 10 + (props.pages % 10 === 0 ? 0 : 1));

    let startPage, endPage;
    if (totalPages <= 5) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }
    }
    for (let i = startPage; i <= endPage; i++) {
        let active = currentPage === i ? 'active' : '';
        pageLinks.push(
            <ul key={i} className="pagination justify-content-md-end">
                <li className={`page-item ${active}`} key={i} onClick={() => PageChange(i)}><a className='page-link'>{i}</a></li>
            </ul>
        )
    }


    return (

        <div className="text-md-right">
            {
                props.pageCount <= 10 ?
                    null
                    :
                    <>
                        <div className='row justify-content-md-end' style={{ marginTop: 40, marginRight: 1 }}>
                            <ul className="pagination justify-content-md-end">
                                <li className="page-item" onClick={props.currentPage === 1 ? null : () => PageChange(props.currentPage - 1)}>
                                    <a className="page-link" aria-label="Previous">
                                        <span aria-hidden="true">«</span>
                                    </a>
                                </li>
                            </ul>
                            <ul className='pagination'>
                                {pageLinks}
                            </ul>
                            <ul className="pagination justify-content-md-end">
                                <li className="page-item" onClick={props.currentPage === Math.ceil(props.pageCount / 10) ? null : () => PageChange(props.currentPage + 1)}>
                                    <a className="page-link" aria-label="Next">
                                        <span aria-hidden="true">»</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div style={{color: 'grey'}}>{currentPage} - {totalPages} of {totalPages}</div>
                    </>
            }

        </div>
    )
}

export default Pagination;