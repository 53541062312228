import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { NavLink, generatePath } from "react-router-dom";
import { APIMethod } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import ModalPopup from '../../../../components/ModalPopUp';
import Pagination from '../../../../components/Pagination';
import { useHistory } from "react-router";
import NoData from "../../../../components/NoDataFound";
import moment from "moment";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";


const Role = (props) => {
    const [loading, setLoading] = useState(false);
    const [roleList, setRoleList] = useState([]);
    // Edit
    const [id, setId] = useState('');
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    // page
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // Add modal box
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    const history = useHistory();
    const [adding, setAdding] = useState();
    const [editing, setEditing] = useState();
    const [deleting, setDeleting] = useState();

    useEffect(() => {
        getRoleList(1)
        var data = JSON.parse(localStorage.getItem("user_Role"))
        checkPermission(data)
    }, [])

    const checkPermission = (data) => {
        const resObject = data.find(item => item.name === "Manage Security")
        const valObject = resObject.access.find(item => item.name === "Roles")
        setAdding(valObject.is_create)
        setEditing(valObject.is_update)
        setDeleting(valObject.is_delete)
    }

    const getRoleList = (page, key) => {
        const new_key = key === undefined ? '' : key
        global.new_key = key === undefined ? '' : key
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/roles/list/${page}?keyword=` + new_key, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRoleList(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const nextPage = (pageNumber) => {
        getRoleList(pageNumber)
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const _onEdit = (id) => {
        id && history.push(generatePath("/edit-role/:id", { id }));
    }
    const Search = (key) => {
        getRoleList(1, key)
    }

    const getData = () => {
        getRoleList(1)
    }
    
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Roles</h2>
                        </div>
                        <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search by role name" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            {
                                adding === true ?
                                    <NavLink to="/add-role" className="btn btn-brand-1 bg-success px-4">+ Add</NavLink> : null
                            }

                        </div>
                    </div>

                    <table className="table table-style-1 table-responsive-lg mb-4 table reports" id="reportTable">
                        <thead>
                            <tr>
                                <th scope="col">role name</th>
                                <th scope="col">Effective Date</th>
                                <th scope="col">Description</th>
                                <th scope="col" className="text-center" width="110">{editing === true || deleting === true ? 'Action' : null}</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/roles/'}
                            updateList={getData}
                        ></ModalPopup>
                        <tbody>
                            {
                                loading === false ? roleList.map((res) => {
                                    return (
                                        <tr>
                                            <td>{res.name}</td>
                                            <td>{moment(res.effective_date, "DD/MM/YYYY").format('MM-DD-YYYY')}</td>
                                            <td title={res.description}>{res.description.length > 110 ? `${res.description.substring(0, 110)}...` : res.description}</td>
                                            <td className="text-center">
                                                {
                                                    editing === true ?
                                                    res.id === 73 ? null :
                                                        <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res.id)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                }
                                                {
                                                    deleting === true ?
                                                    res.id === 73 ? null :
                                                        <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-trash"></i></button> : null
                                                }

                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                    {
                        roleList.length === 0 && loading === false && global.new_key ?
                        <SearchNoDataFound message={'No Role Found'}/> 
                            : roleList.length === 0 && loading === false ?  <NoData /> : null
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">
                            <Pagination
                                pages={pageCount}
                                endPoint={'/roles/list/'}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                            />
                        </nav>
                    </div>

                </div>
            </div>

            {/* Add Modal Box */}
            <Modal show={show} onHide={handleClose}
                {...props}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="modal-header px-md-5">
                    <h5 className="modal-title" id="">Add Role</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <Modal.Body>
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Role Name</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Effective Date</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description</label>
                                <textarea className="form-control h-auto" id="" cols="30" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1">Save</button>
                </div>
            </Modal>
        </>
    )
}
export default Role;